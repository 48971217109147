import { useNavigate } from "react-router";
import Icon from "../Icon";
import { GoBack } from "./styles";
import backIcon from "../../assets/icons/arrow-small-left.svg";

interface ComponentProps {}

const Component = ({}: ComponentProps) => {
  // Use this hook to programmatically navigate to another page
  const navigate = useNavigate();

  // This function is used to navigate to the home page
  // It will be called when the button is clicked
  const goBack = () => {
    navigate(-1);
  };

  return (
    <GoBack onClick={() => goBack()}>
      <Icon icon={backIcon} color="black" size="24px" />
    </GoBack>
  );
};

export default Component;
