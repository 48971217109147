import logoWhite from "../assets/images/logo-white.png";
import logoBlack from "../assets/images/logo-black.png";

interface Company {
  name: string;
  primaryColor: string;
  secondaryColor?: string;
  logoWhite: string;
  logoBlack: string;
}

const company: Company = {
  name: "Adecco",
  primaryColor: "#e20014",
  logoWhite,
  logoBlack,
};

export default company;
