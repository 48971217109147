import {
  SelectOnePagoOutDto,
  SelectOnePagoInDto,
} from "../../../types/pago.dto";

export const SELECT_ONE_PAGO = "SELECT_ONE_PAGO";
export const SELECT_ONE_PAGO_SUCCESS = "SELECT_ONE_PAGO_SUCCESS";
export const SELECT_ONE_PAGO_ERROR = "SELECT_ONE_PAGO_ERROR";
export const RESET_SELECT_ONE_PAGO = "RESET_SELECT_ONE_PAGO";

export interface SelectOnePago {
  type: typeof SELECT_ONE_PAGO;
  payload: SelectOnePagoOutDto;
}

export interface SelectOnePagoSuccess {
  type: typeof SELECT_ONE_PAGO_SUCCESS;
  payload: SelectOnePagoInDto;
}

export interface SelectOnePagoError {
  type: typeof SELECT_ONE_PAGO_ERROR;
  payload: string | boolean;
}

export interface ResetSelectOnePago {
  type: typeof RESET_SELECT_ONE_PAGO;
  payload: null;
}

export interface State {
  data: SelectOnePagoInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | SelectOnePago
  | SelectOnePagoSuccess
  | SelectOnePagoError
  | ResetSelectOnePago;
