import { SignInData, SignUpData } from "../../types/auth.dto";
import {
  RECOVER_PASSWORD,
  RecoverPassword,
  SIGN_IN,
  SIGN_UP,
  SIGN_OUT,
  SignIn,
  SignOut,
  SignUp,
  RESET_PASSWORD,
  ResetPassword,
} from "../types/auth";

export const signIn = (value: SignInData): SignIn => {
  return { type: SIGN_IN, payload: value };
};

export const signUp = (value: SignUpData): SignUp => {
  return { type: SIGN_UP, payload: value };
};

export const signOut = (): SignOut => {
  return { type: SIGN_OUT, payload: null };
};

export const recoverPassword = (payload: {
  email: string;
}): RecoverPassword => {
  return { type: RECOVER_PASSWORD, payload };
};

export const resetPassword = (payload: {
  id: string;
  password: string;
}): ResetPassword => {
  return { type: RESET_PASSWORD, payload };
};
