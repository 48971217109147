import * as Yup from "yup";

export const schema = Yup.object().shape({
  comment: Yup.string().required("Ingresa un comentario."),
});

interface FormValues {
  comment: string;
}

export const initialValues: FormValues = {
  comment: "",
};
