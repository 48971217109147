import { useActions } from "../../../../../provider/ActionsProvider";
import { Filters } from "./styles";
import { useLayout } from "../../../../../provider/LayoutProvider";

const Component = () => {
  const { layout } = useLayout();
  const { filters } = useActions();

  if (!layout.showActions) {
    return null;
  }

  return <Filters>{filters}</Filters>;
};

export default Component;
