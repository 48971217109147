import React, { useContext, useEffect, useState } from "react";

declare global {
  interface Document {
    mozFullScreen: any;
    webkitIsFullScreen: any;
    cancelFullScreen;
    mozCancelFullScreen;
    webkitCancelFullScreen;
  }

  interface HTMLElement {
    mozRequestFullScreen: any;
    webkitRequestFullScreen: any;
  }
}

interface Layout {
  showSubHeader: boolean;
  showTitle: boolean;
  showBackButton: boolean;
  title: string;
  breadcrumb: string;
  showBreadcrumb: boolean;
  showActions: boolean;
  sidebarOpened: boolean;
  fullFillContent: boolean;
}

const initialValues: Layout = {
  showSubHeader: true,
  showTitle: true,
  showBackButton: false,
  title: "",
  breadcrumb: "",
  showBreadcrumb: true,
  showActions: true,
  sidebarOpened: true,
  fullFillContent: false,
};

interface ContexInitialValuesDto {
  layout: Layout;
  setLayout: React.Dispatch<React.SetStateAction<Layout>>;
  resetLayout: () => void;
  toggleFullScren: () => void;
}

const contexInitialValues: ContexInitialValuesDto = {
  layout: initialValues,
  setLayout: () => {},
  resetLayout: () => {},
  toggleFullScren: () => {},
};

export const LayoutProvidersContext = React.createContext(contexInitialValues);

export const useLayout = () => useContext(LayoutProvidersContext);

const LayoutProvider = ({ children, useLocation }) => {
  const [layout, setLayout] = useState<Layout>(initialValues);
  const { pathname } = useLocation();

  const resetLayout = () => {
    setLayout(initialValues);
  };

  const toggleFullScren = () => {
    if (!document.fullscreenElement) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen();
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  };

  useEffect(() => {
    resetLayout();
  }, [pathname]);

  return (
    <LayoutProvidersContext.Provider
      value={{
        layout,
        setLayout,
        toggleFullScren,
        resetLayout,
      }}
    >
      {children}
    </LayoutProvidersContext.Provider>
  );
};

export default LayoutProvider;
