import {
  DeleteJudicialOutDto,
  DeleteJudicialInDto,
} from "../../../types/judicial.dto";

export const DELETE_JUDICIAL = "DELETE_JUDICIAL";
export const DELETE_JUDICIAL_SUCCESS = "DELETE_JUDICIAL_SUCCESS";
export const DELETE_JUDICIAL_ERROR = "DELETE_JUDICIAL_ERROR";
export const RESET_DELETE_JUDICIAL = "RESET_DELETE_JUDICIAL";

export interface DeleteJudicial {
  type: typeof DELETE_JUDICIAL;
  payload: DeleteJudicialOutDto;
}

export interface DeleteJudicialSuccess {
  type: typeof DELETE_JUDICIAL_SUCCESS;
  payload: DeleteJudicialInDto;
}

export interface DeleteJudicialError {
  type: typeof DELETE_JUDICIAL_ERROR;
  payload: string | boolean;
}

export interface ResetDeleteJudicial {
  type: typeof RESET_DELETE_JUDICIAL;
  payload: null;
}

export interface State {
  data: DeleteJudicialInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | DeleteJudicial
  | DeleteJudicialSuccess
  | DeleteJudicialError
  | ResetDeleteJudicial;
