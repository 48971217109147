import styled from "styled-components";

export const GoBack = styled("button")`
  background: none;
  border: 0px;
  padding: 0px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-right: 12px;
  text-decoration: none;
  cursor: pointer;
  color: ${(props) => props.theme.colors.Purple};
  display: block;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  span {
    margin-left: 6px;
  }
`;
