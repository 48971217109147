import {
  NewActivityOutDto,
  NewActivityInDto,
} from "../../../types/activity.dto";

export const NEW_ACTIVITY = "NEW_ACTIVITY";
export const NEW_ACTIVITY_SUCCESS = "NEW_ACTIVITY_SUCCESS";
export const NEW_ACTIVITY_ERROR = "NEW_ACTIVITY_ERROR";
export const RESET_NEW_ACTIVITY = "RESET_NEW_ACTIVITY";

export interface NewActivity {
  type: typeof NEW_ACTIVITY;
  payload: NewActivityOutDto;
}

export interface NewActivitySuccess {
  type: typeof NEW_ACTIVITY_SUCCESS;
  payload: NewActivityInDto;
}

export interface NewActivityError {
  type: typeof NEW_ACTIVITY_ERROR;
  payload: string | boolean;
}

export interface ResetNewActivity {
  type: typeof RESET_NEW_ACTIVITY;
  payload: null;
}

export interface State {
  data: NewActivityInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | NewActivity
  | NewActivitySuccess
  | NewActivityError
  | ResetNewActivity;
