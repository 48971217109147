import styled from "styled-components";

export const Comments = styled("div")`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const CommentsList = styled("ol")`
  padding: 0px;
  margin: 0px;
  border: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 10px 0px;
`;

export const Comment = styled("li")`
  list-style: none;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const CommentContent = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  padding-bottom: 15px;
`;

export const Text = styled("div")`
  background-color: #e9edf1;
  padding: 10px;
  border-radius: 8px;
  color: black;
  font-size: 14px;
  line-height: 16px;
`;
export const Whom = styled("div")`
  color: black;
  line-height: 16px;
  font-size: 12px;
  padding: 2px 10px;
  // padding-top: 5px;
  // margin-top: 10px;
  // border-top: 1px solid #0000001f;
`;

export const ExtraInformation = styled("div")`
  line-height: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Date = styled("span")`
  font-size: 10px;
`;

export const NewComment = styled("div")``;

export const Form = styled("form")`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  h3 {
    margin: 0px;
  }
`;

export const FullHeight = styled("div")`
  height: 100%;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;

  div {
    height: 100%;

    textarea {
      height: 100% !important;
    }
  }
`;
