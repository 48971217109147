import {
  DeleteFacturacionOutDto,
  DeleteFacturacionInDto,
} from "../../../types/facturacion.dto";

export const DELETE_FACTURACION = "DELETE_FACTURACION";
export const DELETE_FACTURACION_SUCCESS = "DELETE_FACTURACION_SUCCESS";
export const DELETE_FACTURACION_ERROR = "DELETE_FACTURACION_ERROR";
export const RESET_DELETE_FACTURACION = "RESET_DELETE_FACTURACION";

export interface DeleteFacturacion {
  type: typeof DELETE_FACTURACION;
  payload: DeleteFacturacionOutDto;
}

export interface DeleteFacturacionSuccess {
  type: typeof DELETE_FACTURACION_SUCCESS;
  payload: DeleteFacturacionInDto;
}

export interface DeleteFacturacionError {
  type: typeof DELETE_FACTURACION_ERROR;
  payload: string | boolean;
}

export interface ResetDeleteFacturacion {
  type: typeof RESET_DELETE_FACTURACION;
  payload: null;
}

export interface State {
  data: DeleteFacturacionInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | DeleteFacturacion
  | DeleteFacturacionSuccess
  | DeleteFacturacionError
  | ResetDeleteFacturacion;
