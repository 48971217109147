import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import { Card, Center } from "../../../../../components/StyledComponents";
import Grid from "../../../../../components/Grid";
import Button from "../../../../../components/Button";
import Input from "../../../../../components/Form/Input";
import Submit from "../../../../../components/Form/Submit";
import {
  initialValues,
  schema,
} from "../../../../../constants/form/profile/edit";
import { hideModal } from "../../../../../store/actions/modal";
import { Action, useActions } from "../../../../../provider/ActionsProvider";
import { UserDto } from "../../../../../types/user.dto";
import {
  resetUpdateUserInformation,
  updateUserInformation,
} from "../../../../../store/actions/user";
import { toast } from "react-toastify";

interface ComponentProps {
  userInformation: UserDto;
  userInformationStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  updateUserInformationStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({
  userInformation,
  userInformationStates,
  updateUserInformationStates,
}: ComponentProps) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    dispatch(
      updateUserInformation({ ...values, itemId: userInformation.itemId })
    );

    setFormActions(actions);
  };

  useEffect(() => {
    if (updateUserInformationStates.success) {
      toast.success("👌🏼 Datos personales actualizados");
      setTimeout(() => {
        formActions?.resetForm();
        dispatch(resetUpdateUserInformation());
      }, 1000);
    }

    if (updateUserInformationStates.error) {
      toast.error(`😱 No se ha podido actualizar los datos personales`);
      setTimeout(() => {
        dispatch(resetUpdateUserInformation());
      }, 2000);
    }
  }, [updateUserInformationStates]);

  return (
    <>
      <h2>Datos personales</h2>
      <Card>
        <Formik
          initialValues={{
            ...initialValues,
            ...(!!userInformation && {
              nombre: userInformation.nombre,
              apellido: userInformation.apellido,
            }),
          }}
          onSubmit={(values, actions) => {
            if (onSubmit) onSubmit({ values, actions });
          }}
          validateOnChange={formSubmmited}
          validateOnBlur={false}
          validationSchema={schema}
          enableReinitialize
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <form
                className="theme-form"
                onSubmit={(event) => {
                  setFormSubmmited(true);
                  handleSubmit(event);
                }}
                id="change-information"
              >
                <Grid.Row>
                  <Grid.Col>
                    <Input
                      name="nombre"
                      error={errors["nombre"]}
                      touched={touched["nombre"]}
                      value={values["nombre"]}
                      type="nombre"
                      placeholder=""
                      disabled={
                        userInformationStates.loading ||
                        updateUserInformationStates.loading
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        label: "Nombre *",
                        marginBottom: 24,
                      }}
                    />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col>
                    <Input
                      name="apellido"
                      error={errors["apellido"]}
                      touched={touched["apellido"]}
                      value={values["apellido"]}
                      type="apellido"
                      placeholder=""
                      disabled={
                        userInformationStates.loading ||
                        updateUserInformationStates.loading
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        label: "Apellido *",
                        marginBottom: 24,
                      }}
                    />
                  </Grid.Col>
                </Grid.Row>

                <div style={{ height: "40px" }} />

                <Center horizontal="center" vertical="center">
                  <Submit
                    isSubmmiting={updateUserInformationStates.loading}
                    form="change-information"
                    color="Primary"
                    options={{
                      type: "filled",
                      skin: "primary",
                      size: "lg",
                      marginBottom: "0px",
                    }}
                  >
                    Confirmar
                  </Submit>

                  <Button
                    onClick={resetForm}
                    type="button"
                    options={{
                      type: "outline",
                      skin: "danger",
                      size: "lg",
                      marginBottom: "0px",
                    }}
                    style={{ marginLeft: "10px" }}
                  >
                    Cancelar
                  </Button>
                </Center>
              </form>
            );
          }}
        </Formik>
      </Card>
    </>
  );
};

const states = ({ userStore }) => {
  const { data: userInformation, states: userInformationStates } =
    userStore.information;
  const { states: updateUserInformationStates } = userStore.updateInformation;
  return {
    userInformation,
    userInformationStates,
    updateUserInformationStates,
  };
};

export default connect(states)(Component);
