import action from "../helpers/rest-client";

export class ErrorPrejudicial extends Error {}

class Prejudicial {
  public new(payload) {
    return action
      .Post({
        url: "/prejudiciales",
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public edit({ itemId, ...rest }) {
    return action
      .Patch({
        url: `/prejudiciales/${itemId}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getAll({ page, limit, filters }) {
    const queryString = new URLSearchParams(filters).toString();
    const offset = page * limit;

    return action
      .Get({
        url: `/prejudiciales?offset=${offset}&limit=${limit}${
          !!queryString ? `&${queryString}` : ""
        }`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getOne({ itemId }) {
    return action
      .Get({
        url: `/prejudiciales/${itemId}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public delete({ itemId }) {
    return action
      .Del({
        url: `/prejudiciales/${itemId}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getFiles({ itemId }) {
    return action
      .Get({
        url: `/prejudiciales/files/${itemId}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getComments({ itemId }) {
    return action
      .Get({
        url: `/prejudiciales/comments/${itemId}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getStatics() {
    return action
      .Get({
        url: "/prejudiciales/static",
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const prejudicial = new Prejudicial();
export default prejudicial;
