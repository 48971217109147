import styled from "styled-components";

export const Dropzone = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  border: 1px dashed #bdbdbd;

  border-radius: 16px;
  color: #bdbdbd;
  outline: none;

  width: 100%;
  height: 173px;
  margin-bottom: 16px;
  cursor pointer;

  span {
    display: block;
    line-height: 16px;
    min-height: 16px;
  }
`;

export const Aside = styled("aside")`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 4px;
`;

export const Card = styled("div")`
  border-radius: 16px;
  overflow: hidden;
  flex: calc(50% - 2px);
  max-width: calc(50% - 2px);
`;

export const Thumb = styled("div")<{ background }>`
  ${({ background }) =>
    !!background ? `background-image: url(${background});` : ""}
  background-position: center;
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 16px;
  position: relative;
  width: 100%;
  height: 97px;
  border: 1px solid #dbdbdb;
`;

export const ThumbInner = styled("div")`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

export const Delete = styled("button")`
  padding: 0px;
  margin: 0px;
  border: none;
  color: white;
  width: 36px;
  height: 36px;
  background-color: white;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
`;

export const Error = styled("p")`
  color: red;
  font-size: 12px;
  margin-top: 0px;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const DropzoneLabel = styled("label")`
  margin-bottom: 32px;
  background-color: ${({ theme }) => theme.colors.Purple};
  font-size: 14px;
  color: white;
  font-weight: bold;
  height: 36px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0px 24px;
  border-radius: 24px;
  cursor: pointer;
`;

export const Message = styled("p")`
  margin: 0px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin: 0px;
  text-align: center;
`;

export const Scroll = styled("div")`
  max-height: 97px;
  overflow: auto;
`;
