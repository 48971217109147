import { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Icon from "../../../../Icon";
import fileInbox from "../../../../../assets/icons/file-inbox.svg";
import trash from "../../../../../assets/icons/delete.svg";
import constants from "../../../../../constants";
import { AttachedFile } from "../../../../../types/file.dto";
import {
  Dropzone,
  DropzoneLabel,
  Aside,
  Thumb,
  ThumbInner,
  Delete,
  Error,
  Card,
  Message,
  Scroll,
} from "./styles";
import slugify from "slugify";

interface DropzoneFile extends AttachedFile {
  slug?: string;
  preview?: any;
  loading?: string;
}

interface DropzoneComponent {
  hideDroppedFiles?: boolean; //Se agrego para formularios en formik ya que se duplicaba la imagen cargada
  showLoadFileCta?: boolean;
  defaultFiles?: File[] | any;
  onChange?: (files) => void;
  onRemoveItem?: (event, file, newFiles) => void;
  labelButton?: string;
  maxFileSize?: number;
  maxFilesNumber?: number;
  aceptedExtensions?: typeof constants.fileUploader.aceptedMimes;
  disabled?: boolean;
}

const Basic = ({
  showLoadFileCta = true,
  defaultFiles = [],
  onRemoveItem,
  onChange,
  labelButton,
  maxFileSize = 150000000,
  maxFilesNumber = 3,
  aceptedExtensions = constants.fileUploader.aceptedMimes,
  hideDroppedFiles,
  disabled,
}: DropzoneComponent) => {
  const [preloadedFiles, setPreloadedFiles] = useState<DropzoneFile[]>([]);
  const [invalidTypeError, setInvalidTypeError] = useState<boolean>(false);
  const [tooManyFilesError, setTooManyFilesError] = useState<boolean>(false);
  const [tooLargeFileError, setTooLargeFileError] = useState<boolean>(false);

  const isValid = (acceptedFiles) => {
    setTooManyFilesError(false);
    setInvalidTypeError(false);

    const mergedFiles = acceptedFiles.concat(preloadedFiles);
    const tooManyFiles = mergedFiles.length > maxFilesNumber;

    const tooLargeFile =
      mergedFiles.filter((file) => {
        return file.size > maxFileSize;
      }).length > 0;

    const invalidType =
      mergedFiles.filter((file) => {
        return !aceptedExtensions.includes(file.type || file.mime);
      }).length > 0;

    setTooManyFilesError(tooManyFiles);
    setInvalidTypeError(invalidType);
    setTooLargeFileError(tooLargeFile);

    return !(invalidType || tooManyFiles || tooLargeFile);
  };

  const onDrop = (acceptedFiles) => {
    if (isValid(acceptedFiles)) {
      const mergedFiles = acceptedFiles.concat(preloadedFiles);

      const newFiles = mergedFiles.map((file) => {
        if (file.url) {
          return file;
        } else {
          return Object.assign(file, {
            preview:
              URL.createObjectURL(file) ||
              constants.fileUploader.icons[file.type || file.mime],
          });
        }
      });

      setPreloadedFiles([...newFiles]);

      if (!!onChange) {
        onChange(newFiles);
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  useEffect(() => {
    if (defaultFiles.length > 0) {
      setPreloadedFiles(defaultFiles);
    }
  }, [defaultFiles]);

  return (
    <section className="container" style={{ width: "100%" }}>
      {showLoadFileCta && (
        <DropzoneLabel htmlFor="dropzone-input">{labelButton}</DropzoneLabel>
      )}
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} id="dropzone-input" />
        <Dropzone>
          <Icon icon={fileInbox} color="#DBDBDB" size="48px" />
          {invalidTypeError && <Error>Archivo no soportado.</Error>}
          <Message>
            Arrastra tu archivo o haz
            <br /> click aquí <br /> (Restan{" "}
            {maxFilesNumber - preloadedFiles.length})
          </Message>
          {tooManyFilesError && (
            <Error>
              Máximo {maxFilesNumber}{" "}
              {`archivo${maxFilesNumber > 1 ? "s" : ""}`}.
            </Error>
          )}
          {tooLargeFileError && (
            <Error>
              Archivo muy pesado.
              <br />
              Máximo {maxFileSize / 1000000}Mb
            </Error>
          )}
        </Dropzone>
      </div>

      <Scroll>
        <Aside>
          {/* {!hideDroppedFiles &&
          files.length > 0 &&
          files?.map((file, index) => (
            <Card key={slugify(`${file.name}-${index}`, { lower: true })}>
              <Thumb background={file.preview}>
                <ThumbInner>
                  <Delete
                    type="button"
                    onClick={(event) => {
                      const newFiles = [...files];
                      newFiles.splice(newFiles.indexOf(file), 1);
                      setFiles(newFiles);

                      if (onRemoveItem) {
                        onRemoveItem(event, file);
                      }
                    }}
                  >
                    <Icon icon={trash} color="black" size="16px" />
                  </Delete>
                </ThumbInner>
              </Thumb>
            </Card>
          ))} */}

          {preloadedFiles.length > 0 &&
            preloadedFiles?.map((file, index) => (
              <Card key={slugify(`${file?.name}-${index}`, { lower: true })}>
                <Thumb
                  background={
                    file?.preview ||
                    file?.url ||
                    constants.fileUploader.icons[file?.mime]
                  }
                >
                  <ThumbInner>
                    <Delete
                      type="button"
                      onClick={(event) => {
                        const newFiles = preloadedFiles.filter(
                          (file, fileIndex) => fileIndex !== index
                        );

                        setPreloadedFiles(newFiles);

                        if (onRemoveItem) {
                          onRemoveItem(event, file, newFiles);
                        }
                      }}
                    >
                      <Icon icon={trash} color="black" size="16px" />
                    </Delete>
                  </ThumbInner>
                </Thumb>
              </Card>
            ))}
        </Aside>
      </Scroll>
    </section>
  );
};

export default Basic;
