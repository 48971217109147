import styled from "styled-components";

export const Logo = styled("div")`
  width: 100%;
  height: 60px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.Primary};
`;

export const Image = styled("img")`
  max-width: 100%;
  max-height: 100%;
`;
