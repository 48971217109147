import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Grid from "../../../../components/Grid";
import { Tag } from "../../../../components/StyledComponents";
import Table from "../../../../components/Table";
import { Action, useActions } from "../../../../provider/ActionsProvider";
import { getAllJudiciales } from "../../../../store/actions/judicial";
import {
  getAllFacturaciones,
  getOneFacturacion,
  getStatics,
} from "../../../../store/actions/facturacion";
import Filters from "./components/Filters";
import { GetAllJudicialesInDto } from "../../../../types/judicial.dto";
import Tabs from "../../../../components/OtherContent";
import { dateSpliter } from "../../../../helpers/data-handler";
import { GetAllFacturacionesInDto } from "../../../../types/facturacion.dto";
import { argentinaPesos } from "../../../../helpers/loca-currency";
import { useLayout } from "../../../../provider/LayoutProvider";

interface ComponentProps {
  facturaciones: GetAllFacturacionesInDto;
  facturacionesStates: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
  facturacionStaticsStates: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
  facturacionStatics;
}

const Component = ({
  facturaciones,
  facturacionesStates,
  facturacionStatics,
  facturacionStaticsStates,
}: ComponentProps) => {
  const { layout, setLayout } = useLayout();
  const { setActions, setFilters } = useActions();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeFilters, setActiveFilters] = useState();

  const actions = useMemo(
    (): Action[] => [
      {
        component: "link",
        label: "Nueva solicitud",
        href: "/dashboard/facturacion/new",
        options: {
          type: "filled",
          size: "lg",
          skin: "primary",
        },
      },
    ],
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: "Título",
        accessor: "title",
      },
      {
        Header: "Tipo",
        Cell: ({ value }) => {
          if (!!value) {
            return (
              facturacionStatics?.categories["tipo"]?.filter(
                (etapa) => etapa.id === value[0]
              )[0].text || ""
            );
          }
        },
        accessor: "tipo",
      },
      {
        Header: "Nro. OP",
        accessor: "nro-op",
      },
      {
        Header: "Fecha Origen",
        Cell: ({ value }) => {
          if (!!value) {
            const dateSplitted = dateSpliter(value);
            return `${dateSplitted?.day?.name.short} ${dateSplitted?.day?.number} de ${dateSplitted?.month?.name?.short} del ${dateSplitted?.year}`;
          }
        },
        accessor: "fecha-orig-start",
      },
      {
        Header: "Concepto",
        Cell: ({ value }) => {
          if (!!value) {
            return (
              facturacionStatics?.categories["concepto"]?.filter(
                (etapa) => etapa.id === value[0]
              )[0].text || ""
            );
          }
        },
        accessor: "concepto",
      },
      {
        Header: "Importe",
        Cell: ({ value }) => {
          if (!!value) {
            return argentinaPesos(value, { showCurrency: true });
          }
        },
        accessor: "importe-total-honorarios",
      },
      {
        Header: "Estado",
        Cell: ({ value }) => {
          if (!!value) {
            const item = facturacionStatics?.categories["status"]?.filter(
              (etapa) => etapa.id === value
            )[0];

            return (
              <Tag
                style={{
                  ...(!!item?.color && { backgroundColor: "#" + item?.color }),
                }}
              >
                {item?.text}
              </Tag>
            );
          }
        },
        accessor: "status",
      },
    ],
    [facturacionStatics]
  );

  const getElements = ({ page, limit }) => {
    dispatch(getAllFacturaciones({ page, limit, filters: activeFilters }));
  };

  const getElement = (row) => {
    navigate(`/dashboard/facturacion/${row.original["item-id"]}`);
  };

  useEffect(() => {
    setActions(actions);
    // setFilters(<Filters setActiveFilters={setActiveFilters} />);
    !facturacionStatics && dispatch(getStatics());
    setLayout({ ...layout, showBackButton: false });
  }, [facturaciones]);

  return (
    <Grid.ContainerFluid>
      <Grid.Row>
        <Grid.Col>
          <Table
            columns={columns}
            data={facturaciones?.hints}
            pagination={facturaciones?.pagination}
            onRowClick={(row, index) => getElement(row)}
            fetchData={(data) => getElements(data)}
            loading={facturacionesStates?.loading || !facturaciones}
          />
        </Grid.Col>
      </Grid.Row>
    </Grid.ContainerFluid>
  );
};

const states = ({ facturacionStore }) => {
  const { data: facturaciones, states: facturacionesStates } =
    facturacionStore.all;
  const { data: facturacionStatics, states: facturacionStaticsStates } =
    facturacionStore.statics;

  return {
    facturaciones,
    facturacionesStates,
    facturacionStaticsStates,
    facturacionStatics,
  };
};

export default connect(states)(Component);
