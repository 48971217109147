import { Ring } from "./styles";

interface ComponentProps {
  size: number;
}
const Component = ({ size }: ComponentProps) => {
  return (
    <div
      style={{
        width: size + "px",
        height: size + "px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Ring size={size} />
    </div>
  );
};

export default Component;
