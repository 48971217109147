import { EditPagoOutDto, EditPagoInDto } from "../../../types/pago.dto";

export const EDIT_PAGO = "EDIT_PAGO";
export const EDIT_PAGO_SUCCESS = "EDIT_PAGO_SUCCESS";
export const EDIT_PAGO_ERROR = "EDIT_PAGO_ERROR";
export const RESET_EDIT_PAGO = "RESET_EDIT_PAGO";

export interface EditPago {
  type: typeof EDIT_PAGO;
  payload: EditPagoOutDto;
}

export interface EditPagoSuccess {
  type: typeof EDIT_PAGO_SUCCESS;
  payload: EditPagoInDto;
}

export interface EditPagoError {
  type: typeof EDIT_PAGO_ERROR;
  payload: string | boolean;
}

export interface ResetEditPago {
  type: typeof RESET_EDIT_PAGO;
  payload: null;
}
export interface State {
  data: EditPagoInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | EditPago
  | EditPagoSuccess
  | EditPagoError
  | ResetEditPago;
