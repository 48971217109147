import {
  NewActivityOutDto,
  EditActivityOutDto,
  DeleteActivityOutDto,
  GetAllActivitiesOutDto,
  GetOneActivityOutDto,
} from "../../types/activity.dto";

import {
  NEW_ACTIVITY,
  NewActivity,
  RESET_NEW_ACTIVITY,
  ResetNewActivity,
} from "../types/activity/new";

import {
  EDIT_ACTIVITY,
  EditActivity,
  RESET_EDIT_ACTIVITY,
  ResetEditActivity,
} from "../types/activity/edit";

import {
  DELETE_ACTIVITY,
  RESET_DELETE_ACTIVITY,
  ResetDeleteActivity,
  DeleteActivity,
} from "../types/activity/delete";

import {
  GET_ONE_ACTIVITY,
  GetOneActivity,
  RESET_GET_ONE_ACTIVITY,
  ResetGetOneActivity,
} from "../types/activity/getOne";

import {
  GET_ALL_ACTIVITIES,
  GetAllActivities,
  RESET_GET_ALL_ACTIVITIES,
  ResetGetAllActivities,
} from "../types/activity/getAll";

export const getOneActivity = (
  payload: GetOneActivityOutDto
): GetOneActivity => {
  return { type: GET_ONE_ACTIVITY, payload };
};

export const resetGetOneActivity = (): ResetGetOneActivity => {
  return { type: RESET_GET_ONE_ACTIVITY, payload: null };
};

export const getAllActivities = (
  payload: GetAllActivitiesOutDto
): GetAllActivities => {
  console.log("getAllActivities");
  return { type: GET_ALL_ACTIVITIES, payload };
};

export const resetGetAllActivities = (): ResetGetAllActivities => {
  return { type: RESET_GET_ALL_ACTIVITIES, payload: null };
};

export const newActivity = (payload: NewActivityOutDto): NewActivity => {
  return { type: NEW_ACTIVITY, payload };
};

export const resetNewActivity = (): ResetNewActivity => {
  return { type: RESET_NEW_ACTIVITY, payload: null };
};

export const editActivity = (payload: EditActivityOutDto): EditActivity => {
  return { type: EDIT_ACTIVITY, payload };
};

export const resetEditActivity = (): ResetEditActivity => {
  return { type: RESET_EDIT_ACTIVITY, payload: null };
};

export const deleteActivity = (
  payload: DeleteActivityOutDto
): DeleteActivity => {
  return { type: DELETE_ACTIVITY, payload };
};

export const resetDeleteActivity = (): ResetDeleteActivity => {
  return { type: RESET_DELETE_ACTIVITY, payload: null };
};
