import styled from "styled-components";

export const Breadcrumb = styled("div")`
  ol {
    padding: 0px;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    li + li:before {
      padding: 0 5px;
      color: #ccc;
      content: "/";
    }

    li {
      list-style: none;
      font-size: 13px;
      color: #90999c;

      & > * {
        font-size: 13px;
        color: #90999c;
        text-decoration: none;
      }
    }
  }
`;
