import styled, { keyframes } from "styled-components";
import { Field as UiField } from "../Field";

const checkboxCheck = keyframes`
  0%{
    width: 0;
    height: 0;
    border-color: white;
    transform: translate3d(0,0,0) rotate(45deg);
  }
  33%{
    width: 8px;
    height: 10px;
    transform: translate3d(-4px,-8px,0) rotate(45deg);
  }
  100%{    
    width: 8px;
    height: 16px;    
    border-color: white;
    transform: translate3d(-8px,-15px,0) rotate(45deg);
}`;

export const Field = styled(UiField)`
  border: none;
  background: transparent;
  padding: 0;
  flex-direction: column;
  align-items: start;
`;

export const Checkbox = styled("div")<{ size }>`
  display: flex;
  position: relative;
  flex-direction: column;
  min-height: ${(props) => (props.size === "small" ? "16px" : "20px")};
  min-width: ${(props) => (props.size === "small" ? "16px" : "20px")};
`;

export const Input = styled("input")<{ size; theme }>`
  display: none;
  position: relative;
  height: ${(props) => (props.size === "small" ? "16px" : "20px")};
  width: ${(props) => (props.size === "small" ? "16px" : "20px")} !important;

  &:not(:disabled) {
    &:focus ~ label > span,
    &:active ~ label > span {
      border: 2px solid ${(props) => props.theme.colors.PrimaryLight};
      background: transparent;
    }

    &:checked ~ label > span {
      border: 1px solid ${(props) => props.theme.colors.Primary};
      background-color: ${(props) => props.theme.colors.Primary};

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        zoom: 0.75;
        border-radius: 1.5px;
        border-right: 3px solid transparent;
        border-bottom: 3px solid transparent;
        transform: rotate(45deg) translate(-50%, -50%);
        transform-origin: 0% 100%;
        animation: ${checkboxCheck} 125ms 250ms cubic-bezier(0.4, 0, 0.23, 1)
          forwards;
      }
    }
  }

  &:disabled {
    & ~ span {
      border: 2px solid rgba(0, 0, 24, 0.08);
    }
  }
`;

export const OptionLabel = styled("label")<{
  disabled;
  size;
  withLabel;
  theme;
}>`
  position: relative;
  display: flex;
  align-items: flex-start;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  color: ${(props) =>
    props.disabled ? "rgba(0, 0, 24, 0.16)" : "rgba(0, 0, 24, 0.84)"};
  min-height: ${(props) => (props.size === "small" ? "16px" : "20px")};
  z-index: 2;

  font-size: ${(props) => props.theme.form.size[props.size].mobileFontSize};
  line-height: ${(props) => props.theme.form.size[props.size].mobileLineHeight};

  @media (min-width: 768px) {
    font-size: ${(props) => props.theme.form.size[props.size].fontSize};
    line-height: ${(props) => props.theme.form.size[props.size].lineHeight};
  }
`;

export const Checkmark = styled("span")<{ size; theme }>`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  width: 19px;
  height: 19px;
  background: transparent;
  border: 1px solid #9e9e9e;
  border-radius: 4px;
  cursor: pointer;
  transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
`;
