import {
  initialState,
  Actions,
  State,
  GET_ONE_PAGO,
  GET_ONE_PAGO_SUCCESS,
  GET_ONE_PAGO_ERROR,
  RESET_GET_ONE_PAGO,
} from "../../types/pago/getOne";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ONE_PAGO:
      return {
        ...state,
        data: null,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case GET_ONE_PAGO_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case GET_ONE_PAGO_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: true,
          error: action.payload,
          success: false,
        },
      };
    case RESET_GET_ONE_PAGO:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducers;
