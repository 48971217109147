import {
  NewCalendarOutDto,
  NewCalendarInDto,
} from "../../../types/calendar.dto";

export const NEW_CALENDAR = "NEW_CALENDAR";
export const NEW_CALENDAR_SUCCESS = "NEW_CALENDAR_SUCCESS";
export const NEW_CALENDAR_ERROR = "NEW_CALENDAR_ERROR";
export const RESET_NEW_CALENDAR = "RESET_NEW_CALENDAR";

export interface NewCalendar {
  type: typeof NEW_CALENDAR;
  payload: NewCalendarOutDto;
}

export interface NewCalendarSuccess {
  type: typeof NEW_CALENDAR_SUCCESS;
  payload: NewCalendarInDto;
}

export interface NewCalendarError {
  type: typeof NEW_CALENDAR_ERROR;
  payload: string | boolean;
}

export interface ResetNewCalendar {
  type: typeof RESET_NEW_CALENDAR;
  payload: null;
}

export interface State {
  data: NewCalendarInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | NewCalendar
  | NewCalendarSuccess
  | NewCalendarError
  | ResetNewCalendar;
