export const GET_STATICS_JUDICIAL = "GET_STATICS_JUDICIAL";
export const GET_STATICS_JUDICIAL_SUCCESS = "GET_STATICS_JUDICIAL_SUCCESS";
export const GET_STATICS_JUDICIAL_ERROR = "GET_STATICS_JUDICIAL_ERROR";
export const RESET_GET_STATICS_JUDICIAL = "RESET_GET_STATICS_JUDICIAL";

export interface GetStaticsJudicial {
  type: typeof GET_STATICS_JUDICIAL;
  payload: null;
}

export interface GetStaticsJudicialSuccess {
  type: typeof GET_STATICS_JUDICIAL_SUCCESS;
  payload: any;
}

export interface GetStaticsJudicialError {
  type: typeof GET_STATICS_JUDICIAL_ERROR;
  payload: string | boolean;
}

export interface ResetGetStaticsJudicial {
  type: typeof RESET_GET_STATICS_JUDICIAL;
  payload: null;
}

export interface State {
  data: any;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | GetStaticsJudicial
  | GetStaticsJudicialSuccess
  | GetStaticsJudicialError
  | ResetGetStaticsJudicial;
