import Bold from "../../../../../assets/fonts/DM-Sans/DMSans-Bold.ttf";
import BoldItalic from "../../../../../assets/fonts/DM-Sans/DMSans-BoldItalic.ttf";
import Medium from "../../../../../assets/fonts/DM-Sans/DMSans-Medium.ttf";
import MediumItalic from "../../../../../assets/fonts/DM-Sans/DMSans-MediumItalic.ttf";
import Regular from "../../../../../assets/fonts/DM-Sans/DMSans-Regular.ttf";
import Italic from "../../../../../assets/fonts/DM-Sans/DMSans-Italic.ttf";

const fontName = "DM Sans";
const fontSizeBase = 14;

const fontFace = `  
  @font-face {
    font-display: swap;
    font-weight: 400;
    font-style: normal;
    font-family: ${fontName};
    src: local(${fontName}), url('${Regular}') format('truetype');
  }

  @font-face {
    font-display: swap;
    font-weight: 400;
    font-style: italic;
    font-family: ${fontName};
    src: local(${fontName}), url('${Italic}') format('truetype');
  }
  
  @font-face {
    font-display: swap;
    font-weight: 500;
    font-style: normal;
    font-family: ${fontName};
    src: local(${fontName}), url('${Medium}') format('truetype');
  }

  @font-face {
    font-display: swap;
    font-weight: 500;
    font-style: italic;
    font-family: ${fontName};
    src: local(${fontName}), url('${MediumItalic}') format('truetype');
  }
  
  @font-face {
    font-display: swap;
    font-weight: 700;
    font-style: normal;
    font-family: ${fontName};
    src: local(${fontName}), url('${Bold}') format('truetype');
  }

  @font-face {
    font-display: swap;
    font-weight: 700;
    font-style: italic;
    font-family: ${fontName};
    src: local(${fontName}), url('${BoldItalic}') format('truetype');
  }
`;

export const fonts = {
  fontFace,
  family: `'${fontName}', sans-serif`,
  sizes: {
    base: `${fontSizeBase}px`,
    small: `${fontSizeBase - 4}px`,
    medium: `${fontSizeBase}px`,
    large: `${fontSizeBase + 4}px`,
    h1: `${fontSizeBase + 38}px`,
    h2: `${fontSizeBase + 18}px`,
    h3: `${fontSizeBase + 12}px`,
    h4: `${fontSizeBase + 2}px`,
    h5: `${fontSizeBase}px`,
    h6: `${fontSizeBase - 2}px`,
  },
};
