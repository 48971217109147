import action from "../helpers/rest-client";

export class ErrorStatics extends Error {}

class Statics {
  public getClientes({ page, limit }) {
    const offset = page * limit;

    return action
      .Get({
        url: `/clientes?offset=${offset}&limit=${limit}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getComerciales({ page, limit }) {
    const offset = page * limit;

    return action
      .Get({
        url: `/comerciales?offset=${offset}&limit=${limit}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public getCorresponsales({ page, limit }) {
    const offset = page * limit;

    return action
      .Get({
        url: `/corresponsales?offset=${offset}&limit=${limit}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getSucursales({ page, limit }) {
    const offset = page * limit;

    return action
      .Get({
        url: `/sucursales?offset=${offset}&limit=${limit}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getMediadores({ page, limit }) {
    const offset = page * limit;

    return action
      .Get({
        url: `/mediadores?offset=${offset}&limit=${limit}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getJurisdicciones({ page, limit }) {
    const offset = page * limit;

    return action
      .Get({
        url: `/jurisdiccion?offset=${offset}&limit=${limit}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getContratos({ page, limit }) {
    const offset = page * limit;

    return action
      .Get({
        url: `/contratos?offset=${offset}&limit=${limit}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const statics = new Statics();
export default statics;
