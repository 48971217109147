import useBreadcrumbs from "use-react-router-breadcrumbs";
import Helmet from "react-helmet";
import { useLayout } from "../../../../../provider/LayoutProvider";
import { Title } from "./styles";
import { useEffect, useState } from "react";

const routes = [
  {
    path: "dashboard",
    breadcrumb: null,
    children: [
      {
        path: "judiciales",
        breadcrumb: "Judiciales",
        children: [
          { path: "new", breadcrumb: "Nuevo Judicial" },
          { path: "list", breadcrumb: "Judiciales" },
          { path: "edit", breadcrumb: "Editar Judicial" },
          { path: "preview", breadcrumb: "Judicial" },
        ],
      },
      {
        path: "prejudiciales",
        breadcrumb: "Prejudiciales",
        children: [
          { path: "new", breadcrumb: "Nuevo Prejudicial" },
          { path: "list", breadcrumb: "Prejudiciales" },
          { path: "edit", breadcrumb: "Editar Prejudicial" },
          { path: "preview", breadcrumb: "Prejudicial" },
        ],
      },
      {
        path: "agenda",
        breadcrumb: "Agenda",
        children: [
          { path: "new", breadcrumb: "Nuevo evento" },
          { path: "list", breadcrumb: null },
          { path: "edit", breadcrumb: "Editar evento" },
          { path: "preview", breadcrumb: "Evento" },
        ],
      },
      {
        path: "pagos",
        breadcrumb: "Pago",
        children: [
          { path: "new", breadcrumb: "Nuevo pago" },
          { path: "list", breadcrumb: "Pagos" },
          { path: "edit", breadcrumb: "Editar pago" },
          { path: "preview", breadcrumb: "Pago" },
        ],
      },
      {
        path: "facturacion",
        breadcrumb: "Facturación",
        children: [
          { path: "new", breadcrumb: "Nueva Solicitud de facturación" },
          { path: "list", breadcrumb: "Facturaciones" },
          { path: "edit", breadcrumb: "Editar facturación" },
          { path: "preview", breadcrumb: "Facturación" },
        ],
      },
    ],
  },
];

const Component = () => {
  const { layout } = useLayout();
  const [title, setTitle] = useState("");
  const breadcrumbs = useBreadcrumbs(routes);

  useEffect(() => {
    const layoutTitle = layout.title;
    layoutTitle && setTitle(layout.title);

    const breadcrumb = breadcrumbs[breadcrumbs.length - 1].breadcrumb;
    !layoutTitle &&
      !!breadcrumb &&
      typeof breadcrumb !== "object" &&
      typeof breadcrumb === "string" &&
      setTitle(breadcrumb);

    const routeBreadcrumb =
      breadcrumbs[breadcrumbs.length - 1]?.match?.route?.breadcrumb;
    (!layoutTitle && !breadcrumb) ||
      (typeof breadcrumb === "object" &&
        !!routeBreadcrumb &&
        typeof routeBreadcrumb === "string" &&
        setTitle(routeBreadcrumb));
  }, [breadcrumbs]);

  if (!layout.showTitle) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>
          {`Adecco Legales - 
          ${title}`}
        </title>
      </Helmet>
      <Title>{title}</Title>
    </>
  );
};

export default Component;
