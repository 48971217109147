import { put, call, takeLatest } from "redux-saga/effects";
import services from "../../services";
import {
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_ERROR,
  SIGN_UP,
  SIGN_UP_SUCCESS,
  SIGN_UP_ERROR,
  SIGN_OUT,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_ERROR,
  RECOVER_PASSWORD,
  RECOVER_PASSWORD_SUCCESS,
  RECOVER_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  SignIn,
  SignUp,
} from "../types/auth";

function* signIn({ payload }: SignIn): any {
  try {
    const result = yield call(services.auth.signIn, payload);
    yield put({ type: SIGN_IN_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: SIGN_IN_ERROR, payload: error });
  }
}

function* signUp({ payload }: SignUp): any {
  try {
    const result = yield call(services.auth.signUp, payload);
    yield put({ type: SIGN_UP_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: SIGN_UP_ERROR, payload: error });
  }
}

function* signOut(): any {
  try {
    const result = yield call(services.auth.signOut);
    yield put({ type: SIGN_OUT_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: SIGN_OUT_ERROR, payload: error });
  }
}

function* recoverPassword({ payload }): any {
  try {
    const result = yield call(services.auth.recoverPassword, payload);
    yield put({ type: RECOVER_PASSWORD_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: RECOVER_PASSWORD_ERROR, payload: error });
  }
}

function* resetPassword({ payload }): any {
  try {
    const result = yield call(services.auth.resetPassword, payload);
    yield put({ type: RESET_PASSWORD_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: RESET_PASSWORD_ERROR, payload: error });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(SIGN_IN, signIn);
  yield takeLatest<any>(SIGN_UP, signUp);
  yield takeLatest<any>(SIGN_OUT, signOut);
  yield takeLatest<any>(RECOVER_PASSWORD, recoverPassword);
  yield takeLatest<any>(RESET_PASSWORD, resetPassword);
}
