import { NewPagoOutDto, NewPagoInDto } from "../../../types/pago.dto";

export const NEW_PAGO = "NEW_PAGO";
export const NEW_PAGO_SUCCESS = "NEW_PAGO_SUCCESS";
export const NEW_PAGO_ERROR = "NEW_PAGO_ERROR";
export const RESET_NEW_PAGO = "RESET_NEW_PAGO";

export interface NewPago {
  type: typeof NEW_PAGO;
  payload: NewPagoOutDto;
}

export interface NewPagoSuccess {
  type: typeof NEW_PAGO_SUCCESS;
  payload: NewPagoInDto;
}

export interface NewPagoError {
  type: typeof NEW_PAGO_ERROR;
  payload: string | boolean;
}

export interface ResetNewPago {
  type: typeof RESET_NEW_PAGO;
  payload: null;
}

export interface State {
  data: NewPagoInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions = NewPago | NewPagoSuccess | NewPagoError | ResetNewPago;
