import {
  GetOneActivityInDto,
  GetOneActivityOutDto,
} from "../../../types/activity.dto";

export const GET_ONE_ACTIVITY = "GET_ONE_ACTIVITY";
export const GET_ONE_ACTIVITY_SUCCESS = "GET_ONE_ACTIVITY_SUCCESS";
export const GET_ONE_ACTIVITY_ERROR = "GET_ONE_ACTIVITY_ERROR";
export const RESET_GET_ONE_ACTIVITY = "RESET_GET_ONE_ACTIVITY";

export interface GetOneActivity {
  type: typeof GET_ONE_ACTIVITY;
  payload: GetOneActivityOutDto;
}

export interface GetOneActivitySuccess {
  type: typeof GET_ONE_ACTIVITY_SUCCESS;
  payload: GetOneActivityInDto;
}

export interface GetOneActivityError {
  type: typeof GET_ONE_ACTIVITY_ERROR;
  payload: string | boolean;
}

export interface ResetGetOneActivity {
  type: typeof RESET_GET_ONE_ACTIVITY;
  payload: null;
}

export interface State {
  data: GetOneActivityInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | GetOneActivity
  | GetOneActivitySuccess
  | GetOneActivityError
  | ResetGetOneActivity;
