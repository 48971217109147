const values = [
  { label: "Provincia ART", value: 1 },
  { label: "Galeno ART", value: 2 },
  {
    label: "Swiss Medical ART",
    value: 3,
  },
  { label: "PROVINCIA", value: 4 },
  { label: "Mapfre", value: 5 },
  { label: "Fed. Patronal", value: 6 },
  { label: "FEDERACION PATRONAL ART SA", value: 7 },
  { label: "GALENO  ART S.A.", value: 8 },
  { label: "PROVINCIA ASEGURADORA DE RIESGOS DEL TRABAJO S.A.", value: 9 },
  { label: "FEDERACION PATRONAL SEGUROS S.A", value: 10 },
  { label: "Mapre", value: 11 },
  { label: "GALENO", value: 12 },
  { label: "LIBERTY", value: 13 },
];

export default values;
