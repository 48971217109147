import { EditFileOutDto, EditFileInDto } from "../../../types/file.dto";

export const EDIT_FILE = "EDIT_FILE";
export const EDIT_FILE_SUCCESS = "EDIT_FILE_SUCCESS";
export const EDIT_FILE_ERROR = "EDIT_FILE_ERROR";
export const RESET_EDIT_FILE = "RESET_EDIT_FILE";

export interface EditFile {
  type: typeof EDIT_FILE;
  payload: EditFileOutDto;
}

export interface EditFileSuccess {
  type: typeof EDIT_FILE_SUCCESS;
  payload: EditFileInDto;
}

export interface EditFileError {
  type: typeof EDIT_FILE_ERROR;
  payload: string | boolean;
}

export interface ResetEditFile {
  type: typeof RESET_EDIT_FILE;
  payload: null;
}
export interface State {
  data: EditFileInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | EditFile
  | EditFileSuccess
  | EditFileError
  | ResetEditFile;
