export const GET_STATICS_FACTURACION = "GET_STATICS_FACTURACION";
export const GET_STATICS_FACTURACION_SUCCESS =
  "GET_STATICS_FACTURACION_SUCCESS";
export const GET_STATICS_FACTURACION_ERROR = "GET_STATICS_FACTURACION_ERROR";
export const RESET_GET_STATICS_FACTURACION = "RESET_GET_STATICS_FACTURACION";

export interface GetStaticsFacturacion {
  type: typeof GET_STATICS_FACTURACION;
  payload: null;
}

export interface GetStaticsFacturacionSuccess {
  type: typeof GET_STATICS_FACTURACION_SUCCESS;
  payload: any;
}

export interface GetStaticsFacturacionError {
  type: typeof GET_STATICS_FACTURACION_ERROR;
  payload: string | boolean;
}

export interface ResetGetStaticsFacturacion {
  type: typeof RESET_GET_STATICS_FACTURACION;
  payload: null;
}

export interface State {
  data: any;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | GetStaticsFacturacion
  | GetStaticsFacturacionSuccess
  | GetStaticsFacturacionError
  | ResetGetStaticsFacturacion;
