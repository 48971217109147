import { GetCommentOutDto, GetCommentInDto } from "../../../types/comment.dto";

export const GET_COMMENTS_CALENDAR = "GET_COMMENTS_CALENDAR";
export const GET_COMMENTS_CALENDAR_SUCCESS = "GET_COMMENTS_CALENDAR_SUCCESS";
export const GET_COMMENTS_CALENDAR_ERROR = "GET_COMMENTS_CALENDAR_ERROR";
export const RESET_GET_COMMENTS_CALENDAR = "RESET_GET_COMMENTS_CALENDAR";

export interface GetCommentsCalendar {
  type: typeof GET_COMMENTS_CALENDAR;
  payload: GetCommentOutDto;
}

export interface GetCommentsCalendarSuccess {
  type: typeof GET_COMMENTS_CALENDAR_SUCCESS;
  payload: GetCommentInDto[];
}

export interface GetCommentsCalendarError {
  type: typeof GET_COMMENTS_CALENDAR_ERROR;
  payload: string | boolean;
}

export interface ResetGetCommentsCalendar {
  type: typeof RESET_GET_COMMENTS_CALENDAR;
  payload: null;
}

export interface State {
  data: GetCommentInDto[] | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | GetCommentsCalendar
  | GetCommentsCalendarSuccess
  | GetCommentsCalendarError
  | ResetGetCommentsCalendar;
