import * as Yup from "yup";

export const schema = Yup.object().shape({
  files: Yup.array().nullable(),
});

interface FormValues {
  files?: File[];
}

export const initialValues: FormValues = {
  files: undefined,
};
