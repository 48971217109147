import styled from "styled-components";
import { Link as ReactRouterLink } from "react-router-dom";

const linkStyles = {
  outline: {
    primary: {
      color: "Primary",
      borderColor: "Primary",
      backgroundColor: "Transparent",
    },
    secondary: {
      color: "Secondary",
      borderColor: "Secondary",
      backgroundColor: "Transparent",
    },
    gray: {
      color: "Black",
      borderColor: "Black",
      backgroundColor: "Transparent",
    },
    white: {
      color: "White",
      borderColor: "White",
      backgroundColor: "Transparent",
    },
    danger: {
      color: "Danger",
      borderColor: "Danger",
      backgroundColor: "Transparent",
    },
  },
  filled: {
    primary: {
      color: "White",
      borderColor: "Primary",
      backgroundColor: "Primary",
    },
    secondary: {
      color: "White",
      borderColor: "Secondary",
      backgroundColor: "Secondary",
    },
    gray: {
      color: "White",
      borderColor: "White",
      backgroundColor: "Transparent",
    },
    white: {
      color: "Primary",
      borderColor: "Primary",
      backgroundColor: "White",
    },
    danger: {
      color: "White",
      borderColor: "Danger",
      backgroundColor: "Danger",
    },
  },
  link: {
    primary: {
      color: "Primary",
      borderColor: "Transparent",
      backgroundColor: "Transparent",
    },
    secondary: {
      color: "Secondary",
      borderColor: "Transparent",
      backgroundColor: "Transparent",
    },
    gray: {
      color: "White",
      borderColor: "Transparent",
      backgroundColor: "Transparent",
    },
    white: {
      color: "White",
      borderColor: "Transparent",
      backgroundColor: "Transparent",
    },
    danger: {
      color: "Danger",
      borderColor: "Transparent",
      backgroundColor: "Transparent",
    },
  },
};

export const Link = styled("a")<{ options }>`
  ${(props: any) => {
    const { block, theme } = props;
    const { size, type, skin, marginBottom } = props.options;

    return `
      ${block ? `display: block; width: 100%;` : `display: inline-block;`}
      ${
        skin !== "transparent"
          ? `border: 1px solid ${
              theme.colors[linkStyles[type][skin].borderColor] || "transparent"
            };`
          : ``
      }
      background-color: ${
        theme.colors[linkStyles[type][skin].backgroundColor] || "transparent"
      };
      font-size: ${theme.link.size[size].fontSize};
      color: ${theme.colors[linkStyles[type][skin].color]};
      border-radius: ${theme.link.size[size].borderRadius};
      ${type !== "link" ? `padding: 0px 12px;` : ``};
      ${type !== "link" ? `min-width: 120px;` : ``};
      ${
        type !== "link"
          ? `line-height: calc(${theme.link.size[size].lineHeight} - ${
              skin !== "transparent" ? "4px" : "0px"
            });`
          : ``
      }
      text-decoration: none;
      letter-spacing: 0.25px;
      font-weight: ${type !== "link" ? "inherit" : "normal"};
      min-width: ${type === "link" ? "0" : "150px"};
      text-align: center;
      transition: all .2s ease-in-out;
      margin-bottom: ${marginBottom ? marginBottom : "0px"};

      &:hover {
        ${
          type !== "link"
            ? "box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);"
            : "text-decoration: underline;"
        }
        
      }
    `;
  }}
`;

export const NativeLink = styled(ReactRouterLink)<{
  options;
}>`
  ${(props: any) => {
    const { block, theme } = props;
    const { size, type, skin } = props.options;

    return `
      ${block ? `display: block; width: 100%;` : `display: inline-block;`}
      ${
        skin !== "transparent"
          ? `border: 1px solid ${
              theme.colors[linkStyles[type][skin].borderColor]
            };`
          : ``
      }
      background-color: ${theme.colors[linkStyles[type][skin].backgroundColor]};
      font-size: ${theme.link.size[size].fontSize};
      color: ${theme.colors[linkStyles[type][skin].color]};
      border-radius: ${theme.link.size[size].borderRadius};
      ${type !== "link" ? `padding: 0px 12px;` : ``}
      ${type !== "link" ? `min-width: 120px;` : ``}
      ${
        type !== "link"
          ? `line-height: calc(${theme.link.size[size].lineHeight} - ${
              skin !== "transparent" ? "4px" : "0px"
            });`
          : ``
      }
      text-decoration: none;
      letter-spacing: 0.25px;
      font-weight: ${type !== "link" ? "inherit" : "normal"};
      min-width: ${type === "link" ? "0" : "150px"};
      text-align: center;
      transition: all .2s ease-in-out;

      &:hover {
        ${
          type !== "link"
            ? "box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);"
            : "text-decoration: underline;"
        }
        
      }
    `;
  }}
`;

export const DisabledLink = styled("span")<{
  type;
  size;
  skin;
  block?: boolean;
}>`
  ${(props: any) => {
    const { block, size, type, skin, theme } = props;

    return `
      ${block ? `display: block; width: 100%;` : `display: inline-block;`}
      ${
        skin !== "transparent"
          ? `border: 2px solid ${
              theme.colors[linkStyles[type][skin].borderColor]
            };`
          : ``
      }
      background-color: ${theme.colors.Black40};
      font-size: ${theme.link.size[size].fontSize};
      color: ${theme.colors[linkStyles[type][skin].color]};
      border-color: ${theme.colors.Black40};
      border-radius: ${theme.link.size[size].borderRadius};
      ${type !== "link" ? `padding: 0px 12px;` : ``};
      ${type !== "link" ? `min-width: 120px;` : ``};
      ${type !== "link" ? `height: ${theme.link.size[size].lineHeight}` : ``};
      ${
        type !== "link"
          ? `line-height: ${theme.link.size[size].lineHeight};`
          : ``
      }
      text-decoration: none;
      letter-spacing: 0.25px;
      font-weight: 700;
      text-align: center;
      
    `;
  }}

  & > * {
    display: inline-block;
  }
`;
