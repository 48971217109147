import { colors } from "./../globals/colors";

export const form = {
  skin: {
    base: {
      idle: {
        color: colors.Gray,
        placeHolderColor: colors.Gray,
        backgroundColor: "#fff",
        borderColor: "rgb(0 0 0 / 20%)",
        iconColor: colors.Gray,
      },
      disabled: {
        color: colors.Gray,
        placeHolderColor: colors.Black,
        backgroundColor: "#c8c8c8",
        borderColor: "#c8c8c8",
        iconColor: colors.Gray,
      },
      active: {
        color: colors.Gray,
        placeHolderColor: colors.Primary,
        backgroundColor: "#fff",
        borderColor: "#c2bfbf",
        iconColor: colors.Primary,
      },
      hover: {
        color: colors.Gray,
        placeHolderColor: colors.Black,
        backgroundColor: "#fff",
        borderColor: "#c2bfbf",
        iconColor: colors.Gray,
      },
      focus: {
        color: colors.Gray,
        placeHolderColor: colors.Primary,
        backgroundColor: "#fff",
        borderColor: "#c2bfbf",
        iconColor: colors.Primary,
      },
      error: {
        color: colors.Danger,
        placeHolderColor: colors.Danger,
        backgroundColor: "#fff",
        borderColor: colors.Danger,
        iconColor: colors.Danger,
      },
      readonly: {
        color: colors.Gray,
        placeHolderColor: colors.LightGray,
        backgroundColor: "#ebebeb",
        borderColor: "transparent",
        iconColor: colors.Gray,
      },
    },
  },
  size: {
    md: {
      fontSize: "14px",
      lineHeight: "19px",
      mobileFontSize: "14px",
      mobileLineHeight: "17px",
      height: "36px",
      padding: "10px",
    },
  },
};
