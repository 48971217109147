import { put, call, all, takeLatest } from "redux-saga/effects";
import services from "../../services";

import {
  NewFacturacion,
  NEW_FACTURACION,
  NEW_FACTURACION_SUCCESS,
  NEW_FACTURACION_ERROR,
} from "../types/facturacion/new";

import {
  EditFacturacion,
  EDIT_FACTURACION,
  EDIT_FACTURACION_SUCCESS,
  EDIT_FACTURACION_ERROR,
} from "../types/facturacion/edit";

import {
  DeleteFacturacion,
  DELETE_FACTURACION,
  DELETE_FACTURACION_SUCCESS,
  DELETE_FACTURACION_ERROR,
} from "../types/facturacion/delete";

import {
  GetOneFacturacion,
  GET_ONE_FACTURACION,
  GET_ONE_FACTURACION_SUCCESS,
  GET_ONE_FACTURACION_ERROR,
} from "../types/facturacion/getOne";

import {
  SelectOneFacturacion,
  SELECT_ONE_FACTURACION,
  SELECT_ONE_FACTURACION_SUCCESS,
  SELECT_ONE_FACTURACION_ERROR,
} from "../types/facturacion/selectOne";

import {
  GetAllFacturaciones,
  GET_ALL_FACTURACIONES,
  GET_ALL_FACTURACIONES_SUCCESS,
  GET_ALL_FACTURACIONES_ERROR,
} from "../types/facturacion/getAll";

import {
  GetCommentsFacturacion,
  GET_COMMENTS_FACTURACION,
  GET_COMMENTS_FACTURACION_SUCCESS,
  GET_COMMENTS_FACTURACION_ERROR,
} from "../types/facturacion/getComments";

import {
  GetStaticsFacturacion,
  GET_STATICS_FACTURACION,
  GET_STATICS_FACTURACION_SUCCESS,
  GET_STATICS_FACTURACION_ERROR,
} from "../types/facturacion/statics";

function* getAllFacturaciones({ payload }) {
  try {
    const { response, error } = yield call(
      services.facturacion.getAll,
      payload
    );

    if (error) {
      yield put({
        type: GET_ALL_FACTURACIONES_ERROR,
        payload: error,
      });
    } else {
      yield put({ type: GET_ALL_FACTURACIONES_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: GET_ALL_FACTURACIONES_ERROR,
      payload: "Error inesperado al obtener todos los facturaciones.",
    });
  }
}

function* getOneFacturacion({ payload }) {
  try {
    const { response, error } = yield call(
      services.facturacion.getOne,
      payload
    );

    if (error) {
      yield put({
        type: GET_ONE_FACTURACION_ERROR,
        payload: error,
      });
    } else {
      yield put({ type: GET_ONE_FACTURACION_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: GET_ONE_FACTURACION_ERROR,
      payload: "Error inesperado al obtener el facturacion seleccionado.",
    });
  }
}

function* selectOneFacturacion({ payload }) {
  try {
    const { response, error } = yield call(
      services.facturacion.getOne,
      payload
    );

    if (error) {
      yield put({
        type: SELECT_ONE_FACTURACION_ERROR,
        payload: error,
      });
    } else {
      yield put({ type: SELECT_ONE_FACTURACION_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: SELECT_ONE_FACTURACION_ERROR,
      payload: "Error inesperado al seleccionar el facturacion.",
    });
  }
}

function* newFacturacion({ payload }) {
  try {
    const { response, error } = yield call(services.facturacion.new, payload);

    if (error) {
      yield put({
        type: NEW_FACTURACION_ERROR,
        payload: error,
      });
    } else {
      yield all([
        put({ type: NEW_FACTURACION_SUCCESS, payload: response }),
        put({ type: GET_ALL_FACTURACIONES, payload: null }),
      ]);
    }
  } catch {
    yield put({
      type: NEW_FACTURACION_ERROR,
      payload: "Error inesperado al crear un nuevo facturacion.",
    });
  }
}

function* editFacturacion({ payload }) {
  try {
    const { response, error } = yield call(services.facturacion.edit, payload);

    if (error) {
      yield put({
        type: EDIT_FACTURACION_ERROR,
        payload: error,
      });
    } else {
      yield all([
        put({ type: EDIT_FACTURACION_SUCCESS, payload: response }),
        put({ type: GET_ALL_FACTURACIONES, payload: null }),
      ]);
    }
  } catch {
    yield put({
      type: EDIT_FACTURACION_ERROR,
      payload: "Error inesperado al editar el facturacion seleccionado.",
    });
  }
}

function* deleteFacturacion({ payload }) {
  try {
    const { response, error } = yield call(
      services.facturacion.delete,
      payload
    );

    if (error) {
      yield put({
        type: DELETE_FACTURACION_ERROR,
        payload: error,
      });
    } else {
      yield all([
        put({ type: DELETE_FACTURACION_SUCCESS, payload: response }),
        put({ type: GET_ALL_FACTURACIONES, payload: null }),
      ]);
    }
  } catch {
    yield put({
      type: DELETE_FACTURACION_ERROR,
      payload: "Error inesperado al eliminar el facturacion seleccionado.",
    });
  }
}

function* getCommentsFacturacion({ payload }) {
  try {
    const { response, error } = yield call(
      services.facturacion.getComments,
      payload
    );

    if (error) {
      yield put({
        type: GET_COMMENTS_FACTURACION_ERROR,
        payload: error,
      });
    } else {
      yield put({ type: GET_COMMENTS_FACTURACION_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: GET_COMMENTS_FACTURACION_ERROR,
      payload:
        "Error inesperado al ontener comentarios del facturacion seleccionado.",
    });
  }
}

function* getStatics() {
  try {
    const { response, error } = yield call(services.facturacion.getStatics);

    if (error) {
      yield put({
        type: GET_STATICS_FACTURACION_ERROR,
        payload: error,
      });
    } else {
      yield put({ type: GET_STATICS_FACTURACION_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: GET_STATICS_FACTURACION_ERROR,
      payload: "Error inesperado al ontener los estaticos de facturacion.",
    });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllFacturaciones>(
    GET_ALL_FACTURACIONES,
    getAllFacturaciones
  );
  yield takeLatest<GetOneFacturacion>(GET_ONE_FACTURACION, getOneFacturacion);
  yield takeLatest<EditFacturacion>(EDIT_FACTURACION, editFacturacion);
  yield takeLatest<DeleteFacturacion>(DELETE_FACTURACION, deleteFacturacion);
  yield takeLatest<NewFacturacion>(NEW_FACTURACION, newFacturacion);
  yield takeLatest<SelectOneFacturacion>(
    SELECT_ONE_FACTURACION,
    selectOneFacturacion
  );
  yield takeLatest<GetCommentsFacturacion>(
    GET_COMMENTS_FACTURACION,
    getCommentsFacturacion
  );
  yield takeLatest<GetStaticsFacturacion>(GET_STATICS_FACTURACION, getStatics);
}
