import {
  DeletePrejudicialOutDto,
  DeletePrejudicialInDto,
} from "../../../types/prejudicial.dto";

export const DELETE_PREJUDICIAL = "DELETE_PREJUDICIAL";
export const DELETE_PREJUDICIAL_SUCCESS = "DELETE_PREJUDICIAL_SUCCESS";
export const DELETE_PREJUDICIAL_ERROR = "DELETE_PREJUDICIAL_ERROR";
export const RESET_DELETE_PREJUDICIAL = "RESET_DELETE_PREJUDICIAL";

export interface DeletePrejudicial {
  type: typeof DELETE_PREJUDICIAL;
  payload: DeletePrejudicialOutDto;
}

export interface DeletePrejudicialSuccess {
  type: typeof DELETE_PREJUDICIAL_SUCCESS;
  payload: DeletePrejudicialInDto;
}

export interface DeletePrejudicialError {
  type: typeof DELETE_PREJUDICIAL_ERROR;
  payload: string | boolean;
}

export interface ResetDeletePrejudicial {
  type: typeof RESET_DELETE_PREJUDICIAL;
  payload: null;
}

export interface State {
  data: DeletePrejudicialInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | DeletePrejudicial
  | DeletePrejudicialSuccess
  | DeletePrejudicialError
  | ResetDeletePrejudicial;
