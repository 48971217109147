import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Button from "../../../Button";
import Submit from "../../../Form/Submit";
import { Formik } from "formik";
import { Center } from "../../../StyledComponents";
import { toast } from "react-toastify";
import Icon from "../../../Icon";
import { initialValues, schema } from "../../../../constants/form/file/new";

import DownloadIcon from "../../../../assets/icons/cloud-download-alt.svg";
import AudioIcon from "../../../../assets/icons/file-audio-regular.svg";
import ExcelIcon from "../../../../assets/icons/file-excel-regular.svg";
import ImageIcon from "../../../../assets/icons/file-image-regular.svg";
import VideoIcon from "../../../../assets/icons/file-video-regular.svg";
import WordIcon from "../../../../assets/icons/file-word-regular.svg";
import ZipIcon from "../../../../assets/icons/file-zipper-regular.svg";
import PdfIcon from "../../../../assets/icons/file-pdf-regular.svg";

import { Form, FullHeight } from "./styles";
import constants from "../../../../constants";
import File from "../../../Form/File";
import { EntitiesEnum } from "../../../../types/entities.enum";
import {
  deleteFile,
  newFile,
  resetNewFile,
} from "../../../../store/actions/files";
import { NewFileInDto } from "../../../../types/file.dto";

interface ComponentProps {
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
  entity: EntitiesEnum;
  entityId?: string;
  newFileInformation: NewFileInDto;
  newFileInformationStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
}

const Component = ({
  setShowForm,
  entity,
  entityId,
  newFileInformation,
  newFileInformationStates,
}: ComponentProps) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    if (entity && entityId) {
      dispatch(newFile({ entity, entityId, files: values.files }));
      setFormActions(actions);
    }
  };

  useEffect(() => {
    if (newFileInformationStates.success && !!newFileInformation) {
      toast.success("👌🏼 Archivos subidos correctamente");
      setTimeout(() => {
        formActions?.resetForm();
        dispatch(resetNewFile());
        setShowForm(false);
      }, 1000);
    }

    if (newFileInformationStates.error) {
      toast.error(`😱 No se han podido subir los archivos`);
    }
  }, [newFileInformationStates, newFileInformation]);

  return (
    <Formik
      initialValues={{ ...initialValues }}
      onSubmit={(values, actions) => {
        if (onSubmit) onSubmit({ values, actions });
      }}
      validateOnChange={formSubmmited}
      validateOnBlur={false}
      validationSchema={schema}
      enableReinitialize
    >
      {({
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        resetForm,
        setFieldValue,
        isSubmitting,
      }) => {
        return (
          <>
            <Form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="new-file-form"
            >
              <h3>Subir archivos</h3>

              <FullHeight>
                <File
                  name="files"
                  error={errors["files"]}
                  touched={touched["files"]}
                  value={values["files"]}
                  rules={{
                    maxFiles: 10,
                    typeFiles: ["image", "audio", "video", "other"],
                  }}
                  onRemoveItem={(event, file) => {
                    if (!!entity && !!entityId && file.id) {
                      dispatch(
                        deleteFile({ entity, entityId, fileId: file.id })
                      );
                    }
                  }}
                  options={{
                    explainText:
                      "Puedes guardar imágenes, audios, PDF y videos de hasta 10Mb.",
                    marginBottom: 40,
                    showLoadFileCta: false,
                  }}
                />
              </FullHeight>

              <Center styles={{ justifyContent: "space-between" }}>
                <Button
                  onClick={() => {
                    resetForm();
                    setShowForm(false);
                  }}
                  type="button"
                  options={{
                    type: "link",
                    skin: "primary",
                    size: "md",
                    marginBottom: "0px",
                  }}
                  style={{ marginRight: "10px" }}
                >
                  Cancelar
                </Button>

                <Submit
                  isSubmmiting={newFileInformationStates.loading}
                  form="new-file-form"
                  color="Primary"
                  options={{
                    type: "filled",
                    skin: "primary",
                    size: "md",
                    marginBottom: "0px",
                  }}
                >
                  Confirmar
                </Submit>
              </Center>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

const state = ({ filesStore }) => {
  const { data: newFileInformation, states: newFileInformationStates } =
    filesStore.newOne;

  return {
    newFileInformation,
    newFileInformationStates,
  };
};

export default connect(state)(Component);
