import { ErrorMessage } from "../Field";

interface ComponentProps {
  children: string;
  visible: boolean;
  styles?;
}

const Component = ({ children, visible = false, ...rest }: ComponentProps) => {
  if (!visible) {
    return null;
  }

  return <ErrorMessage {...rest}>{children}</ErrorMessage>;
};

export default Component;
