import styled from "styled-components";

export const Filters = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;

  & > * {
    & + *:not(:first-child) {
      margin-left: 10px;
    }
  }
`;
