import action from "./../helpers/rest-client";
import { UserDto } from "../types/user.dto";

export class ErrorUser extends Error {}

class UserService {
  public getUserInformation() {
    const user: UserDto = JSON.parse(localStorage.getItem("user") || "{}");
    return { ...user };
  }

  public async updateUserInformation(payload) {
    return action
      .Patch({
        url: `/profile`,
        body: payload,
      })
      .then((resp) => {
        const userStored = localStorage.getItem("user");

        localStorage.setItem(
          "user",
          JSON.stringify({
            ...JSON.parse(userStored || "{}"),
            ...resp?.success?.result,
          })
        );

        return resp;
      })
      .catch((error) => {
        throw new ErrorUser(error.message);
      });
  }

  public async getUserProfile() {
    return action
      .Get({
        url: "/profile",
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorUser(error.message);
      });
  }

  public async changePassword(payload) {
    return action
      .Patch({
        url: `/profile/psw/`,
        body: payload,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorUser(error.message);
      });
  }
}

const user = new UserService();
export default user;
