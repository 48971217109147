import { call, put, takeLatest, all } from "redux-saga/effects";
import services from "../../services";
import { SIGN_IN_SUCCESS, SIGN_OUT_SUCCESS } from "../types/auth";
import {
  GET_USER_MAIN_INFORMATION,
  GET_USER_MAIN_INFORMATION_SUCCESS,
  GET_USER_MAIN_INFORMATION_ERROR,
} from "../types/user/get-information";
import {
  UPDATE_USER_MAIN_INFORMATION,
  UPDATE_USER_MAIN_INFORMATION_SUCCESS,
  UPDATE_USER_MAIN_INFORMATION_ERROR,
} from "../types/user/update-information";
import { RESET_USER_MAIN_INFORMATION } from "../types/user/reset-information";
import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
} from "../types/user/change-password";

function* saveUserInformation({ payload }) {
  yield put({ type: GET_USER_MAIN_INFORMATION_SUCCESS, payload });
}

function* updateUserInformation({ payload }) {
  try {
    const response = yield call(services.user.updateUserInformation, payload);
    yield all([
      put({
        type: UPDATE_USER_MAIN_INFORMATION_SUCCESS,
        payload: response,
      }),
      put({
        type: GET_USER_MAIN_INFORMATION_SUCCESS,
        payload: response,
      }),
    ]);
    yield;
  } catch (error) {
    yield put({ type: UPDATE_USER_MAIN_INFORMATION_ERROR, payload: error });
  }
}

function* getUserInformation() {
  try {
    const response = yield call(services.user.getUserInformation);
    yield put({ type: GET_USER_MAIN_INFORMATION_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_USER_MAIN_INFORMATION_ERROR, payload: error });
  }
}

function* changePassword({ payload }) {
  try {
    const reponse = yield call(services.user.changePassword, payload);
    yield put({ type: CHANGE_PASSWORD_SUCCESS, payload: reponse });
  } catch (error) {
    yield put({ type: CHANGE_PASSWORD_ERROR, payload: error });
  }
}

function* resetUserInformation() {
  yield put({ type: RESET_USER_MAIN_INFORMATION });
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(SIGN_IN_SUCCESS, saveUserInformation);
  yield takeLatest<any>(GET_USER_MAIN_INFORMATION, getUserInformation);
  yield takeLatest<any>(UPDATE_USER_MAIN_INFORMATION, updateUserInformation);
  yield takeLatest<any>(SIGN_OUT_SUCCESS, resetUserInformation);
  yield takeLatest<any>(CHANGE_PASSWORD, changePassword);
}
