import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Button from "../../../Button";
import Submit from "../../../Form/Submit";
import { Formik } from "formik";
import { Center } from "../../../StyledComponents";

import { toast } from "react-toastify";
import Icon from "../../../Icon";
import { initialValues, schema } from "../../../../constants/form/comment/new";
import Loading from "./components/Loading";
import DownloadIcon from "../../../../assets/icons/cloud-download-alt.svg";

import DeleteIcon from "../../../../assets/icons/delete.svg";

import AudioIcon from "../../../../assets/icons/file-audio-regular.svg";
import ExcelIcon from "../../../../assets/icons/file-excel-regular.svg";
import ImageIcon from "../../../../assets/icons/file-image-regular.svg";
import VideoIcon from "../../../../assets/icons/file-video-regular.svg";
import WordIcon from "../../../../assets/icons/file-word-regular.svg";
import ZipIcon from "../../../../assets/icons/file-zipper-regular.svg";
import PdfIcon from "../../../../assets/icons/file-pdf-regular.svg";

import {
  Files,
  FilesList,
  File,
  Whom,
  Name,
  Date,
  Actions,
  Download,
  Delete,
  Separator,
  Information,
  ExtraInformation,
  Scroll,
} from "./styles";
import constants from "../../../../constants";
import { EntitiesEnum } from "../../../../types/entities.enum";
import {
  deleteFile,
  getAllFiles,
  getOneFile,
  resetDeleteFile,
  resetGetOneFile,
} from "../../../../store/actions/files";
import { dateSpliter } from "../../../../helpers/data-handler";
import {
  FileDto,
  GetAllFilesInDto,
  GetOneFileInDto,
} from "../../../../types/file.dto";
import { ghostLink } from "../../../../helpers/ghost-link";

const mimesIcons = {
  "image/jpeg": ImageIcon,
};

interface ComponentProps {
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
  entity: EntitiesEnum;
  entityId?: string;
  allFiles: FileDto[];
  allFilesStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  oneFile: GetOneFileInDto;
  oneFileStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  deletedFile;
  deletedFileStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
}

const Component = ({
  setShowForm,
  entity,
  entityId,
  allFiles,
  allFilesStates,
  oneFile,
  oneFileStates,
  deletedFile,
  deletedFileStates,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState<number>();

  const downloadFile = () => {
    fetch(`data:${oneFile?.mimetype};base64,` + oneFile?.data)
      .then((res) => res.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        ghostLink(url);
      });
  };

  useEffect(() => {
    if (oneFileStates.success) {
      downloadFile();

      setTimeout(() => {
        dispatch(resetGetOneFile());
      }, 1000);
    }
  }, [oneFileStates]);

  useEffect(() => {
    if (deletedFileStates.success) {
      toast.success("👌🏼 Archivo eliminado correctamente");
      setTimeout(() => {
        dispatch(resetDeleteFile());
      }, 2000);
    }

    if (deletedFileStates.error) {
      toast.error(`😱 No se ha podido eliminar el archivo`);
      setTimeout(() => {
        dispatch(resetDeleteFile());
      }, 2000);
    }
  }, [deletedFileStates]);

  return (
    <Files>
      <Scroll>
        <FilesList>
          {allFilesStates?.loading && (
            <Center horizontal="center" vertical="center">
              <Loading size={30} />
            </Center>
          )}

          {allFiles?.map((file) => {
            const createdAt = dateSpliter(file?.createdAt);
            const createdAtFormated = `${createdAt.day?.name?.short} ${createdAt.day?.number} de ${createdAt.month?.name?.short} del ${createdAt.year}`;

            return (
              <File>
                <Icon
                  icon={mimesIcons[file?.mimetype]}
                  size="24px"
                  color="black"
                />

                <Information>
                  <Name>{file?.originalname}</Name>

                  <ExtraInformation>
                    {/* <Whom>Jonathan Innen</Whom> */}
                    <Date>{createdAtFormated}</Date>
                  </ExtraInformation>
                </Information>

                <Actions>
                  <Download
                    type="button"
                    onClick={() => {
                      if (!!entity && !!entityId && !!file?.id) {
                        dispatch(getOneFile({ entity, entityId, file }));
                        setSelectedFile(file?.id);
                      }
                    }}
                  >
                    {oneFileStates.loading && selectedFile === file?.id ? (
                      <Loading size={24} />
                    ) : (
                      <Icon icon={DownloadIcon} size="24px" color="black" />
                    )}
                  </Download>
                  {/* <Separator />
                  <Delete
                    type="button"
                    onClick={() => {
                      if (!!entity && !!entityId && !!file?.id) {
                        dispatch(
                          deleteFile({ entity, entityId, fileId: file?.id })
                        );
                        setSelectedFile(file?.id);
                      }
                    }}
                  >
                    {deletedFileStates.loading && selectedFile === file?.id ? (
                      <Loading size={24} />
                    ) : (
                      <Icon icon={DeleteIcon} size="24px" color="black" />
                    )}
                  </Delete> */}
                </Actions>
              </File>
            );
          })}
        </FilesList>
      </Scroll>

      <Button
        onClick={() => setShowForm(true)}
        type="button"
        options={{
          type: "filled",
          skin: "primary",
          size: "lg",
          block: true,
          marginBottom: "0px",
        }}
      >
        Subir archivo
      </Button>
    </Files>
  );
};

const state = ({ filesStore }) => {
  const { data: allFiles, states: allFilesStates } = filesStore.all;
  const { data: oneFile, states: oneFileStates } = filesStore.one;
  const { data: deletedFile, states: deletedFileStates } = filesStore.deleteOne;

  return {
    allFiles,
    allFilesStates,
    oneFile,
    oneFileStates,
    deletedFile,
    deletedFileStates,
  };
};

export default connect(state)(Component);
