import { NewFileOutDto, NewFileInDto } from "../../../types/file.dto";

export const NEW_FILE = "NEW_FILE";
export const NEW_FILE_SUCCESS = "NEW_FILE_SUCCESS";
export const NEW_FILE_ERROR = "NEW_FILE_ERROR";
export const RESET_NEW_FILE = "RESET_NEW_FILE";

export interface NewFile {
  type: typeof NEW_FILE;
  payload: NewFileOutDto;
}

export interface NewFileSuccess {
  type: typeof NEW_FILE_SUCCESS;
  payload: NewFileInDto;
}

export interface NewFileError {
  type: typeof NEW_FILE_ERROR;
  payload: string | boolean;
}

export interface ResetNewFile {
  type: typeof RESET_NEW_FILE;
  payload: null;
}

export interface State {
  data: NewFileInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions = NewFile | NewFileSuccess | NewFileError | ResetNewFile;
