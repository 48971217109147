import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import { Card, Center } from "../../../../../components/StyledComponents";
import Grid from "../../../../../components/Grid";
import Button from "../../../../../components/Button";
import Input from "../../../../../components/Form/Input";
import Submit from "../../../../../components/Form/Submit";
import {
  schema,
  initialValues,
} from "../../../../../constants/form/profile/change-password";
import {
  changePassword,
  resetChangePassword,
} from "../../../../../store/actions/user";
import { UserDto } from "../../../../../types/user.dto";
import { toast } from "react-toastify";
interface ComponentProps {
  userInformation: UserDto;
  userInformationStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  changePasswordStates: {
    loading: boolean;
    success: boolean;
    error: boolean;
  };
}

const Component = ({
  userInformation,
  userInformationStates,
  changePasswordStates,
}: ComponentProps) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    dispatch(changePassword(values));
    setFormActions(actions);
  };

  useEffect(() => {
    if (changePasswordStates.success) {
      toast.success("👌🏼 Contraseña actualizada");
      setTimeout(() => {
        formActions?.resetForm();
        dispatch(resetChangePassword());
      }, 1000);
    }

    if (changePasswordStates.error) {
      toast.error(`😱 No se ha podido actualizar la contraseña`);
      setTimeout(() => {
        dispatch(resetChangePassword());
      }, 2000);
    }
  }, [changePasswordStates]);

  return (
    <>
      <h2>Cambiar contraseña</h2>
      <Card>
        <Formik
          initialValues={{ ...initialValues }}
          onSubmit={(values, actions) => {
            if (onSubmit) onSubmit({ values, actions });
          }}
          validateOnChange={formSubmmited}
          validateOnBlur={false}
          validationSchema={schema}
          enableReinitialize
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <form
                className="theme-form"
                onSubmit={(event) => {
                  setFormSubmmited(true);
                  handleSubmit(event);
                }}
                id="change-password"
              >
                <Grid.Row>
                  <Grid.Col>
                    <Input
                      name="oldPassword"
                      error={errors["oldPassword"]}
                      touched={touched["oldPassword"]}
                      value={values["oldPassword"]}
                      type="password"
                      placeholder=""
                      disabled={changePasswordStates.loading}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        label: "Contraseña actual *",
                        marginBottom: 24,
                      }}
                    />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col>
                    <Input
                      name="newPassword"
                      error={errors["newPassword"]}
                      touched={touched["newPassword"]}
                      value={values["newPassword"]}
                      type="password"
                      placeholder=""
                      disabled={changePasswordStates.loading}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        label: "Nueva contraseña *",
                        marginBottom: 24,
                      }}
                    />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col>
                    <Input
                      name="confirmNewPassword"
                      error={errors["confirmNewPassword"]}
                      touched={touched["confirmNewPassword"]}
                      value={values["confirmNewPassword"]}
                      type="password"
                      placeholder=""
                      disabled={changePasswordStates.loading}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        label: "Confirmar nueva contraseña *",
                        marginBottom: 24,
                      }}
                    />
                  </Grid.Col>
                </Grid.Row>

                <div style={{ height: "40px" }} />

                <Center horizontal="center" vertical="center">
                  <Submit
                    isSubmmiting={changePasswordStates.loading}
                    form="change-password"
                    color="Primary"
                    options={{
                      type: "filled",
                      skin: "primary",
                      size: "lg",
                      marginBottom: "0px",
                    }}
                  >
                    Confirmar
                  </Submit>

                  <Button
                    onClick={resetForm}
                    disabled={changePasswordStates.loading}
                    type="button"
                    options={{
                      type: "outline",
                      skin: "danger",
                      size: "lg",
                      marginBottom: "0px",
                    }}
                    style={{ marginLeft: "10px" }}
                  >
                    Cancelar
                  </Button>
                </Center>
              </form>
            );
          }}
        </Formik>
      </Card>
    </>
  );
};

const states = ({ userStore }) => {
  const { states: changePasswordStates } = userStore.changePassword;
  const { data: userInformation, states: userInformationStates } =
    userStore.information;

  return {
    userInformation,
    userInformationStates,
    changePasswordStates,
  };
};

export default connect(states)(Component);
