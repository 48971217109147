import action from "../helpers/rest-client";

export class ErrorJudicial extends Error {}

class Judicial {
  public new({ entity, entityId, files }) {
    const formData = new FormData();

    files.forEach(async (element) => {
      formData.append("files", element, element.name);
    });

    return action
      .Post({
        url: `/file/${entity}/${entityId}`,
        body: formData,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public edit({ entity, entityId, fileId, file }) {
    return action
      .Patch({
        url: `/file/${entity}/${entityId}/${fileId}`,
        body: file,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getAll({ entity, entityId }) {
    return action
      .Get({
        url: `/file/${entity}/${entityId}`,
      })
      .then((response) => {
        return { response: response?.success?.result };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getOne({ entity, entityId, file }) {
    return action
      .Get({
        url: `/file/${entity}/${entityId}/${file?.id}`,
      })
      .then((response) => {
        return { response: { data: response?.success?.result, ...file } };
      })
      .catch((error) => {
        return { error };
      });
  }

  public delete({ entity, entityId, fileId }) {
    return action
      .Del({
        url: `/file/${entity}/${entityId}/${fileId}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const judicial = new Judicial();
export default judicial;
