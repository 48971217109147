import {
  SelectOneFacturacionOutDto,
  SelectOneFacturacionInDto,
} from "../../../types/facturacion.dto";

export const SELECT_ONE_FACTURACION = "SELECT_ONE_FACTURACION";
export const SELECT_ONE_FACTURACION_SUCCESS = "SELECT_ONE_FACTURACION_SUCCESS";
export const SELECT_ONE_FACTURACION_ERROR = "SELECT_ONE_FACTURACION_ERROR";
export const RESET_SELECT_ONE_FACTURACION = "RESET_SELECT_ONE_FACTURACION";

export interface SelectOneFacturacion {
  type: typeof SELECT_ONE_FACTURACION;
  payload: SelectOneFacturacionOutDto;
}

export interface SelectOneFacturacionSuccess {
  type: typeof SELECT_ONE_FACTURACION_SUCCESS;
  payload: SelectOneFacturacionInDto;
}

export interface SelectOneFacturacionError {
  type: typeof SELECT_ONE_FACTURACION_ERROR;
  payload: string | boolean;
}

export interface ResetSelectOneFacturacion {
  type: typeof RESET_SELECT_ONE_FACTURACION;
  payload: null;
}

export interface State {
  data: SelectOneFacturacionInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | SelectOneFacturacion
  | SelectOneFacturacionSuccess
  | SelectOneFacturacionError
  | ResetSelectOneFacturacion;
