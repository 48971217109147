import {
  GetOneCalendarOutDto,
  GetOneCalendarInDto,
} from "../../../types/calendar.dto";

export const GET_ONE_CALENDAR = "GET_ONE_CALENDAR";
export const GET_ONE_CALENDAR_SUCCESS = "GET_ONE_CALENDAR_SUCCESS";
export const GET_ONE_CALENDAR_ERROR = "GET_ONE_CALENDAR_ERROR";
export const RESET_GET_ONE_CALENDAR = "RESET_GET_ONE_CALENDAR";

export interface GetOneCalendar {
  type: typeof GET_ONE_CALENDAR;
  payload: GetOneCalendarOutDto;
}

export interface GetOneCalendarSuccess {
  type: typeof GET_ONE_CALENDAR_SUCCESS;
  payload: GetOneCalendarInDto;
}

export interface GetOneCalendarError {
  type: typeof GET_ONE_CALENDAR_ERROR;
  payload: string | boolean;
}

export interface ResetGetOneCalendar {
  type: typeof RESET_GET_ONE_CALENDAR;
  payload: null;
}

export interface State {
  data: GetOneCalendarInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | GetOneCalendar
  | GetOneCalendarSuccess
  | GetOneCalendarError
  | ResetGetOneCalendar;
