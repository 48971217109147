import menu from "./menu";
import usersTypes from "./users-types";
import company from "./company";
import statics from "./statics";
import fileUploader from "./file-uploader";

const constants = {
  menu,
  usersTypes,
  company,
  statics,
  fileUploader,
};

export default constants;
