import styled from "styled-components";

const buttonStyles = {
  outline: {
    primary: {
      color: "Primary",
      borderColor: "Primary",
      backgroundColor: "Transparent",
    },
    secondary: {
      color: "Secondary",
      borderColor: "Secondary",
      backgroundColor: "Transparent",
    },
    gray: {
      color: "Black",
      borderColor: "Black",
      backgroundColor: "Transparent",
    },
    white: {
      color: "White",
      borderColor: "White",
      backgroundColor: "Transparent",
    },
    danger: {
      color: "Danger",
      borderColor: "Danger",
      backgroundColor: "Transparent",
    },
  },
  filled: {
    primary: {
      color: "White",
      borderColor: "Primary",
      backgroundColor: "Primary",
    },
    secondary: {
      color: "White",
      borderColor: "Secondary",
      backgroundColor: "Secondary",
    },
    gray: {
      color: "White",
      borderColor: "White",
      backgroundColor: "Transparent",
    },
    white: {
      color: "Primary",
      borderColor: "Primary",
      backgroundColor: "White",
    },
    danger: {
      color: "White",
      borderColor: "Danger",
      backgroundColor: "Danger",
    },
  },
  link: {
    primary: {
      color: "Primary",
      borderColor: "Transparent",
      backgroundColor: "Transparent",
    },
    secondary: {
      color: "Secondary",
      borderColor: "Transparent",
      backgroundColor: "Transparent",
    },
    gray: {
      color: "White",
      borderColor: "Transparent",
      backgroundColor: "Transparent",
    },
    white: {
      color: "White",
      borderColor: "Transparent",
      backgroundColor: "Transparent",
    },
    danger: {
      color: "Danger",
      borderColor: "Transparent",
      backgroundColor: "Transparent",
    },
  },
};

export const Button = styled("button")<{
  options;
  block?: boolean;
  disabled?: boolean;
}>`
  ${(props: any) => {
    const { options, theme, disabled } = props;
    const { size, type, skin, block, marginBottom } = options;

    return `
      font-family: 'Inter',sans-serif;
      cursor: pointer;
      border: none;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      ${
        !(type !== "common" && type !== "link") || skin === "transparent"
          ? ``
          : `border: 1px solid ${
              theme.colors[buttonStyles[type][skin].borderColor]
            }`
      };
      ${type !== "link" ? `height: ${theme.button.size[size].height}` : ``};
      background-color: ${
        !disabled
          ? theme.colors[buttonStyles[type][skin].backgroundColor] ||
            "transparent"
          : theme.colors.Black40
      };
      font-size: ${
        type === "link" ? "inherit" : theme.button.size[size].fontSize
      };
      color: ${theme.colors[buttonStyles[type][skin].color]};
      border-radius: ${theme.button.size[size].borderRadius};
      padding: ${type === "link" ? "0px" : "0px 17px"};
      text-decoration: none;
      letter-spacing: 0.2px;
      font-weight: ${type === "link" ? "normal" : "500"};
      ${block ? `width: 100%;` : ``}
      min-width: ${type === "link" ? "0" : "150px"};
      text-align: center;
      transition: all .2s ease-in-out;
      margin-bottom: ${marginBottom ? marginBottom : "0px"};

      &:hover {
        ${
          type !== "link"
            ? "box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);"
            : "text-decoration: underline;"
        }
        
      }
    `;
  }}
`;
