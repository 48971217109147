import styled from "styled-components";

export const Filters = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  z-index: 100;
  gap: 5px;
`;
