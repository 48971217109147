import { CSSProperties, FunctionComponent, useRef, useState } from "react";
import { useFormikFieldValidation } from "../../../hooks/useFormikFieldValidation";
import { ClearIndicatorProps } from "react-select";
import { Select } from "./styles";
import { CSSObject } from "@emotion/serialize";
import { Field as FormikField } from "formik";

import {
  FieldContainer,
  Field,
  Label,
  FieldBefore,
  HelperLine,
  HelperText,
  ErrorMessage,
  Required,
  Backdrop,
} from "../Field";
import Loader from "../../Spinner";

const initialValue = {
  options: {
    skin: "base",
    size: "md",
    loading: false,
  },
};

interface Option {
  label: string;
  value: string | number;
}

interface ComponentProps {
  name?: string;
  items?: Option | Option[];
  forwardRef?;
  placeholder?: string;
  readOnly?: boolean;
  options?: {
    label?: string;
    skin?: "base";
    size?: "md";
    helperText?: string;
    before?: any;
    after?: any;
    marginBottom?: number;
    hidden?: boolean;
    noOptionsText?: string;
    loading?: boolean;
  };
  style?: any;
  hasError?: boolean;
  error?: {};
  touched?: {};
  defaultValue?: Option | Option[];
  isMulti?: boolean;
  isClearable?: boolean;
  closeMenuOnSelect?: boolean;
  components?: any;
  disabled?: boolean;
  onOptionSelected?: (newValue, actionMeta: any) => void;
  isOptionDisabled?: (option: any, optionSelected: any) => boolean;
  onFocus?: (data: any) => void;
  onBlur?: (data: any) => void;
  onChange?: (data: any) => void;
}

const CustomClearText: FunctionComponent = () => <></>;
const ClearIndicator = (props: ClearIndicatorProps<Option, true>) => {
  const {
    children = <CustomClearText />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props) as CSSProperties}
    >
      <div style={{ padding: "0px 5px" }}>{children}</div>
    </div>
  );
};

const Component = ({
  name,
  items,
  error,
  touched,
  defaultValue,
  readOnly = false,
  isMulti = false,
  forwardRef,
  placeholder = "Selecciona...",
  onOptionSelected,
  isOptionDisabled,
  disabled,
  style,
  options,
  onFocus,
  onBlur,
  onChange,
  ...rest
}: ComponentProps) => {
  const { classes, setActive } = useFormikFieldValidation(
    error,
    name,
    disabled,
    readOnly
  );
  const [forcedValue, setForcedValue] = useState<Option | Option[] | null>(
    null
  );

  const hasError = !!error && !!touched;

  const { loading, skin, size, marginBottom, noOptionsText } = {
    ...initialValue.options,
    ...options,
  };

  const handleOnBlur = (event, callback) => {
    setActive(false);
    !!callback && callback(event);
  };

  const handleOnFocus = (event, callback) => {
    setActive(true);
    !!callback && callback(event);
  };

  return (
    <FieldContainer marginBottom={marginBottom}>
      {options && options.label && (
        <Label size={options?.size} htmlFor={`input-${name}`}>
          {options.label}
          {/* {rules && rules.required && <CustomSup>*</CustomSup>} */}
        </Label>
      )}

      <Field skin={skin} size={size} className={classes} style={style}>
        {loading && (
          <Backdrop>
            <Loader />
          </Backdrop>
        )}

        {options && options.before && (
          <FieldBefore size={options?.size} className="field-before">
            {options.before}
          </FieldBefore>
        )}

        <FormikField
          name={name}
          options={items}
          component={({ options: fieldOptions, field, form }) => {
            const handleChange = (newValue: Option | Option[], actionMeta) => {
              if (field.name) {
                form.setFieldValue(
                  field.name,
                  isMulti
                    ? (newValue as Option[]).map((item: Option) => item.value)
                    : (newValue as Option).value
                );
              }

              if (!!onOptionSelected) {
                onOptionSelected(
                  isMulti ? (newValue as Option[]) : (newValue as Option),
                  { ...actionMeta, setForcedValue }
                );
              }
            };

            const getValue = () => {
              if (fieldOptions) {
                return isMulti
                  ? fieldOptions.filter(
                      (option) => field.value?.indexOf(option.value) >= 0
                    )
                  : fieldOptions.find((option) => option.value === field.value);
              } else {
                return isMulti ? [] : ("" as any);
              }
            };

            if (!fieldOptions) {
              return <Loader />;
            }

            return (
              <Select
                variant="base"
                skin={skin}
                size={size}
                classNamePrefix="select"
                name={field.name}
                // styles={{
                //   container: (base: CSSObject) => ({
                //     ...base,
                //     flex: 1,
                //   }),
                //   menuList: (base: CSSObject) => ({
                //     ...base,
                //     textTransform: "capitalize",
                //   }),
                //   multiValueLabel: (base: CSSObject) => ({
                //     ...base,
                //     textTransform: "capitalize",
                //   }),
                //   valueContainer: (base: CSSObject) => ({
                //     ...base,
                //     textTransform: "capitalize",
                //   }),
                //   control: (base: CSSObject) => ({
                //     ...base,
                //     ...(hasError && { borderColor: "red" }),
                //   }),
                // }}

                options={fieldOptions}
                menuPlacement="auto"
                isOptionDisabled={(option) =>
                  isOptionDisabled
                    ? isOptionDisabled(option, getValue())
                    : false
                }
                noOptionsMessage={({ inputValue }) =>
                  inputValue ? noOptionsText : "No hay resultados"
                }
                {...(forwardRef && { ref: forwardRef })}
                value={forcedValue || getValue()}
                {...(!!defaultValue && { defaultValue })}
                onChange={handleChange}
                onBlur={(field) => handleOnBlur(field, onBlur)}
                //onFocus={(field) => handleOnFocus(field, onFocus)}
                isMulti={isMulti}
                isDisabled={loading || disabled}
                {...(isMulti && {
                  components: { ClearIndicator },
                  closeMenuOnSelect: false,
                })}
                placeholder={placeholder}
                {...rest}
                {...(readOnly && { menuIsOpen: false })}
                readOnly={readOnly}
              />
            );
          }}
        />
      </Field>
      {((options && options.helperText) || error) && (
        <HelperLine marginBottom={marginBottom}>
          {!!error && !!touched && <ErrorMessage>{error}</ErrorMessage>}
          {options && options.helperText && !error && (
            <HelperText>{options.helperText}</HelperText>
          )}
        </HelperLine>
      )}
    </FieldContainer>
  );
};

export default Component;
