import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import SweetAlert from "sweetalert2";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { Center, Card } from "../../../../components/StyledComponents";
import Wysiwyg from "../../../../components/Form/Wysiwyg";
import Grid from "../../../../components/Grid";
import Button from "../../../../components/Button";
import Input from "../../../../components/Form/Input";
import Submit from "../../../../components/Form/Submit";
import ReactSelect from "../../../../components/Form/ReactSelect";
import OtherContent from "../../../../components/OtherContent";
import {
  initialValues,
  schema,
} from "../../../../constants/form/facturacion/edit";
import {
  editFacturacion,
  getOneFacturacion,
  getStatics,
  resetEditFacturacion,
} from "../../../../store/actions/facturacion";
import { hideModal } from "../../../../store/actions/modal";
import { Action, useActions } from "../../../../provider/ActionsProvider";
import Icon from "../../../../components/Icon";
import CurrencyIcon from "../../../../assets/icons/dollar.svg";
import { GetAllPrejudicialesInDto } from "../../../../types/prejudicial.dto";
import { GetAllJudicialesInDto } from "../../../../types/judicial.dto";
import { getAllJudiciales } from "../../../../store/actions/judicial";
import { getAllPrejudiciales } from "../../../../store/actions/prejudicial";
import {
  FacturacionDto,
  GetOneFacturacionInDto,
} from "../../../../types/facturacion.dto";
import { useLayout } from "../../../../provider/LayoutProvider";
import { stripHtml } from "../../../../helpers/strip-html";
import { argentinaPesos } from "../../../../helpers/loca-currency";
import {
  dateSpliter,
  setToLocalTimeZone,
} from "../../../../helpers/data-handler";
import { preventEnter } from "../../../../helpers/prevent-enter";
import checkEmpty from "../../../../helpers/check-empty";
import { GetAllStaticsInDto } from "../../../../types/statics.dto";
import { EntitiesEnum } from "../../../../types/entities.enum";

interface ComponentProps {
  editFacturacionStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  facturacion: GetOneFacturacionInDto;
  facturacionStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  facturacionStatics;
  facturacionStaticsStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  judiciales: GetAllJudicialesInDto;
  judicialesStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  prejudiciales: GetAllPrejudicialesInDto;
  prejudicialesStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  statics: GetAllStaticsInDto;
  staticsStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
}

const type = {
  PREJUDICIAL: 1,
  JUDICIAL: 2,
  HONORARIOS_EXTRAORDINARIOS: 5,
};

const Component = ({
  editFacturacionStates,
  facturacion,
  facturacionStates,
  facturacionStaticsStates,
  facturacionStatics,
  judiciales,
  judicialesStates,
  prejudiciales,
  prejudicialesStates,
  statics,
  staticsStates,
}: ComponentProps) => {
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [formSubmmited, setFormSubmmited] = useState(false);
  const { setActions } = useActions();
  const dispatch = useDispatch();
  const { layout, setLayout } = useLayout();
  let { id } = useParams();

  const getEnabledStatus = (currentStatus) => {
    const envioFc = 7;
    const aprobado = 8;

    if (!currentStatus) {
      return null;
    }

    return facturacionStatics?.categories["status"]
      ?.filter((status) => {
        return currentStatus === aprobado
          ? status.id === aprobado || status.id === envioFc
          : true;
      })
      .map((status) => ({
        label: status.text,
        value: status.id,
      }));
  };

  const actions = useMemo(
    (): Action[] =>
      canEdit
        ? [
            {
              component: "submit",
              label: "Guardar cambios",
              form: "edit-facturacion-form",
              options: {
                type: "filled",
                skin: "primary",
                size: "lg",
                marginBottom: "0px",
              },
            },
            {
              component: "button",
              label: "Cancelar",
              onClick: () => setCanEdit(false),
              options: {
                type: "outline",
                skin: "danger",
                size: "lg",
                marginBottom: "0px",
              },
            },
          ]
        : [
            {
              component: "button",
              label: "Editar",
              onClick: () => setCanEdit(true),
              options: {
                type: "filled",
                skin: "primary",
                size: "lg",
                marginBottom: "0px",
              },
            },
          ],
    [canEdit, editFacturacionStates, facturacionStates]
  );

  const showMessage = () => {
    SweetAlert.fire({
      text: "No olvides cargar Nro de factura y OC. Luego podrás actualizar el estado.",
      showCancelButton: false,
      confirmButtonText: "Confirmar",
      allowEscapeKey: false,
    });
  };

  const onSubmit = ({ values, actions }) => {
    const envioFc = 7;

    if (
      values.status === envioFc &&
      (!values["nro-op"] || !values["factura-ndeg"])
    ) {
      showMessage();
    } else {
      const casos = values["casocontraro"]?.reduce(
        (previousValue, currentValue) => {
          const caso = casoContratos.filter(
            (element) => element.value === currentValue
          )[0];

          return {
            ...previousValue,
            [caso.type]: [...(previousValue[caso.type] || []), caso.value],
          };
        },
        {}
      );

      const payload = {
        ...(values["casocontraro"] && casos),
        ...(values["importe-2"] && {
          "importe-2": checkEmpty(values["importe-2"], "currency"),
        }),
        title: checkEmpty(values.title),
        status: checkEmpty(values.status),
        "fecha-start": checkEmpty(setToLocalTimeZone(values["fecha-start"])),
        tipo: [values.tipo],
        concepto: [values.concepto],
        descripcion: checkEmpty(values.descripcion),
        "fecha-orig-start": checkEmpty(
          setToLocalTimeZone(values["fecha-orig-start"])
        ),
        "compania-2": checkEmpty(values["compania-2"]),
        "nro-op": checkEmpty(values["nro-op"]),
        "factura-ndeg": checkEmpty(values["factura-ndeg"]),
        itemId: values["item-id"],
      };

      dispatch(editFacturacion(payload));
    }
  };

  const casoContratos = useMemo(
    (): {
      label: string;
      value: string;
      type: string;
    }[] => [
      ...(!!judiciales?.hints
        ? judiciales?.hints?.map((judicial) => ({
            label: judicial.caratula,
            value: judicial["item-id"],
            type: "casocontraro-judiciales",
          }))
        : []),
      ...(!!prejudiciales?.hints
        ? prejudiciales?.hints?.map((prejudicial) => ({
            label: prejudicial.title,
            value: prejudicial["item-id"],
            type: "casocontraro-pre-judiciales",
          }))
        : []),
      ...((statics?.contratos &&
        statics?.contratos?.map((contrato) => ({
          label: contrato.resumen,
          value: contrato.itemId,
          type: "casocontraro-contratos",
        }))) ||
        []),
    ],
    [judiciales, prejudiciales, statics]
  );

  useEffect(() => {
    if (editFacturacionStates.success) {
      dispatch(hideModal());
      setTimeout(() => {
        dispatch(resetEditFacturacion());
      }, 2000);
    }
  }, [editFacturacionStates]);

  useEffect(() => {
    dispatch(getStatics());
    !judiciales && dispatch(getAllJudiciales({ page: 0, limit: 5000 }));
    !prejudiciales && dispatch(getAllPrejudiciales({ page: 0, limit: 5000 }));
  }, []);

  useEffect(() => {
    setActions(actions);
  }, [canEdit]);

  useEffect(() => {
    if (editFacturacionStates.success) {
      toast.success("👌🏼 Facturación actualizada correctamente");
      setTimeout(() => {
        dispatch(resetEditFacturacion());
      }, 1000);
    }

    if (editFacturacionStates.error) {
      toast.error(`😱 No se ha podido actualizar la facturación`);
      setTimeout(() => {
        dispatch(resetEditFacturacion());
      }, 2000);
    }
  }, [editFacturacionStates]);

  useEffect(() => {
    if (!!id) {
      dispatch(getOneFacturacion({ itemId: id }));
    }
  }, [id]);

  useEffect(() => {
    if (!!facturacion) {
      setLayout({
        ...layout,
        title: String(facturacion?.title || facturacion["item-id"]),
        breadcrumb: String(facturacion?.title || facturacion["item-id"]),
        showBackButton: true,
      });
    }

    setActions(actions);
  }, [facturacion]);

  return (
    <Grid.ContainerFluid>
      <Grid.Row>
        <Grid.Col lg={10} offset={{ lg: 1 }}>
          <Grid.Row>
            <Grid.Col lg={6} offset={{ lg: 1 }}>
              <Formik
                initialValues={{
                  ...initialValues,
                  ...(!!facturacion && {
                    ...facturacion,
                    tipo: facturacion?.tipo[0],
                    concepto: facturacion.concepto[0],
                    "fecha-start": dateSpliter(facturacion["fecha-start"])
                      .inputMonth,
                    "importe-2": argentinaPesos(facturacion["importe-2"]),
                    casocontraro: [
                      ...(!!facturacion["casocontraro-judiciales"]
                        ? [facturacion["casocontraro-judiciales"]]
                        : []),
                      ...(!!facturacion["casocontraro-pre-judiciales"]
                        ? [facturacion["casocontraro-pre-judiciales"]]
                        : []),
                      ...(!!facturacion["casocontraro-contratos"]
                        ? [facturacion["casocontraro-contratos"]]
                        : []),
                    ].flat(),
                    "importe-total-honorarios": argentinaPesos(
                      facturacion["importe-total-honorarios"]
                    ),
                  }),
                }}
                onSubmit={(values, actions) => {
                  if (onSubmit) onSubmit({ values, actions });
                }}
                validateOnChange={formSubmmited}
                validateOnBlur={false}
                validationSchema={schema}
                enableReinitialize
              >
                {({
                  touched,
                  errors,
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  resetForm,
                  setFieldValue,
                  isSubmitting,
                }) => {
                  return (
                    <form
                      className="theme-form"
                      onSubmit={(event) => {
                        setFormSubmmited(true);
                        handleSubmit(event);
                      }}
                      onKeyDown={(event) => !canEdit && preventEnter(event)}
                      id="edit-facturacion-form"
                    >
                      <Grid.Row>
                        <Grid.Col>
                          <Input
                            name="title"
                            readOnly={!canEdit}
                            disabled={editFacturacionStates.loading}
                            error={errors["title"]}
                            touched={touched["title"]}
                            value={values["title"]}
                            type="text"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Titulo *",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Col lg={6}>
                          <Input
                            name="fecha-orig-start"
                            disabled={editFacturacionStates.loading}
                            error={errors["fecha-orig-start"]}
                            touched={touched["fecha-orig-start"]}
                            value={values["fecha-orig-start"]}
                            type="date"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            readOnly
                            options={{
                              label: "Fecha *",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={6}>
                          <Input
                            name="fecha-start"
                            readOnly={!canEdit}
                            disabled={editFacturacionStates.loading}
                            error={errors["fecha-start"]}
                            touched={touched["fecha-start"]}
                            value={values["fecha-start"]}
                            type="month"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Período a que corresponde *",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <ReactSelect
                            name="compania-2"
                            readOnly={!canEdit}
                            disabled={
                              editFacturacionStates.loading ||
                              facturacionStaticsStates.loading
                            }
                            error={errors["compania-2"]}
                            touched={touched["compania-2"]}
                            items={facturacionStatics?.categories[
                              "compania-2"
                            ]?.map((etapa) => ({
                              label: etapa.text,
                              value: etapa.id,
                            }))}
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Compañia *",
                              marginBottom: 24,
                              loading: facturacionStaticsStates.loading,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <ReactSelect
                            name="concepto"
                            readOnly={!canEdit}
                            disabled={
                              editFacturacionStates.loading ||
                              facturacionStaticsStates.loading
                            }
                            error={errors["concepto"]}
                            touched={touched["concepto"]}
                            items={facturacionStatics?.categories[
                              "concepto"
                            ]?.map((etapa) => ({
                              label: etapa.text,
                              value: etapa.id,
                            }))}
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Concepto *",
                              marginBottom: 24,
                              loading: facturacionStaticsStates.loading,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <ReactSelect
                            name="tipo"
                            readOnly={!canEdit}
                            disabled={
                              editFacturacionStates.loading ||
                              facturacionStaticsStates.loading
                            }
                            error={errors["tipo"]}
                            touched={touched["tipo"]}
                            items={facturacionStatics?.categories["tipo"]?.map(
                              (etapa) => ({
                                label: etapa.text,
                                value: etapa.id,
                              })
                            )}
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Tipo *",
                              marginBottom: 24,
                              loading: facturacionStaticsStates.loading,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      {(values["tipo"] === type.JUDICIAL ||
                        values["tipo"] === type.PREJUDICIAL) && (
                        <Grid.Row>
                          <Grid.Col>
                            <ReactSelect
                              name="casocontraro"
                              readOnly={!canEdit}
                              disabled={
                                editFacturacionStates.loading ||
                                judicialesStates.loading ||
                                prejudicialesStates.loading
                              }
                              error={errors["casocontraro"]}
                              touched={touched["casocontraro"]}
                              items={[
                                ...casoContratos.filter(
                                  (casoContrato) =>
                                    (values["tipo"] === type.JUDICIAL &&
                                      casoContrato.type ===
                                        "casocontraro-judiciales") ||
                                    (values["tipo"] === type.PREJUDICIAL &&
                                      casoContrato.type ===
                                        "casocontraro-pre-judiciales")
                                ),
                              ]}
                              placeholder="Seleccione"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isMulti
                              options={{
                                label: "Caso/s",
                                marginBottom: 24,
                                loading:
                                  judicialesStates.loading ||
                                  prejudicialesStates.loading,
                              }}
                            />
                          </Grid.Col>
                        </Grid.Row>
                      )}

                      {values["tipo"] === type.HONORARIOS_EXTRAORDINARIOS && (
                        <Grid.Row>
                          <Grid.Col>
                            <Input
                              name="importe-2"
                              readOnly={!canEdit}
                              disabled={editFacturacionStates.loading}
                              error={errors["importe-2"]}
                              touched={touched["importe-2"]}
                              value={values["importe-2"]}
                              placeholder=""
                              onChange={handleChange}
                              onBlur={handleBlur}
                              options={{
                                label: "Importe",
                                marginBottom: 24,
                                before: (
                                  <Icon
                                    icon={CurrencyIcon}
                                    size="14px"
                                    color="black"
                                  />
                                ),
                              }}
                            />
                          </Grid.Col>
                        </Grid.Row>
                      )}

                      <Grid.Row>
                        <Grid.Col>
                          <Input
                            name="importe-total-honorarios"
                            readOnly
                            disabled
                            error={errors["importe-total-honorarios"]}
                            touched={touched["importe-total-honorarios"]}
                            value={values["importe-total-honorarios"]}
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Importe total honorarios (solo lectura)",
                              marginBottom: 24,
                              before: (
                                <Icon
                                  icon={CurrencyIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <Input
                            name="nro-op"
                            readOnly={
                              !canEdit ||
                              (canEdit &&
                                values.status !== 7 &&
                                values.status !== 8)
                            }
                            disabled={editFacturacionStates.loading}
                            error={errors["nro-op"]}
                            touched={touched["nro-op"]}
                            value={values["nro-op"]}
                            type="text"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Número de OC",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <Input
                            name="factura-ndeg"
                            readOnly={
                              !canEdit ||
                              (canEdit &&
                                values.status !== 7 &&
                                values.status !== 8)
                            }
                            disabled={editFacturacionStates.loading}
                            error={errors["factura-ndeg"]}
                            touched={touched["factura-ndeg"]}
                            value={values["factura-ndeg"]}
                            type="text"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Factura Nro",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <ReactSelect
                            name="status"
                            readOnly={!canEdit || values["status"] !== 8}
                            disabled={
                              editFacturacionStates.loading ||
                              facturacionStaticsStates.loading
                            }
                            error={errors["status"]}
                            touched={touched["status"]}
                            items={getEnabledStatus(values["status"])}
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Estado",
                              marginBottom: 24,
                              loading: facturacionStaticsStates.loading,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <Wysiwyg
                            readOnly={!canEdit}
                            disabled={editFacturacionStates.loading}
                            toolbar={{ options: [] }}
                            name="descripcion"
                            setFieldValue={(val) =>
                              setFieldValue("descripcion", val)
                            }
                            value={values["descripcion"]}
                            style={{ minHeight: "113px" }}
                            options={{
                              label: "Descripción",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <div style={{ height: "50px" }} />

                      {canEdit && (
                        <Center horizontal="center" vertical="center">
                          <Submit
                            isSubmmiting={editFacturacionStates.loading}
                            form="edit-facturacion-form"
                            color="Primary"
                            options={{
                              type: "filled",
                              skin: "primary",
                              size: "lg",
                              marginBottom: "0px",
                            }}
                          >
                            Guardar cambios
                          </Submit>

                          <Button
                            onClick={() => setCanEdit(false)}
                            type="button"
                            options={{
                              type: "outline",
                              skin: "danger",
                              size: "lg",
                              marginBottom: "0px",
                            }}
                            style={{ marginLeft: "10px" }}
                          >
                            Cancelar
                          </Button>
                        </Center>
                      )}

                      <div style={{ height: "50px" }} />
                    </form>
                  );
                }}
              </Formik>
            </Grid.Col>
            <Grid.Col lg={4}>
              <Card
                style={{
                  position: "sticky",
                  top: "0",
                  height: "calc(100vh - 83px - 60px - 80px)",
                  minHeight: "485px",
                }}
              >
                <OtherContent entity={EntitiesEnum.FACTURACION} entityId={id} />
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
    </Grid.ContainerFluid>
  );
};

const states = ({
  facturacionStore,
  judicialStore,
  prejudicialStore,
  staticsStore,
}) => {
  const { states: editFacturacionStates } = facturacionStore.editOne;
  const { data: facturacionStatics, states: facturacionStaticsStates } =
    facturacionStore.statics;
  const { data: facturacion, states: facturacionStates } = facturacionStore.one;

  const { data: judiciales, states: judicialesStates } = judicialStore.all;
  const { data: prejudiciales, states: prejudicialesStates } =
    prejudicialStore.all;
  const { data: statics, states: staticsStates } = staticsStore;
  return {
    editFacturacionStates,
    facturacion,
    facturacionStates,
    facturacionStaticsStates,
    facturacionStatics,
    judiciales,
    judicialesStates,
    prejudiciales,
    prejudicialesStates,
    statics,
    staticsStates,
  };
};

export default connect(states)(Component);
