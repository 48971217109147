import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import auth from "./auth";
import user from "./user";
import modal from "./modal";
import judicial from "./judicial";
import prejudicial from "./prejudicial";
import calendar from "./calendar";
import pago from "./pago";
import facturacion from "./facturacion";
import statics from "./statics";
import files from "./files";
import activity from "./activity";

const createRootReducer = (history: any) =>
  combineReducers({
    routerStore: connectRouter(history),
    authStore: auth,
    modalStore: modal,
    userStore: user,
    judicialStore: judicial,
    prejudicialStore: prejudicial,
    calendarStore: calendar,
    pagoStore: pago,
    facturacionStore: facturacion,
    staticsStore: statics,
    filesStore: files,
    activityStore: activity,
  });

export default createRootReducer;
