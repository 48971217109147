import * as Yup from "yup";

export const schema = Yup.object().shape({
  caratula: Yup.string().nullable().required("Campo obligatorio."),
  "nro-expediente": Yup.string().nullable().required("Campo obligatorio."),
  category: Yup.number()
    .nullable()
    .typeError("Selecciona una opción.")
    .required("Selecciona una opción."),
  estado: Yup.number()
    .nullable()
    .typeError("Campo obligatorio.")
    .required("Campo obligatorio."),
  "actor-dni": Yup.string().nullable().required("Campo obligatorio."),
  "fecha-recepcion-demanda": Yup.string()
    .nullable()
    .required("Campo obligatorio"),
  "fecha-vencimiento-demanda": Yup.string()
    .nullable()
    .required("Campo obligatorio."),
  "fecha-despido": Yup.string().nullable().required("Campo obligatorio"),
  evaluacion: Yup.number()
    .nullable()
    .typeError("Campo obligatorio.")
    .required("Campo obligatorio"),
  contraparte: Yup.string().nullable().required("Campo obligatorio"),

  responsabilidad: Yup.number().nullable(),
  "estadoetapa-procesal": Yup.number()
    .nullable()
    .typeError("Campo obligatorio.")
    .required("Campo obligatorio"),
  jurisdiccionjuzgado: Yup.number().nullable(),
  "fecha-cierre": Yup.string().nullable(),
  "coberturaart-2": Yup.number().nullable(),
  "compania-2": Yup.number().nullable(),
  embargo: Yup.string().nullable(),
  codemandado: Yup.string().nullable(),
  "clienterazon-social": Yup.string().nullable(),
  "prevision-estudio": Yup.string().nullable(),
  "monto-conciliado-total": Yup.mixed()
    .nullable()
    .test("currencyCheck", "Ingresa un monto correcto", (value) => {
      return (
        !value || (!!value && /^[+-]?\d{1,3}(.?\d{3})*(,\d+)?$/gm.test(value))
      );
    }),
  "estimacion-estudio-conciliacion-3": Yup.mixed()
    .nullable()
    .test("currencyCheck", "Ingresa un monto correcto", (value) => {
      return (
        !value || (!!value && /^[+-]?\d{1,3}(.?\d{3})*(,\d+)?$/gm.test(value))
      );
    }),
  "monto-condena-1ra-inst-2": Yup.mixed()
    .nullable()
    .test("currencyCheck", "Ingresa un monto correcto", (value) => {
      return (
        !value || (!!value && /^[+-]?\d{1,3}(.?\d{3})*(,\d+)?$/gm.test(value))
      );
    }),
  "money-2": Yup.mixed()
    .nullable()
    .test("currencyCheck", "Ingresa un monto correcto", (value) => {
      return (
        !value || (!!value && /^[+-]?\d{1,3}(.?\d{3})*(,\d+)?$/gm.test(value))
      );
    }),
  "pericia-contable": Yup.mixed()
    .nullable()
    .test("currencyCheck", "Ingresa un monto correcto", (value) => {
      return (
        !value || (!!value && /^[+-]?\d{1,3}(.?\d{3})*(,\d+)?$/gm.test(value))
      );
    }),
  "ultima-pretension": Yup.mixed()
    .nullable()
    .test("currencyCheck", "Ingresa un monto correcto", (value) => {
      return (
        !value || (!!value && /^[+-]?\d{1,3}(.?\d{3})*(,\d+)?$/gm.test(value))
      );
    }),
  "ultimo-ofrecimiento": Yup.mixed()
    .nullable()
    .test("currencyCheck", "Ingresa un monto correcto", (value) => {
      return (
        !value || (!!value && /^[+-]?\d{1,3}(.?\d{3})*(,\d+)?$/gm.test(value))
      );
    }),
  observaciones: Yup.string().nullable(),
});

interface FormValues {
  caratula: string;
  "nro-expediente": string;
  category: number[] | [];
  responsabilidad?: number;
  "estadoetapa-procesal": number | null;
  estado?: number;
  "actor-dni": string;
  jurisdiccionjuzgado?: number;
  "fecha-cierre": string | null;
  "fecha-recepcion-demanda": string | null;
  "fecha-vencimiento-demanda": string | null;
  "coberturaart-2"?: number;
  "compania-2"?: number;
  embargo: string;
  codemandado: string;
  "clienterazon-social": string;
  "fecha-despido": string | null;
  evaluacion?: number;
  "prevision-estudio": string;
  "monto-conciliado-total": string;
  "estimacion-estudio-conciliacion-3": string;
  "monto-condena-1ra-inst-2": string;
  "money-2": string;
  contraparte: string;
  "pericia-contable": string;
  "ultima-pretension": string;
  "ultimo-ofrecimiento": string;
  observaciones: string;
}

export const initialValues: FormValues = {
  caratula: "",
  "nro-expediente": "",
  category: [],
  responsabilidad: undefined,
  "estadoetapa-procesal": null,
  estado: undefined,
  "actor-dni": "",
  jurisdiccionjuzgado: undefined,
  "fecha-cierre": null,
  "fecha-recepcion-demanda": null,
  "fecha-vencimiento-demanda": null,
  "coberturaart-2": undefined,
  "compania-2": undefined,
  embargo: "",
  codemandado: "",
  "clienterazon-social": "",
  "fecha-despido": null,
  evaluacion: undefined,
  "prevision-estudio": "",
  "monto-conciliado-total": "",
  "estimacion-estudio-conciliacion-3": "",
  "monto-condena-1ra-inst-2": "",
  "money-2": "",
  contraparte: "",
  "pericia-contable": "",
  "ultima-pretension": "",
  "ultimo-ofrecimiento": "",
  observaciones: "",
};
