export const argentinaPesos = (
  x,
  options?: {
    showCurrency: boolean;
  }
) => {
  if (!x) return "";
  const number = x.toString();

  return Number(number)
    .toLocaleString("es-AR", {
      ...(options?.showCurrency && {
        style: "currency",
        currency: "ARS",
      }),
    })
    .toString();
};
