import { DeletePagoOutDto, DeletePagoInDto } from "../../../types/pago.dto";

export const DELETE_PAGO = "DELETE_PAGO";
export const DELETE_PAGO_SUCCESS = "DELETE_PAGO_SUCCESS";
export const DELETE_PAGO_ERROR = "DELETE_PAGO_ERROR";
export const RESET_DELETE_PAGO = "RESET_DELETE_PAGO";

export interface DeletePago {
  type: typeof DELETE_PAGO;
  payload: DeletePagoOutDto;
}

export interface DeletePagoSuccess {
  type: typeof DELETE_PAGO_SUCCESS;
  payload: DeletePagoInDto;
}

export interface DeletePagoError {
  type: typeof DELETE_PAGO_ERROR;
  payload: string | boolean;
}

export interface ResetDeletePago {
  type: typeof RESET_DELETE_PAGO;
  payload: null;
}

export interface State {
  data: DeletePagoInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | DeletePago
  | DeletePagoSuccess
  | DeletePagoError
  | ResetDeletePago;
