import { Logo, Image } from "./styles";
import constants from "../../../../../constants";

const Component = () => {
  return (
    <Logo>
      <Image src={constants.company.logoBlack} alt="Dev Place" />
    </Logo>
  );
};

export default Component;
