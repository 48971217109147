import { put, call, all, takeLatest } from "redux-saga/effects";
import services from "../../services";

import {
  NewPago,
  NEW_PAGO,
  NEW_PAGO_SUCCESS,
  NEW_PAGO_ERROR,
} from "../types/pago/new";

import {
  EditPago,
  EDIT_PAGO,
  EDIT_PAGO_SUCCESS,
  EDIT_PAGO_ERROR,
} from "../types/pago/edit";

import {
  DeletePago,
  DELETE_PAGO,
  DELETE_PAGO_SUCCESS,
  DELETE_PAGO_ERROR,
} from "../types/pago/delete";

import {
  GetOnePago,
  GET_ONE_PAGO,
  GET_ONE_PAGO_SUCCESS,
  GET_ONE_PAGO_ERROR,
} from "../types/pago/getOne";

import {
  SelectOnePago,
  SELECT_ONE_PAGO,
  SELECT_ONE_PAGO_SUCCESS,
  SELECT_ONE_PAGO_ERROR,
} from "../types/pago/selectOne";

import {
  GetAllPagos,
  GET_ALL_PAGOS,
  GET_ALL_PAGOS_SUCCESS,
  GET_ALL_PAGOS_ERROR,
} from "../types/pago/getAll";

import {
  GetCommentsPago,
  GET_COMMENTS_PAGO,
  GET_COMMENTS_PAGO_SUCCESS,
  GET_COMMENTS_PAGO_ERROR,
} from "../types/pago/getComments";

import {
  GetStaticsPago,
  GET_STATICS_PAGO,
  GET_STATICS_PAGO_SUCCESS,
  GET_STATICS_PAGO_ERROR,
} from "../types/pago/statics";

function* getAllPagos({ payload }) {
  try {
    const { response, error } = yield call(services.pago.getAll, payload);

    if (error) {
      yield put({
        type: GET_ALL_PAGOS_ERROR,
        payload: error,
      });
    } else {
      yield put({ type: GET_ALL_PAGOS_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: GET_ALL_PAGOS_ERROR,
      payload: "Error inesperado al obtener todos los pagos.",
    });
  }
}

function* getOnePago({ payload }) {
  try {
    const { response, error } = yield call(services.pago.getOne, payload);

    if (error) {
      yield put({
        type: GET_ONE_PAGO_ERROR,
        payload: error,
      });
    } else {
      yield put({ type: GET_ONE_PAGO_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: GET_ONE_PAGO_ERROR,
      payload: "Error inesperado al obtener el pago seleccionado.",
    });
  }
}

function* selectOnePago({ payload }) {
  try {
    const { response, error } = yield call(services.pago.getOne, payload);

    if (error) {
      yield put({
        type: SELECT_ONE_PAGO_ERROR,
        payload: error,
      });
    } else {
      yield put({ type: SELECT_ONE_PAGO_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: SELECT_ONE_PAGO_ERROR,
      payload: "Error inesperado al seleccionar el pago.",
    });
  }
}

function* newPago({ payload }) {
  try {
    const { response, error } = yield call(services.pago.new, payload);

    if (error) {
      yield put({
        type: NEW_PAGO_ERROR,
        payload: error,
      });
    } else {
      yield all([
        put({ type: NEW_PAGO_SUCCESS, payload: response }),
        put({ type: GET_ALL_PAGOS, payload: null }),
      ]);
    }
  } catch {
    yield put({
      type: NEW_PAGO_ERROR,
      payload: "Error inesperado al crear un nuevo pago.",
    });
  }
}

function* editPago({ payload }) {
  try {
    const { response, error } = yield call(services.pago.edit, payload);

    if (error) {
      yield put({
        type: EDIT_PAGO_ERROR,
        payload: error,
      });
    } else {
      yield all([
        put({ type: EDIT_PAGO_SUCCESS, payload: response }),
        put({ type: GET_ALL_PAGOS, payload: null }),
      ]);
    }
  } catch {
    yield put({
      type: EDIT_PAGO_ERROR,
      payload: "Error inesperado al editar el pago seleccionado.",
    });
  }
}

function* deletePago({ payload }) {
  try {
    const { response, error } = yield call(services.pago.delete, payload);

    if (error) {
      yield put({
        type: DELETE_PAGO_ERROR,
        payload: error,
      });
    } else {
      yield all([
        put({ type: DELETE_PAGO_SUCCESS, payload: response }),
        put({ type: GET_ALL_PAGOS, payload: null }),
      ]);
    }
  } catch {
    yield put({
      type: DELETE_PAGO_ERROR,
      payload: "Error inesperado al eliminar el pago seleccionado.",
    });
  }
}

function* getCommentsPago({ payload }) {
  try {
    const { response, error } = yield call(services.pago.getComments, payload);

    if (error) {
      yield put({
        type: GET_COMMENTS_PAGO_ERROR,
        payload: error,
      });
    } else {
      yield put({ type: GET_COMMENTS_PAGO_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: GET_COMMENTS_PAGO_ERROR,
      payload: "Error inesperado al ontener comentarios del pago seleccionado.",
    });
  }
}

function* getStatics() {
  try {
    const { response, error } = yield call(services.pago.getStatics);

    if (error) {
      yield put({
        type: GET_STATICS_PAGO_ERROR,
        payload: error,
      });
    } else {
      yield put({ type: GET_STATICS_PAGO_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: GET_STATICS_PAGO_ERROR,
      payload: "Error inesperado al ontener los estaticos de pago.",
    });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllPagos>(GET_ALL_PAGOS, getAllPagos);
  yield takeLatest<GetOnePago>(GET_ONE_PAGO, getOnePago);
  yield takeLatest<EditPago>(EDIT_PAGO, editPago);
  yield takeLatest<DeletePago>(DELETE_PAGO, deletePago);
  yield takeLatest<NewPago>(NEW_PAGO, newPago);
  yield takeLatest<SelectOnePago>(SELECT_ONE_PAGO, selectOnePago);
  yield takeLatest<GetCommentsPago>(GET_COMMENTS_PAGO, getCommentsPago);
  yield takeLatest<GetStaticsPago>(GET_STATICS_PAGO, getStatics);
}
