import {
  NewFileOutDto,
  EditFileOutDto,
  DeleteFileOutDto,
  GetAllFilesOutDto,
  GetOneFileOutDto,
} from "../../types/file.dto";

import {
  NEW_FILE,
  NewFile,
  RESET_NEW_FILE,
  ResetNewFile,
} from "../types/files/new";

import {
  EDIT_FILE,
  EditFile,
  RESET_EDIT_FILE,
  ResetEditFile,
} from "../types/files/edit";

import {
  DELETE_FILE,
  RESET_DELETE_FILE,
  ResetDeleteFile,
  DeleteFile,
} from "../types/files/delete";

import {
  GET_ONE_FILE,
  GetOneFile,
  RESET_GET_ONE_FILE,
  ResetGetOneFile,
} from "../types/files/getOne";

import {
  GET_ALL_FILES,
  GetAllFiles,
  RESET_GET_ALL_FILES,
  ResetGetAllFiles,
} from "../types/files/getAll";

export const getOneFile = (payload: GetOneFileOutDto): GetOneFile => {
  return { type: GET_ONE_FILE, payload };
};

export const resetGetOneFile = (): ResetGetOneFile => {
  return { type: RESET_GET_ONE_FILE, payload: null };
};

export const getAllFiles = (payload: GetAllFilesOutDto): GetAllFiles => {
  return { type: GET_ALL_FILES, payload };
};

export const resetGetAllFiles = (): ResetGetAllFiles => {
  return { type: RESET_GET_ALL_FILES, payload: null };
};

export const newFile = (payload: NewFileOutDto): NewFile => {
  return { type: NEW_FILE, payload };
};

export const resetNewFile = (): ResetNewFile => {
  return { type: RESET_NEW_FILE, payload: null };
};

export const editFile = (payload: EditFileOutDto): EditFile => {
  return { type: EDIT_FILE, payload };
};

export const resetEditFile = (): ResetEditFile => {
  return { type: RESET_EDIT_FILE, payload: null };
};

export const deleteFile = (payload: DeleteFileOutDto): DeleteFile => {
  return { type: DELETE_FILE, payload };
};

export const resetDeleteFile = (): ResetDeleteFile => {
  return { type: RESET_DELETE_FILE, payload: null };
};
