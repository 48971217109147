import * as Yup from "yup";

export const schema = Yup.object().shape({
  code: Yup.string().required("Este campo es requerido."),
  email: Yup.string()
    .email("Ingrese un e-mail correcto.")
    .required("Este campo es requerido"),
  password: Yup.string()
    .required("Este campo es requerido")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Debe contener 8 caracateres, uno en mayuscula, uno en minuscula, un numero y un caracter especial."
    ),
  "confirm-password": Yup.string()
    .required("Este campo es requerido")
    .oneOf([Yup.ref("password"), null], "La contraseña no coincide"),
});

interface FormValues {
  code: string;
  email: string;
  password: string;
  "confirm-password": string;
}

export const initialValues: FormValues = {
  code: "",
  email: "",
  password: "",
  "confirm-password": "",
};
