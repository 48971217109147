import { GetAllFilesInDto, GetAllFilesOutDto } from "../../../types/file.dto";

export const GET_ALL_FILES = "GET_ALL_FILES";
export const GET_ALL_FILES_SUCCESS = "GET_ALL_FILES_SUCCESS";
export const GET_ALL_FILES_ERROR = "GET_ALL_FILES_ERROR";
export const RESET_GET_ALL_FILES = "RESET_GET_ALL_FILES";

export interface GetAllFiles {
  type: typeof GET_ALL_FILES;
  payload: GetAllFilesOutDto;
}

export interface GetAllFilesSuccess {
  type: typeof GET_ALL_FILES_SUCCESS;
  payload: GetAllFilesInDto;
}

export interface GetAllFilesError {
  type: typeof GET_ALL_FILES_ERROR;
  payload: string | boolean;
}

export interface ResetGetAllFiles {
  type: typeof RESET_GET_ALL_FILES;
  payload: null;
}

export interface State {
  data: GetAllFilesInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | GetAllFiles
  | GetAllFilesSuccess
  | GetAllFilesError
  | ResetGetAllFiles;
