import {
  SelectOneJudicialOutDto,
  SelectOneJudicialInDto,
} from "../../../types/judicial.dto";

export const SELECT_ONE_JUDICIAL = "SELECT_ONE_JUDICIAL";
export const SELECT_ONE_JUDICIAL_SUCCESS = "SELECT_ONE_JUDICIAL_SUCCESS";
export const SELECT_ONE_JUDICIAL_ERROR = "SELECT_ONE_JUDICIAL_ERROR";
export const RESET_SELECT_ONE_JUDICIAL = "RESET_SELECT_ONE_JUDICIAL";

export interface SelectOneJudicial {
  type: typeof SELECT_ONE_JUDICIAL;
  payload: SelectOneJudicialOutDto;
}

export interface SelectOneJudicialSuccess {
  type: typeof SELECT_ONE_JUDICIAL_SUCCESS;
  payload: SelectOneJudicialInDto;
}

export interface SelectOneJudicialError {
  type: typeof SELECT_ONE_JUDICIAL_ERROR;
  payload: string | boolean;
}

export interface ResetSelectOneJudicial {
  type: typeof RESET_SELECT_ONE_JUDICIAL;
  payload: null;
}

export interface State {
  data: SelectOneJudicialInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | SelectOneJudicial
  | SelectOneJudicialSuccess
  | SelectOneJudicialError
  | ResetSelectOneJudicial;
