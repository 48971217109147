import List from "./List";
import New from "./New";
import Preview from "./Preview";

const Pages = {
  List,
  New,
  Preview,
};

export default Pages;
