import styled from "styled-components";

export const Form = styled("form")`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  h3 {
    margin: 0px;
  }
`;

export const FullHeight = styled("div")`
  height: 100%;
  width: 100%;
  margin-top: 16px;
`;
