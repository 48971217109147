import styled from "styled-components";

export const Name = styled("div")`
  margin-top: 55px;
  font-size: 20px;
  font-weight: bold;
  color: #334253;
`;

export const Title = styled("h3")`
  margin: 45px 0px 30px;
  font-size: 20px;
  color: #182028;
`;
