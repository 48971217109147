import {
  initialState,
  Actions,
  State,
  NEW_ACTIVITY,
  NEW_ACTIVITY_SUCCESS,
  NEW_ACTIVITY_ERROR,
  RESET_NEW_ACTIVITY,
} from "../../types/activity/new";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case NEW_ACTIVITY:
      return {
        ...state,
        data: null,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case NEW_ACTIVITY_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case NEW_ACTIVITY_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          error: action.payload,
          success: false,
        },
      };
    case RESET_NEW_ACTIVITY:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducers;
