import {
  initialState,
  Actions,
  State,
  NEW_JUDICIAL,
  NEW_JUDICIAL_SUCCESS,
  NEW_JUDICIAL_ERROR,
  RESET_NEW_JUDICIAL,
} from "../../types/judicial/new";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case NEW_JUDICIAL:
      return {
        ...state,
        data: null,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case NEW_JUDICIAL_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case NEW_JUDICIAL_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: true,
          error: action.payload,
          success: false,
        },
      };
    case RESET_NEW_JUDICIAL:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducers;
