import { connect } from "react-redux";
import Banner from "./components/Banner";
import Avatar from "../../../components/Avatar";
import { Name } from "./styles";
import { UserDto } from "../../../types/user.dto";
import { useLayout } from "../../../provider/LayoutProvider";
import { useEffect } from "react";
import Grid from "../../../components/Grid";
import ChangePassword from "./components/ChangePassword";
import ChangeInformation from "./components/ChangeInformation";

interface ComponentProps {
  user: UserDto;
}

const Component = ({ user }: ComponentProps) => {
  const { layout, setLayout } = useLayout();

  useEffect(() => {
    setLayout({
      ...layout,
      showSubHeader: false,
      fullFillContent: true,
    });
  }, []);

  return (
    <>
      <Banner />

      <Grid.Container>
        <Grid.Row>
          <Grid.Col offset={{ lg: 1 }} sm={10}>
            <Grid.Row>
              <Grid.Col sm={3}>
                <Avatar size="xbig" style={{ marginTop: "-80px" }} />

                <Name>
                  {user?.nombre}, {user?.apellido}
                </Name>
                <p>{user?.email}</p>
              </Grid.Col>

              <Grid.Col sm={6}>
                <div style={{ height: "54px" }} />
                <ChangeInformation />
                <div style={{ height: "24px" }} />
                <ChangePassword />
                <div style={{ height: "54px" }} />
              </Grid.Col>

              <Grid.Col sm={3}>
                <></>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </>
  );
};

const states = ({ userStore }) => {
  const { data: user } = userStore.information;

  return {
    user,
  };
};

export default connect(states)(Component);
