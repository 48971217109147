import paymentMethod from "./paymentMethod";
import motivo from "./motivo";
import tipo from "./tipo";

const values = {
  paymentMethod,
  motivo,
  tipo,
};

export default values;
