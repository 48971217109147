import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { dateSpliter } from "../../../../helpers/data-handler";
import { DeleteActivity } from "../../../../store/types/activity/delete";
import { GetAllActivities } from "../../../../store/types/activity/getAll";
import { GetOneActivity } from "../../../../store/types/activity/getOne";
import { ActivityDto } from "../../../../types/activity.dto";
import { GetCommentInDto } from "../../../../types/comment.dto";
import { EntitiesEnum } from "../../../../types/entities.enum";
import Button from "../../../Button";
import Loading from "./components/Loading";

import Icon from "../../../Icon";
import {
  Comments,
  CommentsList,
  Comment,
  Text,
  Whom,
  Date,
  ExtraInformation,
  Scroll,
} from "./styles";
import { Center } from "../../../StyledComponents";

interface ComponentProps {
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
  entity: EntitiesEnum;
  entityId?: string;
  allComments: ActivityDto[];
  allCommentsStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  oneComment: GetOneActivity;
  oneCommentStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  deletedComment: DeleteActivity;
  deletedCommentStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
}

const Component = ({
  setShowForm,
  entity,
  entityId,
  allComments,
  allCommentsStates,
  oneComment,
  oneCommentStates,
  deletedComment,
  deletedCommentStates,
}: ComponentProps) => {
  return (
    <Comments>
      <Scroll>
        <CommentsList>
          {allCommentsStates.loading && (
            <Center horizontal="center" vertical="center">
              <Loading size={30} />
            </Center>
          )}
          {allComments?.map((comment) => {
            const createdAt = dateSpliter(comment.createdAt);
            const createdAtFormated = `${createdAt.day?.name?.short} ${createdAt.day?.number} de ${createdAt.month?.name?.short} del ${createdAt.year}`;

            return (
              <Comment>
                <Text>
                  {comment?.description.substring(
                    comment?.description.indexOf(":") + 1
                  )}
                </Text>

                <ExtraInformation>
                  <Whom>
                    {comment?.usuario?.nombre || ""}{" "}
                    {comment?.usuario?.apellido || ""}
                  </Whom>
                  <Date>{createdAtFormated}</Date>
                </ExtraInformation>
              </Comment>
            );
          })}
        </CommentsList>
      </Scroll>

      <Button
        onClick={() => setShowForm(true)}
        type="button"
        options={{
          type: "filled",
          skin: "primary",
          size: "lg",
          block: true,
          marginBottom: "0px",
        }}
      >
        Nuevo comentario
      </Button>
    </Comments>
  );
};

const state = ({ activityStore }) => {
  const { data: allComments, states: allCommentsStates } = activityStore.all;
  const { data: oneComment, states: oneCommentStates } = activityStore.one;
  const { data: deletedComment, states: deletedCommentStates } =
    activityStore.deleteOne;

  return {
    allComments,
    allCommentsStates,
    oneComment,
    oneCommentStates,
    deletedComment,
    deletedCommentStates,
  };
};

export default connect(state)(Component);
