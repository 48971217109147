import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import { Center } from "../../../../components/StyledComponents";
import Grid from "../../../../components/Grid";
import Input from "../../../../components/Form/Input";
import Textarea from "../../../../components/Form/TextArea";
import Checkbox from "../../../../components/Form/Checkbox";
import Submit from "../../../../components/Form/Submit";
import ReactSelect from "../../../../components/Form/ReactSelect";
import { initialValues, schema } from "../../../../constants/form/calendar/new";
import {
  getStatics,
  newCalendar,
  resetNewCalendar,
} from "../../../../store/actions/calendar";
import { toast } from "react-toastify";
import Icon from "../../../../components/Icon";
import { Action, useActions } from "../../../../provider/ActionsProvider";
import {
  minInputDate,
  setToLocalTimeZone,
} from "../../../../helpers/data-handler";
import MarkerIcon from "../../../../assets/icons/marker.svg";
import UsersIcon from "../../../../assets/icons/users.svg";
import { GetAllJudicialesInDto } from "../../../../types/judicial.dto";
import { GetAllPrejudicialesInDto } from "../../../../types/prejudicial.dto";
import { getAllStatics } from "../../../../store/actions/statics";
import { GetAllStaticsInDto } from "../../../../types/statics.dto";
import { getAllJudiciales } from "../../../../store/actions/judicial";
import { getAllPrejudiciales } from "../../../../store/actions/prejudicial";
import { useLayout } from "../../../../provider/LayoutProvider";
import Link from "../../../../components/Link";
import { useNavigate } from "react-router";
import { NewCalendarInDto } from "../../../../types/calendar.dto";
import arrowUpRightFromSquare from "../../../../assets/icons/arrow-up-right-from-square.svg";
import checkEmpty from "../../../../helpers/check-empty";
interface Participante {
  label: string;
  value: string;
  type: string;
}

interface Lugar {
  label: string;
  value: number;
  type: string;
  direccion: string;
}

interface Caso {
  label: string;
  value: string;
  type: string;
}
interface ComponentProps {
  newCalendarStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  newCalendarInformation: NewCalendarInDto;
  calendarStaticsStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  calendarStatics;
  judiciales: GetAllJudicialesInDto;
  judicialesStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  prejudiciales: GetAllPrejudicialesInDto;
  prejudicialesStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  statics: GetAllStaticsInDto;
  staticsStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
}

const Component = ({
  newCalendarStates,
  newCalendarInformation,
  calendarStaticsStates,
  calendarStatics,
  judiciales,
  judicialesStates,
  prejudiciales,
  prejudicialesStates,
  statics,
  staticsStates,
}: ComponentProps) => {
  const { layout, setLayout } = useLayout();
  const navigate = useNavigate();
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [casoLink, setCasoLink] = useState<string | null>(null);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const { setActions } = useActions();
  const dispatch = useDispatch();

  const createCasoLink = (value) => {
    if (!value || !casos) {
      return setCasoLink(null);
    }

    const selectedCaso = casos.filter((caso) => caso.value === value)[0];

    setCasoLink(
      `/dashboard/${selectedCaso?.type?.replace(
        "relationship-2-",
        ""
      )}/${value}`
    );
  };

  const actions = useMemo(
    (): Action[] => [
      {
        component: "submit",
        label: "Confirmar",
        form: "new-calendar-form",
        options: {
          type: "filled",
          skin: "primary",
          size: "lg",
          marginBottom: "0px",
        },
      },
      {
        component: "link",
        label: "Cancelar",
        href: "/dashboard/agenda/list",
        options: {
          type: "outline",
          skin: "danger",
          size: "lg",
          marginBottom: "0px",
        },
      },
    ],
    []
  );

  const participantes = useMemo(
    (): Participante[] =>
      !!statics
        ? [
            ...((statics.comerciales &&
              statics?.comerciales?.map((comercial) => ({
                label: comercial.name,
                value: comercial.itemId,
                type: "participants-comerciales",
              }))) ||
              []),
          ]
        : [],
    [statics]
  );

  const lugares = useMemo(
    (): Lugar[] =>
      !!statics
        ? [
            ...((statics?.clientes &&
              statics?.clientes?.map((cliente) => ({
                label: cliente.name || "Sin nombre",
                value: cliente.itemId,
                direccion: cliente.address2 || "",
                type: "relationship-clientes",
              }))) ||
              []),
            ...((statics.mediadores &&
              statics?.mediadores?.map((mediador) => ({
                label: mediador.titulo,
                value: mediador.itemId,
                direccion: mediador.direccion || "",
                type: "relationship-mediadores",
              }))) ||
              []),
            ...((statics.jurisdicciones &&
              statics.jurisdicciones.map((jurisdiccion) => ({
                label: jurisdiccion.titulo,
                value: jurisdiccion.itemId,
                direccion: jurisdiccion.direccion || "",
                type: "relationship-jurisdiccion",
              }))) ||
              []),
            ...((statics.sucursales &&
              statics.sucursales.map((sucursal) => ({
                label: sucursal.titulo,
                value: sucursal.itemId,
                direccion: sucursal.direccion || "",
                type: "relationship-sucursales",
              }))) ||
              []),
          ]
        : [],
    [statics]
  );

  const casos = useMemo(
    (): Caso[] => [
      ...(!!judiciales?.hints
        ? judiciales?.hints?.map((judicial) => ({
            label: judicial.caratula,
            value: judicial["item-id"],
            type: "relationship-2-judiciales",
          }))
        : []),
      ...(!!prejudiciales?.hints
        ? prejudiciales?.hints?.map((prejudicial) => ({
            label: prejudicial.title,
            value: prejudicial["item-id"],
            type: "relationship-2-pre-judiciales",
          }))
        : []),
      ...((statics?.contratos &&
        statics?.contratos?.map((contrato) => ({
          label: contrato.resumen,
          value: contrato.itemId,
          type: "relationship-2-contratos",
        }))) ||
        []),
    ],
    [judiciales, prejudiciales, statics]
  );

  const onSubmit = ({ values, actions }) => {
    console.log(values);

    const relationshipKey = lugares?.filter(
      (lugar) => lugar?.value === values["relationship"]
    );
    console.log({ relationshipKey });

    const relationship2Key = casos?.filter(
      (caso) => caso?.value === values["relationship-2"]
    );

    console.log({ relationship2Key });
    const participants = values["participants"].reduce(
      (previousValue, currentValue) => {
        const participante = participantes.filter(
          (element) => element.value === currentValue
        )[0];
        return {
          ...previousValue,
          [participante.type]: [
            ...(previousValue[participante.type] || []),
            participante.value,
          ],
        };
      },
      {}
    );

    const payload = {
      ...(relationship2Key[0] &&
        (values["tipo-2"] !== 4 || values["tipo-2"] !== 5) && {
          [relationship2Key[0].type]: checkEmpty(values["relationship-2"]),
        }),
      ...(relationshipKey[0] &&
        values["relationship"] && {
          [relationshipKey[0].type]: checkEmpty(values["relationship"]),
        }),
      ...(participants || []),
      "date-start": checkEmpty(
        setToLocalTimeZone(`${values["date-start"]}T${values["hour-start"]}:00`)
      ),
      "date-end": checkEmpty(
        setToLocalTimeZone(`${values["date-end"]}T${values["hour-end"]}:00`)
      ),
      "tipo-2": [values["tipo-2"]],
      type: [values.type],
      referncia: checkEmpty(values.referncia),
      direccion: checkEmpty(values.direccion),
      notes: checkEmpty(values["notes"]),
      tipo: 2,
    };

    dispatch(newCalendar(payload));
    setFormActions(actions);
  };

  useEffect(() => {
    setActions(actions);
    !statics && dispatch(getAllStatics());
    !judiciales && dispatch(getAllJudiciales({ page: 0, limit: 5000 }));
    !prejudiciales && dispatch(getAllPrejudiciales({ page: 0, limit: 5000 }));

    dispatch(getStatics());
    setLayout({ ...layout, fullFillContent: false, showBackButton: true });
  }, []);

  useEffect(() => {
    if (newCalendarStates.success && !!newCalendarInformation) {
      toast.success("👌🏼 Evento agregado al calendario");
      setTimeout(() => {
        formActions?.resetForm();
        dispatch(resetNewCalendar());
        navigate(`/dashboard/agenda/${newCalendarInformation["item-id"]}`, {
          replace: true,
        });
      }, 1000);
    }

    if (newCalendarStates.error) {
      toast.error(`😱 No se ha podido agregar el evento al calendario`);
      setTimeout(() => {
        dispatch(resetNewCalendar());
      }, 2000);
    }
  }, [newCalendarStates, newCalendarInformation]);

  return (
    <Formik
      initialValues={{ ...initialValues }}
      onSubmit={(values, actions) => {
        if (onSubmit) onSubmit({ values, actions });
      }}
      validateOnChange={formSubmmited}
      validateOnBlur={false}
      validationSchema={schema}
      enableReinitialize
    >
      {({
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        resetForm,
        setFieldValue,
      }) => {
        console.log({ values, errors });
        return (
          <form
            className="theme-form"
            onSubmit={(event) => {
              setFormSubmmited(true);
              handleSubmit(event);
            }}
            id="new-calendar-form"
          >
            <Grid.ContainerFluid>
              <Grid.Row>
                <Grid.Col lg={10} offset={{ lg: 1 }}>
                  <Grid.Row>
                    <Grid.Col lg={4}>
                      <h2>Datos del evento</h2>

                      <Grid.Row>
                        <Grid.Col>
                          <Input
                            name="referncia"
                            disabled={newCalendarStates.loading}
                            error={errors["referncia"]}
                            touched={touched["referncia"]}
                            value={values["referncia"]}
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Título *",
                              marginBottom: 20,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <Input
                            name="date-start"
                            disabled={newCalendarStates.loading}
                            error={errors["date-start"]}
                            touched={touched["date-start"]}
                            value={values["date-start"]}
                            type="date"
                            min={minInputDate()}
                            placeholder="Fecha inicio *"
                            onChange={(event) => {
                              handleChange(event);
                              if (!values["date-end"]) {
                                setFieldValue("date-end", event.target.value);
                              }
                            }}
                            onBlur={handleBlur}
                            options={{
                              label: "Fecha inicio *",
                              marginBottom: 0,
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col>
                          <Input
                            name="hour-start"
                            disabled={
                              values["all-day"].length > 0 ||
                              newCalendarStates.loading
                            }
                            error={errors["hour-start"]}
                            touched={touched["hour-start"]}
                            value={values["hour-start"]}
                            type="time"
                            placeholder="Hora inicio"
                            onChange={(event) => {
                              handleChange(event);

                              if (!values["hour-end"]) {
                                let [hours, minutes] =
                                  event.target.value.split(":");
                                minutes = Number(minutes) + 30;

                                if (minutes >= 60) {
                                  hours = Number(hours) + 1;
                                  minutes =
                                    minutes - 60 > 10
                                      ? minutes - 60
                                      : `0${minutes - 60}`;
                                }

                                setFieldValue(
                                  "hour-end",
                                  `${hours}:${minutes}`
                                );
                              }
                            }}
                            onBlur={handleBlur}
                            options={{
                              label: "Hora inicio *",
                              marginBottom: 0,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <Checkbox
                            name="all-day"
                            disabled={newCalendarStates.loading}
                            value={values["all-day"]}
                            error={errors["all-day"]}
                            touched={touched["all-day"]}
                            items={[{ value: "true", label: "Todo el día" }]}
                            onChange={(event) => {
                              if (event.currentTarget.checked) {
                                setFieldValue("date-end", values["date-start"]);
                                setFieldValue("hour-start", "00:00");
                                setFieldValue("hour-end", "23:59");
                              } else {
                                setFieldValue("date-end", null);
                                setFieldValue("hour-end", null);
                              }

                              handleChange(event);
                            }}
                            onBlur={handleBlur}
                            options={{
                              marginBottom: 20,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <Input
                            name="date-end"
                            disabled={
                              values["all-day"].length > 0 ||
                              newCalendarStates.loading
                            }
                            error={errors["date-end"]}
                            touched={touched["date-end"]}
                            value={values["date-end"]}
                            type="date"
                            min={minInputDate()}
                            placeholder="Fecha fin"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Fecha fin *",
                              marginBottom: 20,
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col>
                          <Input
                            name="hour-end"
                            disabled={
                              values["all-day"].length > 0 ||
                              newCalendarStates.loading
                            }
                            error={errors["hour-end"]}
                            touched={touched["hour-end"]}
                            value={values["hour-end"]}
                            type="time"
                            placeholder="Hora fin"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Hora fin *",
                              marginBottom: 20,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <ReactSelect
                            name="participants"
                            disabled={
                              newCalendarStates.loading || staticsStates.loading
                            }
                            error={errors["participants"]}
                            touched={touched["participants"]}
                            items={participantes}
                            placeholder="Agregar participantes"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isMulti
                            options={{
                              before: (
                                <Icon
                                  icon={UsersIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                              loading: staticsStates.loading,
                              label: "Participantes",
                              marginBottom: 20,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <ReactSelect
                            name="relationship"
                            disabled={
                              newCalendarStates.loading || staticsStates.loading
                            }
                            error={errors["relationship"]}
                            touched={touched["relationship"]}
                            items={lugares.filter((lugar) => {
                              const tipo = Number(values["tipo-2"]);
                              const judicial = 2;
                              const prejudicial = 3;

                              if (tipo === judicial) {
                                return (
                                  lugar.type === "relationship-jurisdiccion"
                                );
                              }

                              if (tipo === prejudicial) {
                                return lugar.type === "relationship-mediadores";
                              }

                              return true;
                            })}
                            placeholder=""
                            onChange={handleChange}
                            onOptionSelected={(data) => {
                              setFieldValue("direccion", data.direccion);
                            }}
                            onBlur={handleBlur}
                            options={{
                              before: (
                                <Icon
                                  icon={MarkerIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                              loading: staticsStates.loading,
                              label: "Lugar",
                              marginBottom: 20,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <Input
                            name="direccion"
                            disabled={newCalendarStates.loading}
                            error={errors["direccion"]}
                            touched={touched["direccion"]}
                            value={values["direccion"]}
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              before: (
                                <Icon
                                  icon={MarkerIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                              label: "Dirección",
                              marginBottom: 20,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>
                    </Grid.Col>

                    <Grid.Col lg={4}>
                      <h2>Información adicional</h2>

                      <Grid.Row>
                        <Grid.Col>
                          <ReactSelect
                            name="tipo-2"
                            disabled={
                              newCalendarStates.loading ||
                              calendarStaticsStates.loading
                            }
                            error={errors["tipo-2"]}
                            touched={touched["tipo-2"]}
                            items={calendarStatics?.categories["type-2"]
                              .filter((type2) => type2.status === "active")
                              .map((type2) => ({
                                label: type2.text,
                                value: type2.id,
                              }))}
                            placeholder="Seleccione"
                            onOptionSelected={(data) => {
                              setFieldValue("relationship-2", null);
                            }}
                            onChange={(event) => {
                              handleChange(event);

                              if (
                                event?.target?.value &&
                                event?.target?.value !== 4 &&
                                event?.target?.value !== 5
                              ) {
                                const firstcase = casos?.filter(
                                  (caso) =>
                                    (caso.type === "relationship-2-contratos" &&
                                      event.target.value === 1) ||
                                    (caso.type ===
                                      "relationship-2-judiciales" &&
                                      event.target.value === 2) ||
                                    (caso.type ===
                                      "relationship-2-pre-judiciales" &&
                                      event.target.value === 3)
                                )[0].value;

                                setFieldValue("relationship-2", firstcase);
                              } else {
                                setFieldValue("relationship-2", undefined);
                              }
                            }}
                            onBlur={handleBlur}
                            options={{
                              label: "Tipo *",
                              marginBottom: 20,
                              loading: calendarStaticsStates.loading,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      {!!values &&
                        !!values["tipo-2"] &&
                        values["tipo-2"] !== 4 &&
                        values["tipo-2"] !== 5 && (
                          <Grid.Row>
                            <Grid.Col>
                              <Center style={{ gap: "8px" }}>
                                <ReactSelect
                                  name="relationship-2"
                                  disabled={
                                    newCalendarStates.loading ||
                                    prejudicialesStates.loading ||
                                    judicialesStates.loading
                                  }
                                  error={errors["relationship-2"]}
                                  touched={touched["relationship-2"]}
                                  items={casos.filter(
                                    (caso) =>
                                      (caso.type ===
                                        "relationship-2-contratos" &&
                                        values["tipo-2"] === 1) ||
                                      (caso.type ===
                                        "relationship-2-judiciales" &&
                                        values["tipo-2"] === 2) ||
                                      (caso.type ===
                                        "relationship-2-pre-judiciales" &&
                                        values["tipo-2"] === 3)
                                  )}
                                  placeholder=""
                                  onChange={handleChange}
                                  onOptionSelected={(data) => {
                                    setCasoLink(null);
                                    createCasoLink(data.value);
                                  }}
                                  onBlur={handleBlur}
                                  options={{
                                    label: "Caso *",
                                    marginBottom: 20,
                                    loading:
                                      prejudicialesStates.loading ||
                                      judicialesStates.loading,
                                  }}
                                />

                                {casoLink &&
                                  (values["tipo-2"] === 2 ||
                                    values["tipo-2"] === 3) && (
                                    <a
                                      target="_blank"
                                      style={{
                                        textDecoration: "none",
                                        marginRight: "8px",
                                        marginTop: "24px",
                                        height: "38px",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                      href={casoLink}
                                    >
                                      <Icon
                                        icon={arrowUpRightFromSquare}
                                        size="16px"
                                        color="black"
                                      />
                                    </a>
                                  )}
                              </Center>
                            </Grid.Col>
                          </Grid.Row>
                        )}

                      <Grid.Row>
                        <Grid.Col>
                          <ReactSelect
                            name="type"
                            disabled={
                              newCalendarStates.loading ||
                              calendarStaticsStates.loading
                            }
                            error={errors["type"]}
                            touched={touched["type"]}
                            items={calendarStatics?.categories?.type
                              ?.filter((type) => type.status === "active")
                              .map((type) => ({
                                label: type.text,
                                value: type.id,
                              }))}
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              loading: calendarStaticsStates.loading,
                              label: "Motivo *",
                              marginBottom: 20,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <Textarea
                            name="notes"
                            disabled={newCalendarStates.loading}
                            error={errors["notes"]}
                            touched={touched["notes"]}
                            value={values["notes"]}
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{ minHeight: "113px" }}
                            options={{
                              label: "Notas",
                              marginBottom: 20,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>
                    </Grid.Col>
                  </Grid.Row>
                </Grid.Col>
              </Grid.Row>
            </Grid.ContainerFluid>

            <div style={{ height: "50px" }} />

            <Center horizontal="center" vertical="center">
              <Submit
                isSubmmiting={newCalendarStates.loading}
                form="new-calendar-form"
                color="Primary"
                options={{
                  type: "filled",
                  skin: "primary",
                  size: "lg",
                  marginBottom: "0px",
                }}
              >
                Confirmar
              </Submit>

              <Link
                href="/dashboard/agenda/list"
                options={{
                  type: "outline",
                  skin: "danger",
                  size: "lg",
                  marginBottom: "0px",
                }}
                style={{ marginLeft: "10px" }}
              >
                Cancelar
              </Link>
            </Center>

            <div style={{ height: "50px" }} />
          </form>
        );
      }}
    </Formik>
  );
};

const states = ({
  calendarStore,
  judicialStore,
  prejudicialStore,
  staticsStore,
}) => {
  const { states: newCalendarStates, data: newCalendarInformation } =
    calendarStore.newOne;
  const { data: calendarStatics, states: calendarStaticsStates } =
    calendarStore.statics;
  const { data: judiciales, states: judicialesStates } = judicialStore.all;
  const { data: prejudiciales, states: prejudicialesStates } =
    prejudicialStore.all;
  const { data: statics, states: staticsStates } = staticsStore;

  return {
    newCalendarStates,
    newCalendarInformation,
    calendarStaticsStates,
    calendarStatics,
    judiciales,
    judicialesStates,
    prejudiciales,
    prejudicialesStates,
    statics,
    staticsStates,
  };
};

export default connect(states)(Component);
