import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import { Center, Card } from "../../../../components/StyledComponents";
import Wysiwyg from "../../../../components/Form/Wysiwyg";
import Grid from "../../../../components/Grid";
import Link from "../../../../components/Link";
import Input from "../../../../components/Form/Input";
import Submit from "../../../../components/Form/Submit";
import ReactSelect from "../../../../components/Form/ReactSelect";
import Tabs from "../../../../components/OtherContent";
import {
  initialValues,
  schema,
} from "../../../../constants/form/prejudicial/new";
import {
  getStatics,
  newPrejudicial,
  resetNewPrejudicial,
} from "../../../../store/actions/prejudicial";
import { hideModal } from "../../../../store/actions/modal";
import { Action, useActions } from "../../../../provider/ActionsProvider";
import Icon from "../../../../components/Icon";
import CurrencyIcon from "../../../../assets/icons/dollar.svg";
import PercentageIcon from "../../../../assets/icons/percentage.svg";
import constants from "../../../../constants";
import { resetNewJudicial } from "../../../../store/actions/judicial";
import { toast } from "react-toastify";
import { getAllStatics } from "../../../../store/actions/statics";
import { GetAllStaticsInDto } from "../../../../types/statics.dto";
import { setToLocalTimeZone } from "../../../../helpers/data-handler";
import { useLayout } from "../../../../provider/LayoutProvider";
import { NewPrejudicialInDto } from "../../../../types/prejudicial.dto";
import { useNavigate } from "react-router";
import checkEmpty from "../../../../helpers/check-empty";
interface ComponentProps {
  newPrejudicialStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  newPrejudicialInformation: NewPrejudicialInDto;
  prejudicialStaticsStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  prejudicialStatics;
  statics: GetAllStaticsInDto;
  staticsStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
}

const Component = ({
  newPrejudicialStates,
  newPrejudicialInformation,
  prejudicialStaticsStates,
  prejudicialStatics,
  statics,
  staticsStates,
}: ComponentProps) => {
  const { layout, setLayout } = useLayout();
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const { setActions } = useActions();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const actions = useMemo(
    (): Action[] => [
      {
        component: "submit",
        label: "Confirmar",
        form: "new-prejudicial-form",
        options: {
          type: "filled",
          skin: "primary",
          size: "lg",
          marginBottom: "0px",
        },
      },
      {
        component: "link",
        label: "Cancelar*",
        href: "/dashboard/prejudiciales/list",
        options: {
          type: "outline",
          skin: "danger",
          size: "lg",
          marginBottom: "0px",
        },
      },
    ],
    []
  );

  const mediadores = useMemo(
    (): { label: string; value: string }[] =>
      !!statics
        ? [
            ...((statics.mediadores &&
              statics?.mediadores?.map((mediador) => ({
                label: mediador.titulo,
                value: mediador.itemId,
              }))) ||
              []),
          ]
        : [],
    [statics]
  );

  const onSubmit = ({ values, actions }) => {
    //console.log(values["fecha-de-recepcion-citacion-a-seclo-start"]);
    //     value={values["pretension"]}
    // value={values["1-0-0-rubros-ley"]}
    // value={values["monto-conciliado-adecco"]}
    // value={values["monto-responsabilidad-adecco"]}
    // value={values["honorarios-abogado-actor-2"]}
    // value={values["honorarios-conciliador-2"]}

    const payload = {
      title: checkEmpty(values.title),
      tipo: checkEmpty(values.tipo),
      fuero: checkEmpty(values.fuero),
      compania: checkEmpty(values.compania),
      "clienterazon-social": checkEmpty(values["clienterazon-social"]),
      observaciones: checkEmpty(values.observaciones),
      "observaciones-2": checkEmpty(values["observaciones-2"]),
      "jurisdiccion-2": checkEmpty(values["jurisdiccion-2"]),
      "actor-dni": checkEmpty(values["actor-dni"], "dni"),
      "fecha-de-recepcion-citacion-a-seclo-start": checkEmpty(
        setToLocalTimeZone(values["fecha-de-recepcion-citacion-a-seclo-start"])
      ),
      "fecha-de-audiencia-start": checkEmpty(
        setToLocalTimeZone(values["fecha-de-audiencia-start"])
      ),
      estado: [values.estado],
      "nro-audiencias-2": checkEmpty(values["nro-audiencias-2"]),
      pretension: checkEmpty(values.pretension, "currency"),
      "1-0-0-rubros-ley": checkEmpty(values["1-0-0-rubros-ley"], "currency"),
      "monto-conciliado-adecco": checkEmpty(
        values["monto-conciliado-adecco"],
        "currency"
      ),
      "monto-responsabilidad-adecco": checkEmpty(
        values["monto-responsabilidad-adecco"],
        "currency"
      ),
      "monto-responsabilidad-usuaria": checkEmpty(
        values["monto-responsabilidad-usuaria"],
        "currency"
      ),
      "honorarios-abogado-actor-2": checkEmpty(
        values["honorarios-abogado-actor-2"],
        "currency"
      ),
      "honorarios-conciliador-2": checkEmpty(
        values["honorarios-conciliador-2"],
        "currency"
      ),
      "tasa-2": checkEmpty(values["tasa-2"]),
    };

    dispatch(newPrejudicial(payload));
    setFormActions(actions);
  };

  useEffect(() => {
    if (newPrejudicialStates.success) {
      dispatch(hideModal());
      setTimeout(() => {
        dispatch(resetNewPrejudicial());
      }, 2000);
    }
  }, [newPrejudicialStates]);

  useEffect(() => {
    setActions(actions);
    dispatch(getStatics());
    !statics && dispatch(getAllStatics());
    setLayout({ ...layout, showBackButton: true });
  }, []);

  useEffect(() => {
    if (newPrejudicialStates.success && !!newPrejudicialInformation) {
      toast.success("👌🏼 Prejudicial creado correctamente");
      setTimeout(() => {
        formActions?.resetForm();
        dispatch(resetNewJudicial());
        navigate(
          `/dashboard/prejudiciales/${newPrejudicialInformation["item-id"]}`,
          {
            replace: true,
          }
        );
      }, 1000);
    }

    if (newPrejudicialStates.error) {
      toast.error(`😱 No se ha podido crear el prejudicial`);
      setTimeout(() => {
        dispatch(resetNewJudicial());
      }, 2000);
    }
  }, [newPrejudicialStates, newPrejudicialInformation]);

  return (
    <Formik
      initialValues={{ ...initialValues }}
      onSubmit={(values, actions) => {
        if (onSubmit) onSubmit({ values, actions });
      }}
      validateOnChange={formSubmmited}
      validateOnBlur={false}
      validationSchema={schema}
      enableReinitialize
    >
      {({
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        resetForm,
        setFieldValue,
        isSubmitting,
      }) => {
        return (
          <form
            className="theme-form"
            onSubmit={(event) => {
              setFormSubmmited(true);
              handleSubmit(event);
            }}
            id="new-prejudicial-form"
          >
            <Grid.ContainerFluid>
              <Grid.Row>
                <Grid.Col lg={10} offset={{ lg: 1 }}>
                  <Grid.Row>
                    <Grid.Col lg={8}>
                      <Grid.Row>
                        <Grid.Col>
                          <Input
                            name="title"
                            disabled={newPrejudicialStates.loading}
                            error={errors["title"]}
                            touched={touched["title"]}
                            value={values["title"]}
                            type="text"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Titulo *",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <ReactSelect
                            name="tipo"
                            disabled={newPrejudicialStates.loading}
                            error={errors["tipo"]}
                            touched={touched["tipo"]}
                            items={prejudicialStatics?.categories?.tipo?.map(
                              (tipo) => ({
                                label: tipo.text,
                                value: tipo.id,
                              })
                            )}
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Tipo *",
                              marginBottom: 24,
                              loading: prejudicialStaticsStates.loading,
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col>
                          <ReactSelect
                            name="fuero"
                            disabled={newPrejudicialStates.loading}
                            error={errors["fuero"]}
                            touched={touched["fuero"]}
                            items={prejudicialStatics?.categories?.fuero?.map(
                              (fuero) => ({
                                label: fuero.text,
                                value: fuero.id,
                              })
                            )}
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Fuero *",
                              marginBottom: 24,
                              loading: prejudicialStaticsStates.loading,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <ReactSelect
                            name="compania"
                            disabled={newPrejudicialStates.loading}
                            error={errors["compania"]}
                            touched={touched["compania"]}
                            items={prejudicialStatics?.categories?.compania?.map(
                              (compania) => {
                                return {
                                  label: compania.text,
                                  value: compania.id,
                                };
                              }
                            )}
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Empleador real",
                              marginBottom: 24,
                              loading: prejudicialStaticsStates.loading,
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col>
                          <Input
                            name="clienterazon-social"
                            disabled={newPrejudicialStates.loading}
                            error={errors["clienterazon-social"]}
                            touched={touched["clienterazon-social"]}
                            value={values["clienterazon-social"]}
                            type="text"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Cliente",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <Input
                            name="observaciones"
                            disabled={newPrejudicialStates.loading}
                            error={errors["observaciones"]}
                            touched={touched["observaciones"]}
                            value={values["observaciones"]}
                            type="text"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Partes del proceso",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col>
                          <ReactSelect
                            name="jurisdiccion-2"
                            disabled={newPrejudicialStates.loading}
                            error={errors["jurisdiccion-2"]}
                            touched={touched["jurisdiccion-2"]}
                            items={mediadores}
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Mediadores *",
                              marginBottom: 24,
                              loading: staticsStates.loading,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col lg={6}>
                          <Input
                            name="actor-dni"
                            disabled={newPrejudicialStates.loading}
                            error={errors["actor-dni"]}
                            touched={touched["actor-dni"]}
                            value={values["actor-dni"]}
                            type="text"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Actor DNI *",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={3}>
                          <Input
                            name="fecha-de-recepcion-citacion-a-seclo-start"
                            disabled={newPrejudicialStates.loading}
                            error={
                              errors[
                                "fecha-de-recepcion-citacion-a-seclo-start"
                              ]
                            }
                            touched={
                              touched[
                                "fecha-de-recepcion-citacion-a-seclo-start"
                              ]
                            }
                            value={
                              values[
                                "fecha-de-recepcion-citacion-a-seclo-start"
                              ]
                            }
                            type="date"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Fecha recepción citación",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={3}>
                          <Input
                            name="fecha-de-audiencia-start"
                            disabled={newPrejudicialStates.loading}
                            error={errors["fecha-de-audiencia-start"]}
                            touched={touched["fecha-de-audiencia-start"]}
                            value={values["fecha-de-audiencia-start"]}
                            type="date"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Fecha audiencia *",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col lg={3}>
                          <ReactSelect
                            name="estado"
                            disabled={newPrejudicialStates.loading}
                            error={errors["estado"]}
                            touched={touched["estado"]}
                            items={prejudicialStatics?.categories?.estado?.map(
                              (estado) => ({
                                label: estado.text,
                                value: estado.id,
                              })
                            )}
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Estado *",
                              marginBottom: 24,
                              loading: prejudicialStaticsStates.loading,
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={3}>
                          <ReactSelect
                            name="nro-audiencias-2"
                            disabled={newPrejudicialStates.loading}
                            error={errors["nro-audiencias-2"]}
                            touched={touched["nro-audiencias-2"]}
                            items={
                              constants.statics.prejudiciales.numeroAudiencias
                            }
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Nro. Audiencias *",
                              marginBottom: 24,
                              loading: prejudicialStaticsStates.loading,
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={3}>
                          <Input
                            name="pretension"
                            disabled={newPrejudicialStates.loading}
                            error={errors["pretension"]}
                            touched={touched["pretension"]}
                            value={values["pretension"]}
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Pretensión",
                              marginBottom: 24,
                              before: (
                                <Icon
                                  icon={CurrencyIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={3}>
                          <Input
                            name="1-0-0-rubros-ley"
                            disabled={newPrejudicialStates.loading}
                            error={errors["1-0-0-rubros-ley"]}
                            touched={touched["1-0-0-rubros-ley"]}
                            value={values["1-0-0-rubros-ley"]}
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Ofrecimiento",
                              marginBottom: 24,
                              before: (
                                <Icon
                                  icon={CurrencyIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col lg={4}>
                          <Input
                            name="monto-conciliado-adecco"
                            disabled={newPrejudicialStates.loading}
                            error={errors["monto-conciliado-adecco"]}
                            touched={touched["monto-conciliado-adecco"]}
                            value={values["monto-conciliado-adecco"]}
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Monto conciliado",
                              marginBottom: 24,
                              before: (
                                <Icon
                                  icon={CurrencyIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={4}>
                          <Input
                            name="monto-responsabilidad-adecco"
                            disabled={newPrejudicialStates.loading}
                            error={errors["monto-responsabilidad-adecco"]}
                            touched={touched["monto-responsabilidad-adecco"]}
                            value={values["monto-responsabilidad-adecco"]}
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Monto responsabilidad Adecco",
                              marginBottom: 24,
                              before: (
                                <Icon
                                  icon={CurrencyIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={4}>
                          <Input
                            name="monto-responsabilidad-usuaria"
                            disabled={newPrejudicialStates.loading}
                            error={errors["monto-responsabilidad-usuaria"]}
                            touched={touched["monto-responsabilidad-usuaria"]}
                            value={values["monto-responsabilidad-usuaria"]}
                            type="text"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Monto responsabilidad usuaria",
                              marginBottom: 24,
                              before: (
                                <Icon
                                  icon={CurrencyIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col lg={4}>
                          <Input
                            name="honorarios-abogado-actor-2"
                            disabled={newPrejudicialStates.loading}
                            error={errors["honorarios-abogado-actor-2"]}
                            touched={touched["honorarios-abogado-actor-2"]}
                            value={values["honorarios-abogado-actor-2"]}
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Honorarios Abogado/Actor",
                              marginBottom: 24,
                              before: (
                                <Icon
                                  icon={CurrencyIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={4}>
                          <Input
                            name="honorarios-conciliador-2"
                            disabled={newPrejudicialStates.loading}
                            error={errors["honorarios-conciliador-2"]}
                            touched={touched["honorarios-conciliador-2"]}
                            value={values["honorarios-conciliador-2"]}
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Honorarios Conciliador",
                              marginBottom: 24,
                              before: (
                                <Icon
                                  icon={CurrencyIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={4}>
                          <Input
                            name="tasa-2"
                            disabled={newPrejudicialStates.loading}
                            error={errors["tasa-2"]}
                            touched={touched["tasa-2"]}
                            value={values["tasa-2"]}
                            type="text"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Tasa",
                              marginBottom: 24,
                              before: (
                                <Icon
                                  icon={PercentageIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <Wysiwyg
                            disabled={newPrejudicialStates.loading}
                            toolbar={{ options: [] }}
                            name="observaciones-2"
                            setFieldValue={(val) =>
                              setFieldValue("observaciones-2", val)
                            }
                            value={values["observaciones-2"]}
                            style={{ minHeight: "113px" }}
                            options={{
                              label: "Observaciones",
                              marginBottom: 20,
                              loading: newPrejudicialStates.loading,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>
                    </Grid.Col>
                  </Grid.Row>
                </Grid.Col>
              </Grid.Row>
            </Grid.ContainerFluid>

            <div style={{ height: "50px" }} />

            <Center horizontal="center" vertical="center">
              <Submit
                isSubmmiting={newPrejudicialStates.loading}
                form="new-prejudicial-form"
                color="Primary"
                options={{
                  type: "filled",
                  skin: "primary",
                  size: "lg",
                  marginBottom: "0px",
                }}
              >
                Confirmar
              </Submit>

              <Link
                href="/dashboard/prejudiciales/list"
                options={{
                  type: "outline",
                  skin: "danger",
                  size: "lg",
                  marginBottom: "0px",
                }}
                style={{ marginLeft: "10px" }}
              >
                Cancelar
              </Link>
            </Center>

            <div style={{ height: "50px" }} />
          </form>
        );
      }}
    </Formik>
  );
};

const states = ({ prejudicialStore, staticsStore }) => {
  const { states: newPrejudicialStates, data: newPrejudicialInformation } =
    prejudicialStore.newOne;
  const { data: prejudicialStatics, states: prejudicialStaticsStates } =
    prejudicialStore.statics;
  const { data: statics, states: staticsStates } = staticsStore;
  return {
    newPrejudicialStates,
    newPrejudicialInformation,
    prejudicialStaticsStates,
    prejudicialStatics,
    statics,
    staticsStates,
  };
};

export default connect(states)(Component);
