import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  ${(props) => props.theme.fonts.fontFace}

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: ${(props) => props.theme.fonts.family};
    font-size: ${(props) => props.theme.fonts.sizes.base};
    font-weight: 400;
    line-height: 19px;
    color: ${(props) => props.theme.colors.Gray};
    text-align: left;
    background-color: ${(props) => props.theme.colors.LightGray};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  a,
  p,
  button,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  sup,
  sub,
  span,
  div,
   {
    font-family: ${(props) => props.theme.fonts.family};
  }
  
  .swal2-styled.swal2-confirm:focus {
    box-shadow: 0 0 0 0px rgb(224 102 102 / 50%);
}

.swal2-styled.swal2-confirm {
    border: 0;
    border-radius: 0.25em;
    background: initial;
    background-color: rgb(5 161 145);
    color: #fff;
    font-size: 1em;
}

.swal2-title{
  line-height: normal;
}
  `;
