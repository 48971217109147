import { UserDto } from "./../../../types/user.dto";

export const UPDATE_USER_MAIN_INFORMATION = "UPDATE_USER_MAIN_INFORMATION";
export const UPDATE_USER_MAIN_INFORMATION_SUCCESS =
  "UPDATE_USER_MAIN_INFORMATION_SUCCESS";
export const UPDATE_USER_MAIN_INFORMATION_ERROR =
  "UPDATE_USER_MAIN_INFORMATION_ERROR";
export const RESET_UPDATE_USER_MAIN_INFORMATION =
  "RESET_UPDATE_USER_MAIN_INFORMATION";

export interface UpdateUserInformation {
  type: typeof UPDATE_USER_MAIN_INFORMATION;
  payload: { nombre: string; apellido: string };
}

export interface UpdateUserInformationSuccess {
  type: typeof UPDATE_USER_MAIN_INFORMATION_SUCCESS;
  payload: UserDto;
}

export interface UpdateUserInformationError {
  type: typeof UPDATE_USER_MAIN_INFORMATION_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateUserInformation {
  type: typeof RESET_UPDATE_USER_MAIN_INFORMATION;
  payload: null;
}

export interface State {
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | UpdateUserInformation
  | UpdateUserInformationSuccess
  | UpdateUserInformationError
  | ResetUpdateUserInformation;
