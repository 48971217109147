import styled from "styled-components";

import background1 from "../../../../../assets/backgrounds/background-1.jpg";
import background2 from "../../../../../assets/backgrounds/background-2.jpg";
import background3 from "../../../../../assets/backgrounds/background-3.jpg";
import background4 from "../../../../../assets/backgrounds/background-4.jpg";

const backgrounds = [background1, background2, background3, background4];

const randomBg = Math.floor(Math.random() * backgrounds.length);

export const BackgroundContainer = styled("div")`
  background-color: #1e1e1e;
  width: 100%;
  height: 248px;
  border-radius: 0px;
  box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 20%);
  background-image: url(${backgrounds[randomBg]});
  background-size: cover;
`;
