import styled from "styled-components";

export const Layout = styled("div")`
  display: grid;
  gap: 0px;
  grid-template-areas: "auto content";
  grid-template-columns: 1fr 400px 1fr;
  grid-template-rows: 100%;
  min-width: 100vw;
  min-height: 100vh;
  background-color: white;
`;

export const Content = styled("main")`
  text-align: center;
  grid-area: content;
  padding: 46px 0;
`;

export const Title = styled("h1")`
  font-size: 40px;
  font-weight: 500;
  line-height: normal;
  color: black;
  margin: 0px 0px 50px;
`;

export const Logo = styled("img")`
  margin-bottom: 0px;
`;
