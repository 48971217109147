const size = {
  sm: {
    fontSize: "14px",
    lineHeight: "24px",
    borderRadius: "6px",
  },
  md: {
    fontSize: "14px",
    lineHeight: "32px",
    borderRadius: "8px",
  },
  lg: {
    fontSize: "16px",
    lineHeight: "40px",
    borderRadius: "10px",
  },
};

export const link = {
  size,
};
