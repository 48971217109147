import {
  NewFacturacionOutDto,
  EditFacturacionOutDto,
  DeleteFacturacionOutDto,
  GetAllFacturacionesOutDto,
  GetOneFacturacionOutDto,
  SelectOneFacturacionOutDto,
} from "../../types/facturacion.dto";

import {
  NEW_FACTURACION,
  NewFacturacion,
  RESET_NEW_FACTURACION,
  ResetNewFacturacion,
} from "../types/facturacion/new";

import {
  EDIT_FACTURACION,
  RESET_EDIT_FACTURACION,
  ResetEditFacturacion,
  EditFacturacion,
} from "../types/facturacion/edit";

import {
  DELETE_FACTURACION,
  RESET_DELETE_FACTURACION,
  ResetDeleteFacturacion,
  DeleteFacturacion,
} from "../types/facturacion/delete";

import {
  GET_ONE_FACTURACION,
  GetOneFacturacion,
  RESET_GET_ONE_FACTURACION,
  ResetGetOneFacturacion,
} from "../types/facturacion/getOne";

import {
  SELECT_ONE_FACTURACION,
  SelectOneFacturacion,
  RESET_SELECT_ONE_FACTURACION,
  ResetSelectOneFacturacion,
} from "../types/facturacion/selectOne";

import {
  GET_ALL_FACTURACIONES,
  GetAllFacturaciones,
  RESET_GET_ALL_FACTURACIONES,
  ResetGetAllFacturaciones,
} from "../types/facturacion/getAll";

import {
  GET_STATICS_FACTURACION,
  GetStaticsFacturacion,
  RESET_GET_STATICS_FACTURACION,
  ResetGetStaticsFacturacion,
} from "../types/facturacion/statics";

export const getOneFacturacion = (
  payload: GetOneFacturacionOutDto
): GetOneFacturacion => {
  return { type: GET_ONE_FACTURACION, payload };
};

export const resetGetOneFacturacion = (): ResetGetOneFacturacion => {
  return { type: RESET_GET_ONE_FACTURACION, payload: null };
};

export const selectOneFacturacion = (
  payload: SelectOneFacturacionOutDto
): SelectOneFacturacion => {
  return { type: SELECT_ONE_FACTURACION, payload };
};

export const resetSelectOneFacturacion = (): ResetSelectOneFacturacion => {
  return { type: RESET_SELECT_ONE_FACTURACION, payload: null };
};

export const getAllFacturaciones = (
  payload: GetAllFacturacionesOutDto
): GetAllFacturaciones => {
  return { type: GET_ALL_FACTURACIONES, payload };
};

export const resetGetAllFacturaciones = (): ResetGetAllFacturaciones => {
  return { type: RESET_GET_ALL_FACTURACIONES, payload: null };
};

export const newFacturacion = (
  payload: NewFacturacionOutDto
): NewFacturacion => {
  return { type: NEW_FACTURACION, payload };
};

export const resetNewFacturacion = (): ResetNewFacturacion => {
  return { type: RESET_NEW_FACTURACION, payload: null };
};

export const editFacturacion = (
  payload: EditFacturacionOutDto
): EditFacturacion => {
  return { type: EDIT_FACTURACION, payload };
};

export const resetEditFacturacion = (): ResetEditFacturacion => {
  return { type: RESET_EDIT_FACTURACION, payload: null };
};

export const deleteFacturacion = (
  payload: DeleteFacturacionOutDto
): DeleteFacturacion => {
  return { type: DELETE_FACTURACION, payload };
};

export const resetDeleteFacturacion = (): ResetDeleteFacturacion => {
  return { type: RESET_DELETE_FACTURACION, payload: null };
};

export const getStatics = (): GetStaticsFacturacion => {
  return { type: GET_STATICS_FACTURACION, payload: null };
};

export const resetGetStatics = (): ResetGetStaticsFacturacion => {
  return { type: RESET_GET_STATICS_FACTURACION, payload: null };
};
