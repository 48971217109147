import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import { Center, Card } from "../../../../components/StyledComponents";
import Tabs from "../../../../components/OtherContent";
import Grid from "../../../../components/Grid";
import Button from "../../../../components/Button";
import Input from "../../../../components/Form/Input";
import Textarea from "../../../../components/Form/TextArea";
import Submit from "../../../../components/Form/Submit";
import ReactSelect from "../../../../components/Form/ReactSelect";
import { initialValues, schema } from "../../../../constants/form/judicial/new";
import {
  getStatics,
  newJudicial,
  resetNewJudicial,
} from "../../../../store/actions/judicial";
import { hideModal } from "../../../../store/actions/modal";
import Icon from "../../../../components/Icon";
import CurrencyIcon from "../../../../assets/icons/dollar.svg";
import PercentageIcon from "../../../../assets/icons/percentage.svg";
import { Action, useActions } from "../../../../provider/ActionsProvider";
import constants from "../../../../constants";
import { toast } from "react-toastify";
import { getAllStatics } from "../../../../store/actions/statics";
import { setToLocalTimeZone } from "../../../../helpers/data-handler";
import { useLayout } from "../../../../provider/LayoutProvider";
import { Link } from "../../../../components/Link/styles";
import { useNavigate } from "react-router";
import { NewJudicialInDto } from "../../../../types/judicial.dto";
import checkEmpty from "../../../../helpers/check-empty";
import questionMarkIcon from "../../../../assets/icons/question-square.svg";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

interface Jurisdiccion {
  label: string;
  value: string;
  type: string;
}

interface Categoria {
  label: string;
  value: string;
}

interface Responsabilidad {
  label: string;
  value: string;
}

interface Estado {
  label: string;
  value: string;
}

interface EstadoEtapaProcesal {
  label: string;
  value: string;
}

interface CoberturaArt {
  label: string;
  value: string;
}

interface Compania2 {
  label: string;
  value: string;
}
interface ComponentProps {
  newJudicialStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  newJudicialInformation: NewJudicialInDto;
  judicialStatics;
  judicialStaticsStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  statics;
  staticsStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
}

const Component = ({
  newJudicialStates,
  newJudicialInformation,
  judicialStatics,
  judicialStaticsStates,
  statics,
  staticsStates,
}: ComponentProps) => {
  const { layout, setLayout } = useLayout();
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const { setActions } = useActions();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const actions = useMemo(
    (): Action[] => [
      {
        component: "submit",
        label: "Confirmar",
        form: "new-judicial-form",
        options: {
          type: "filled",
          skin: "primary",
          size: "lg",
          marginBottom: "0px",
        },
      },
      {
        component: "link",
        label: "Cancelar",
        href: "/dashboard/judiciales/list",
        options: {
          type: "outline",
          skin: "danger",
          size: "lg",
          marginBottom: "0px",
        },
      },
    ],
    []
  );

  const jurisdicciones = useMemo(
    (): Jurisdiccion[] =>
      !!statics?.jurisdicciones
        ? [
            ...((statics?.jurisdicciones &&
              statics?.jurisdicciones?.map((jurisdiccion) => ({
                label: jurisdiccion.titulo || "Sin nombre",
                value: jurisdiccion.itemId,
              }))) ||
              []),
          ]
        : [],
    [statics]
  );

  const categorias = useMemo(
    (): Categoria[] =>
      !!judicialStatics?.categories
        ? [
            ...((judicialStatics?.categories?.category &&
              judicialStatics?.categories?.category?.map((category) => ({
                label: category.text || "Sin nombre",
                value: category.id,
              }))) ||
              []),
          ]
        : [],
    [judicialStatics]
  );

  const responsables = useMemo(
    (): Responsabilidad[] =>
      !!judicialStatics?.categories
        ? [
            ...((judicialStatics?.categories?.responsabilidad &&
              judicialStatics?.categories?.responsabilidad?.map(
                (responsabilidad) => ({
                  label: responsabilidad.text || "Sin nombre",
                  value: responsabilidad.id,
                })
              )) ||
              []),
          ]
        : [],
    [judicialStatics]
  );

  const estados = useMemo(
    (): Estado[] =>
      !!judicialStatics?.categories
        ? [
            ...((judicialStatics?.categories?.estado &&
              judicialStatics?.categories?.estado?.map((estado) => ({
                label: estado.text || "Sin nombre",
                value: estado.id,
              }))) ||
              []),
          ]
        : [],
    [judicialStatics]
  );

  const estadosEtapaProcesal = useMemo(
    (): EstadoEtapaProcesal[] =>
      !!judicialStatics?.categories
        ? [
            ...((judicialStatics?.categories["estadoetapa-procesal"] &&
              judicialStatics?.categories["estadoetapa-procesal"]?.map(
                (estadoEtapaProcesal) => ({
                  label: estadoEtapaProcesal.text || "Sin nombre",
                  value: estadoEtapaProcesal.id,
                })
              )) ||
              []),
          ]
        : [],
    [judicialStatics]
  );

  const coberturasArt2 = useMemo(
    (): CoberturaArt[] =>
      !!judicialStatics?.categories
        ? [
            ...((judicialStatics?.categories["coberturaart-2"] &&
              judicialStatics?.categories["coberturaart-2"]?.map(
                (coberturaArt) => ({
                  label: coberturaArt.text || "Sin nombre",
                  value: coberturaArt.id,
                })
              )) ||
              []),
          ]
        : [],
    [judicialStatics]
  );

  const companias2 = useMemo(
    (): Compania2[] =>
      !!judicialStatics?.categories
        ? [
            ...((judicialStatics?.categories["compania-2"] &&
              judicialStatics?.categories["compania-2"]?.map((compania2) => ({
                label: compania2.text || "Sin nombre",
                value: compania2.id,
              }))) ||
              []),
          ]
        : [],
    [judicialStatics]
  );

  const onSubmit = ({ values, actions }) => {
    const payload = {
      caratula: checkEmpty(values.caratula),
      "nro-expediente": checkEmpty(values["nro-expediente"]),
      category: checkEmpty(values.category),
      responsabilidad: checkEmpty(values.responsabilidad),
      "estadoetapa-procesal": checkEmpty(values["estadoetapa-procesal"]),
      estado: checkEmpty(values.estado),
      "actor-dni": checkEmpty(values["actor-dni"], "dni"),
      jurisdiccionjuzgado: checkEmpty(values.jurisdiccionjuzgado),
      "fecha-cierre": checkEmpty(setToLocalTimeZone(values["fecha-cierre"])),
      "fecha-recepcion-demanda": checkEmpty(
        setToLocalTimeZone(values["fecha-recepcion-demanda"])
      ),
      "fecha-vencimiento-demanda": checkEmpty(
        setToLocalTimeZone(values["fecha-vencimiento-demanda"])
      ),
      "coberturaart-2": checkEmpty(values["coberturaart-2"]),
      "compania-2": checkEmpty(values["compania-2"]),
      embargo: checkEmpty(values.embargo, "currency"),
      codemandado: checkEmpty(values.codemandado),
      "clienterazon-social": checkEmpty(values["clienterazon-social"]),
      "fecha-despido": checkEmpty(setToLocalTimeZone(values["fecha-despido"])),
      evaluacion: checkEmpty(values.evaluacion),
      "prevision-estudio": checkEmpty(values["prevision-estudio"]),
      "monto-conciliado-total": checkEmpty(
        values["monto-conciliado-total"],
        "currency"
      ),
      "estimacion-estudio-conciliacion-3": checkEmpty(
        values["estimacion-estudio-conciliacion-3"],
        "currency"
      ),
      "monto-condena-1ra-inst-2": checkEmpty(
        values["monto-condena-1ra-inst-2"],
        "currency"
      ),
      "money-2": checkEmpty(values["money-2"], "currency"),
      contraparte: checkEmpty(values.contraparte),
      "pericia-contable": checkEmpty(values["pericia-contable"], "currency"),
      "ultima-pretension": checkEmpty(values["ultima-pretension"], "currency"),
      "ultimo-ofrecimiento": checkEmpty(
        values["ultimo-ofrecimiento"],
        "currency"
      ),
      observaciones: checkEmpty(values.observaciones),
      itemId: values["item-id"],
    };

    console.log(payload);

    dispatch(newJudicial(payload));
    setFormActions(actions);
  };

  useEffect(() => {
    if (newJudicialStates.success) {
      dispatch(hideModal());
      setTimeout(() => {
        dispatch(resetNewJudicial());
      }, 2000);
    }
  }, [newJudicialStates]);

  useEffect(() => {
    setActions(actions);
    dispatch(getStatics());
    !statics && dispatch(getAllStatics());
    !judicialStatics && dispatch(getStatics());
  }, []);

  useEffect(() => {
    setActions(actions);
    setLayout({ ...layout, showBackButton: true });
  }, []);

  useEffect(() => {
    if (newJudicialStates.success && !!newJudicialInformation) {
      toast.success("👌🏼 Judicial creado correctamente");
      setTimeout(() => {
        formActions?.resetForm();
        dispatch(resetNewJudicial());
        navigate(`/dashboard/judiciales/${newJudicialInformation["item-id"]}`, {
          replace: true,
        });
      }, 1000);
    }

    if (newJudicialStates.error) {
      toast.error(`😱 No se ha podido crear el judicial`);
      setTimeout(() => {
        dispatch(resetNewJudicial());
      }, 2000);
    }
  }, [newJudicialStates, newJudicialInformation]);

  return (
    <Formik
      initialValues={{ ...initialValues }}
      onSubmit={(values, actions) => {
        if (onSubmit) onSubmit({ values, actions });
      }}
      validateOnChange={formSubmmited}
      validateOnBlur={false}
      validationSchema={schema}
      enableReinitialize
    >
      {({
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        resetForm,
        setFieldValue,
        isSubmitting,
      }) => {
        return (
          <form
            className="theme-form"
            onSubmit={(event) => {
              setFormSubmmited(true);
              handleSubmit(event);
            }}
            id="new-judicial-form"
          >
            <Grid.ContainerFluid>
              <Grid.Row>
                <Grid.Col lg={10} offset={{ lg: 1 }}>
                  <Grid.Row>
                    <Grid.Col lg={8}>
                      <Grid.Row>
                        <Grid.Col lg={6}>
                          <Input
                            disabled={newJudicialStates.loading}
                            name="caratula"
                            error={errors["caratula"]}
                            touched={touched["caratula"]}
                            value={values["caratula"]}
                            type="text"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Caratula *",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={3}>
                          <Input
                            disabled={newJudicialStates.loading}
                            name="nro-expediente"
                            error={errors["nro-expediente"]}
                            touched={touched["nro-expediente"]}
                            value={values["nro-expediente"]}
                            type="text"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Nro. Expediente *",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={3}>
                          <ReactSelect
                            disabled={
                              judicialStaticsStates.loading ||
                              newJudicialStates.loading
                            }
                            name="category"
                            error={errors["category"]}
                            touched={touched["category"]}
                            items={categorias}
                            placeholder="Selecciona"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Naturaleza/Objeto *",
                              loading: judicialStaticsStates.loading,
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col lg={4}>
                          <ReactSelect
                            disabled={
                              judicialStaticsStates.loading ||
                              newJudicialStates.loading
                            }
                            name="responsabilidad"
                            error={errors["responsabilidad"]}
                            touched={touched["responsabilidad"]}
                            items={responsables}
                            placeholder="Seleccionar"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Responsabilidad",
                              loading: judicialStaticsStates.loading,
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={4}>
                          <ReactSelect
                            disabled={
                              judicialStaticsStates.loading ||
                              newJudicialStates.loading
                            }
                            name="estadoetapa-procesal"
                            error={errors["estadoetapa-procesal"]}
                            touched={touched["estadoetapa-procesal"]}
                            items={estadosEtapaProcesal}
                            placeholder="Seleccionar"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Etapa procesal *",
                              loading: judicialStaticsStates.loading,
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={4}>
                          <ReactSelect
                            disabled={
                              judicialStaticsStates.loading ||
                              newJudicialStates.loading
                            }
                            name="estado"
                            error={errors["estado"]}
                            touched={touched["estado"]}
                            items={estados}
                            placeholder="Seleccionar"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Estado *",
                              loading: judicialStaticsStates.loading,
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col lg={4}>
                          <Input
                            disabled={newJudicialStates.loading}
                            name="actor-dni"
                            error={errors["actor-dni"]}
                            touched={touched["actor-dni"]}
                            value={values["actor-dni"]}
                            type="text"
                            placeholder="Ingrese DNI"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Actor (DNI) *",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={8}>
                          <ReactSelect
                            disabled={
                              judicialStaticsStates.loading ||
                              newJudicialStates.loading
                            }
                            name="jurisdiccionjuzgado"
                            error={errors["jurisdiccionjuzgado"]}
                            touched={touched["jurisdiccionjuzgado"]}
                            items={jurisdicciones}
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Jurisdicción",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col lg={4}>
                          <Input
                            disabled={newJudicialStates.loading}
                            name="fecha-cierre"
                            error={errors["fecha-cierre"]}
                            touched={touched["fecha-cierre"]}
                            value={values["fecha-cierre"]}
                            type="date"
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Fecha de cierre",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={4}>
                          <Input
                            disabled={newJudicialStates.loading}
                            name="fecha-recepcion-demanda"
                            error={errors["fecha-recepcion-demanda"]}
                            touched={touched["fecha-recepcion-demanda"]}
                            value={values["fecha-recepcion-demanda"]}
                            type="date"
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Fecha recepción demanda *",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={4}>
                          <Input
                            disabled={newJudicialStates.loading}
                            name="fecha-vencimiento-demanda"
                            error={errors["fecha-vencimiento-demanda"]}
                            touched={touched["fecha-vencimiento-demanda"]}
                            value={values["fecha-vencimiento-demanda"]}
                            type="date"
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Fecha vencimiento demanda *",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col lg={4}>
                          <ReactSelect
                            disabled={
                              judicialStaticsStates.loading ||
                              newJudicialStates.loading
                            }
                            name="coberturaart-2"
                            error={errors["coberturaart-2"]}
                            touched={touched["coberturaart-2"]}
                            items={coberturasArt2}
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Cobertura ART",
                              loading: judicialStaticsStates.loading,
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={4}>
                          <ReactSelect
                            disabled={
                              judicialStaticsStates.loading ||
                              newJudicialStates.loading
                            }
                            name="compania-2"
                            error={errors["compania-2"]}
                            touched={touched["compania-2"]}
                            items={companias2}
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Empleador Real",
                              loading: judicialStaticsStates.loading,
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={4}>
                          <Input
                            disabled={newJudicialStates.loading}
                            name="embargo"
                            error={errors["embargo"]}
                            touched={touched["embargo"]}
                            placeholder="00.000,00"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Embargo",
                              marginBottom: 24,
                              before: (
                                <Icon
                                  icon={CurrencyIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col lg={6}>
                          <Input
                            disabled={newJudicialStates.loading}
                            name="codemandado"
                            error={errors["codemandado"]}
                            touched={touched["codemandado"]}
                            value={values["codemandado"]}
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Partes del proceso",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={3}>
                          <Input
                            disabled={newJudicialStates.loading}
                            name="clienterazon-social"
                            error={errors["clienterazon-social"]}
                            touched={touched["clienterazon-social"]}
                            value={values["clienterazon-social"]}
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Cliente (Razón social)",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={3}>
                          <Input
                            disabled={newJudicialStates.loading}
                            name="fecha-despido"
                            error={errors["fecha-despido"]}
                            touched={touched["fecha-despido"]}
                            value={values["fecha-despido"]}
                            type="date"
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: (
                                <Center horizontal="space">
                                  <span>Fecha despido *</span>

                                  <Tippy content="Fecha del hecho desde que se calculan los intereses">
                                    <div>
                                      <Icon
                                        icon={questionMarkIcon}
                                        color="#e20014"
                                        size="14px"
                                      />
                                    </div>
                                  </Tippy>
                                </Center>
                              ),
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col lg={4}>
                          <ReactSelect
                            name="evaluacion"
                            error={errors["evaluacion"]}
                            touched={touched["evaluacion"]}
                            items={constants.statics.judiciales.evaluacion}
                            placeholder="Selecciona"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Evaluación *",
                              loading: judicialStaticsStates.loading,
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={4}>
                          <Input
                            disabled={newJudicialStates.loading}
                            name="prevision-estudio"
                            error={errors["prevision-estudio"]}
                            touched={touched["prevision-estudio"]}
                            value={values["prevision-estudio"]}
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Previsión Estudio %",
                              marginBottom: 24,
                              after: (
                                <Icon
                                  icon={PercentageIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={4}>
                          <Input
                            disabled={newJudicialStates.loading}
                            name="monto-conciliado-total"
                            error={errors["monto-conciliado-total"]}
                            touched={touched["monto-conciliado-total"]}
                            value={values["monto-conciliado-total"]}
                            placeholder="00.000,00"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            options={{
                              label: "Monto Conciliado",
                              marginBottom: 24,
                              before: (
                                <Icon
                                  icon={CurrencyIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col lg={4}>
                          <Input
                            disabled={newJudicialStates.loading}
                            name="estimacion-estudio-conciliacion-3"
                            error={errors["estimacion-estudio-conciliacion-3"]}
                            touched={
                              touched["estimacion-estudio-conciliacion-3"]
                            }
                            value={values["estimacion-estudio-conciliacion-3"]}
                            placeholder="00.000,00"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            options={{
                              label: "Estimacion Estudio Conciliacion",
                              marginBottom: 24,
                              before: (
                                <Icon
                                  icon={CurrencyIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={4}>
                          <Input
                            disabled={newJudicialStates.loading}
                            name="monto-condena-1ra-inst-2"
                            error={errors["monto-condena-1ra-inst-2"]}
                            touched={touched["monto-condena-1ra-inst-2"]}
                            value={values["monto-condena-1ra-inst-2"]}
                            placeholder="00.000,00"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            options={{
                              label: "Monto Condena 1° Instancia",
                              marginBottom: 24,
                              before: (
                                <Icon
                                  icon={CurrencyIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={4}>
                          <Input
                            disabled={newJudicialStates.loading}
                            name="money-2"
                            error={errors["money-2"]}
                            touched={touched["money-2"]}
                            value={values["money-2"]}
                            placeholder="00.000,00"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            options={{
                              label: "Monto Condena 2° Instancia",
                              marginBottom: 24,
                              before: (
                                <Icon
                                  icon={CurrencyIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col lg={3}>
                          <Input
                            disabled={newJudicialStates.loading}
                            name="contraparte"
                            error={errors["contraparte"]}
                            touched={touched["contraparte"]}
                            value={values["contraparte"]}
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Abogado contraparte *",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={3}>
                          <Input
                            disabled={newJudicialStates.loading}
                            name="pericia-contable"
                            error={errors["pericia-contable"]}
                            touched={touched["pericia-contable"]}
                            value={values["pericia-contable"]}
                            placeholder="00.000,00"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            options={{
                              label: "Pericia contable",
                              marginBottom: 24,
                              before: (
                                <Icon
                                  icon={CurrencyIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={3}>
                          <Input
                            disabled={newJudicialStates.loading}
                            name="ultima-pretension"
                            error={errors["ultima-pretension"]}
                            touched={touched["ultima-pretension"]}
                            value={values["ultima-pretension"]}
                            placeholder="00.000,00"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            options={{
                              label: "Última pretensión",
                              marginBottom: 24,
                              before: (
                                <Icon
                                  icon={CurrencyIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={3}>
                          <Input
                            disabled={newJudicialStates.loading}
                            name="ultimo-ofrecimiento"
                            error={errors["ultimo-ofrecimiento"]}
                            touched={touched["ultimo-ofrecimiento"]}
                            value={values["ultimo-ofrecimiento"]}
                            placeholder="00.000,00"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            options={{
                              label: "Último ofrecimiento",
                              marginBottom: 24,
                              before: (
                                <Icon
                                  icon={CurrencyIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <Textarea
                            disabled={newJudicialStates.loading}
                            name="observaciones"
                            error={errors["observaciones"]}
                            touched={touched["observaciones"]}
                            value={values["observaciones"]}
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Observaciones",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>
                    </Grid.Col>
                  </Grid.Row>
                </Grid.Col>
              </Grid.Row>
            </Grid.ContainerFluid>

            <div style={{ height: "50px" }} />

            <Center horizontal="center" vertical="center">
              <Submit
                isSubmmiting={newJudicialStates.loading}
                form="new-judicial-form"
                color="Primary"
                options={{
                  type: "filled",
                  skin: "primary",
                  size: "lg",
                  marginBottom: "0px",
                }}
              >
                Confirmar
              </Submit>

              <Link
                href="/dashboard/judiciales/list"
                options={{
                  type: "outline",
                  skin: "danger",
                  size: "lg",
                  marginBottom: "0px",
                }}
                style={{ marginLeft: "10px" }}
              >
                Cancelar
              </Link>
            </Center>

            <div style={{ height: "50px" }} />
          </form>
        );
      }}
    </Formik>
  );
};

const states = ({ judicialStore, staticsStore }) => {
  const { states: newJudicialStates, data: newJudicialInformation } =
    judicialStore.newOne;
  const { data: judicialStatics, states: judicialStaticsStates } =
    judicialStore.statics;
  const { data: statics, states: staticsStates } = staticsStore;

  return {
    newJudicialStates,
    newJudicialInformation,
    judicialStatics,
    judicialStaticsStates,
    statics,
    staticsStates,
  };
};

export default connect(states)(Component);
