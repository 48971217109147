import * as Yup from "yup";
import { dateSpliter } from "../../../helpers/data-handler";

export const schema = Yup.object().shape({
  referencia: Yup.string().nullable().required("Campo obligatorio."),
  tipo: Yup.number().nullable().required("Campo obligatorio."),
  caso: Yup.number()
    .nullable()
    .when("tipo", {
      is: 1,
      then: Yup.number()
        .typeError("Campo obligatorio")
        .required("Campo obligatorio"),
    })
    .when("tipo", {
      is: 2,
      then: Yup.number()
        .typeError("Campo obligatorio")
        .required("Campo obligatorio"),
    }),
  "date-start": Yup.string().nullable().required("Campo obligatorio."),
  motivo: Yup.number().nullable().required("Campo obligatorio."),
  compania: Yup.number().nullable().required("Campo obligatorio."),
  status: Yup.string().nullable().required("Campo obligatorio."),
  "fecha-origen-start": Yup.string().nullable().required("Campo obligatorio."),
  "beneficiario-2": Yup.string().nullable(),
  cbu: Yup.number().nullable(),
  descripcion: Yup.string().nullable(),

  capital: Yup.mixed()
    .nullable()
    .test("currencyCheck", "Ingresa un monto correcto", (value) => {
      return (
        !value || (!!value && /^[+-]?\d{1,3}(.?\d{3})*(,\d+)?$/gm.test(value))
      );
    }),
  honorario: Yup.mixed()
    .nullable()
    .test("currencyCheck", "Ingresa un monto correcto", (value) => {
      return (
        !value || (!!value && /^[+-]?\d{1,3}(.?\d{3})*(,\d+)?$/gm.test(value))
      );
    }),
  "honorarios-conciliadormediador": Yup.mixed()
    .nullable()
    .test("currencyCheck", "Ingresa un monto correcto", (value) => {
      return (
        !value || (!!value && /^[+-]?\d{1,3}(.?\d{3})*(,\d+)?$/gm.test(value))
      );
    }),
  iva: Yup.mixed()
    .nullable()
    .test("currencyCheck", "Ingresa un monto correcto", (value) => {
      return (
        !value || (!!value && /^[+-]?\d{1,3}(.?\d{3})*(,\d+)?$/gm.test(value))
      );
    }),
  intereses: Yup.mixed()
    .nullable()
    .test("currencyCheck", "Ingresa un monto correcto", (value) => {
      return (
        !value || (!!value && /^[+-]?\d{1,3}(.?\d{3})*(,\d+)?$/gm.test(value))
      );
    }),
  aportes: Yup.mixed()
    .nullable()
    .test("currencyCheck", "Ingresa un monto correcto", (value) => {
      return (
        !value || (!!value && /^[+-]?\d{1,3}(.?\d{3})*(,\d+)?$/gm.test(value))
      );
    }),
  t: Yup.mixed()
    .nullable()
    .test("currencyCheck", "Ingresa un monto correcto", (value) => {
      return (
        !value || (!!value && /^[+-]?\d{1,3}(.?\d{3})*(,\d+)?$/gm.test(value))
      );
    }),
  "otros-gastos": Yup.mixed()
    .nullable()
    .test("currencyCheck", "Ingresa un monto correcto", (value) => {
      return (
        !value || (!!value && /^[+-]?\d{1,3}(.?\d{3})*(,\d+)?$/gm.test(value))
      );
    }),
});

interface FormValues {
  tipo: number | null;
  caso: number | null;
  referencia: string;
  "date-start": string | null;
  motivo: number | null;
  compania: number | null;
  status: number;
  "fecha-origen-start": string | null;

  "beneficiario-2"?: string;
  cbu?: number;
  descripcion?: string;
  capital?: string;
  honorario?: string;
  "honorarios-conciliadormediador"?: string;
  iva?: string;
  intereses?: string;
  aportes?: string;
  t?: string;
  "otros-gastos"?: string;
}

export const initialValues: FormValues = {
  tipo: null,
  caso: null,
  referencia: "",
  "date-start": "",
  motivo: null,
  compania: null,
  status: 3,
  "fecha-origen-start": dateSpliter(new Date()).inputDate,

  "beneficiario-2": undefined,
  cbu: undefined,
  descripcion: undefined,
  capital: undefined,
  honorario: undefined,
  "honorarios-conciliadormediador": undefined,
  iva: undefined,
  intereses: undefined,
  aportes: undefined,
  t: undefined,
  "otros-gastos": undefined,
};
