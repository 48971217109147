import { combineReducers } from "redux";
import deleteOne from "./deleteOne";
import editOne from "./editOne";
import all from "./getAll";
import getComments from "./getComments";
import one from "./getOne";
import newOne from "./newOne";
import selected from "./selectOne";
import statics from "./statics";

const user = combineReducers({
  newOne,
  editOne,
  deleteOne,
  selected,
  one,
  all,
  getComments,
  statics,
});

export default user;
