import {
  initialState,
  Actions,
  State,
  GET_ALL_PREJUDICIALES,
  GET_ALL_PREJUDICIALES_SUCCESS,
  GET_ALL_PREJUDICIALES_ERROR,
  RESET_GET_ALL_PREJUDICIALES,
} from "../../types/prejudicial/getAll";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_PREJUDICIALES:
      return {
        ...state,
        data: null,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case GET_ALL_PREJUDICIALES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case GET_ALL_PREJUDICIALES_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          error: action.payload,
          success: false,
        },
      };
    case RESET_GET_ALL_PREJUDICIALES:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducers;
