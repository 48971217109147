import { CSSProperties, useEffect, useState } from "react";
import { useFormikFieldValidation } from "../../../hooks/useFormikFieldValidation";
import {
  FieldContainer,
  Field,
  Label,
  FieldBefore,
  FieldAfter,
  HelperLine,
  HelperText,
  ExplainText,
  ErrorMessage,
  Required,
  CharacterCounter,
  Backdrop,
} from "../Field";
import Loader from "../../Spinner";
import { WysiwygGlobalStyles } from "./styles";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, ContentState, EditorState, EditorProps } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import "./textEditor.css";

const initialValue = {
  options: {
    skin: "base",
    size: "md",
  },
};

interface ITextEditorProps {
  name: string;
  value: string;
  toolbar?: object;
  disabled?: boolean;
  style?: CSSProperties;
  error?: {};
  touched?: {};
  placeholder?: string;
  onFocus?: (data: any) => void;
  onBlur?: (data: any) => void;
  onChange?: (data: any) => void;
  setFieldValue: (data: any) => void;
  maxLength?: number;
  autocomplete?: "off" | "on" | "new-password";
  forwardRef?;
  readOnly?: boolean;
  options?: {
    label?: string;
    loading?: boolean;
    skin?: "base";
    size?: "md";
    helperText?: string;
    explainText?: string;
    before?: any;
    after?: any;
    marginBottom?: number;
    hidden?: boolean;
  };
}

const Component = ({
  forwardRef,
  options = {},
  disabled,
  name,
  value,
  error,
  touched,
  maxLength,
  onFocus,
  onBlur,
  onChange,
  setFieldValue,
  style = {},
  readOnly,
  ...rest
}: ITextEditorProps) => {
  const [numCharacters, setNumCharacters] = useState(0);
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );

  const { classes, setActive } = useFormikFieldValidation(
    error,
    name,
    disabled,
    readOnly
  );

  const { skin, size, marginBottom, hidden } = {
    ...initialValue.options,
    ...options,
  };

  const prepareDraft = (value: string) => {
    const draft = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  const handleOnChange = (state, callback) => {
    const forFormik = draftToHtml(convertToRaw(state.getCurrentContent()));
    setFieldValue(forFormik);
    setEditorState(state);
    callback && callback(forFormik);
  };

  const handleOnBlur = (event, callback) => {
    setActive(false);
    callback && callback(event);
  };

  const handleOnFocus = (event, callback) => {
    setActive(true);
    callback && callback(event);
  };

  useEffect(() => {
    const fieldContent = draftToHtml(
      convertToRaw(prepareDraft(value || "").getCurrentContent())
    );
    const stateContent = draftToHtml(
      convertToRaw(editorState?.getCurrentContent())
    );

    if (!!value && fieldContent !== stateContent) {
      setEditorState(prepareDraft(value));
    }
  }, [value]);

  return (
    <FieldContainer
      marginBottom={marginBottom}
      {...(hidden && { style: { display: "none" } })}
    >
      {options?.label && (
        <Label
          size={options?.size}
          htmlFor={`input-${name}`}
          style={!!options?.explainText ? { marginBottom: "0px" } : {}}
        >
          {options.label}
          {/* {rules && rules.required && <Required>*</Required>} */}
        </Label>
      )}

      {!!options?.explainText && (
        <ExplainText>{options?.explainText}</ExplainText>
      )}

      <Field
        skin={skin}
        size={size}
        className={classes}
        style={{ padding: "0px" }}
      >
        {options?.before && (
          <FieldBefore size={options?.size} className="field-before">
            {options.before}
          </FieldBefore>
        )}

        <WysiwygGlobalStyles />

        {options.loading && (
          <Backdrop>
            <Loader />
          </Backdrop>
        )}

        <Editor
          editorState={editorState}
          wrapperClassName="custom-wrapper"
          editorClassName="custom-editor"
          onEditorStateChange={(state) => {
            handleOnChange(state, onChange);
          }}
          onBlur={(event) => handleOnBlur(event, onBlur)}
          onFocus={(event) => handleOnFocus(event, onFocus)}
          readOnly={readOnly}
          {...rest}
        />

        {options?.after && (
          <FieldAfter size={options?.size} className="field-after">
            {options && options.after}
          </FieldAfter>
        )}
      </Field>

      {((options && options.helperText) || maxLength || error) && (
        <HelperLine marginBottom={marginBottom}>
          {!!error && !!touched && <ErrorMessage>{error}</ErrorMessage>}
          {options && options.helperText && !error && (
            <HelperText>{options.helperText}</HelperText>
          )}
          {maxLength && (
            <CharacterCounter errors={!!error && !!touched}>
              {numCharacters} / {maxLength}
            </CharacterCounter>
          )}
        </HelperLine>
      )}
    </FieldContainer>
  );
};

export default Component;
