import { combineReducers } from "redux";
import deleteOne from "./deleteOne";
import editOne from "./editOne";
import all from "./getAll";
import one from "./getOne";
import newOne from "./newOne";

const user = combineReducers({
  newOne,
  editOne,
  deleteOne,
  one,
  all,
});

export default user;
