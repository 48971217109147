import { GetCommentOutDto, GetCommentInDto } from "../../../types/comment.dto";

export const GET_COMMENTS_PREJUDICIAL = "GET_COMMENTS_PREJUDICIAL";
export const GET_COMMENTS_PREJUDICIAL_SUCCESS =
  "GET_COMMENTS_PREJUDICIAL_SUCCESS";
export const GET_COMMENTS_PREJUDICIAL_ERROR = "GET_COMMENTS_PREJUDICIAL_ERROR";
export const RESET_GET_COMMENTS_PREJUDICIAL = "RESET_GET_COMMENTS_PREJUDICIAL";

export interface GetCommentsPrejudicial {
  type: typeof GET_COMMENTS_PREJUDICIAL;
  payload: GetCommentOutDto;
}

export interface GetCommentsPrejudicialSuccess {
  type: typeof GET_COMMENTS_PREJUDICIAL_SUCCESS;
  payload: GetCommentInDto[];
}

export interface GetCommentsPrejudicialError {
  type: typeof GET_COMMENTS_PREJUDICIAL_ERROR;
  payload: string | boolean;
}

export interface ResetGetCommentsPrejudicial {
  type: typeof RESET_GET_COMMENTS_PREJUDICIAL;
  payload: null;
}

export interface State {
  data: GetCommentInDto[] | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | GetCommentsPrejudicial
  | GetCommentsPrejudicialSuccess
  | GetCommentsPrejudicialError
  | ResetGetCommentsPrejudicial;
