import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import { Center, Card } from "../../../../components/StyledComponents";
import Grid from "../../../../components/Grid";
import Link from "../../../../components/Link";
import Input from "../../../../components/Form/Input";
import Textarea from "../../../../components/Form/TextArea";
import Submit from "../../../../components/Form/Submit";
import ReactSelect from "../../../../components/Form/ReactSelect";
import Tabs from "../../../../components/OtherContent";
import {
  initialValues,
  schema,
} from "../../../../constants/form/facturacion/new";
import {
  getStatics,
  newFacturacion,
  resetNewFacturacion,
} from "../../../../store/actions/facturacion";
import { hideModal } from "../../../../store/actions/modal";
import { Action, useActions } from "../../../../provider/ActionsProvider";
import Icon from "../../../../components/Icon";
import CurrencyIcon from "../../../../assets/icons/dollar.svg";
import {
  minInputDate,
  setToLocalTimeZone,
  toDateInputValue,
} from "../../../../helpers/data-handler";
import {
  GetAllPrejudicialesInDto,
  PrejudicialDto,
} from "../../../../types/prejudicial.dto";
import { GetAllJudicialesInDto } from "../../../../types/judicial.dto";
import { getAllJudiciales } from "../../../../store/actions/judicial";
import { getAllPrejudiciales } from "../../../../store/actions/prejudicial";
import { toast } from "react-toastify";
import { useLayout } from "../../../../provider/LayoutProvider";
import { NewFacturacionInDto } from "../../../../types/facturacion.dto";
import { useNavigate } from "react-router";
import checkEmpty from "../../../../helpers/check-empty";
import { GetAllStaticsInDto } from "../../../../types/statics.dto";

interface ComponentProps {
  newFacturacionStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  newFacturacionInformation: NewFacturacionInDto;
  facturacionStaticsStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  facturacionStatics;
  judiciales: GetAllJudicialesInDto;
  judicialesStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  prejudiciales: GetAllPrejudicialesInDto;
  prejudicialesStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  statics: GetAllStaticsInDto;
  staticsStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
}

const type = {
  PREJUDICIAL: 1,
  JUDICIAL: 2,
  HONORARIOS_EXTRAORDINARIOS: 5,
};

const Component = ({
  newFacturacionStates,
  newFacturacionInformation,
  facturacionStaticsStates,
  facturacionStatics,
  judiciales,
  judicialesStates,
  prejudiciales,
  prejudicialesStates,
  statics,
  staticsStates,
}: ComponentProps) => {
  const { layout, setLayout } = useLayout();
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const { setActions } = useActions();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const actions = useMemo(
    (): Action[] => [
      {
        component: "submit",
        label: "Confirmar",
        form: "new-facturacion-form",
        options: {
          type: "filled",
          skin: "primary",
          size: "lg",
          marginBottom: "0px",
        },
      },
      {
        component: "link",
        label: "Cancelar",
        href: "/dashboard/facturacion/list",
        options: {
          type: "outline",
          skin: "danger",
          size: "lg",
          marginBottom: "0px",
        },
      },
    ],
    []
  );

  const casoContratos = useMemo(
    (): {
      label: string;
      value: string;
      type: string;
    }[] =>
      [
        ...(!!judiciales?.hints
          ? judiciales?.hints?.map((judicial) => ({
              label: judicial.caratula,
              value: judicial["item-id"],
              type: "casocontraro-judiciales",
            }))
          : []),
        ...(!!prejudiciales?.hints
          ? prejudiciales?.hints?.map((prejudicial) => ({
              label: prejudicial.title,
              value: prejudicial["item-id"],
              type: "casocontraro-pre-judiciales",
            }))
          : []),
        ...((statics?.contratos &&
          statics?.contratos?.map((contrato) => ({
            label: contrato.resumen,
            value: contrato.itemId,
            type: "casocontraro-contratos",
          }))) ||
          []),
      ].flat(),
    [judiciales, prejudiciales, statics]
  );

  const onSubmit = ({ values, actions }) => {
    const casos = values["casocontraro"]?.reduce(
      (previousValue, currentValue) => {
        const caso = casoContratos.filter(
          (element) => element.value === currentValue
        )[0];

        return {
          ...previousValue,
          [caso.type]: [...(previousValue[caso.type] || []), caso.value],
        };
      },
      {}
    );

    const payload = {
      ...((values["tipo"] === type.JUDICIAL ||
        values["tipo"] === type.PREJUDICIAL) && {
        ...(values["casocontraro"] && casos),
      }),
      tipo: [values.tipo],
      concepto: [values.concepto],
      status: checkEmpty(values.status),
      "fecha-start": checkEmpty(setToLocalTimeZone(values["fecha-start"])),
      "fecha-orig-start": checkEmpty(
        setToLocalTimeZone(values["fecha-orig-start"])
      ),
      "compania-2": checkEmpty(values["compania-2"]),
      title: checkEmpty(values.title),
      descripcion: checkEmpty(values.descripcion),
      "importe-2": checkEmpty(values["importe-2"], "currency"),
      "nro-op": checkEmpty(values["nro-op"]),
      "factura-ndeg": checkEmpty(values["factura-ndeg"]),
    };

    dispatch(newFacturacion(payload));
    setFormActions(actions);
  };

  useEffect(() => {
    setActions(actions);
    dispatch(getStatics());
    !judiciales && dispatch(getAllJudiciales({ page: 0, limit: 5000 }));
    !prejudiciales && dispatch(getAllPrejudiciales({ page: 0, limit: 5000 }));
    setLayout({ ...layout, showBackButton: true });
  }, []);

  useEffect(() => {
    if (newFacturacionStates.success && !!newFacturacionInformation) {
      toast.success("👌🏼 Facturación creada correctamente");
      setTimeout(() => {
        formActions?.resetForm();
        dispatch(resetNewFacturacion());
        navigate(
          `/dashboard/facturacion/${newFacturacionInformation["item-id"]}`,
          { replace: true }
        );
      }, 1000);
    }

    if (newFacturacionStates.error) {
      toast.error(`😱 No se ha podido crear la facturación`);
      setTimeout(() => {
        dispatch(resetNewFacturacion());
      }, 2000);
    }
  }, [newFacturacionStates, newFacturacionInformation]);

  return (
    <Formik
      initialValues={{
        ...initialValues,
        "fecha-orig-start": toDateInputValue(new Date()),
        status: 1,
      }}
      onSubmit={(values, actions) => {
        if (onSubmit) onSubmit({ values, actions });
      }}
      validateOnChange={formSubmmited}
      validateOnBlur={false}
      validationSchema={schema}
      enableReinitialize
    >
      {({
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        resetForm,
        setFieldValue,
        isSubmitting,
      }) => {
        return (
          <form
            className="theme-form"
            onSubmit={(event) => {
              setFormSubmmited(true);
              handleSubmit(event);
            }}
            id="new-facturacion-form"
          >
            <Grid.ContainerFluid>
              <Grid.Row>
                <Grid.Col lg={10} offset={{ lg: 1 }}>
                  <Grid.Row>
                    <Grid.Col lg={6} offset={{ lg: 1 }}>
                      <Grid.Row>
                        <Grid.Col>
                          <Input
                            name="title"
                            disabled={newFacturacionStates.loading}
                            error={errors["title"]}
                            touched={touched["title"]}
                            value={values["title"]}
                            type="text"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Titulo *",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Col lg={6}>
                          <Input
                            name="fecha-orig-start"
                            disabled={newFacturacionStates.loading}
                            error={errors["fecha-orig-start"]}
                            touched={touched["fecha-orig-start"]}
                            value={values["fecha-orig-start"]}
                            type="date"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            readOnly
                            options={{
                              label: "Fecha *",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={6}>
                          <Input
                            name="fecha-start"
                            disabled={newFacturacionStates.loading}
                            error={errors["fecha-start"]}
                            touched={touched["fecha-start"]}
                            value={values["fecha-start"]}
                            type="month"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Período a que corresponde *",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <ReactSelect
                            name="compania-2"
                            disabled={
                              newFacturacionStates.loading ||
                              facturacionStaticsStates.loading
                            }
                            error={errors["compania-2"]}
                            touched={touched["compania-2"]}
                            items={facturacionStatics?.categories[
                              "compania-2"
                            ]?.map((etapa) => ({
                              label: etapa.text,
                              value: etapa.id,
                            }))}
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Compañia *",
                              marginBottom: 24,
                              loading: facturacionStaticsStates.loading,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <ReactSelect
                            name="concepto"
                            disabled={
                              newFacturacionStates.loading ||
                              facturacionStaticsStates.loading
                            }
                            error={errors["concepto"]}
                            touched={touched["concepto"]}
                            items={facturacionStatics?.categories[
                              "concepto"
                            ]?.map((etapa) => ({
                              label: etapa.text,
                              value: etapa.id,
                            }))}
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Concepto *",
                              marginBottom: 24,
                              loading: facturacionStaticsStates.loading,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <ReactSelect
                            name="tipo"
                            disabled={
                              newFacturacionStates.loading ||
                              facturacionStaticsStates.loading
                            }
                            error={errors["tipo"]}
                            touched={touched["tipo"]}
                            items={facturacionStatics?.categories["tipo"]?.map(
                              (etapa) => ({
                                label: etapa.text,
                                value: etapa.id,
                              })
                            )}
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Tipo *",
                              marginBottom: 24,
                              loading: facturacionStaticsStates.loading,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      {(values["tipo"] === type.JUDICIAL ||
                        values["tipo"] === type.PREJUDICIAL) && (
                        <Grid.Row>
                          <Grid.Col>
                            <ReactSelect
                              name="casocontraro"
                              disabled={
                                newFacturacionStates.loading ||
                                judicialesStates.loading ||
                                prejudicialesStates.loading
                              }
                              error={errors["casocontraro"]}
                              touched={touched["casocontraro"]}
                              items={[
                                ...casoContratos.filter(
                                  (casoContrato) =>
                                    (values["tipo"] === type.JUDICIAL &&
                                      casoContrato.type ===
                                        "casocontraro-judiciales") ||
                                    (values["tipo"] === type.PREJUDICIAL &&
                                      casoContrato.type ===
                                        "casocontraro-pre-judiciales")
                                ),
                              ]}
                              placeholder="Seleccione"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isMulti
                              options={{
                                label: "Caso/s",
                                marginBottom: 24,
                                loading:
                                  judicialesStates.loading ||
                                  prejudicialesStates.loading,
                              }}
                            />
                          </Grid.Col>
                        </Grid.Row>
                      )}

                      {values["tipo"] === type.HONORARIOS_EXTRAORDINARIOS && (
                        <Grid.Row>
                          <Grid.Col>
                            <Input
                              name="importe-2"
                              disabled={newFacturacionStates.loading}
                              error={errors["importe-2"]}
                              touched={touched["importe-2"]}
                              value={values["importe-2"]}
                              placeholder=""
                              onChange={handleChange}
                              onBlur={handleBlur}
                              options={{
                                label: "Importe",
                                marginBottom: 24,
                                before: (
                                  <Icon
                                    icon={CurrencyIcon}
                                    size="14px"
                                    color="black"
                                  />
                                ),
                              }}
                            />
                          </Grid.Col>
                        </Grid.Row>
                      )}

                      <Grid.Row>
                        <Grid.Col>
                          <Input
                            name="nro-op"
                            readOnly={
                              values.status !== 7 && values.status !== 8
                            }
                            disabled={newFacturacionStates.loading}
                            error={errors["nro-op"]}
                            touched={touched["nro-op"]}
                            value={values["nro-op"]}
                            type="text"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Número de OC",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <Input
                            name="factura-ndeg"
                            readOnly={
                              values.status !== 7 && values.status !== 8
                            }
                            disabled={newFacturacionStates.loading}
                            error={errors["factura-ndeg"]}
                            touched={touched["factura-ndeg"]}
                            value={values["factura-ndeg"]}
                            type="text"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Factura Nro",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <ReactSelect
                            name="status"
                            disabled={
                              newFacturacionStates.loading ||
                              facturacionStaticsStates.loading
                            }
                            readOnly
                            error={errors["status"]}
                            touched={touched["status"]}
                            items={facturacionStatics?.categories.status?.map(
                              (status) => ({
                                label: status.text,
                                value: status.id,
                              })
                            )}
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Estado",
                              marginBottom: 24,
                              loading: facturacionStaticsStates.loading,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <Textarea
                            name="descripcion"
                            disabled={newFacturacionStates.loading}
                            error={errors["descripcion"]}
                            touched={touched["descripcion"]}
                            value={values["descripcion"]}
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Descripción",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>
                    </Grid.Col>
                  </Grid.Row>
                </Grid.Col>
              </Grid.Row>
            </Grid.ContainerFluid>

            <div style={{ height: "50px" }} />

            <Center horizontal="center" vertical="center">
              <Submit
                isSubmmiting={newFacturacionStates.loading}
                form="new-facturacion-form"
                color="Primary"
                options={{
                  type: "filled",
                  skin: "primary",
                  size: "lg",
                  marginBottom: "0px",
                }}
              >
                Confirmar
              </Submit>

              <Link
                href="/dashboard/facturacion/list"
                options={{
                  type: "outline",
                  skin: "danger",
                  size: "lg",
                  marginBottom: "0px",
                }}
                style={{ marginLeft: "10px" }}
              >
                Cancelar
              </Link>
            </Center>

            <div style={{ height: "50px" }} />
          </form>
        );
      }}
    </Formik>
  );
};

const states = ({
  facturacionStore,
  judicialStore,
  prejudicialStore,
  staticsStore,
}) => {
  const { states: newFacturacionStates, data: newFacturacionInformation } =
    facturacionStore.newOne;
  const { data: facturacionStatics, states: facturacionStaticsStates } =
    facturacionStore.statics;
  const { data: judiciales, states: judicialesStates } = judicialStore.all;
  const { data: prejudiciales, states: prejudicialesStates } =
    prejudicialStore.all;
  const { data: statics, states: staticsStates } = staticsStore;

  return {
    newFacturacionStates,
    newFacturacionInformation,
    facturacionStaticsStates,
    facturacionStatics,
    judiciales,
    judicialesStates,
    prejudiciales,
    prejudicialesStates,
    statics,
    staticsStates,
  };
};

export default connect(states)(Component);
