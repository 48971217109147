import { GetAllPagosOutDto, GetAllPagosInDto } from "../../../types/pago.dto";

export const GET_ALL_PAGOS = "GET_ALL_PAGOS";
export const GET_ALL_PAGOS_SUCCESS = "GET_ALL_PAGOS_SUCCESS";
export const GET_ALL_PAGOS_ERROR = "GET_ALL_PAGOS_ERROR";
export const RESET_GET_ALL_PAGOS = "RESET_GET_ALL_PAGOS";

export interface GetAllPagos {
  type: typeof GET_ALL_PAGOS;
  payload: GetAllPagosOutDto;
}

export interface GetAllPagosSuccess {
  type: typeof GET_ALL_PAGOS_SUCCESS;
  payload: GetAllPagosInDto[];
}

export interface GetAllPagosError {
  type: typeof GET_ALL_PAGOS_ERROR;
  payload: string | boolean;
}

export interface ResetGetAllPagos {
  type: typeof RESET_GET_ALL_PAGOS;
  payload: null;
}

export interface State {
  data: GetAllPagosInDto[] | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | GetAllPagos
  | GetAllPagosSuccess
  | GetAllPagosError
  | ResetGetAllPagos;
