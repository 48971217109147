import { put, call, all, takeLatest } from "redux-saga/effects";
import services from "../../services";

import {
  NewJudicial,
  NEW_JUDICIAL,
  NEW_JUDICIAL_SUCCESS,
  NEW_JUDICIAL_ERROR,
} from "../types/judicial/new";

import {
  EditJudicial,
  EDIT_JUDICIAL,
  EDIT_JUDICIAL_SUCCESS,
  EDIT_JUDICIAL_ERROR,
} from "../types/judicial/edit";

import {
  DeleteJudicial,
  DELETE_JUDICIAL,
  DELETE_JUDICIAL_SUCCESS,
  DELETE_JUDICIAL_ERROR,
} from "../types/judicial/delete";

import {
  GetOneJudicial,
  GET_ONE_JUDICIAL,
  GET_ONE_JUDICIAL_SUCCESS,
  GET_ONE_JUDICIAL_ERROR,
} from "../types/judicial/getOne";

import {
  SelectOneJudicial,
  SELECT_ONE_JUDICIAL,
  SELECT_ONE_JUDICIAL_SUCCESS,
  SELECT_ONE_JUDICIAL_ERROR,
} from "../types/judicial/selectOne";

import {
  GetAllJudiciales,
  GET_ALL_JUDICIALES,
  GET_ALL_JUDICIALES_SUCCESS,
  GET_ALL_JUDICIALES_ERROR,
} from "../types/judicial/getAll";

import {
  GetCommentsJudicial,
  GET_COMMENTS_JUDICIAL,
  GET_COMMENTS_JUDICIAL_SUCCESS,
  GET_COMMENTS_JUDICIAL_ERROR,
} from "../types/judicial/getComments";

import {
  GetStaticsJudicial,
  GET_STATICS_JUDICIAL,
  GET_STATICS_JUDICIAL_SUCCESS,
  GET_STATICS_JUDICIAL_ERROR,
} from "../types/judicial/statics";

function* getAllJudiciales({ payload }) {
  try {
    const { response, error } = yield call(services.judicial.getAll, payload);

    if (error) {
      yield put({
        type: GET_ALL_JUDICIALES_ERROR,
        payload: error,
      });
    } else {
      yield put({ type: GET_ALL_JUDICIALES_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: GET_ALL_JUDICIALES_ERROR,
      payload: "Error inesperado al obtener todos los judiciales.",
    });
  }
}

function* getOneJudicial({ payload }) {
  try {
    const { response, error } = yield call(services.judicial.getOne, payload);

    if (error) {
      yield put({
        type: GET_ONE_JUDICIAL_ERROR,
        payload: error,
      });
    } else {
      yield put({ type: GET_ONE_JUDICIAL_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: GET_ONE_JUDICIAL_ERROR,
      payload: "Error inesperado al obtener el judicial seleccionado.",
    });
  }
}

function* selectOneJudicial({ payload }) {
  try {
    const { response, error } = yield call(services.judicial.getOne, payload);

    if (error) {
      yield put({
        type: SELECT_ONE_JUDICIAL_ERROR,
        payload: error,
      });
    } else {
      yield put({ type: SELECT_ONE_JUDICIAL_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: SELECT_ONE_JUDICIAL_ERROR,
      payload: "Error inesperado al seleccionar el judicial.",
    });
  }
}

function* newJudicial({ payload }) {
  try {
    const { response, error } = yield call(services.judicial.new, payload);

    if (error) {
      yield put({
        type: NEW_JUDICIAL_ERROR,
        payload: error,
      });
    } else {
      yield all([
        put({ type: NEW_JUDICIAL_SUCCESS, payload: response }),
        put({ type: GET_ALL_JUDICIALES, payload: null }),
      ]);
    }
  } catch {
    yield put({
      type: NEW_JUDICIAL_ERROR,
      payload: "Error inesperado al crear un nuevo judicial.",
    });
  }
}

function* editJudicial({ payload }) {
  try {
    const { response, error } = yield call(services.judicial.edit, payload);

    if (error) {
      yield put({
        type: EDIT_JUDICIAL_ERROR,
        payload: error,
      });
    } else {
      yield all([
        put({ type: EDIT_JUDICIAL_SUCCESS, payload: response }),
        put({ type: GET_ALL_JUDICIALES, payload: null }),
      ]);
    }
  } catch {
    yield put({
      type: EDIT_JUDICIAL_ERROR,
      payload: "Error inesperado al editar el judicial seleccionado.",
    });
  }
}

function* deleteJudicial({ payload }) {
  try {
    const { response, error } = yield call(services.judicial.delete, payload);

    if (error) {
      yield put({
        type: DELETE_JUDICIAL_ERROR,
        payload: error,
      });
    } else {
      yield all([
        put({ type: DELETE_JUDICIAL_SUCCESS, payload: response }),
        put({ type: GET_ALL_JUDICIALES, payload: null }),
      ]);
    }
  } catch {
    yield put({
      type: DELETE_JUDICIAL_ERROR,
      payload: "Error inesperado al eliminar el judicial seleccionado.",
    });
  }
}

function* getCommentsJudicial({ payload }) {
  try {
    const { response, error } = yield call(
      services.judicial.getComments,
      payload
    );

    if (error) {
      yield put({
        type: GET_COMMENTS_JUDICIAL_ERROR,
        payload: error,
      });
    } else {
      yield put({ type: GET_COMMENTS_JUDICIAL_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: GET_COMMENTS_JUDICIAL_ERROR,
      payload:
        "Error inesperado al ontener comentarios del judicial seleccionado.",
    });
  }
}

function* getStatics() {
  try {
    const { response, error } = yield call(services.judicial.getStatics);

    if (error) {
      yield put({
        type: GET_STATICS_JUDICIAL_ERROR,
        payload: error,
      });
    } else {
      yield put({ type: GET_STATICS_JUDICIAL_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: GET_STATICS_JUDICIAL_ERROR,
      payload: "Error inesperado al ontener los estaticos de judicial.",
    });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllJudiciales>(GET_ALL_JUDICIALES, getAllJudiciales);
  yield takeLatest<GetOneJudicial>(GET_ONE_JUDICIAL, getOneJudicial);
  yield takeLatest<EditJudicial>(EDIT_JUDICIAL, editJudicial);
  yield takeLatest<DeleteJudicial>(DELETE_JUDICIAL, deleteJudicial);
  yield takeLatest<NewJudicial>(NEW_JUDICIAL, newJudicial);
  yield takeLatest<SelectOneJudicial>(SELECT_ONE_JUDICIAL, selectOneJudicial);
  yield takeLatest<GetCommentsJudicial>(
    GET_COMMENTS_JUDICIAL,
    getCommentsJudicial
  );
  yield takeLatest<GetStaticsJudicial>(GET_STATICS_JUDICIAL, getStatics);
}
