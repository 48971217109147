import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import { Center, Card } from "../../../../components/StyledComponents";
import { toast } from "react-toastify";
import Wysiwyg from "../../../../components/Form/Wysiwyg";
import Grid from "../../../../components/Grid";
import Button from "../../../../components/Button";
import Input from "../../../../components/Form/Input";
import Submit from "../../../../components/Form/Submit";
import ReactSelect from "../../../../components/Form/ReactSelect";
import OtherContent from "../../../../components/OtherContent";
import {
  initialValues,
  schema,
} from "../../../../constants/form/prejudicial/edit";
import {
  editPrejudicial,
  getOnePrejudicial,
  getStatics,
  newPrejudicial,
  resetEditPrejudicial,
  resetNewPrejudicial,
} from "../../../../store/actions/prejudicial";
import { hideModal } from "../../../../store/actions/modal";
import { Action, useActions } from "../../../../provider/ActionsProvider";
import Icon from "../../../../components/Icon";
import CurrencyIcon from "../../../../assets/icons/dollar.svg";
import PercentageIcon from "../../../../assets/icons/percentage.svg";
import constants from "../../../../constants";
import {
  GetAllPrejudicialesInDto,
  GetOnePrejudicialInDto,
  PrejudicialDto,
} from "../../../../types/prejudicial.dto";
import { useParams } from "react-router";
import { GetAllStaticsInDto } from "../../../../types/statics.dto";
import { getAllStatics } from "../../../../store/actions/statics";
import { useLayout } from "../../../../provider/LayoutProvider";
import { stripHtml } from "../../../../helpers/strip-html";
import { argentinaPesos } from "../../../../helpers/loca-currency";
import {
  dateSpliter,
  setToLocalTimeZone,
} from "../../../../helpers/data-handler";
import { preventEnter } from "../../../../helpers/prevent-enter";
import checkEmpty from "../../../../helpers/check-empty";
import { EntitiesEnum } from "../../../../types/entities.enum";

interface ComponentProps {
  editPrejudicialStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  prejudicial: GetOnePrejudicialInDto;
  prejudicialStatics;
  prejudicialStaticsStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  statics: GetAllStaticsInDto;
  staticsStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
}

const Component = ({
  editPrejudicialStates,
  prejudicial,
  prejudicialStaticsStates,
  prejudicialStatics,
  statics,
  staticsStates,
}: ComponentProps) => {
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [selectedPrejudicial, setSelectedPrejudicial] =
    useState<PrejudicialDto>();
  const { layout, setLayout } = useLayout();
  const dispatch = useDispatch();
  const { setActions } = useActions();
  let { id } = useParams();

  const actions = useMemo(
    (): Action[] =>
      canEdit
        ? [
            {
              component: "submit",
              label: "Guardar cambios",
              form: "edit-prejudicial-form",
              options: {
                type: "filled",
                skin: "primary",
                size: "lg",
                marginBottom: "0px",
              },
            },
            {
              component: "button",
              label: "Cancelar",
              onClick: () => setCanEdit(false),
              options: {
                type: "outline",
                skin: "danger",
                size: "lg",
                marginBottom: "0px",
              },
            },
          ]
        : [
            {
              component: "button",
              label: "Editar",
              onClick: () => setCanEdit(true),
              options: {
                type: "filled",
                skin: "primary",
                size: "lg",
                marginBottom: "0px",
              },
            },
          ],
    [canEdit]
  );

  const mediadores = useMemo(
    (): { label: string; value: string }[] =>
      !!statics
        ? [
            ...((statics.mediadores &&
              statics?.mediadores?.map((mediador) => ({
                label: mediador.titulo,
                value: mediador.itemId,
              }))) ||
              []),
          ]
        : [],
    [statics]
  );

  const onSubmit = ({ values, actions }) => {
    console.log(values["fecha-de-recepcion-citacion-a-seclo-start"]);

    const payload = {
      title: checkEmpty(values.title),
      tipo: checkEmpty(values.tipo),
      fuero: checkEmpty(values.fuero),
      compania: checkEmpty(values.compania),
      "clienterazon-social": checkEmpty(values["clienterazon-social"]),
      observaciones: checkEmpty(values.observaciones),
      "observaciones-2": checkEmpty(values["observaciones-2"]),
      "jurisdiccion-2": checkEmpty(values["jurisdiccion-2"]),
      "actor-dni": checkEmpty(values["actor-dni"], "dni"),
      "fecha-de-recepcion-citacion-a-seclo-start": checkEmpty(
        setToLocalTimeZone(values["fecha-de-recepcion-citacion-a-seclo-start"])
      ),
      "fecha-de-audiencia-start": checkEmpty(
        setToLocalTimeZone(values["fecha-de-audiencia-start"])
      ),
      estado: [values.estado],
      "nro-audiencias-2": checkEmpty(values["nro-audiencias-2"]),
      pretension: checkEmpty(values.pretension, "currency"),
      "1-0-0-rubros-ley": checkEmpty(values["1-0-0-rubros-ley"], "currency"),
      "monto-conciliado-adecco": checkEmpty(
        values["monto-conciliado-adecco"],
        "currency"
      ),
      "monto-responsabilidad-adecco": checkEmpty(
        values["monto-responsabilidad-adecco"],
        "currency"
      ),
      "monto-responsabilidad-usuaria": checkEmpty(
        values["monto-responsabilidad-usuaria"],
        "currency"
      ),
      "honorarios-abogado-actor-2": checkEmpty(
        values["honorarios-abogado-actor-2"],
        "currency"
      ),
      "honorarios-conciliador-2": checkEmpty(
        values["honorarios-conciliador-2"],
        "currency"
      ),
      "tasa-2": checkEmpty(values["tasa-2"]),
      itemId: values["item-id"],
    };

    dispatch(editPrejudicial(payload));
  };

  useEffect(() => {
    if (editPrejudicialStates.success) {
      dispatch(hideModal());
      setTimeout(() => {
        dispatch(resetEditPrejudicial());
      }, 2000);
    }
  }, [editPrejudicialStates]);

  useEffect(() => {
    dispatch(getStatics());
    !statics && dispatch(getAllStatics());
  }, []);

  useEffect(() => {
    setActions(actions);
  }, [canEdit]);

  useEffect(() => {
    if (editPrejudicialStates.success) {
      toast.success("👌🏼 Prejudicial actualizado correctamente");
      setTimeout(() => {
        dispatch(resetEditPrejudicial());
      }, 1000);
    }

    if (editPrejudicialStates.error) {
      toast.error(`😱 No se ha podido actualizar el prejudicial`);
      setTimeout(() => {
        dispatch(resetEditPrejudicial());
      }, 2000);
    }
  }, [editPrejudicialStates]);

  useEffect(() => {
    if (!!id) {
      dispatch(getOnePrejudicial({ itemId: id }));
    }
  }, [id]);

  useEffect(() => {
    if (!!prejudicial) {
      setLayout({
        ...layout,
        title: String(prejudicial?.title || prejudicial["item-id"]),
        breadcrumb: String(prejudicial?.title || prejudicial["item-id"]),
        showBackButton: true,
      });
    }
    setActions(actions);
  }, [prejudicial]);

  return (
    <Grid.ContainerFluid>
      <Grid.Row>
        <Grid.Col lg={10} offset={{ lg: 1 }}>
          <Grid.Row>
            <Grid.Col lg={8}>
              <Formik
                initialValues={{
                  ...initialValues,
                  ...(!!prejudicial && {
                    ...prejudicial,
                    estado: prejudicial.estado[0],
                    "actor-dni": checkEmpty(prejudicial["actor-dni"], "dni"),
                    pretension: argentinaPesos(prejudicial["pretension"]),
                    "1-0-0-rubros-ley": argentinaPesos(
                      prejudicial["1-0-0-rubros-ley"]
                    ),
                    "monto-conciliado-adecco": argentinaPesos(
                      prejudicial["monto-conciliado-adecco"]
                    ),
                    "monto-responsabilidad-adecco": argentinaPesos(
                      prejudicial["monto-responsabilidad-adecco"]
                    ),
                    "honorarios-abogado-actor-2": argentinaPesos(
                      prejudicial["honorarios-abogado-actor-2"]
                    ),
                    "honorarios-conciliador-2": argentinaPesos(
                      prejudicial["honorarios-conciliador-2"]
                    ),
                    "fecha-de-audiencia-start": dateSpliter(
                      prejudicial["fecha-de-audiencia-start"]
                    ).inputDate,
                    "fecha-de-recepcion-citacion-a-seclo-start": dateSpliter(
                      prejudicial["fecha-de-recepcion-citacion-a-seclo-start"]
                    ).inputDate,
                  }),
                }}
                onSubmit={(values, actions) => {
                  if (onSubmit) onSubmit({ values, actions });
                }}
                validateOnChange={formSubmmited}
                validateOnBlur={false}
                validationSchema={schema}
                enableReinitialize
              >
                {({
                  touched,
                  errors,
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  resetForm,
                  setFieldValue,
                  isSubmitting,
                }) => {
                  return (
                    <form
                      className="theme-form"
                      onSubmit={(event) => {
                        setFormSubmmited(true);
                        handleSubmit(event);
                      }}
                      onKeyDown={(event) => !canEdit && preventEnter(event)}
                      id="edit-prejudicial-form"
                    >
                      <Grid.Row>
                        <Grid.Col>
                          <Input
                            readOnly={!canEdit}
                            disabled={editPrejudicialStates.loading}
                            name="title"
                            error={errors["title"]}
                            touched={touched["title"]}
                            value={values["title"]}
                            type="text"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Titulo *",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <ReactSelect
                            readOnly={!canEdit}
                            disabled={
                              editPrejudicialStates.loading ||
                              prejudicialStaticsStates.loading
                            }
                            name="tipo"
                            error={errors["tipo"]}
                            touched={touched["tipo"]}
                            items={prejudicialStatics?.categories?.tipo?.map(
                              (tipo) => ({
                                label: tipo.text,
                                value: tipo.id,
                              })
                            )}
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Tipo *",
                              marginBottom: 24,
                              loading: prejudicialStaticsStates.loading,
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col>
                          <ReactSelect
                            readOnly={!canEdit}
                            disabled={
                              editPrejudicialStates.loading ||
                              prejudicialStaticsStates.loading
                            }
                            name="fuero"
                            error={errors["fuero"]}
                            touched={touched["fuero"]}
                            items={prejudicialStatics?.categories?.fuero?.map(
                              (fuero) => ({
                                label: fuero.text,
                                value: fuero.id,
                              })
                            )}
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Fuero *",
                              marginBottom: 24,
                              loading: prejudicialStaticsStates.loading,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <ReactSelect
                            readOnly={!canEdit}
                            disabled={
                              editPrejudicialStates.loading ||
                              prejudicialStaticsStates.loading
                            }
                            name="compania"
                            error={errors["compania"]}
                            touched={touched["compania"]}
                            items={prejudicialStatics?.categories?.compania?.map(
                              (compania) => {
                                return {
                                  label: compania.text,
                                  value: compania.id,
                                };
                              }
                            )}
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Empleador real",
                              marginBottom: 24,
                              loading: prejudicialStaticsStates.loading,
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col>
                          <Input
                            readOnly={!canEdit}
                            disabled={editPrejudicialStates.loading}
                            name="clienterazon-social"
                            error={errors["clienterazon-social"]}
                            touched={touched["clienterazon-social"]}
                            value={values["clienterazon-social"]}
                            type="text"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Cliente",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <Input
                            readOnly={!canEdit}
                            disabled={editPrejudicialStates.loading}
                            name="observaciones"
                            error={errors["observaciones"]}
                            touched={touched["observaciones"]}
                            value={values["observaciones"]}
                            type="text"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Partes del proceso",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col>
                          <ReactSelect
                            readOnly={!canEdit}
                            disabled={
                              editPrejudicialStates.loading ||
                              staticsStates.loading
                            }
                            name="jurisdiccion-2"
                            error={errors["jurisdiccion-2"]}
                            touched={touched["jurisdiccion-2"]}
                            items={mediadores}
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Mediadores *",
                              marginBottom: 24,
                              loading: staticsStates.loading,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col lg={6}>
                          <Input
                            readOnly={!canEdit}
                            disabled={editPrejudicialStates.loading}
                            name="actor-dni"
                            error={errors["actor-dni"]}
                            touched={touched["actor-dni"]}
                            value={values["actor-dni"]}
                            type="text"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Actor DNI *",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={3}>
                          <Input
                            readOnly={!canEdit}
                            disabled={editPrejudicialStates.loading}
                            name="fecha-de-recepcion-citacion-a-seclo-start"
                            error={
                              errors[
                                "fecha-de-recepcion-citacion-a-seclo-start"
                              ]
                            }
                            touched={
                              touched[
                                "fecha-de-recepcion-citacion-a-seclo-start"
                              ]
                            }
                            value={
                              values[
                                "fecha-de-recepcion-citacion-a-seclo-start"
                              ]
                            }
                            type="date"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Fecha recepción citación",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={3}>
                          <Input
                            readOnly={!canEdit}
                            disabled={editPrejudicialStates.loading}
                            name="fecha-de-audiencia-start"
                            error={errors["fecha-de-audiencia-start"]}
                            touched={touched["fecha-de-audiencia-start"]}
                            value={values["fecha-de-audiencia-start"]}
                            type="date"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Fecha audiencia *",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col lg={3}>
                          <ReactSelect
                            readOnly={!canEdit}
                            disabled={
                              editPrejudicialStates.loading ||
                              prejudicialStaticsStates.loading
                            }
                            name="estado"
                            error={errors["estado"]}
                            touched={touched["estado"]}
                            items={prejudicialStatics?.categories?.estado?.map(
                              (estado) => ({
                                label: estado.text,
                                value: estado.id,
                              })
                            )}
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Estado *",
                              marginBottom: 24,
                              loading: prejudicialStaticsStates.loading,
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={3}>
                          <ReactSelect
                            readOnly={!canEdit}
                            disabled={editPrejudicialStates.loading}
                            name="nro-audiencias-2"
                            error={errors["nro-audiencias-2"]}
                            touched={touched["nro-audiencias-2"]}
                            items={
                              constants.statics.prejudiciales.numeroAudiencias
                            }
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Nro. Audiencias *",
                              marginBottom: 24,
                              loading: prejudicialStaticsStates.loading,
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={3}>
                          <Input
                            readOnly={!canEdit}
                            disabled={editPrejudicialStates.loading}
                            name="pretension"
                            error={errors["pretension"]}
                            touched={touched["pretension"]}
                            value={values["pretension"]}
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Pretensión",
                              marginBottom: 24,
                              before: (
                                <Icon
                                  icon={CurrencyIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={3}>
                          <Input
                            readOnly={!canEdit}
                            disabled={editPrejudicialStates.loading}
                            name="1-0-0-rubros-ley"
                            error={errors["1-0-0-rubros-ley"]}
                            touched={touched["1-0-0-rubros-ley"]}
                            value={values["1-0-0-rubros-ley"]}
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Ofrecimiento",
                              marginBottom: 24,
                              before: (
                                <Icon
                                  icon={CurrencyIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col lg={4}>
                          <Input
                            readOnly={!canEdit}
                            disabled={editPrejudicialStates.loading}
                            name="monto-conciliado-adecco"
                            error={errors["monto-conciliado-adecco"]}
                            touched={touched["monto-conciliado-adecco"]}
                            value={values["monto-conciliado-adecco"]}
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Monto conciliado",
                              marginBottom: 24,
                              before: (
                                <Icon
                                  icon={CurrencyIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={4}>
                          <Input
                            readOnly={!canEdit}
                            disabled={editPrejudicialStates.loading}
                            name="monto-responsabilidad-adecco"
                            error={errors["monto-responsabilidad-adecco"]}
                            touched={touched["monto-responsabilidad-adecco"]}
                            value={values["monto-responsabilidad-adecco"]}
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Monto responsabilidad Adecco",
                              marginBottom: 24,
                              before: (
                                <Icon
                                  icon={CurrencyIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={4}>
                          <Input
                            readOnly={!canEdit}
                            disabled={editPrejudicialStates.loading}
                            name="monto-responsabilidad-usuaria"
                            error={errors["monto-responsabilidad-usuaria"]}
                            touched={touched["monto-responsabilidad-usuaria"]}
                            value={values["monto-responsabilidad-usuaria"]}
                            type="text"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Monto responsabilidad usuaria",
                              marginBottom: 24,
                              before: (
                                <Icon
                                  icon={CurrencyIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col lg={4}>
                          <Input
                            readOnly={!canEdit}
                            disabled={editPrejudicialStates.loading}
                            name="honorarios-abogado-actor-2"
                            error={errors["honorarios-abogado-actor-2"]}
                            touched={touched["honorarios-abogado-actor-2"]}
                            value={values["honorarios-abogado-actor-2"]}
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Honorarios Abogado/Actor",
                              marginBottom: 24,
                              before: (
                                <Icon
                                  icon={CurrencyIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={4}>
                          <Input
                            readOnly={!canEdit}
                            disabled={editPrejudicialStates.loading}
                            name="honorarios-conciliador-2"
                            error={errors["honorarios-conciliador-2"]}
                            touched={touched["honorarios-conciliador-2"]}
                            value={values["honorarios-conciliador-2"]}
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Honorarios Conciliador",
                              marginBottom: 24,
                              before: (
                                <Icon
                                  icon={CurrencyIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={4}>
                          <Input
                            readOnly={!canEdit}
                            disabled={editPrejudicialStates.loading}
                            name="tasa-2"
                            error={errors["tasa-2"]}
                            touched={touched["tasa-2"]}
                            value={values["tasa-2"]}
                            type="text"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Tasa",
                              marginBottom: 24,
                              before: (
                                <Icon
                                  icon={PercentageIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <Wysiwyg
                            readOnly={!canEdit}
                            disabled={editPrejudicialStates.loading}
                            toolbar={{ options: [] }}
                            name="observaciones-2"
                            setFieldValue={(val) =>
                              setFieldValue("observaciones-2", val)
                            }
                            value={values["observaciones-2"]}
                            style={{ minHeight: "113px" }}
                            options={{
                              label: "Observaciones",
                              marginBottom: 20,
                              loading: editPrejudicialStates.loading,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <div style={{ height: "50px" }} />

                      {canEdit && (
                        <Center horizontal="center" vertical="center">
                          <Submit
                            isSubmmiting={editPrejudicialStates.loading}
                            form="edit-prejudicial-form"
                            color="Primary"
                            options={{
                              type: "filled",
                              skin: "primary",
                              size: "lg",
                              marginBottom: "0px",
                            }}
                          >
                            Guardar cambios
                          </Submit>

                          <Button
                            onClick={() => setCanEdit(false)}
                            type="button"
                            options={{
                              type: "outline",
                              skin: "danger",
                              size: "lg",
                              marginBottom: "0px",
                            }}
                            style={{ marginLeft: "10px" }}
                          >
                            Cancelar
                          </Button>
                        </Center>
                      )}

                      <div style={{ height: "50px" }} />
                    </form>
                  );
                }}
              </Formik>
            </Grid.Col>
            <Grid.Col lg={4}>
              <Card
                style={{
                  position: "sticky",
                  top: "0",
                  height: "calc(100vh - 83px - 60px - 80px)",
                  minHeight: "485px",
                }}
              >
                <OtherContent
                  entity={EntitiesEnum.PREJUDICIALES}
                  entityId={id}
                />
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
    </Grid.ContainerFluid>
  );
};

const states = ({ prejudicialStore, staticsStore }) => {
  const { states: editPrejudicialStates } = prejudicialStore.editOne;
  const { data: prejudicial } = prejudicialStore?.one;
  const { data: prejudicialStatics, states: prejudicialStaticsStates } =
    prejudicialStore.statics;
  const { data: statics, states: staticsStates } = staticsStore;

  return {
    prejudicial,
    editPrejudicialStates,
    prejudicialStaticsStates,
    prejudicialStatics,
    statics,
    staticsStates,
  };
};

export default connect(states)(Component);
