import action from "../helpers/rest-client";

export class ErrorJudicial extends Error {}

class Judicial {
  public new(payload) {
    return action
      .Post({
        url: "/schedule",
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public edit({ itemId, ...rest }) {
    return action
      .Patch({
        url: `/schedule/${itemId}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getAll() {
    return action
      .Get({
        url: "/schedule?limit=10000",
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getOne({ itemId }) {
    return action
      .Get({
        url: `/schedule/${itemId}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public delete({ itemId }) {
    return action
      .Del({
        url: `/schedule/${itemId}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getStatics() {
    return action
      .Get({
        url: "/schedule/static",
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getFiles({ itemId }) {
    return action
      .Get({
        url: `/schedule/files/${itemId}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getComments({ itemId }) {
    return action
      .Get({
        url: `/schedule/comments/${itemId}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const judicial = new Judicial();
export default judicial;
