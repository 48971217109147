import React, { ReactElement, useContext, useEffect, useState } from "react";

export interface Action {
  label: string;
  component: "submit" | "button" | "link";
  onClick?: () => void;
  href?: string;
  form?: string;
  isLoading?: boolean;
  options: {
    type: "filled" | "outline" | "link";
    size: "xs" | "sm" | "md" | "lg" | "xl";
    skin: "primary" | "secondary" | "gray" | "transparent" | "white" | "danger";
    block?: boolean;
    marginBottom?: string;
  };
}

interface InitialValuesDto {
  actions: Action[];
  filters: ReactElement | null;
  setActions: React.Dispatch<React.SetStateAction<Action[]>>;
  setFilters: React.Dispatch<React.SetStateAction<ReactElement | null>>;
}

const initialValues: InitialValuesDto = {
  actions: [] as Action[],
  filters: null,
  setActions: () => {},
  setFilters: () => {},
};

export const ActionsContext = React.createContext(initialValues);

export const useActions = () => useContext(ActionsContext);

const ActionsProvider = ({ children, useLocation }) => {
  const [actions, setActions] = useState<Action[]>([]);
  const [filters, setFilters] = useState<ReactElement | null>(null);
  const { pathname } = useLocation();

  const resetLayout = () => {
    setActions([]);
    setFilters(null);
  };

  useEffect(() => {
    resetLayout();

    return () => {
      resetLayout();
    };
  }, [pathname]);

  return (
    <ActionsContext.Provider
      value={{ filters, setFilters, actions, setActions }}
    >
      {children}
    </ActionsContext.Provider>
  );
};

export default ActionsProvider;
