const values = [
  {
    label: "Transferencia",
    value: "TRANSFERENCIA",
  },
  {
    label: "Cheque",
    value: "CHEQUE",
  },
];

export default values;
