import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import { Center } from "../../../StyledComponents";
import TextArea from "../../../Form/TextArea";
import Submit from "../../../Form/Submit";
import Button from "../../../Button";
import { initialValues, schema } from "../../../../constants/form/comment/new";
import { Form, FullHeight } from "./styles";
import { EntitiesEnum } from "../../../../types/entities.enum";
import {
  newActivity,
  resetNewActivity,
} from "../../../../store/actions/activity";
import { toast } from "react-toastify";
import { UserDto } from "../../../../types/user.dto";

interface ComponentProps {
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
  entity: EntitiesEnum;
  entityId?: string;
  newComment;
  newCommentStates;
  userInformation: UserDto;
}

const Component = ({
  setShowForm,
  entity,
  entityId,
  newComment,
  newCommentStates,
  userInformation,
}: ComponentProps) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    if (entity && entityId) {
      dispatch(
        newActivity({
          entity,
          entityId,
          comment: `${userInformation.nombre} ${userInformation.apellido}: ${values["comment"]}`,
        })
      );
      setFormActions(actions);
    }
  };

  useEffect(() => {
    if (newCommentStates.success && !!newActivity) {
      toast.success("👌🏼 Comentario agregado correctamente");
      setTimeout(() => {
        formActions?.resetForm();
        dispatch(resetNewActivity());
        setShowForm(false);
      }, 1000);
    }

    if (newCommentStates.error) {
      toast.error(`😱 No se ha podido agregar su comentario`);
    }
  }, [newCommentStates, newActivity]);

  return (
    <Formik
      initialValues={{ ...initialValues }}
      onSubmit={(values, actions) => {
        if (onSubmit) onSubmit({ values, actions });
      }}
      validateOnChange={formSubmmited}
      validateOnBlur={false}
      validationSchema={schema}
      enableReinitialize
    >
      {({
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        resetForm,
        setFieldValue,
        isSubmitting,
      }) => {
        return (
          <>
            <Form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="add-new-comment"
            >
              <h3>Nuevo Comentario</h3>

              <FullHeight>
                <TextArea
                  readOnly={newCommentStates.loading}
                  disabled={newCommentStates.loading}
                  name="comment"
                  value={values["comment"]}
                  error={errors["comment"]}
                  touched={touched["comment"]}
                  placeholder="Deja un comentario"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={{
                    marginBottom: 0,
                  }}
                />
              </FullHeight>

              <Center styles={{ justifyContent: "space-between" }}>
                <Button
                  onClick={() => {
                    resetForm();
                    setShowForm(false);
                  }}
                  type="button"
                  options={{
                    type: "link",
                    skin: "primary",
                    size: "md",
                    marginBottom: "0px",
                  }}
                  style={{ marginRight: "10px" }}
                >
                  Cancelar
                </Button>

                <Submit
                  isSubmmiting={newCommentStates.loading}
                  form="add-new-comment"
                  color="Primary"
                  options={{
                    type: "filled",
                    skin: "primary",
                    size: "md",
                    marginBottom: "0px",
                  }}
                >
                  Confirmar
                </Submit>
              </Center>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

const state = ({ activityStore, userStore }) => {
  const { data: newComment, states: newCommentStates } = activityStore.newOne;
  const { data: userInformation } = userStore.information;

  return {
    newComment,
    newCommentStates,
    userInformation,
  };
};

export default connect(state)(Component);
