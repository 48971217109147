import styled, { css } from "styled-components";
import { NavLink as NavLinkUi } from "react-router-dom";

export const Navbar = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Nav = styled("nav")`
  grid-area: nav;
`;

export const NavItems = styled("ul")`
  margin: 0px;
  padding: 0px;
  list-style: none;
`;

export const NavItem = styled("li")`
  padding-right: 0px;
`;

const Link = css`
  text-decoration: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  font-size: 16px;
  width: 100%;
  margin-bottom: 1px;
  padding: 0px 15px 0px;
  position: relative;
  border-radius: 0px 0px 0px 0px;
  transition: all 0.25s ease-in-out;
  color: ${(props) => props.theme.colors.White};

  &.no-styles {
    border: 0;
    margin: 0;
    background: transparent;

    &:before,
    &:after {
      display: none;
    }
  }

  &.active {
    font-weight: 500;
    background-color: ${(props) => props.theme.colors.LightBlue};
    // border-radius: 0px 33px 33px 0px;
    color: ${(props) => props.theme.colors.White};

    i {
      &:before {
        background-color: ${(props) => props.theme.colors.Primary};
      }
    }

    .caret {
      transform: rotate(0deg);
    }

    &:before {
      content: "";
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-right: 20px solid #f2f4fa;
      border-radius: 4px;
      position: absolute;
      right: -10px;
      z-index: 10;
    }
  }

  &:not(.active):hover {
    background-color: ${(props) => props.theme.colors.LightGray};
    // border-radius: 0px 33px 33px 0px;
    color: ${(props) => props.theme.colors.DarkGray};

    i {
      &:before {
        background-color: ${(props) => props.theme.colors.DarkGray};
      }
    }
  }

  span {
    display: flex;
    align-items: center;
    justify-content: space-between;

    i {
      margin-right: 10px;
      &:before {
        transition: all 0.25s ease-in-out;
      }
    }
  }
`;

export const ButtonLink = styled("button")`
  border: 0px;
  margin: 0px;
  background: none;
  cursor: pointer;
  ${Link}
`;

export const NavLink = styled(NavLinkUi)`
  ${Link}
`;

export const Caret = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out;
  transform: rotate(-180deg);
  width: 24px;
  height: 24px;

  &.active {
    transform: rotate(0deg);
  }
`;

export const DropdownMenu = styled("div")<{ amountItems; isActiveDropdown }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  height: ${({ amountItems }) => amountItems * 34 + 1}px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  opacity: ${({ isActiveDropdown }) => (isActiveDropdown ? 1 : 0)};
  max-height: ${({ isActiveDropdown, amountItems }) =>
    isActiveDropdown ? amountItems * 34 : 0}px;
`;

export const DropdownItem = styled("div")`
  min-width: 185px;
  margin-bottom: 1px;
`;

export const SubNavLink = styled(NavLinkUi)`
  text-decoration: none;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 7px 10px;
  position: relative;
  border-radius: 16px;
  transition: all 0.25s ease-in-out;
  // color: ${(props) => props.theme.colors.Gray};
  // background-color: white;

  span {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &.no-styles {
    border: 0;
    margin: 0;
    background: transparent;

    &:before,
    &:after {
      display: none;
    }
  }

  &.active {
    font-weight: 500;
    background-color: ${(props) => props.theme.colors.LightBlue};
    color: ${(props) => props.theme.colors.Primary};
  }

  &:not(.active):hover {
    background-color: ${(props) => props.theme.colors.LightGray};
    color: ${(props) => props.theme.colors.DarkGray};
  }
`;
