import {
  NewFacturacionOutDto,
  NewFacturacionInDto,
} from "../../../types/facturacion.dto";

export const NEW_FACTURACION = "NEW_FACTURACION";
export const NEW_FACTURACION_SUCCESS = "NEW_FACTURACION_SUCCESS";
export const NEW_FACTURACION_ERROR = "NEW_FACTURACION_ERROR";
export const RESET_NEW_FACTURACION = "RESET_NEW_FACTURACION";

export interface NewFacturacion {
  type: typeof NEW_FACTURACION;
  payload: NewFacturacionOutDto;
}

export interface NewFacturacionSuccess {
  type: typeof NEW_FACTURACION_SUCCESS;
  payload: NewFacturacionInDto;
}

export interface NewFacturacionError {
  type: typeof NEW_FACTURACION_ERROR;
  payload: string | boolean;
}

export interface ResetNewFacturacion {
  type: typeof RESET_NEW_FACTURACION;
  payload: null;
}

export interface State {
  data: NewFacturacionInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | NewFacturacion
  | NewFacturacionSuccess
  | NewFacturacionError
  | ResetNewFacturacion;
