import { Loader, Circle } from "./styles";

interface ComponentProps {
  color?: "Primary" | "Secondary" | "White";
}

const Component = (props: ComponentProps) => {
  return (
    <Loader>
      <Circle {...props}></Circle>
      <Circle {...props}></Circle>
      <Circle {...props}></Circle>
      <Circle {...props}></Circle>
    </Loader>
  );
};

export default Component;
