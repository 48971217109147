import auth from "./auth";
import user from "./user";
import prejudicial from "./prejudicial";
import judicial from "./judicial";
import calendar from "./calendar";
import pago from "./pago";
import facturacion from "./facturacion";
import statics from "./statics";
import files from "./files";
import activity from "./activity";

const services = {
  auth,
  user,
  prejudicial,
  judicial,
  calendar,
  pago,
  facturacion,
  statics,
  files,
  activity,
};

export default services;
