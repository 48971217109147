import * as Yup from "yup";

export const schema = Yup.object().shape({
  oldPassword: Yup.string().required("Este campo es requerido"),
  confirmNewPassword: Yup.string()
    .required("Este campo es requerido")
    .oneOf([Yup.ref("confirmNewPassword"), null], "La contraseña no coincide"),
  newPassword: Yup.string()
    .required("Este campo es requerido")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Debe contener 8 caracateres, uno en mayuscula, uno en minuscula, un numero y un caracter especial."
    ),
});

interface FormValues {
  oldPassword: string;
  confirmNewPassword: string;
  newPassword: string;
}

export const initialValues: FormValues = {
  oldPassword: "",
  confirmNewPassword: "",
  newPassword: "",
};
