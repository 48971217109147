import {
  NewJudicialOutDto,
  NewJudicialInDto,
} from "../../../types/judicial.dto";

export const NEW_JUDICIAL = "NEW_JUDICIAL";
export const NEW_JUDICIAL_SUCCESS = "NEW_JUDICIAL_SUCCESS";
export const NEW_JUDICIAL_ERROR = "NEW_JUDICIAL_ERROR";
export const RESET_NEW_JUDICIAL = "RESET_NEW_JUDICIAL";

export interface NewJudicial {
  type: typeof NEW_JUDICIAL;
  payload: NewJudicialOutDto;
}

export interface NewJudicialSuccess {
  type: typeof NEW_JUDICIAL_SUCCESS;
  payload: NewJudicialInDto;
}

export interface NewJudicialError {
  type: typeof NEW_JUDICIAL_ERROR;
  payload: string | boolean;
}

export interface ResetNewJudicial {
  type: typeof RESET_NEW_JUDICIAL;
  payload: null;
}

export interface State {
  data: NewJudicialInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | NewJudicial
  | NewJudicialSuccess
  | NewJudicialError
  | ResetNewJudicial;
