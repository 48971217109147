export const GET_STATICS_CALENDAR = "GET_STATICS_CALENDAR";
export const GET_STATICS_CALENDAR_SUCCESS = "GET_STATICS_CALENDAR_SUCCESS";
export const GET_STATICS_CALENDAR_ERROR = "GET_STATICS_CALENDAR_ERROR";
export const RESET_GET_STATICS_CALENDAR = "RESET_GET_STATICS_CALENDAR";

export interface GetStaticsCalendar {
  type: typeof GET_STATICS_CALENDAR;
  payload: null;
}

export interface GetStaticsCalendarSuccess {
  type: typeof GET_STATICS_CALENDAR_SUCCESS;
  payload: any;
}

export interface GetStaticsCalendarError {
  type: typeof GET_STATICS_CALENDAR_ERROR;
  payload: string | boolean;
}

export interface ResetGetStaticsCalendar {
  type: typeof RESET_GET_STATICS_CALENDAR;
  payload: null;
}

export interface State {
  data: any;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | GetStaticsCalendar
  | GetStaticsCalendarSuccess
  | GetStaticsCalendarError
  | ResetGetStaticsCalendar;
