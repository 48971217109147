import { CSSProperties, useState } from "react";
import { useFormikFieldValidation } from "../../../hooks/useFormikFieldValidation";
import { Textarea } from "./styles";
import calculateNodeHeight from "./calculateNodeHeight";
import {
  FieldContainer,
  Field,
  Label,
  FieldBefore,
  FieldAfter,
  HelperLine,
  HelperText,
  ExplainText,
  ErrorMessage,
  Required,
  CharacterCounter,
} from "../Field";

const initialValue = {
  options: {
    skin: "base",
    size: "md",
  },
};

interface ComponentProps {
  name: string;
  value?: string | number | null;
  type?: string;
  disabled?: boolean;
  style?: CSSProperties;
  error?: {};
  touched?: {};
  placeholder?: string;
  onFocus?: (data: any) => void;
  onBlur?: (data: any) => void;
  onChange?: (data: any) => void;
  maxLength?: number;
  autocomplete?: "off" | "on" | "new-password";
  forwardRef?;
  readOnly?: boolean;
  options?: {
    label?: string;
    skin?: "base";
    size?: "md";
    helperText?: string;
    explainText?: string;
    before?: any;
    after?: any;
    marginBottom?: number;
    hidden?: boolean;
  };
}

const Component = ({
  forwardRef,
  options = {},
  type,
  disabled,
  name,
  error,
  touched,
  maxLength,
  onFocus,
  onBlur,
  onChange,
  style = {},
  readOnly,
  ...rest
}: ComponentProps) => {
  const { classes, setActive } = useFormikFieldValidation(
    error,
    name,
    disabled,
    readOnly
  );

  const { skin, size, marginBottom, hidden } = {
    ...initialValue.options,
    ...options,
  };

  const [numCharacters, setNumCharacters] = useState(0);
  const [height, setHeight] = useState(0);

  const handleOnChange = (event, callback) => {
    const { value } = event.target;
    setNumCharacters(value.length);
    handleResize(event);
    callback && callback(event);
  };

  const handleOnBlur = (event, callback) => {
    setActive(false);
    callback && callback(event);
  };

  const handleOnFocus = (event, callback) => {
    setActive(true);
    callback && callback(event);
  };

  const handleResize = (event) => {
    const height = calculateNodeHeight(event.target, event.target.value);
    setHeight(height);
  };

  return (
    <FieldContainer
      marginBottom={marginBottom}
      {...(hidden && { style: { display: "none" } })}
    >
      {options?.label && (
        <Label
          size={options?.size}
          htmlFor={`input-${name}`}
          style={!!options?.explainText ? { marginBottom: "0px" } : {}}
        >
          {options.label}
          {/* {rules && rules.required && <Required>*</Required>} */}
        </Label>
      )}

      {!!options?.explainText && (
        <ExplainText>{options?.explainText}</ExplainText>
      )}

      <Field
        skin={skin}
        size={size}
        className={classes}
        style={{ padding: "0px" }}
      >
        {options?.before && (
          <FieldBefore size={options?.size} className="field-before">
            {options.before}
          </FieldBefore>
        )}

        <Textarea
          style={{ height: `${height}px`, ...style }}
          id={name}
          name={name}
          maxLength={maxLength}
          disabled={disabled}
          readOnly={readOnly}
          ref={forwardRef}
          skin={skin}
          size={size}
          onInput={(event) => handleResize(event)}
          onPaste={(event) => handleResize(event)}
          onCut={(event) => handleResize(event)}
          onChange={(event) => handleOnChange(event, onChange)}
          onBlur={(event) => handleOnBlur(event, onBlur)}
          onFocus={(event) => handleOnFocus(event, onFocus)}
          onWheel={(event) => event.currentTarget.blur()}
          className={`form-control ${
            !!error && !!touched ? "error-field" : ""
          }`}
          {...rest}
        />

        {(options?.after || type === "password") && (
          <FieldAfter size={options?.size} className="field-after">
            {options && options.after}
          </FieldAfter>
        )}
      </Field>

      {((options && options.helperText) || maxLength || error) && (
        <HelperLine marginBottom={marginBottom}>
          {!!error && !!touched && <ErrorMessage>{error}</ErrorMessage>}
          {options && options.helperText && !error && (
            <HelperText>{options.helperText}</HelperText>
          )}
          {maxLength && (
            <CharacterCounter errors={!!error && !!touched}>
              {numCharacters} / {maxLength}
            </CharacterCounter>
          )}
        </HelperLine>
      )}
    </FieldContainer>
  );
};

export default Component;
