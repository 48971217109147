export const GET_STATICS_PAGO = "GET_STATICS_PAGO";
export const GET_STATICS_PAGO_SUCCESS = "GET_STATICS_PAGO_SUCCESS";
export const GET_STATICS_PAGO_ERROR = "GET_STATICS_PAGO_ERROR";
export const RESET_GET_STATICS_PAGO = "RESET_GET_STATICS_PAGO";

export interface GetStaticsPago {
  type: typeof GET_STATICS_PAGO;
  payload: null;
}

export interface GetStaticsPagoSuccess {
  type: typeof GET_STATICS_PAGO_SUCCESS;
  payload: any;
}

export interface GetStaticsPagoError {
  type: typeof GET_STATICS_PAGO_ERROR;
  payload: string | boolean;
}

export interface ResetGetStaticsPago {
  type: typeof RESET_GET_STATICS_PAGO;
  payload: null;
}

export interface State {
  data: any;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | GetStaticsPago
  | GetStaticsPagoSuccess
  | GetStaticsPagoError
  | ResetGetStaticsPago;
