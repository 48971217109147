import {
  GetAllActivitiesInDto,
  GetAllActivitiesOutDto,
} from "../../../types/activity.dto";

export const GET_ALL_ACTIVITIES = "GET_ALL_ACTIVITIES";
export const GET_ALL_ACTIVITIES_SUCCESS = "GET_ALL_ACTIVITIES_SUCCESS";
export const GET_ALL_ACTIVITIES_ERROR = "GET_ALL_ACTIVITIES_ERROR";
export const RESET_GET_ALL_ACTIVITIES = "RESET_GET_ALL_ACTIVITIES";

export interface GetAllActivities {
  type: typeof GET_ALL_ACTIVITIES;
  payload: GetAllActivitiesOutDto;
}

export interface GetAllActivitiesSuccess {
  type: typeof GET_ALL_ACTIVITIES_SUCCESS;
  payload: GetAllActivitiesInDto;
}

export interface GetAllActivitiesError {
  type: typeof GET_ALL_ACTIVITIES_ERROR;
  payload: string | boolean;
}

export interface ResetGetAllActivities {
  type: typeof RESET_GET_ALL_ACTIVITIES;
  payload: null;
}

export interface State {
  data: GetAllActivitiesInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | GetAllActivities
  | GetAllActivitiesSuccess
  | GetAllActivitiesError
  | ResetGetAllActivities;
