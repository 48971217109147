import { put, call, all, takeLatest } from "redux-saga/effects";
import services from "../../services";

import {
  NewActivity,
  NEW_ACTIVITY,
  NEW_ACTIVITY_SUCCESS,
  NEW_ACTIVITY_ERROR,
} from "../types/activity/new";

import {
  EditActivity,
  EDIT_ACTIVITY,
  EDIT_ACTIVITY_SUCCESS,
  EDIT_ACTIVITY_ERROR,
} from "../types/activity/edit";

import {
  GetOneActivity,
  GET_ONE_ACTIVITY,
  GET_ONE_ACTIVITY_SUCCESS,
  GET_ONE_ACTIVITY_ERROR,
} from "../types/activity/getOne";

import {
  GetAllActivities,
  GET_ALL_ACTIVITIES,
  GET_ALL_ACTIVITIES_SUCCESS,
  GET_ALL_ACTIVITIES_ERROR,
} from "../types/activity/getAll";

import {
  DeleteActivity,
  DELETE_ACTIVITY,
  DELETE_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY_ERROR,
} from "../types/activity/delete";

function* newActivity({ payload }) {
  try {
    const { response, error } = yield call(services.activity.new, payload);

    if (error) {
      yield put({
        type: NEW_ACTIVITY_ERROR,
        payload: error,
      });
    } else {
      yield all([
        put({ type: NEW_ACTIVITY_SUCCESS, payload: response }),
        put({ type: GET_ALL_ACTIVITIES, payload }),
      ]);
    }
  } catch {
    yield put({
      type: NEW_ACTIVITY_ERROR,
      payload: "Error inesperado al crear un nuevo archivo.",
    });
  }
}

function* editActivity({ payload }) {
  try {
    const { response, error } = yield call(services.activity.edit, payload);

    if (error) {
      yield put({
        type: EDIT_ACTIVITY_ERROR,
        payload: error,
      });
    } else {
      yield put({ type: EDIT_ACTIVITY_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: EDIT_ACTIVITY_ERROR,
      payload:
        "Error inesperado al obtener archivos del activity seleccionado.",
    });
  }
}

function* getActivity({ payload }) {
  try {
    const { response, error } = yield call(services.activity.getOne, payload);

    if (error) {
      yield put({
        type: GET_ONE_ACTIVITY_ERROR,
        payload: error,
      });
    } else {
      yield put({ type: GET_ONE_ACTIVITY_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: GET_ONE_ACTIVITY_ERROR,
      payload: "Error inesperado al obtener el archivo seleccionado.",
    });
  }
}

function* getAllActivities({ payload }) {
  console.log("getAllActivities 2");
  try {
    const { response, error } = yield call(services.activity.getAll, payload);

    if (error) {
      yield put({
        type: GET_ALL_ACTIVITIES_ERROR,
        payload: error,
      });
    } else {
      yield put({ type: GET_ALL_ACTIVITIES_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: GET_ALL_ACTIVITIES_ERROR,
      payload: "Error inesperado al obtener los archivos.",
    });
  }
}

function* deleteActivity({ payload }) {
  try {
    const { response, error } = yield call(services.activity.delete, payload);

    if (error) {
      yield put({
        type: DELETE_ACTIVITY_ERROR,
        payload: error,
      });
    } else {
      yield put({ type: DELETE_ACTIVITY_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: DELETE_ACTIVITY_ERROR,
      payload: "Error inesperado al intentar eliminar el archivo.",
    });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetOneActivity>(GET_ONE_ACTIVITY, getActivity);
  yield takeLatest<GetAllActivities>(GET_ALL_ACTIVITIES, getAllActivities);
  yield takeLatest<NewActivity>(NEW_ACTIVITY, newActivity);
  yield takeLatest<DeleteActivity>(DELETE_ACTIVITY, deleteActivity);
  yield takeLatest<EditActivity>(EDIT_ACTIVITY, editActivity);
}
