import moment from "moment";
import tzMoment from "moment-timezone";

const months = {
  1: {
    large: "Enero",
    short: "Ene",
  },
  2: {
    large: "Febrero",
    short: "Feb",
  },
  3: {
    large: "Marzo",
    short: "Mar",
  },
  4: {
    large: "Abril",
    short: "Abr",
  },
  5: {
    large: "Mayo",
    short: "May",
  },
  6: {
    large: "Junio",
    short: "Jun",
  },
  7: {
    large: "Julio",
    short: "Jul",
  },
  8: {
    large: "Agosto",
    short: "Ago",
  },
  9: {
    large: "Septiembre",
    short: "Sep",
  },
  10: {
    large: "Octubre",
    short: "Oct",
  },
  11: {
    large: "Noviembre",
    short: "Nov",
  },
  12: {
    large: "Diciembre",
    short: "Dic",
  },
};

const days = [
  {
    large: "domingo",
    short: "Dom",
  },
  {
    large: "lunes",
    short: "Lun",
  },
  {
    large: "martes",
    short: "Mar",
  },
  {
    large: "miercoles",
    short: "Mie",
  },
  {
    large: "jueves",
    short: "Jue",
  },
  {
    large: "viernes",
    short: "Vie",
  },
  {
    large: "sabado",
    short: "Sab",
  },
];

export const minInputDate = () => {
  const today = new Date();
  const dd = today.getDate();
  const mm = today.getMonth() + 1; //January is 0 so need to add 1 to make it 1!
  const yyyy = today.getFullYear();

  const day = dd < 10 ? `0${dd}` : dd;
  const month = mm < 10 ? `0${mm}` : mm;

  return `${yyyy}-${month}-${day}`;
};

export const setToZeroTimeZone = (dateTime) => {
  return tzMoment(dateTime).tz("UTC").format();
};

export const setToLocalTimeZone = (dateTime) => {
  if (!dateTime) return null;
  const LocalTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return tzMoment(dateTime).tz(LocalTimeZone).format();
};

export const dateSpliter = (date) => {
  if (!date) {
    return { inputDate: null };
  }

  const utcDate = setToLocalTimeZone(date);
  const dateObj = moment(utcDate);
  console.log(dateObj.format(), dateObj.day());

  return {
    inputDate: `${dateObj.format("YYYY")}-${dateObj.format(
      "MM"
    )}-${dateObj.format("DD")}`,
    inputMonth: `${dateObj.format("YYYY")}-${dateObj.format("MM")}`,
    inputHour: `${dateObj.format("HH")}:${dateObj.format("mm")}`,
    month: {
      number: dateObj.format("MM"),
      name: months[dateObj.format("M")],
    },
    day: {
      number: dateObj.format("D"),
      name: days[dateObj.format("d")],
    },
    year: dateObj.format("YYYY"),
    hours: dateObj.format("HH"),
    minutes: dateObj.format("mm"),
    seconds: dateObj.format("ss"),
  };
};

export const toDateInputValue = (date) => {
  var local = new Date(date);
  local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
};
