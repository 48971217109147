import styled from "styled-components";

export const Loading = styled("div")`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Text = styled("p")`
  margin: 0px;
`;
