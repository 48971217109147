import {
  Header,
  LeftSection,
  RightSection,
  ToogleNavSidebar,
  ToggleFullScreen,
} from "./styles";
import Icon from "../../../../Icon";
import constants from "../../../../../constants";
import menuBurger from "../../../../../assets/icons/menu-burger.svg";
import expand from "../../../../../assets/icons/expand.svg";
import compress from "../../../../../assets/icons/compress.svg";
import { useLayout } from "../../../../../provider/LayoutProvider";
import { useEffect, useState } from "react";

const Component = ({ children }) => {
  const { layout, setLayout, toggleFullScren } = useLayout();
  const [showFullScreen, setShowFullScreen] = useState<boolean>(false);
  const [sidebarOpened, setSidebarOpened] = useState<boolean>(false);

  useEffect(() => {
    setLayout({ ...layout, sidebarOpened });
  }, [showFullScreen, sidebarOpened]);

  return (
    <Header>
      <LeftSection>
        <ToogleNavSidebar onClick={() => setSidebarOpened(!sidebarOpened)}>
          <Icon
            icon={menuBurger}
            color={constants.company.primaryColor}
            size="16px"
          />
        </ToogleNavSidebar>

        <ToggleFullScreen
          onClick={() => {
            setShowFullScreen(!showFullScreen);
            toggleFullScren();
          }}
        >
          <Icon
            icon={showFullScreen ? compress : expand}
            color={constants.company.primaryColor}
            size="14px"
          />
        </ToggleFullScreen>
      </LeftSection>

      <RightSection>{children}</RightSection>
    </Header>
  );
};

export default Component;
