import * as Yup from "yup";

export const schema = Yup.object().shape({
  title: Yup.string().nullable().required("Campo obligatorio"),
  tipo: Yup.string().nullable().required("Campo obligatorio"),
  fuero: Yup.string().nullable().required("Campo obligatorio"),
  "jurisdiccion-2": Yup.string().nullable().required("Campo obligatorio"),
  "actor-dni": Yup.string().nullable().required("Campo obligatorio"),
  "fecha-de-audiencia-start": Yup.string()
    .nullable()
    .required("Campo obligatorio"),
  estado: Yup.string().nullable().required("Campo obligatorio"),
  "nro-audiencias-2": Yup.string().nullable().required("Campo obligatorio"),
  compania: Yup.string().nullable(),
  "clienterazon-social": Yup.string().nullable(),
  observaciones: Yup.string().nullable(),
  "fecha-de-recepcion-citacion-a-seclo-start": Yup.string().nullable(),
  pretension: Yup.mixed()
    .nullable()
    .test("currencyCheck", "Ingresa un monto correcto", (value) => {
      return (
        !value || (!!value && /^[+-]?\d{1,3}(.?\d{3})*(,\d+)?$/gm.test(value))
      );
    }),
  "1-0-0-rubros-ley": Yup.mixed()
    .nullable()
    .test("currencyCheck", "Ingresa un monto correcto", (value) => {
      return (
        !value || (!!value && /^[+-]?\d{1,3}(.?\d{3})*(,\d+)?$/gm.test(value))
      );
    }),
  "monto-conciliado-adecco": Yup.mixed()
    .nullable()
    .test("currencyCheck", "Ingresa un monto correcto", (value) => {
      return (
        !value || (!!value && /^[+-]?\d{1,3}(.?\d{3})*(,\d+)?$/gm.test(value))
      );
    }),
  "monto-responsabilidad-adecco": Yup.mixed()
    .nullable()
    .test("currencyCheck", "Ingresa un monto correcto", (value) => {
      return (
        !value || (!!value && /^[+-]?\d{1,3}(.?\d{3})*(,\d+)?$/gm.test(value))
      );
    }),
  "monto-responsabilidad-usuaria": Yup.mixed()
    .nullable()
    .test("currencyCheck", "Ingresa un monto correcto", (value) => {
      return (
        !value || (!!value && /^[+-]?\d{1,3}(.?\d{3})*(,\d+)?$/gm.test(value))
      );
    }),
  "honorarios-abogado-actor-2": Yup.mixed()
    .nullable()
    .test("currencyCheck", "Ingresa un monto correcto", (value) => {
      return (
        !value || (!!value && /^[+-]?\d{1,3}(.?\d{3})*(,\d+)?$/gm.test(value))
      );
    }),
  "honorarios-conciliador-2": Yup.mixed()
    .nullable()
    .test("currencyCheck", "Ingresa un monto correcto", (value) => {
      return (
        !value || (!!value && /^[+-]?\d{1,3}(.?\d{3})*(,\d+)?$/gm.test(value))
      );
    }),
  "tasa-2": Yup.number().nullable(),
});

interface FormValues {
  title: string;
  tipo: string;
  fuero: string;
  compania: string;
  "clienterazon-social": string;
  observaciones: string;
  "jurisdiccion-2": string;
  "actor-dni": string;
  "fecha-de-recepcion-citacion-a-seclo-start": string | null;
  "fecha-de-audiencia-start": string | null;
  estado: string;
  "nro-audiencias-2": string;
  pretension?: string;
  "1-0-0-rubros-ley"?: string;
  "monto-conciliado-adecco"?: string;
  "monto-responsabilidad-adecco"?: string;
  "monto-responsabilidad-usuaria"?: string;
  "honorarios-abogado-actor-2"?: string;
  "honorarios-conciliador-2"?: string;
  "tasa-2"?: number;
}

export const initialValues: FormValues = {
  title: "",
  tipo: "",
  fuero: "",
  compania: "",
  "clienterazon-social": "",
  observaciones: "",
  "jurisdiccion-2": "",
  "actor-dni": "",
  "fecha-de-recepcion-citacion-a-seclo-start": null,
  "fecha-de-audiencia-start": null,
  estado: "",
  "nro-audiencias-2": "",
  pretension: undefined,
  "1-0-0-rubros-ley": undefined,
  "monto-conciliado-adecco": undefined,
  "monto-responsabilidad-adecco": undefined,
  "monto-responsabilidad-usuaria": undefined,
  "honorarios-abogado-actor-2": undefined,
  "honorarios-conciliador-2": undefined,
  "tasa-2": undefined,
};
