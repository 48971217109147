import {
  EditPrejudicialOutDto,
  EditPrejudicialInDto,
} from "../../../types/prejudicial.dto";

export const EDIT_PREJUDICIAL = "EDIT_PREJUDICIAL";
export const EDIT_PREJUDICIAL_SUCCESS = "EDIT_PREJUDICIAL_SUCCESS";
export const EDIT_PREJUDICIAL_ERROR = "EDIT_PREJUDICIAL_ERROR";
export const RESET_EDIT_PREJUDICIAL = "RESET_EDIT_PREJUDICIAL";

export interface EditPrejudicial {
  type: typeof EDIT_PREJUDICIAL;
  payload: EditPrejudicialOutDto;
}

export interface EditPrejudicialSuccess {
  type: typeof EDIT_PREJUDICIAL_SUCCESS;
  payload: EditPrejudicialInDto;
}

export interface EditPrejudicialError {
  type: typeof EDIT_PREJUDICIAL_ERROR;
  payload: string | boolean;
}

export interface ResetEditPrejudicial {
  type: typeof RESET_EDIT_PREJUDICIAL;
  payload: null;
}
export interface State {
  data: EditPrejudicialInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | EditPrejudicial
  | EditPrejudicialSuccess
  | EditPrejudicialError
  | ResetEditPrejudicial;
