import useBreadcrumbs from "use-react-router-breadcrumbs";
import { NavLink } from "react-router-dom";
import { useLayout } from "../../../../../provider/LayoutProvider";
import { Breadcrumb } from "./styles";

const routes = [
  {
    path: "dashboard",
    breadcrumb: null,
    children: [
      {
        path: "judiciales",
        breadcrumb: "Judiciales",
        children: [
          { path: "new", breadcrumb: "Nuevo Judicial" },
          { path: "list", breadcrumb: "Judiciales" },
          { path: "edit", breadcrumb: "Editar Judicial" },
          { path: "preview", breadcrumb: "Judicial" },
        ],
      },
      {
        path: "prejudiciales",
        breadcrumb: "Prejudiciales",
        children: [
          { path: "new", breadcrumb: "Nuevo Prejudicial" },
          { path: "list", breadcrumb: "Prejudiciales" },
          { path: "edit", breadcrumb: "Editar Prejudicial" },
          { path: "preview", breadcrumb: "Prejudicial" },
        ],
      },
      {
        path: "agenda",
        breadcrumb: "Agenda",
        children: [
          { path: "new", breadcrumb: "Nuevo evento" },
          { path: "list", breadcrumb: null },
          { path: "edit", breadcrumb: "Editar evento" },
          { path: "preview", breadcrumb: "Evento" },
        ],
      },
      {
        path: "pagos",
        breadcrumb: "Pago",
        children: [
          { path: "new", breadcrumb: "Nuevo" },
          { path: "list", breadcrumb: "Pagos" },
          { path: "edit", breadcrumb: "Editar" },
          { path: "preview", breadcrumb: "Pago" },
        ],
      },
      {
        path: "facturacion",
        breadcrumb: "Facturación",
        children: [
          { path: "new", breadcrumb: "Nueva" },
          { path: "list", breadcrumb: "Facturaciones" },
          { path: "edit", breadcrumb: "Editar" },
          { path: "preview", breadcrumb: "Facturación" },
        ],
      },
    ],
  },
];

const Component = () => {
  const { layout } = useLayout();
  const breadcrumbs = useBreadcrumbs(routes);

  if (!layout.showBreadcrumb) {
    return null;
  }

  return (
    <Breadcrumb>
      <ol>
        {breadcrumbs.map(({ match, breadcrumb }) => (
          <li key={match.pathname}>
            <NavLink to={match.pathname}>{breadcrumb}</NavLink>
          </li>
        ))}
      </ol>
    </Breadcrumb>
  );
};

export default Component;
