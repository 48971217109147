import prejudiciales from "./prejudiciales";
import judiciales from "./judiciales";
import agenda from "./agenda";
import eventFrecuency from "./eventFrecuency";

const values = {
  prejudiciales,
  judiciales,
  agenda,
  eventFrecuency,
};

export default values;
