import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Grid from "../../../../components/Grid";
import { Tag, Card } from "../../../../components/StyledComponents";
import Table from "../../../../components/Table";
import { Action, useActions } from "../../../../provider/ActionsProvider";
import {
  getAllPagos,
  getOnePago,
  getStatics,
} from "../../../../store/actions/pago";
import Filters from "./components/Filters";
import { GetAllPagosInDto } from "../../../../types/pago.dto";
import Tabs from "../../../../components/OtherContent";
import { dateSpliter } from "../../../../helpers/data-handler";
import { argentinaPesos } from "../../../../helpers/loca-currency";
import { useLayout } from "../../../../provider/LayoutProvider";

interface ComponentProps {
  pagos: GetAllPagosInDto;
  pagosStates: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
  pagosStaticsStates: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
  pagosStatics;
}

const Component = ({
  pagos,
  pagosStates,
  pagosStatics,
  pagosStaticsStates,
}: ComponentProps) => {
  const { layout, setLayout } = useLayout();
  const { setActions, setFilters } = useActions();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeFilters, setActiveFilters] = useState();

  const actions = useMemo(
    (): Action[] => [
      {
        component: "link",
        label: "Nuevo pagos",
        href: "/dashboard/pagos/new",
        options: {
          type: "filled",
          size: "lg",
          skin: "primary",
        },
      },
    ],
    []
  );

  const columns = useMemo(
    () => [
      { Header: "Referencia", accessor: "referencia" },
      {
        Header: "Concepto",
        Cell: ({ value }) => {
          if (!!value) {
            return (
              pagosStatics?.categories?.motivo?.filter(
                (motivo) => motivo.id === value
              )[0]?.text || ""
            );
          }
        },
        accessor: "motivo",
      },
      {
        Header: "Fecha Origen",
        Cell: ({ value }) => {
          if (!!value) {
            const dateSplitted = dateSpliter(value);
            return `${dateSplitted?.day?.name.short} ${dateSplitted?.day?.number} de ${dateSplitted?.month?.name?.short} del ${dateSplitted?.year}`;
          }
        },
        accessor: "fecha-origen-start",
      },
      {
        Header: "Monto",
        Cell: ({ value }) => {
          if (!!value) {
            return argentinaPesos(value, { showCurrency: true });
          }
        },
        accessor: "monto-total",
      },
      {
        Header: "Estado",
        Cell: ({ value }) => {
          if (!!value) {
            const item = pagosStatics?.categories?.status?.filter(
              (status) => status.id === value
            )[0];

            return (
              <Tag
                style={{
                  ...(!!item?.color && { backgroundColor: "#" + item?.color }),
                }}
              >
                {item?.text}
              </Tag>
            );
          }
        },

        accessor: "status",
      },
    ],
    [pagosStatics]
  );

  const getElements = ({ page, limit }) => {
    dispatch(getAllPagos({ page, limit, filters: activeFilters }));
  };

  const getElement = (row) => {
    navigate(`/dashboard/pagos/${row.original["item-id"]}`);
  };

  useEffect(() => {
    setActions(actions);
    //setFilters(<Filters setActiveFilters={setActiveFilters}/>);
    !pagosStatics && dispatch(getStatics());
    setLayout({ ...layout, showBackButton: false });
  }, [pagos]);

  return (
    <Grid.ContainerFluid>
      <Grid.Row>
        <Grid.Col>
          <Table
            columns={columns}
            data={pagos?.hints}
            pagination={pagos?.pagination}
            onRowClick={(row, index) => getElement(row)}
            fetchData={(data) => getElements(data)}
            loading={pagosStates?.loading || !pagos}
          />
        </Grid.Col>
      </Grid.Row>
    </Grid.ContainerFluid>
  );
};

const states = ({ pagoStore }) => {
  const { data: pagos, states: pagosStates } = pagoStore.all;
  const { data: pagosStatics, states: pagosStaticsStates } = pagoStore.statics;

  return {
    pagos,
    pagosStates,
    pagosStaticsStates,
    pagosStatics,
  };
};

export default connect(states)(Component);
