const values = [
  { label: "SECLO", value: 1 },
  { label: "ACUERDO ESPONTÁNEO ", value: 2 },
  { label: "GCBA", value: 3 },
  { label: "MTEYSS", value: 4 },
  { label: "ACUERDO POR ACTA NOTARIAL (Escribania)", value: 11 },
  { label: "OCLO", value: 6 },
  { label: "ACUERDO CON HOMOLOGACION JUDICIAL", value: 7 },
  { label: "MEDIACION PRIVADA", value: 5 },
  { label: "OTRO", value: 8 },
  { label: "ACUERDO ESPONTÁNEO", value: 12 },
];

export default values;
