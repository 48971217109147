const values = [
  {
    value: 1264316554,
    label: "CAPPELLETTI Silvina Paula - Mediador",
  },
  {
    value: 1264316559,
    label: "Caprile Silvina Laura - Mediador",
  },
  {
    value: 1264316553,
    label: "Capparelli Julio C\u00e9sar - Mediador",
  },
  {
    value: 1264316552,
    label: "Capaccioli Victor Daniel - Mediador",
  },
  {
    value: 1264316558,
    label: "Capria Catalina Rosa - Mediador",
  },
  {
    value: 1264316557,
    label: "Capretti Viviana Nora - Mediador",
  },
  {
    value: 1264316555,
    label: "Capra Susana Noem\u00ed - Mediador",
  },
  {
    value: 1264316556,
    label: "Caprarulo De Burgos Nora Graciela - Mediador",
  },
];

export default values;
