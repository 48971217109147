export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
export const RESET_CHANGE_PASSWORD = "RESET_CHANGE_PASSWORD";

export interface ChangePassword {
  type: typeof CHANGE_PASSWORD;
  payload: { oldPassword: string; newPassword: string };
}

export interface ChangePasswordSuccess {
  type: typeof CHANGE_PASSWORD_SUCCESS;
  payload: null;
}

export interface ChangePasswordError {
  type: typeof CHANGE_PASSWORD_ERROR;
  payload: boolean | string;
}

export interface ResetChangePassword {
  type: typeof RESET_CHANGE_PASSWORD;
  payload: null;
}

export interface State {
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | ChangePassword
  | ChangePasswordSuccess
  | ChangePasswordError
  | ResetChangePassword;
