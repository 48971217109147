import {
  DeleteCalendarOutDto,
  DeleteCalendarInDto,
} from "../../../types/calendar.dto";

export const DELETE_CALENDAR = "DELETE_CALENDAR";
export const DELETE_CALENDAR_SUCCESS = "DELETE_CALENDAR_SUCCESS";
export const DELETE_CALENDAR_ERROR = "DELETE_CALENDAR_ERROR";
export const RESET_DELETE_CALENDAR = "RESET_DELETE_CALENDAR";

export interface DeleteCalendar {
  type: typeof DELETE_CALENDAR;
  payload: DeleteCalendarOutDto;
}

export interface DeleteCalendarSuccess {
  type: typeof DELETE_CALENDAR_SUCCESS;
  payload: DeleteCalendarInDto;
}

export interface DeleteCalendarError {
  type: typeof DELETE_CALENDAR_ERROR;
  payload: string | boolean;
}

export interface ResetDeleteCalendar {
  type: typeof RESET_DELETE_CALENDAR;
  payload: null;
}

export interface State {
  data: DeleteCalendarInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | DeleteCalendar
  | DeleteCalendarSuccess
  | DeleteCalendarError
  | ResetDeleteCalendar;
