import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Grid from "../../../../components/Grid";
import { Tag, Card } from "../../../../components/StyledComponents";
import Table from "../../../../components/Table";
import { Action, useActions } from "../../../../provider/ActionsProvider";
import { getAllJudiciales } from "../../../../store/actions/judicial";
import {
  getAllPrejudiciales,
  getOnePrejudicial,
  getStatics,
} from "../../../../store/actions/prejudicial";
import Filters from "./components/Filters";
import { GetAllJudicialesInDto } from "../../../../types/judicial.dto";
import Tabs from "../../../../components/OtherContent";
import { dateSpliter } from "../../../../helpers/data-handler";
import { argentinaPesos } from "../../../../helpers/loca-currency";
import { useLayout } from "../../../../provider/LayoutProvider";

interface ComponentProps {
  prejudiciales: GetAllJudicialesInDto;
  prejudicialesStates: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
  prejudicialStaticsStates: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
  prejudicialStatics;
}

const Component = ({
  prejudiciales,
  prejudicialesStates,
  prejudicialStatics,
  prejudicialStaticsStates,
}: ComponentProps) => {
  const { layout, setLayout } = useLayout();
  const { setActions, setFilters } = useActions();
  const [activeFilters, setActiveFilters] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const actions = useMemo(
    (): Action[] => [
      {
        component: "link",
        label: "Nuevo prejudicial",
        href: "/dashboard/prejudiciales/new",
        options: {
          type: "filled",
          size: "lg",
          skin: "primary",
        },
      },
    ],
    []
  );

  const columns = useMemo(
    () => [
      { Header: "Título", accessor: "title" },
      {
        Header: "Tipo",
        Cell: ({ value }) => {
          if (!!value) {
            const item = prejudicialStatics?.categories?.tipo?.filter(
              (tipo) => tipo.id === value
            )[0];

            return (
              <Tag
                style={{
                  ...(!!item?.color && { backgroundColor: "#" + item?.color }),
                }}
              >
                {item?.text}
              </Tag>
            );
          }
        },
        accessor: "tipo",
      },
      {
        Header: "Fecha audiencia",
        Cell: ({ value }) => {
          console.log("Fecha audiencia", { value });
          if (!!value) {
            const dateSplitted = dateSpliter(value);
            return `${dateSplitted?.day?.name.short} ${dateSplitted?.day?.number} de ${dateSplitted?.month?.name?.short} del ${dateSplitted?.year}`;
          }
        },
        accessor: "fecha-de-audiencia-start",
      },
      {
        Header: "Pretensión",
        Cell: ({ value }) => {
          if (!!value) {
            return argentinaPesos(value, { showCurrency: true });
          }
        },
        accessor: "pretension",
      },
      {
        Header: "Ofrecimiento",
        Cell: ({ value }) => {
          if (!!value) {
            return argentinaPesos(value, { showCurrency: true });
          }
        },
        accessor: "100-rubros-ley",
      },
      {
        Header: "Estado",
        Cell: ({ value }) => {
          if (!!value) {
            const item = prejudicialStatics?.categories?.estado?.filter(
              (status) => status.id === value[0]
            )[0];

            return (
              <Tag
                style={{
                  ...(!!item?.color && { backgroundColor: "#" + item?.color }),
                }}
              >
                {item?.text}
              </Tag>
            );
          }
        },

        accessor: "estado",
      },
    ],
    [prejudicialStatics]
  );

  const getElements = ({ page, limit }) => {
    dispatch(getAllPrejudiciales({ page, limit, filters: activeFilters }));
  };

  const getElement = (row) => {
    navigate(`/dashboard/prejudiciales/${row.original["item-id"]}`);
  };

  useEffect(() => {
    setActions(actions);
    setFilters(<Filters setActiveFilters={setActiveFilters} />);
    !prejudicialStatics && dispatch(getStatics());
    setLayout({ ...layout, showBackButton: false });
  }, [prejudiciales]);

  return (
    <Grid.ContainerFluid>
      <Grid.Row>
        <Grid.Col>
          <Table
            columns={columns}
            data={prejudiciales?.hints}
            pagination={prejudiciales?.pagination}
            onRowClick={(row, index) => getElement(row)}
            fetchData={(data) => getElements(data)}
            loading={prejudicialesStates?.loading || !prejudiciales}
          />
        </Grid.Col>
      </Grid.Row>
    </Grid.ContainerFluid>
  );
};

const states = ({ prejudicialStore }) => {
  const { data: prejudiciales, states: prejudicialesStates } =
    prejudicialStore.all;
  const { data: prejudicialStatics, states: prejudicialStaticsStates } =
    prejudicialStore.statics;

  return {
    prejudiciales,
    prejudicialesStates,
    prejudicialStaticsStates,
    prejudicialStatics,
  };
};

export default connect(states)(Component);
