import { SHOW_MODAL, HIDE_MODAL, ShowModal, HideModal,DataModal,DATA_MODAL } from "../types/modal";

export const showModal = (slug: string): ShowModal => {
  return { type: SHOW_MODAL, payload: slug };
};

export const hideModal = (): HideModal => {
  return { type: HIDE_MODAL, payload: null };
};

export const dataModal = (payload):DataModal=>{
  return {type:DATA_MODAL,payload}
} 