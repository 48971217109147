import { Outlet } from "react-router-dom";
import { Layout, Logo, Content } from "./styles";
import constants from "../../../constants";

interface ComponentProps {}

const Component = ({}: ComponentProps) => {
  return (
    <Layout>
      <Content>
        <Logo src={constants.company.logoBlack} alt="Dev Place" width="253px" />
        <Outlet />
      </Content>
    </Layout>
  );
};

export default Component;
