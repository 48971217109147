import {
  EditCalendarOutDto,
  EditCalendarInDto,
} from "../../../types/calendar.dto";

export const EDIT_CALENDAR = "EDIT_CALENDAR";
export const EDIT_CALENDAR_SUCCESS = "EDIT_CALENDAR_SUCCESS";
export const EDIT_CALENDAR_ERROR = "EDIT_CALENDAR_ERROR";
export const RESET_EDIT_CALENDAR = "RESET_EDIT_CALENDAR";

export interface EditCalendar {
  type: typeof EDIT_CALENDAR;
  payload: EditCalendarOutDto;
}

export interface EditCalendarSuccess {
  type: typeof EDIT_CALENDAR_SUCCESS;
  payload: EditCalendarInDto;
}

export interface EditCalendarError {
  type: typeof EDIT_CALENDAR_ERROR;
  payload: string | boolean;
}

export interface ResetEditCalendar {
  type: typeof RESET_EDIT_CALENDAR;
  payload: null;
}
export interface State {
  data: EditCalendarInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | EditCalendar
  | EditCalendarSuccess
  | EditCalendarError
  | ResetEditCalendar;
