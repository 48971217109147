import {
  GetOneJudicialOutDto,
  GetOneJudicialInDto,
} from "../../../types/judicial.dto";

export const GET_ONE_JUDICIAL = "GET_ONE_JUDICIAL";
export const GET_ONE_JUDICIAL_SUCCESS = "GET_ONE_JUDICIAL_SUCCESS";
export const GET_ONE_JUDICIAL_ERROR = "GET_ONE_JUDICIAL_ERROR";
export const RESET_GET_ONE_JUDICIAL = "RESET_GET_ONE_JUDICIAL";

export interface GetOneJudicial {
  type: typeof GET_ONE_JUDICIAL;
  payload: GetOneJudicialOutDto;
}

export interface GetOneJudicialSuccess {
  type: typeof GET_ONE_JUDICIAL_SUCCESS;
  payload: GetOneJudicialInDto;
}

export interface GetOneJudicialError {
  type: typeof GET_ONE_JUDICIAL_ERROR;
  payload: string | boolean;
}

export interface ResetGetOneJudicial {
  type: typeof RESET_GET_ONE_JUDICIAL;
  payload: null;
}

export interface State {
  data: GetOneJudicialInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | GetOneJudicial
  | GetOneJudicialSuccess
  | GetOneJudicialError
  | ResetGetOneJudicial;
