import {
  NewPrejudicialOutDto,
  EditPrejudicialOutDto,
  DeletePrejudicialOutDto,
  GetAllPrejudicialesOutDto,
  GetOnePrejudicialOutDto,
  SelectOnePrejudicialOutDto,
} from "../../types/prejudicial.dto";

import {
  NEW_PREJUDICIAL,
  NewPrejudicial,
  RESET_NEW_PREJUDICIAL,
  ResetNewPrejudicial,
} from "../types/prejudicial/new";

import {
  EDIT_PREJUDICIAL,
  RESET_EDIT_PREJUDICIAL,
  ResetEditPrejudicial,
  EditPrejudicial,
} from "../types/prejudicial/edit";

import {
  DELETE_PREJUDICIAL,
  RESET_DELETE_PREJUDICIAL,
  ResetDeletePrejudicial,
  DeletePrejudicial,
} from "../types/prejudicial/delete";

import {
  GET_ONE_PREJUDICIAL,
  GetOnePrejudicial,
  RESET_GET_ONE_PREJUDICIAL,
  ResetGetOnePrejudicial,
} from "../types/prejudicial/getOne";

import {
  SELECT_ONE_PREJUDICIAL,
  SelectOnePrejudicial,
  RESET_SELECT_ONE_PREJUDICIAL,
  ResetSelectOnePrejudicial,
} from "../types/prejudicial/selectOne";

import {
  GET_ALL_PREJUDICIALES,
  GetAllPrejudiciales,
  RESET_GET_ALL_PREJUDICIALES,
  ResetGetAllPrejudiciales,
} from "../types/prejudicial/getAll";

import {
  GET_STATICS_PREJUDICIAL,
  GetStaticsPrejudicial,
  RESET_GET_STATICS_PREJUDICIAL,
  ResetGetStaticsPrejudicial,
} from "../types/prejudicial/statics";

export const getOnePrejudicial = (
  payload: GetOnePrejudicialOutDto
): GetOnePrejudicial => {
  return { type: GET_ONE_PREJUDICIAL, payload };
};

export const resetGetOnePrejudicial = (): ResetGetOnePrejudicial => {
  return { type: RESET_GET_ONE_PREJUDICIAL, payload: null };
};

export const selectOnePrejudicial = (
  payload: SelectOnePrejudicialOutDto
): SelectOnePrejudicial => {
  return { type: SELECT_ONE_PREJUDICIAL, payload };
};

export const resetSelectOnePrejudicial = (): ResetSelectOnePrejudicial => {
  return { type: RESET_SELECT_ONE_PREJUDICIAL, payload: null };
};

export const getAllPrejudiciales = (
  payload: GetAllPrejudicialesOutDto
): GetAllPrejudiciales => {
  return { type: GET_ALL_PREJUDICIALES, payload };
};

export const resetGetAllPrejudiciales = (): ResetGetAllPrejudiciales => {
  return { type: RESET_GET_ALL_PREJUDICIALES, payload: null };
};

export const newPrejudicial = (
  payload: NewPrejudicialOutDto
): NewPrejudicial => {
  return { type: NEW_PREJUDICIAL, payload };
};

export const resetNewPrejudicial = (): ResetNewPrejudicial => {
  return { type: RESET_NEW_PREJUDICIAL, payload: null };
};

export const editPrejudicial = (
  payload: EditPrejudicialOutDto
): EditPrejudicial => {
  return { type: EDIT_PREJUDICIAL, payload };
};

export const resetEditPrejudicial = (): ResetEditPrejudicial => {
  return { type: RESET_EDIT_PREJUDICIAL, payload: null };
};

export const deletePrejudicial = (
  payload: DeletePrejudicialOutDto
): DeletePrejudicial => {
  return { type: DELETE_PREJUDICIAL, payload };
};

export const resetDeletePrejudicial = (): ResetDeletePrejudicial => {
  return { type: RESET_DELETE_PREJUDICIAL, payload: null };
};

export const getStatics = (): GetStaticsPrejudicial => {
  return { type: GET_STATICS_PREJUDICIAL, payload: null };
};

export const resetGetStatics = (): ResetGetStaticsPrejudicial => {
  return { type: RESET_GET_STATICS_PREJUDICIAL, payload: null };
};
