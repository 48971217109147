import {
  EditJudicialOutDto,
  EditJudicialInDto,
} from "../../../types/judicial.dto";

export const EDIT_JUDICIAL = "EDIT_JUDICIAL";
export const EDIT_JUDICIAL_SUCCESS = "EDIT_JUDICIAL_SUCCESS";
export const EDIT_JUDICIAL_ERROR = "EDIT_JUDICIAL_ERROR";
export const RESET_EDIT_JUDICIAL = "RESET_EDIT_JUDICIAL";

export interface EditJudicial {
  type: typeof EDIT_JUDICIAL;
  payload: EditJudicialOutDto;
}

export interface EditJudicialSuccess {
  type: typeof EDIT_JUDICIAL_SUCCESS;
  payload: EditJudicialInDto;
}

export interface EditJudicialError {
  type: typeof EDIT_JUDICIAL_ERROR;
  payload: string | boolean;
}

export interface ResetEditJudicial {
  type: typeof RESET_EDIT_JUDICIAL;
  payload: null;
}
export interface State {
  data: EditJudicialInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | EditJudicial
  | EditJudicialSuccess
  | EditJudicialError
  | ResetEditJudicial;
