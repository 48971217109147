import {
  GetAllPrejudicialesOutDto,
  GetAllPrejudicialesInDto,
} from "../../../types/prejudicial.dto";

export const GET_ALL_PREJUDICIALES = "GET_ALL_PREJUDICIALES";
export const GET_ALL_PREJUDICIALES_SUCCESS = "GET_ALL_PREJUDICIALES_SUCCESS";
export const GET_ALL_PREJUDICIALES_ERROR = "GET_ALL_PREJUDICIALES_ERROR";
export const RESET_GET_ALL_PREJUDICIALES = "RESET_GET_ALL_PREJUDICIALES";

export interface GetAllPrejudiciales {
  type: typeof GET_ALL_PREJUDICIALES;
  payload: GetAllPrejudicialesOutDto;
}

export interface GetAllPrejudicialesSuccess {
  type: typeof GET_ALL_PREJUDICIALES_SUCCESS;
  payload: GetAllPrejudicialesInDto[];
}

export interface GetAllPrejudicialesError {
  type: typeof GET_ALL_PREJUDICIALES_ERROR;
  payload: string | boolean;
}

export interface ResetGetAllPrejudiciales {
  type: typeof RESET_GET_ALL_PREJUDICIALES;
  payload: null;
}

export interface State {
  data: GetAllPrejudicialesInDto[] | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | GetAllPrejudiciales
  | GetAllPrejudicialesSuccess
  | GetAllPrejudicialesError
  | ResetGetAllPrejudiciales;
