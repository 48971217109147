import {
  GetAllFacturacionesOutDto,
  GetAllFacturacionesInDto,
} from "../../../types/facturacion.dto";

export const GET_ALL_FACTURACIONES = "GET_ALL_FACTURACIONES";
export const GET_ALL_FACTURACIONES_SUCCESS = "GET_ALL_FACTURACIONES_SUCCESS";
export const GET_ALL_FACTURACIONES_ERROR = "GET_ALL_FACTURACIONES_ERROR";
export const RESET_GET_ALL_FACTURACIONES = "RESET_GET_ALL_FACTURACIONES";

export interface GetAllFacturaciones {
  type: typeof GET_ALL_FACTURACIONES;
  payload: GetAllFacturacionesOutDto;
}

export interface GetAllFacturacionesSuccess {
  type: typeof GET_ALL_FACTURACIONES_SUCCESS;
  payload: GetAllFacturacionesInDto[];
}

export interface GetAllFacturacionesError {
  type: typeof GET_ALL_FACTURACIONES_ERROR;
  payload: string | boolean;
}

export interface ResetGetAllFacturaciones {
  type: typeof RESET_GET_ALL_FACTURACIONES;
  payload: null;
}

export interface State {
  data: GetAllFacturacionesInDto[] | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | GetAllFacturaciones
  | GetAllFacturacionesSuccess
  | GetAllFacturacionesError
  | ResetGetAllFacturaciones;
