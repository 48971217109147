import {
  NewPrejudicialOutDto,
  NewPrejudicialInDto,
} from "../../../types/prejudicial.dto";

export const NEW_PREJUDICIAL = "NEW_PREJUDICIAL";
export const NEW_PREJUDICIAL_SUCCESS = "NEW_PREJUDICIAL_SUCCESS";
export const NEW_PREJUDICIAL_ERROR = "NEW_PREJUDICIAL_ERROR";
export const RESET_NEW_PREJUDICIAL = "RESET_NEW_PREJUDICIAL";

export interface NewPrejudicial {
  type: typeof NEW_PREJUDICIAL;
  payload: NewPrejudicialOutDto;
}

export interface NewPrejudicialSuccess {
  type: typeof NEW_PREJUDICIAL_SUCCESS;
  payload: NewPrejudicialInDto;
}

export interface NewPrejudicialError {
  type: typeof NEW_PREJUDICIAL_ERROR;
  payload: string | boolean;
}

export interface ResetNewPrejudicial {
  type: typeof RESET_NEW_PREJUDICIAL;
  payload: null;
}

export interface State {
  data: NewPrejudicialInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | NewPrejudicial
  | NewPrejudicialSuccess
  | NewPrejudicialError
  | ResetNewPrejudicial;
