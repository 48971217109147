import {
  GetAllCalendarsOutDto,
  GetAllCalendarsInDto,
} from "../../../types/calendar.dto";

export const GET_ALL_CALENDARS = "GET_ALL_CALENDARS";
export const GET_ALL_CALENDARS_SUCCESS = "GET_ALL_CALENDARS_SUCCESS";
export const GET_ALL_CALENDARS_ERROR = "GET_ALL_CALENDARS_ERROR";
export const RESET_GET_ALL_CALENDARS = "RESET_GET_ALL_CALENDARS";

export interface GetAllCalendars {
  type: typeof GET_ALL_CALENDARS;
  payload: null;
}

export interface GetAllCalendarsSuccess {
  type: typeof GET_ALL_CALENDARS_SUCCESS;
  payload: GetAllCalendarsInDto[];
}

export interface GetAllCalendarsError {
  type: typeof GET_ALL_CALENDARS_ERROR;
  payload: string | boolean;
}

export interface ResetGetAllCalendars {
  type: typeof RESET_GET_ALL_CALENDARS;
  payload: null;
}

export interface State {
  data: GetAllCalendarsInDto[] | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | GetAllCalendars
  | GetAllCalendarsSuccess
  | GetAllCalendarsError
  | ResetGetAllCalendars;
