import { connect } from "react-redux";
import { UserDto } from "../../types/user.dto";
import { Letter, Link } from "./styles";

const sizes = {
  small: {
    fontSize: "18px",
    width: "42px",
    height: "42px",
  },
  big: {
    fontSize: "32px",
    width: "80px",
    height: "80px",
  },
  xbig: {
    fontSize: "56px",
    width: "160px",
    height: "160px",
    padding: "10px",
    background: "#f1f4f9",
  },
};

interface ComponentProps {
  user: UserDto;
  size?: "small" | "big" | "xbig";
  style?;
}

const Component = ({ user, size = "small", style }: ComponentProps) => {
  return (
    <Link to="/dashboard/profile" style={{ ...sizes[size], ...style }}>
      <Letter>{user?.nombre?.charAt(0)}</Letter>
    </Link>
  );
};

const states = ({ userStore }) => {
  const { data: user } = userStore.information;

  return {
    user,
  };
};

export default connect(states)(Component);
