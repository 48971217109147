import styled from "styled-components";

export const Files = styled("div")`
  height: calc(100% - 55px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const FilesList = styled("ol")`
  padding: 0px;
  margin: 0px;
  border: 0px;
  margin: 10px 0px;
  width: 100%;
`;

export const File = styled("li")`
  width: 100%;
  list-style: none;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #dee2e6;

  &:first-child {
    margin-left: 0px;
  }

  &:last-child {
    margin-right: 0px;
  }
`;

export const Information = styled("div")`
  width: 100%;
  margin: 0px 5px;
`;
export const ExtraInformation = styled("div")`
  line-height: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Whom = styled("span")`
  font-size: 10px;
`;

export const Name = styled("span")`
  font-weight: 500;
`;
export const Date = styled("span")`
  font-size: 10px;
`;
export const Actions = styled("div")`
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid #dee2e6;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Separator = styled("div")`
  height: 100%;
  width: 1px;
  background-color: #dee2e6;
  margin: 0px 10px;
`;

export const Download = styled("button")`
  border: 0px;
  margin: 0px;
  padding: 0px;
  background: none;
  cursor: pointer;
`;

export const Delete = styled("button")`
  border: 0px;
  margin: 0px;
  padding: 0px;
  background: none;
  cursor: pointer;
`;

export const Scroll = styled("div")`
  max-height: calc(100% - 41px);
  width: 100%;
  overflow: auto;
`;
