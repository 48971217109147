import {
  GetAllJudicialesOutDto,
  GetAllJudicialesInDto,
} from "../../../types/judicial.dto";

export const GET_ALL_JUDICIALES = "GET_ALL_JUDICIALES";
export const GET_ALL_JUDICIALES_SUCCESS = "GET_ALL_JUDICIALES_SUCCESS";
export const GET_ALL_JUDICIALES_ERROR = "GET_ALL_JUDICIALES_ERROR";
export const RESET_GET_ALL_JUDICIALES = "RESET_GET_ALL_JUDICIALES";

export interface GetAllJudiciales {
  type: typeof GET_ALL_JUDICIALES;
  payload: GetAllJudicialesOutDto;
}

export interface GetAllJudicialesSuccess {
  type: typeof GET_ALL_JUDICIALES_SUCCESS;
  payload: GetAllJudicialesInDto[];
}

export interface GetAllJudicialesError {
  type: typeof GET_ALL_JUDICIALES_ERROR;
  payload: string | boolean;
}

export interface ResetGetAllJudiciales {
  type: typeof RESET_GET_ALL_JUDICIALES;
  payload: null;
}

export interface State {
  data: GetAllJudicialesInDto[] | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | GetAllJudiciales
  | GetAllJudicialesSuccess
  | GetAllJudicialesError
  | ResetGetAllJudiciales;
