import { Loader } from "./styles";
import logo from "../../assets/images/logo-black.png";

const Component = () => {
  return (
    <Loader>
      <img src={logo} alt="Dev Place" width="183" />
    </Loader>
  );
};

export default Component;
