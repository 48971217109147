import {
  NewPagoOutDto,
  EditPagoOutDto,
  DeletePagoOutDto,
  GetAllPagosOutDto,
  GetOnePagoOutDto,
  SelectOnePagoOutDto,
} from "../../types/pago.dto";

import {
  NEW_PAGO,
  NewPago,
  RESET_NEW_PAGO,
  ResetNewPago,
} from "../types/pago/new";

import {
  EDIT_PAGO,
  RESET_EDIT_PAGO,
  ResetEditPago,
  EditPago,
} from "../types/pago/edit";

import {
  DELETE_PAGO,
  RESET_DELETE_PAGO,
  ResetDeletePago,
  DeletePago,
} from "../types/pago/delete";

import {
  GET_ONE_PAGO,
  GetOnePago,
  RESET_GET_ONE_PAGO,
  ResetGetOnePago,
} from "../types/pago/getOne";

import {
  SELECT_ONE_PAGO,
  SelectOnePago,
  RESET_SELECT_ONE_PAGO,
  ResetSelectOnePago,
} from "../types/pago/selectOne";

import {
  GET_ALL_PAGOS,
  GetAllPagos,
  RESET_GET_ALL_PAGOS,
  ResetGetAllPagos,
} from "../types/pago/getAll";

import {
  GET_STATICS_PAGO,
  GetStaticsPago,
  RESET_GET_STATICS_PAGO,
  ResetGetStaticsPago,
} from "../types/pago/statics";

export const getOnePago = (payload: GetOnePagoOutDto): GetOnePago => {
  return { type: GET_ONE_PAGO, payload };
};

export const resetGetOnePago = (): ResetGetOnePago => {
  return { type: RESET_GET_ONE_PAGO, payload: null };
};

export const selectOnePago = (payload: SelectOnePagoOutDto): SelectOnePago => {
  return { type: SELECT_ONE_PAGO, payload };
};

export const resetSelectOnePago = (): ResetSelectOnePago => {
  return { type: RESET_SELECT_ONE_PAGO, payload: null };
};

export const getAllPagos = (payload: GetAllPagosOutDto): GetAllPagos => {
  return { type: GET_ALL_PAGOS, payload };
};

export const resetGetAllPagos = (): ResetGetAllPagos => {
  return { type: RESET_GET_ALL_PAGOS, payload: null };
};

export const newPago = (payload: NewPagoOutDto): NewPago => {
  return { type: NEW_PAGO, payload };
};

export const resetNewPago = (): ResetNewPago => {
  return { type: RESET_NEW_PAGO, payload: null };
};

export const editPago = (payload: EditPagoOutDto): EditPago => {
  return { type: EDIT_PAGO, payload };
};

export const resetEditPago = (): ResetEditPago => {
  return { type: RESET_EDIT_PAGO, payload: null };
};

export const deletePago = (payload: DeletePagoOutDto): DeletePago => {
  return { type: DELETE_PAGO, payload };
};

export const resetDeletePago = (): ResetDeletePago => {
  return { type: RESET_DELETE_PAGO, payload: null };
};

export const getStatics = (): GetStaticsPago => {
  return { type: GET_STATICS_PAGO, payload: null };
};

export const resetGetStatics = (): ResetGetStaticsPago => {
  return { type: RESET_GET_STATICS_PAGO, payload: null };
};
