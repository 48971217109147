import {
  EditActivityOutDto,
  EditActivityInDto,
} from "../../../types/activity.dto";

export const EDIT_ACTIVITY = "EDIT_ACTIVITY";
export const EDIT_ACTIVITY_SUCCESS = "EDIT_ACTIVITY_SUCCESS";
export const EDIT_ACTIVITY_ERROR = "EDIT_ACTIVITY_ERROR";
export const RESET_EDIT_ACTIVITY = "RESET_EDIT_ACTIVITY";

export interface EditActivity {
  type: typeof EDIT_ACTIVITY;
  payload: EditActivityOutDto;
}

export interface EditActivitySuccess {
  type: typeof EDIT_ACTIVITY_SUCCESS;
  payload: EditActivityInDto;
}

export interface EditActivityError {
  type: typeof EDIT_ACTIVITY_ERROR;
  payload: string | boolean;
}

export interface ResetEditActivity {
  type: typeof RESET_EDIT_ACTIVITY;
  payload: null;
}
export interface State {
  data: EditActivityInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | EditActivity
  | EditActivitySuccess
  | EditActivityError
  | ResetEditActivity;
