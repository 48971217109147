import { put, call, all, takeLatest } from "redux-saga/effects";
import services from "../../services";

import {
  NewCalendar,
  NEW_CALENDAR,
  NEW_CALENDAR_SUCCESS,
  NEW_CALENDAR_ERROR,
} from "../types/calendar/new";

import {
  EditCalendar,
  EDIT_CALENDAR,
  EDIT_CALENDAR_SUCCESS,
  EDIT_CALENDAR_ERROR,
} from "../types/calendar/edit";

import {
  DeleteCalendar,
  DELETE_CALENDAR,
  DELETE_CALENDAR_SUCCESS,
  DELETE_CALENDAR_ERROR,
} from "../types/calendar/delete";

import {
  GetOneCalendar,
  GET_ONE_CALENDAR,
  GET_ONE_CALENDAR_SUCCESS,
  GET_ONE_CALENDAR_ERROR,
} from "../types/calendar/getOne";

import {
  SelectOneCalendar,
  SELECT_ONE_CALENDAR,
  SELECT_ONE_CALENDAR_SUCCESS,
  SELECT_ONE_CALENDAR_ERROR,
} from "../types/calendar/selectOne";

import {
  GetAllCalendars,
  GET_ALL_CALENDARS,
  GET_ALL_CALENDARS_SUCCESS,
  GET_ALL_CALENDARS_ERROR,
} from "../types/calendar/getAll";

import {
  GetCommentsCalendar,
  GET_COMMENTS_CALENDAR,
  GET_COMMENTS_CALENDAR_SUCCESS,
  GET_COMMENTS_CALENDAR_ERROR,
} from "../types/calendar/getComments";

import {
  GetStaticsCalendar,
  GET_STATICS_CALENDAR,
  GET_STATICS_CALENDAR_SUCCESS,
  GET_STATICS_CALENDAR_ERROR,
} from "../types/calendar/statics";

function* getAllCalendares() {
  try {
    const { response, error } = yield call(services.calendar.getAll);

    if (error) {
      yield put({
        type: GET_ALL_CALENDARS_ERROR,
        payload: error,
      });
    } else {
      yield put({ type: GET_ALL_CALENDARS_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: GET_ALL_CALENDARS_ERROR,
      payload: "Error inesperado al obtener todos los agendaes.",
    });
  }
}

function* getOneCalendar({ payload }) {
  try {
    const { response, error } = yield call(services.calendar.getOne, payload);

    if (error) {
      yield put({
        type: GET_ONE_CALENDAR_ERROR,
        payload: error,
      });
    } else {
      yield put({ type: GET_ONE_CALENDAR_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: GET_ONE_CALENDAR_ERROR,
      payload: "Error inesperado al obtener el agenda seleccionado.",
    });
  }
}

function* selectOneCalendar({ payload }) {
  try {
    const { response, error } = yield call(services.calendar.getOne, payload);

    if (error) {
      yield put({
        type: SELECT_ONE_CALENDAR_ERROR,
        payload: error,
      });
    } else {
      yield put({ type: SELECT_ONE_CALENDAR_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: SELECT_ONE_CALENDAR_ERROR,
      payload: "Error inesperado al seleccionar el agenda.",
    });
  }
}

function* newCalendar({ payload }) {
  try {
    const { response, error } = yield call(services.calendar.new, payload);

    if (error) {
      yield put({
        type: NEW_CALENDAR_ERROR,
        payload: error,
      });
    } else {
      yield all([
        put({ type: NEW_CALENDAR_SUCCESS, payload: response }),
        put({ type: GET_ALL_CALENDARS, payload: null }),
      ]);
    }
  } catch {
    yield put({
      type: NEW_CALENDAR_ERROR,
      payload: "Error inesperado al crear un nuevo agenda.",
    });
  }
}

function* editCalendar({ payload }) {
  try {
    const { response, error } = yield call(services.calendar.edit, payload);

    if (error) {
      yield put({
        type: EDIT_CALENDAR_ERROR,
        payload: error,
      });
    } else {
      yield all([
        put({ type: EDIT_CALENDAR_SUCCESS, payload: response }),
        put({ type: GET_ALL_CALENDARS, payload: null }),
      ]);
    }
  } catch {
    yield put({
      type: EDIT_CALENDAR_ERROR,
      payload: "Error inesperado al editar el agenda seleccionado.",
    });
  }
}

function* deleteCalendar({ payload }) {
  try {
    const { response, error } = yield call(services.calendar.delete, payload);

    if (error) {
      yield put({
        type: DELETE_CALENDAR_ERROR,
        payload: error,
      });
    } else {
      yield all([
        put({ type: DELETE_CALENDAR_SUCCESS, payload: response }),
        put({ type: GET_ALL_CALENDARS, payload: null }),
      ]);
    }
  } catch {
    yield put({
      type: DELETE_CALENDAR_ERROR,
      payload: "Error inesperado al eliminar el agenda seleccionado.",
    });
  }
}

function* getCommentsCalendar({ payload }) {
  try {
    const { response, error } = yield call(
      services.calendar.getComments,
      payload
    );

    if (error) {
      yield put({
        type: GET_COMMENTS_CALENDAR_ERROR,
        payload: error,
      });
    } else {
      yield put({ type: GET_COMMENTS_CALENDAR_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: GET_COMMENTS_CALENDAR_ERROR,
      payload:
        "Error inesperado al ontener comentarios del agenda seleccionado.",
    });
  }
}

function* getStatics() {
  try {
    const { response, error } = yield call(services.calendar.getStatics);

    if (error) {
      yield put({
        type: GET_STATICS_CALENDAR_ERROR,
        payload: error,
      });
    } else {
      yield put({ type: GET_STATICS_CALENDAR_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: GET_STATICS_CALENDAR_ERROR,
      payload: "Error inesperado al ontener los estaticos de agenda.",
    });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllCalendars>(GET_ALL_CALENDARS, getAllCalendares);
  yield takeLatest<GetOneCalendar>(GET_ONE_CALENDAR, getOneCalendar);
  yield takeLatest<EditCalendar>(EDIT_CALENDAR, editCalendar);
  yield takeLatest<DeleteCalendar>(DELETE_CALENDAR, deleteCalendar);
  yield takeLatest<NewCalendar>(NEW_CALENDAR, newCalendar);
  yield takeLatest<SelectOneCalendar>(SELECT_ONE_CALENDAR, selectOneCalendar);
  yield takeLatest<GetCommentsCalendar>(
    GET_COMMENTS_CALENDAR,
    getCommentsCalendar
  );
  yield takeLatest<GetStaticsCalendar>(GET_STATICS_CALENDAR, getStatics);
}
