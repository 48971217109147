import {
  NewCalendarOutDto,
  EditCalendarOutDto,
  DeleteCalendarOutDto,
  GetAllCalendarsOutDto,
  GetOneCalendarOutDto,
  SelectOneCalendarOutDto,
} from "../../types/calendar.dto";

import {
  NEW_CALENDAR,
  NewCalendar,
  RESET_NEW_CALENDAR,
  ResetNewCalendar,
} from "../types/calendar/new";

import {
  EDIT_CALENDAR,
  EditCalendar,
  RESET_EDIT_CALENDAR,
  ResetEditCalendar,
} from "../types/calendar/edit";

import {
  DELETE_CALENDAR,
  RESET_DELETE_CALENDAR,
  ResetDeleteCalendar,
  DeleteCalendar,
} from "../types/calendar/delete";

import {
  GET_ONE_CALENDAR,
  GetOneCalendar,
  RESET_GET_ONE_CALENDAR,
  ResetGetOneCalendar,
} from "../types/calendar/getOne";

import {
  SELECT_ONE_CALENDAR,
  SelectOneCalendar,
  RESET_SELECT_ONE_CALENDAR,
  ResetSelectOneCalendar,
} from "../types/calendar/selectOne";

import {
  GET_ALL_CALENDARS,
  GetAllCalendars,
  RESET_GET_ALL_CALENDARS,
  ResetGetAllCalendars,
} from "../types/calendar/getAll";

import {
  GET_STATICS_CALENDAR,
  GetStaticsCalendar,
  RESET_GET_STATICS_CALENDAR,
  ResetGetStaticsCalendar,
} from "../types/calendar/statics";

export const getOneCalendar = (
  payload: GetOneCalendarOutDto
): GetOneCalendar => {
  return { type: GET_ONE_CALENDAR, payload };
};

export const resetGetOneCalendar = (): ResetGetOneCalendar => {
  return { type: RESET_GET_ONE_CALENDAR, payload: null };
};

export const selectOneCalendar = (
  payload: SelectOneCalendarOutDto
): SelectOneCalendar => {
  return { type: SELECT_ONE_CALENDAR, payload };
};

export const resetSelectOneCalendar = (): ResetSelectOneCalendar => {
  return { type: RESET_SELECT_ONE_CALENDAR, payload: null };
};

export const getAllCalendars = (): GetAllCalendars => {
  return { type: GET_ALL_CALENDARS, payload: null };
};

export const resetGetAllCalendars = (): ResetGetAllCalendars => {
  return { type: RESET_GET_ALL_CALENDARS, payload: null };
};

export const newCalendar = (payload: NewCalendarOutDto): NewCalendar => {
  return { type: NEW_CALENDAR, payload };
};

export const resetNewCalendar = (): ResetNewCalendar => {
  return { type: RESET_NEW_CALENDAR, payload: null };
};

export const editCalendar = (payload: EditCalendarOutDto): EditCalendar => {
  return { type: EDIT_CALENDAR, payload };
};

export const resetEditCalendar = (): ResetEditCalendar => {
  return { type: RESET_EDIT_CALENDAR, payload: null };
};

export const deleteCalendar = (
  payload: DeleteCalendarOutDto
): DeleteCalendar => {
  return { type: DELETE_CALENDAR, payload };
};

export const resetDeleteCalendar = (): ResetDeleteCalendar => {
  return { type: RESET_DELETE_CALENDAR, payload: null };
};

export const getStatics = (): GetStaticsCalendar => {
  return { type: GET_STATICS_CALENDAR, payload: null };
};

export const resetGetStatics = (): ResetGetStaticsCalendar => {
  return { type: RESET_GET_STATICS_CALENDAR, payload: null };
};
