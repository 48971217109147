const values = [
  {
    value: 2232684571,
    label: "FISCALIA GENERAL ANTE LA CAMARA - Capital Federal",
  },
  {
    value: 1264323471,
    label:
      "C\u00c1MARA NACIONAL de APELACIONES del TRABAJO - SALA I - Capital Federal",
  },
  {
    value: 1264323474,
    label:
      "C\u00c1MARA NACIONAL de APELACIONES del TRABAJO - SALA IV - Capital Federal",
  },
  {
    value: 1264323473,
    label:
      "C\u00c1MARA NACIONAL de APELACIONES del TRABAJO - SALA III - Capital Federal",
  },
  {
    value: 1264323472,
    label:
      "C\u00c1MARA NACIONAL de APELACIONES del TRABAJO - SALA II - Capital Federal",
  },
  {
    value: 1264323475,
    label:
      "C\u00c1MARA NACIONAL de APELACIONES del TRABAJO - SALA V - Capital Federal",
  },
  {
    value: 1264323476,
    label:
      "C\u00c1MARA NACIONAL de APELACIONES del TRABAJO - SALA VI - Capital Federal",
  },
  {
    value: 1264323480,
    label:
      "C\u00c1MARA NACIONAL de APELACIONES del TRABAJO - SALA VIII - Capital Federal",
  },
  {
    value: 1264323482,
    label:
      "C\u00c1MARA NACIONAL de APELACIONES del TRABAJO - SALA X - Capital Federal",
  },
  {
    value: 1264323481,
    label:
      "C\u00c1MARA NACIONAL de APELACIONES del TRABAJO - SALA IX - Capital Federal",
  },
  {
    value: 1264323478,
    label:
      "C\u00c1MARA NACIONAL de APELACIONES del TRABAJO - SALA VII - Capital Federal",
  },
  {
    value: 1264323485,
    label:
      "JUZGADOS NACIONALES de PRIMERA INSTANCIA del TRABAJO N\u00b0 2 - Capital Federal",
  },
  {
    value: 1264323489,
    label:
      "JUZGADOS NACIONALES de PRIMERA INSTANCIA del TRABAJO N\u00b0 6 - Capital Federal",
  },
  {
    value: 1264323486,
    label:
      "JUZGADOS NACIONALES de PRIMERA INSTANCIA del TRABAJO N\u00b0 3 - Capital Federal",
  },
  {
    value: 1264323490,
    label:
      "JUZGADOS NACIONALES de PRIMERA INSTANCIA del TRABAJO N\u00b0 7 - Capital Federal",
  },
];

export default values;
