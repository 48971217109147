import {
  GetOnePrejudicialOutDto,
  GetOnePrejudicialInDto,
} from "../../../types/prejudicial.dto";

export const GET_ONE_PREJUDICIAL = "GET_ONE_PREJUDICIAL";
export const GET_ONE_PREJUDICIAL_SUCCESS = "GET_ONE_PREJUDICIAL_SUCCESS";
export const GET_ONE_PREJUDICIAL_ERROR = "GET_ONE_PREJUDICIAL_ERROR";
export const RESET_GET_ONE_PREJUDICIAL = "RESET_GET_ONE_PREJUDICIAL";

export interface GetOnePrejudicial {
  type: typeof GET_ONE_PREJUDICIAL;
  payload: GetOnePrejudicialOutDto;
}

export interface GetOnePrejudicialSuccess {
  type: typeof GET_ONE_PREJUDICIAL_SUCCESS;
  payload: GetOnePrejudicialInDto;
}

export interface GetOnePrejudicialError {
  type: typeof GET_ONE_PREJUDICIAL_ERROR;
  payload: string | boolean;
}

export interface ResetGetOnePrejudicial {
  type: typeof RESET_GET_ONE_PREJUDICIAL;
  payload: null;
}

export interface State {
  data: GetOnePrejudicialInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | GetOnePrejudicial
  | GetOnePrejudicialSuccess
  | GetOnePrejudicialError
  | ResetGetOnePrejudicial;
