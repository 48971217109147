import { GetOnePagoOutDto, GetOnePagoInDto } from "../../../types/pago.dto";

export const GET_ONE_PAGO = "GET_ONE_PAGO";
export const GET_ONE_PAGO_SUCCESS = "GET_ONE_PAGO_SUCCESS";
export const GET_ONE_PAGO_ERROR = "GET_ONE_PAGO_ERROR";
export const RESET_GET_ONE_PAGO = "RESET_GET_ONE_PAGO";

export interface GetOnePago {
  type: typeof GET_ONE_PAGO;
  payload: GetOnePagoOutDto;
}

export interface GetOnePagoSuccess {
  type: typeof GET_ONE_PAGO_SUCCESS;
  payload: GetOnePagoInDto;
}

export interface GetOnePagoError {
  type: typeof GET_ONE_PAGO_ERROR;
  payload: string | boolean;
}

export interface ResetGetOnePago {
  type: typeof RESET_GET_ONE_PAGO;
  payload: null;
}

export interface State {
  data: GetOnePagoInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | GetOnePago
  | GetOnePagoSuccess
  | GetOnePagoError
  | ResetGetOnePago;
