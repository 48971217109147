const values = [
  { label: "En trámite", value: 1 },
  { label: "Conciliado", value: 9 },
  { label: "Cerrado sin ofrecimineto", value: 4 },
  { label: "Conciliado en reapertura", value: 5 },
  { label: "Conciliado por la usuaria", value: 6 },
  { label: "Cerrado por incomparecencia del actor", value: 7 },
  { label: "Cerrado por pretensión excesiva", value: 11 },
  { label: "Conciliado en sucursal", value: 13 },
];
export default values;
