import action from "../helpers/rest-client";

export class ErrorFacturacion extends Error {}

class Facturacion {
  public new(payload) {
    return action
      .Post({
        url: "/facturacion",
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public edit({ itemId, ...rest }) {
    return action
      .Patch({
        url: `/facturacion/${itemId}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getAll({ page, limit }) {
    const offset = page * limit;

    return action
      .Get({
        url: `/facturacion?offset=${offset}&limit=${limit}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getOne({ itemId }) {
    return action
      .Get({
        url: `/facturacion/${itemId}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public delete({ itemId }) {
    return action
      .Del({
        url: `/facturacion/${itemId}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getFiles({ itemId }) {
    return action
      .Get({
        url: `/facturacion/files/${itemId}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getComments({ itemId }) {
    return action
      .Get({
        url: `/facturacion/comments/${itemId}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getStatics() {
    return action
      .Get({
        url: "/facturacion/static",
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const facturacion = new Facturacion();
export default facturacion;
