import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import { Center, Card } from "../../../../components/StyledComponents";
import Grid from "../../../../components/Grid";
import Button from "../../../../components/Button";
import Input from "../../../../components/Form/Input";
import Wysiwyg from "../../../../components/Form/Wysiwyg";
import Submit from "../../../../components/Form/Submit";
import Checkbox from "../../../../components/Form/Checkbox";
import ReactSelect from "../../../../components/Form/ReactSelect";
import {
  initialValues,
  schema,
} from "../../../../constants/form/calendar/edit";
import OtherContent from "../../../../components/OtherContent";
import {
  editCalendar,
  getOneCalendar,
  getStatics,
  resetEditCalendar,
} from "../../../../store/actions/calendar";
import Icon from "../../../../components/Icon";
import { Action, useActions } from "../../../../provider/ActionsProvider";
import {
  dateSpliter,
  setToZeroTimeZone,
} from "../../../../helpers/data-handler";
import { useParams } from "react-router";
import { CalendarDto } from "../../../../types/calendar.dto";
import MarkerIcon from "../../../../assets/icons/marker.svg";
import UsersIcon from "../../../../assets/icons/users.svg";
import { getAllJudiciales } from "../../../../store/actions/judicial";
import { getAllPrejudiciales } from "../../../../store/actions/prejudicial";
import { GetAllJudicialesInDto } from "../../../../types/judicial.dto";
import { GetAllPrejudicialesInDto } from "../../../../types/prejudicial.dto";
import { toast } from "react-toastify";
import { useLayout } from "../../../../provider/LayoutProvider";
import { GetAllStaticsInDto } from "../../../../types/statics.dto";
import { getAllStatics } from "../../../../store/actions/statics";
import { preventEnter } from "../../../../helpers/prevent-enter";
import arrowUpRightFromSquare from "../../../../assets/icons/arrow-up-right-from-square.svg";
import checkEmpty from "../../../../helpers/check-empty";
import { EntitiesEnum } from "../../../../types/entities.enum";
interface Participante {
  label: string;
  value: string;
  type: string;
}

interface Lugar {
  label: string;
  value: string;
  type: string;
  direccion: string;
}

interface Caso {
  label: string;
  value: string;
  type: string;
}
interface ComponentProps {
  event: CalendarDto;
  eventStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  editCalendarStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  calendarStaticsStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  calendarStatics;
  judiciales: GetAllJudicialesInDto;
  judicialesStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  prejudiciales: GetAllPrejudicialesInDto;
  prejudicialesStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  statics: GetAllStaticsInDto;
  staticsStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
}

const Component = ({
  event,
  eventStates,
  editCalendarStates,
  calendarStaticsStates,
  calendarStatics,
  judiciales,
  judicialesStates,
  prejudiciales,
  prejudicialesStates,
  statics,
  staticsStates,
}: ComponentProps) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [casoLink, setCasoLink] = useState<string | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<CalendarDto>();
  const dispatch = useDispatch();
  const { setActions } = useActions();
  const { layout, setLayout } = useLayout();
  let { id } = useParams();

  const actions = useMemo(
    (): Action[] =>
      canEdit
        ? [
            {
              component: "submit",
              label: "Guardar cambios",
              form: "edit-calendar-form",
              options: {
                type: "filled",
                skin: "primary",
                size: "lg",
                marginBottom: "0px",
              },
            },
            {
              component: "button",
              label: "Cancelar",
              onClick: () => setCanEdit(false),
              options: {
                type: "outline",
                skin: "danger",
                size: "lg",
                marginBottom: "0px",
              },
            },
          ]
        : [
            {
              component: "button",
              label: "Editar",
              onClick: () => setCanEdit(true),
              options: {
                type: "filled",
                skin: "primary",
                size: "lg",
                marginBottom: "0px",
              },
            },
          ],
    [canEdit]
  );

  const participantes = useMemo(
    (): Participante[] =>
      !!statics
        ? [
            ...((statics.comerciales &&
              statics?.comerciales?.map((comercial) => ({
                label: comercial.name,
                value: comercial.itemId,
                type: "participants-comerciales",
              }))) ||
              []),
          ]
        : [],
    [statics]
  );

  const lugares = useMemo(
    (): Lugar[] =>
      !!statics
        ? [
            ...((statics?.clientes &&
              statics?.clientes?.map((cliente) => ({
                label: cliente.name || "Sin nombre",
                value: cliente.itemId,
                direccion: cliente.address2,
                type: "relationship-clientes",
              }))) ||
              []),
            ...((statics.mediadores &&
              statics?.mediadores?.map((mediador) => ({
                label: mediador.titulo,
                value: mediador.itemId,
                direccion: mediador.direccion,
                type: "relationship-mediadores",
              }))) ||
              []),
            ...((statics.jurisdicciones &&
              statics.jurisdicciones.map((jurisdiccion) => ({
                label: jurisdiccion.titulo,
                value: jurisdiccion.itemId,
                direccion: jurisdiccion.direccion,
                type: "relationship-jurisdiccion",
              }))) ||
              []),
            ...((statics.sucursales &&
              statics.sucursales.map((sucursal) => ({
                label: sucursal.titulo,
                value: sucursal.itemId,
                direccion: sucursal.direccion,
                type: "relationship-sucursales",
              }))) ||
              []),
          ]
        : [],
    [statics]
  );

  const casos = useMemo(
    (): Caso[] => [
      ...(!!judiciales?.hints
        ? judiciales?.hints?.map((judicial) => ({
            label: judicial.caratula,
            value: judicial["item-id"],
            type: "relationship-2-judiciales",
          }))
        : []),
      ...(!!prejudiciales?.hints
        ? prejudiciales?.hints?.map((prejudicial) => ({
            label: prejudicial.title,
            value: prejudicial["item-id"],
            type: "relationship-2-pre-judiciales",
          }))
        : []),
      ...((statics?.contratos &&
        statics?.contratos?.map((contrato) => ({
          label: contrato.resumen,
          value: contrato.itemId,
          type: "relationship-2-contratos",
        }))) ||
        []),
    ],
    [judiciales, prejudiciales, statics]
  );

  const createCasoLink = (value) => {
    console.log(value);

    if (!value || !casos) {
      return setCasoLink(null);
    }

    const selectedCaso = casos.filter((caso) => caso.value === value)[0];

    setCasoLink(
      `/dashboard/${selectedCaso?.type?.replace(
        "relationship-2-",
        ""
      )}/${value}`
    );
  };

  const onSubmit = ({ values, actions }) => {
    console.log(values);

    const relationshipKey = lugares?.filter(
      (lugar) => lugar?.value === values["relationship"]
    );

    console.log({ relationshipKey });

    const relationship2Key = casos?.filter(
      (caso) => caso?.value === values["relationship-2"]
    );

    console.log({ relationship2Key });

    const participants = values["participants"].reduce(
      (previousValue, currentValue) => {
        const participante = participantes.filter(
          (element) => element?.value === currentValue
        )[0];

        return {
          ...previousValue,
          [participante.type]: [
            ...(previousValue[participante.type] || []),
            participante.value,
          ],
        };
      },
      {}
    );

    const payload = {
      ...(relationship2Key[0] &&
        (values["tipo-2"] !== 4 || values["tipo-2"] !== 5) && {
          [relationship2Key[0].type]: checkEmpty(values["relationship-2"]),
        }),
      ...(relationshipKey[0] &&
        values["relationship"] && {
          [relationshipKey[0].type]: checkEmpty(values["relationship"]),
        }),
      ...(participants || []),
      "date-start": checkEmpty(
        setToZeroTimeZone(
          `${values["date-start"]}T${values["hour-start"]}:00-03:00`
        )
      ),
      "date-end": checkEmpty(
        setToZeroTimeZone(
          `${values["date-end"]}T${values["hour-end"]}:00-03:00`
        )
      ),
      "tipo-2": [values["tipo-2"]],
      type: [values.type],
      referncia: checkEmpty(values.referncia),
      direccion: checkEmpty(values.direccion),
      notes: checkEmpty(values["notes"]),
      itemId: values["item-id"],
      tipo: 2,
    };

    dispatch(editCalendar(payload));
  };

  useEffect(() => {
    setActions(actions);
    !statics && dispatch(getAllStatics());
    !judiciales && dispatch(getAllJudiciales({ page: 0, limit: 5000 }));
    !prejudiciales && dispatch(getAllPrejudiciales({ page: 0, limit: 5000 }));
    dispatch(getStatics());
  }, []);

  useEffect(() => {
    if (!!id) {
      dispatch(getOneCalendar({ itemId: id }));
    }
  }, [id]);

  useEffect(() => {
    setActions(actions);
  }, [canEdit, event]);

  useEffect(() => {
    if (editCalendarStates.success) {
      toast.success("👌🏼 Evento actualizado");
      setTimeout(() => {
        dispatch(resetEditCalendar());
      }, 2000);
    }

    if (editCalendarStates.error) {
      toast.error(`😱 No se ha podido actualizar el evento`);
      setTimeout(() => {
        dispatch(resetEditCalendar());
      }, 2000);
    }
  }, [editCalendarStates]);

  useEffect(() => {
    if (!!event) {
      setLayout({
        ...layout,
        fullFillContent: false,
        title: String(event?.referncia || event.itemId),
        breadcrumb: String(event?.referncia || event.itemId),
        showBackButton: true,
      });

      createCasoLink(
        [
          ...(!!event["relationship-2-contratos"]
            ? [event["relationship-2-contratos"]]
            : []),
          ...(!!event["relationship-2-judiciales"]
            ? [event["relationship-2-judiciales"]]
            : []),
          ...(!!event["relationship-2-pre-judiciales"]
            ? [event["relationship-2-pre-judiciales"]]
            : []),
          ...(!!event["relationship-2-pagos"]
            ? [event["relationship-2-pagos"]]
            : []),
        ][0]
      );
    }
  }, [event]);

  return (
    <Grid.ContainerFluid>
      <Grid.Row>
        <Grid.Col lg={10} offset={{ lg: 1 }}>
          <Grid.Row>
            <Grid.Col lg={8}>
              <Formik
                initialValues={{
                  ...initialValues,
                  ...(!!event && {
                    ...event,
                    notes: event["notes"] || "",
                    type: event.type[0],
                    "tipo-2": event["tipo-2"][0],
                    participants: [
                      ...(event["participants-corresponsales"] || []),
                      ...(event["participants-comerciales"] || []),
                      ...(event["participants-clientes"] || []),
                      ...(event["participants-sucursales"] || []),
                    ],
                    "relationship-2": [
                      ...(!!event["relationship-2-contratos"]
                        ? [event["relationship-2-contratos"]]
                        : []),
                      ...(!!event["relationship-2-judiciales"]
                        ? [event["relationship-2-judiciales"]]
                        : []),
                      ...(!!event["relationship-2-pre-judiciales"]
                        ? [event["relationship-2-pre-judiciales"]]
                        : []),
                      ...(!!event["relationship-2-pagos"]
                        ? [event["relationship-2-pagos"]]
                        : []),
                    ][0],
                    relationship: [
                      ...(!!event["relationship-jurisdiccion"]
                        ? [event["relationship-jurisdiccion"]]
                        : []),
                      ...(!!event["relationship-mediadores"]
                        ? [event["relationship-mediadores"]]
                        : []),
                      ...(!!event["relationship-sucursales"]
                        ? [event["relationship-sucursales"]]
                        : []),
                      ...(!!event["relationship-clientes"]
                        ? [event["relationship-clientes"]]
                        : []),
                    ][0],
                    "date-start": dateSpliter(event["date-start"]).inputDate,
                    "date-end": dateSpliter(event["date-end"]).inputDate,
                    "hour-start": dateSpliter(event["date-start"]).inputHour,
                    "hour-end": dateSpliter(event["date-end"]).inputHour,
                  }),
                }}
                onSubmit={(values, actions) => {
                  if (onSubmit) onSubmit({ values, actions });
                }}
                validateOnChange={formSubmmited}
                validateOnBlur={false}
                validationSchema={schema}
                enableReinitialize
              >
                {({
                  touched,
                  errors,
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  resetForm,
                  setFieldValue,
                }) => {
                  return (
                    <form
                      className="theme-form"
                      onSubmit={(event) => {
                        setFormSubmmited(true);
                        handleSubmit(event);
                      }}
                      onKeyDown={(event) => !canEdit && preventEnter(event)}
                      id="edit-calendar-form"
                    >
                      <Grid.Row>
                        <Grid.Col lg={6}>
                          <h2>Datos del evento</h2>

                          <Grid.Row>
                            <Grid.Col>
                              <Input
                                readOnly={!canEdit}
                                disabled={editCalendarStates.loading}
                                name="referncia"
                                error={errors["referncia"]}
                                touched={touched["referncia"]}
                                value={values["referncia"]}
                                placeholder=""
                                onChange={handleChange}
                                onBlur={handleBlur}
                                options={{
                                  label: "Título *",
                                  marginBottom: 20,
                                }}
                              />
                            </Grid.Col>
                          </Grid.Row>

                          <Grid.Row>
                            <Grid.Col>
                              <Input
                                readOnly={!canEdit}
                                disabled={editCalendarStates.loading}
                                name="date-start"
                                error={errors["date-start"]}
                                touched={touched["date-start"]}
                                value={values["date-start"]}
                                type="date"
                                placeholder="Fecha inicio"
                                onChange={(event) => {
                                  handleChange(event);
                                  if (!values["date-end"]) {
                                    setFieldValue(
                                      "date-end",
                                      event.target.value
                                    );
                                  }
                                }}
                                onBlur={handleBlur}
                                options={{
                                  label: "Fecha inicio *",
                                  marginBottom: 20,
                                }}
                              />
                            </Grid.Col>

                            <Grid.Col>
                              <Input
                                readOnly={!canEdit}
                                disabled={
                                  values["all-day"].length > 0 ||
                                  editCalendarStates.loading
                                }
                                name="hour-start"
                                error={errors["hour-start"]}
                                touched={touched["hour-start"]}
                                value={values["hour-start"]}
                                type="time"
                                placeholder="Hora inicio"
                                onChange={(event) => {
                                  handleChange(event);

                                  if (!values["hour-end"]) {
                                    let [hours, minutes] =
                                      event.target.value.split(":");
                                    minutes = Number(minutes) + 30;

                                    if (minutes >= 60) {
                                      hours = Number(hours) + 1;
                                      minutes =
                                        minutes - 60 > 10
                                          ? minutes - 60
                                          : `0${minutes - 60}`;
                                    }

                                    setFieldValue(
                                      "hour-end",
                                      `${hours}:${minutes}`
                                    );
                                  }
                                }}
                                onBlur={handleBlur}
                                options={{
                                  label: "Hora inicio *",
                                  marginBottom: 20,
                                }}
                              />
                            </Grid.Col>
                          </Grid.Row>

                          {/* <Grid.Row>
                            <Grid.Col>
                              <Checkbox
                                name="all-day"
                                readOnly={!canEdit}
                                disabled={editCalendarStates.loading}
                                value={values["all-day"]}
                                error={errors["all-day"]}
                                touched={touched["all-day"]}
                                items={[
                                  { value: "true", label: "Todo el día" },
                                ]}
                                onChange={(event) => {
                                  if (event.currentTarget.checked) {
                                    setFieldValue(
                                      "date-end",
                                      values["date-start"]
                                    );
                                    setFieldValue("hour-start", "00:00");
                                    setFieldValue("hour-end", "23:59");
                                  } else {
                                    setFieldValue("date-end", null);
                                    setFieldValue("hour-end", null);
                                  }

                                  handleChange(event);
                                }}
                                onBlur={handleBlur}
                                options={{
                                  marginBottom: 20,
                                }}
                              />
                            </Grid.Col>
                          </Grid.Row> */}

                          <Grid.Row>
                            <Grid.Col>
                              <Input
                                readOnly={!canEdit}
                                disabled={
                                  values["all-day"].length > 0 ||
                                  editCalendarStates.loading
                                }
                                name="date-end"
                                error={errors["date-end"]}
                                touched={touched["date-end"]}
                                value={values["date-end"]}
                                type="date"
                                placeholder="Fecha fin"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                options={{
                                  label: "Fecha fin *",
                                  marginBottom: 20,
                                }}
                              />
                            </Grid.Col>
                            <Grid.Col>
                              <Input
                                readOnly={!canEdit}
                                disabled={
                                  values["all-day"].length > 0 ||
                                  editCalendarStates.loading
                                }
                                name="hour-end"
                                error={errors["hour-end"]}
                                touched={touched["hour-end"]}
                                value={values["hour-end"]}
                                type="time"
                                placeholder="Hora fin *"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                options={{
                                  label: "Hora fin",
                                  marginBottom: 20,
                                }}
                              />
                            </Grid.Col>
                          </Grid.Row>

                          <Grid.Row>
                            <Grid.Col>
                              <ReactSelect
                                readOnly={!canEdit}
                                disabled={
                                  editCalendarStates.loading ||
                                  staticsStates.loading
                                }
                                name="participants"
                                error={errors["participants"]}
                                touched={touched["participants"]}
                                items={participantes}
                                placeholder="Agregar participantes"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isMulti
                                options={{
                                  before: (
                                    <Icon
                                      icon={UsersIcon}
                                      size="14px"
                                      color="black"
                                    />
                                  ),
                                  loading: staticsStates.loading,
                                  label: "Participantes",
                                  marginBottom: 20,
                                }}
                              />
                            </Grid.Col>
                          </Grid.Row>

                          <Grid.Row>
                            <Grid.Col>
                              <ReactSelect
                                readOnly={!canEdit}
                                disabled={
                                  editCalendarStates.loading ||
                                  staticsStates.loading
                                }
                                name="relationship"
                                error={errors["relationship"]}
                                touched={touched["relationship"]}
                                items={lugares.filter((lugar) => {
                                  const tipo = Number(values["tipo-2"]);
                                  const judicial = 2;
                                  const prejudicial = 3;

                                  if (tipo === judicial) {
                                    return (
                                      lugar.type === "relationship-jurisdiccion"
                                    );
                                  }

                                  if (tipo === prejudicial) {
                                    return (
                                      lugar.type === "relationship-mediadores"
                                    );
                                  }

                                  return true;
                                })}
                                placeholder=""
                                onChange={handleChange}
                                onOptionSelected={(data) => {
                                  setFieldValue("direccion", data.direccion);
                                }}
                                onBlur={handleBlur}
                                options={{
                                  before: (
                                    <Icon
                                      icon={MarkerIcon}
                                      size="14px"
                                      color="black"
                                    />
                                  ),
                                  loading: staticsStates.loading,
                                  label: "Lugar",
                                  marginBottom: 20,
                                }}
                              />
                            </Grid.Col>
                          </Grid.Row>

                          <Grid.Row>
                            <Grid.Col>
                              <Input
                                readOnly={!canEdit}
                                disabled={editCalendarStates.loading}
                                name="direccion"
                                error={errors["direccion"]}
                                touched={touched["direccion"]}
                                value={values["direccion"]}
                                placeholder=""
                                onChange={handleChange}
                                onBlur={handleBlur}
                                options={{
                                  before: (
                                    <Icon
                                      icon={MarkerIcon}
                                      size="14px"
                                      color="black"
                                    />
                                  ),
                                  label: "Dirección",
                                  marginBottom: 20,
                                }}
                              />
                            </Grid.Col>
                          </Grid.Row>
                        </Grid.Col>

                        <Grid.Col lg={6}>
                          <h2>Información adicional</h2>

                          <Grid.Row>
                            <Grid.Col>
                              <ReactSelect
                                readOnly={!canEdit}
                                disabled={
                                  editCalendarStates.loading ||
                                  calendarStaticsStates.loading
                                }
                                name="tipo-2"
                                error={errors["tipo-2"]}
                                touched={touched["tipo-2"]}
                                items={calendarStatics?.categories["type-2"]
                                  .filter((type2) => type2.status === "active")
                                  .map((type2) => ({
                                    label: type2.text,
                                    value: type2.id,
                                  }))}
                                placeholder="Seleccione"
                                onOptionSelected={(data) => {
                                  setCasoLink(null);
                                  setFieldValue("relationship-2", null);
                                }}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                options={{
                                  label: "Tipo *",
                                  marginBottom: 20,
                                  loading: calendarStaticsStates.loading,
                                }}
                              />
                            </Grid.Col>
                          </Grid.Row>
                          {console.log({ values, errors })}

                          {values["tipo-2"] &&
                            values["tipo-2"] !== 4 &&
                            values["tipo-2"] !== 5 && (
                              <Grid.Row>
                                <Grid.Col>
                                  <Center style={{ gap: "8px" }}>
                                    <ReactSelect
                                      readOnly={!canEdit}
                                      disabled={
                                        editCalendarStates.loading ||
                                        prejudicialesStates.loading ||
                                        judicialesStates.loading
                                      }
                                      name="relationship-2"
                                      error={errors["relationship-2"]}
                                      touched={touched["relationship-2"]}
                                      items={casos.filter(
                                        (caso) =>
                                          (caso.type ===
                                            "relationship-2-contratos" &&
                                            values["tipo-2"] === 1) ||
                                          (caso.type ===
                                            "relationship-2-judiciales" &&
                                            values["tipo-2"] === 2) ||
                                          (caso.type ===
                                            "relationship-2-pre-judiciales" &&
                                            values["tipo-2"] === 3)
                                      )}
                                      placeholder=""
                                      onChange={handleChange}
                                      onOptionSelected={(data) => {
                                        createCasoLink(data.value);
                                      }}
                                      onBlur={handleBlur}
                                      options={{
                                        label: "Caso",
                                        marginBottom: 20,
                                        loading:
                                          prejudicialesStates.loading ||
                                          judicialesStates.loading,
                                      }}
                                    />

                                    {casoLink &&
                                      (values["tipo-2"] === 2 ||
                                        values["tipo-2"] === 3) && (
                                        <a
                                          target="_blank"
                                          style={{
                                            textDecoration: "none",
                                            marginRight: "8px",
                                            marginTop: "24px",
                                            height: "38px",
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                          href={casoLink}
                                        >
                                          <Icon
                                            icon={arrowUpRightFromSquare}
                                            size="16px"
                                            color="black"
                                          />
                                        </a>
                                      )}
                                  </Center>
                                </Grid.Col>
                              </Grid.Row>
                            )}

                          <Grid.Row>
                            <Grid.Col>
                              <ReactSelect
                                readOnly={!canEdit}
                                disabled={
                                  editCalendarStates.loading ||
                                  calendarStaticsStates.loading
                                }
                                name="type"
                                error={errors["type"]}
                                touched={touched["type"]}
                                items={calendarStatics?.categories?.type
                                  ?.filter((type) => type.status === "active")
                                  .map((type) => ({
                                    label: type.text,
                                    value: type.id,
                                  }))}
                                placeholder="Seleccione"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                options={{
                                  label: "Motivo *",
                                  marginBottom: 20,
                                  loading: calendarStaticsStates.loading,
                                }}
                              />
                            </Grid.Col>
                          </Grid.Row>

                          <Grid.Row>
                            <Grid.Col>
                              <Wysiwyg
                                readOnly={!canEdit}
                                disabled={
                                  editCalendarStates.loading ||
                                  eventStates.loading
                                }
                                toolbar={{ options: [] }}
                                name="notes"
                                setFieldValue={(val) =>
                                  setFieldValue("notes", val)
                                }
                                value={values["notes"]}
                                style={{ minHeight: "113px" }}
                                options={{
                                  label: "Notas",
                                  marginBottom: 20,
                                  loading: eventStates.loading,
                                }}
                              />
                            </Grid.Col>
                          </Grid.Row>
                        </Grid.Col>
                      </Grid.Row>

                      <div style={{ height: "50px" }} />

                      {canEdit && (
                        <Center horizontal="center" vertical="center">
                          <Submit
                            isSubmmiting={editCalendarStates.loading}
                            form="edit-calendar-form"
                            color="Primary"
                            options={{
                              type: "filled",
                              skin: "primary",
                              size: "lg",
                              marginBottom: "0px",
                            }}
                          >
                            Guardar cambios
                          </Submit>

                          <Button
                            onClick={() => setCanEdit(false)}
                            type="button"
                            options={{
                              type: "outline",
                              skin: "danger",
                              size: "lg",
                              marginBottom: "0px",
                            }}
                            style={{ marginLeft: "10px" }}
                          >
                            Cancelar
                          </Button>
                        </Center>
                      )}

                      <div style={{ height: "50px" }} />
                    </form>
                  );
                }}
              </Formik>
            </Grid.Col>

            <Grid.Col lg={4}>
              <Card
                style={{
                  position: "sticky",
                  top: "0",
                  height: "calc(100vh - 83px - 60px - 80px)",
                  minHeight: "485px",
                }}
              >
                <OtherContent entity={EntitiesEnum.SCHEDULE} entityId={id} />
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
    </Grid.ContainerFluid>
  );
};

const states = ({
  calendarStore,
  judicialStore,
  prejudicialStore,
  staticsStore,
}) => {
  const { states: editCalendarStates } = calendarStore.editOne;
  const { data: event, states: eventStates } = calendarStore.one;
  const { data: judiciales, states: judicialesStates } = judicialStore.all;
  const { data: prejudiciales, states: prejudicialesStates } =
    prejudicialStore.all;
  const { data: calendarStatics, states: calendarStaticsStates } =
    calendarStore.statics;
  const { data: statics, states: staticsStates } = staticsStore;

  return {
    editCalendarStates,
    event,
    eventStates,
    calendarStaticsStates,
    calendarStatics,
    judiciales,
    judicialesStates,
    prejudiciales,
    prejudicialesStates,
    statics,
    staticsStates,
  };
};

export default connect(states)(Component);
