import { GetAllStaticsInDto } from "../../types/statics.dto";

export const GET_ALL_STATICS = "GET_ALL_STATICS";
export const GET_ALL_STATICS_SUCCESS = "GET_ALL_STATICS_SUCCESS";
export const GET_ALL_STATICS_ERROR = "GET_ALL_STATICS_ERROR";
export const RESET_GET_ALL_STATICS = "RESET_GET_ALL_STATICS";

export interface GetAllStatics {
  type: typeof GET_ALL_STATICS;
  payload: null;
}

export interface GetAllStaticsSuccess {
  type: typeof GET_ALL_STATICS_SUCCESS;
  payload: GetAllStaticsInDto;
}

export interface GetAllStaticsError {
  type: typeof GET_ALL_STATICS_ERROR;
  payload: string | boolean;
}

export interface ResetGetAllStatics {
  type: typeof RESET_GET_ALL_STATICS;
  payload: null;
}

export interface State {
  data: GetAllStaticsInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | GetAllStatics
  | GetAllStaticsSuccess
  | GetAllStaticsError
  | ResetGetAllStatics;
