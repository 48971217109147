import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import { Center, Card, Tag2 } from "../../../../components/StyledComponents";
import { toast } from "react-toastify";
import Grid from "../../../../components/Grid";
import Link from "../../../../components/Link";
import Input from "../../../../components/Form/Input";
import Submit from "../../../../components/Form/Submit";
import Textarea from "../../../../components/Form/TextArea";
import ReactSelect from "../../../../components/Form/ReactSelect";
import Tabs from "../../../../components/OtherContent";
import { initialValues, schema } from "../../../../constants/form/pago/new";
import {
  getStatics,
  newPago,
  resetNewPago,
} from "../../../../store/actions/pago";
import { Action, useActions } from "../../../../provider/ActionsProvider";
import Icon from "../../../../components/Icon";
import BankIcon from "../../../../assets/icons/bank.svg";
import { GetAllJudicialesInDto } from "../../../../types/judicial.dto";
import { GetAllPrejudicialesInDto } from "../../../../types/prejudicial.dto";
import { getAllJudiciales } from "../../../../store/actions/judicial";
import { getAllPrejudiciales } from "../../../../store/actions/prejudicial";
import { setToLocalTimeZone } from "../../../../helpers/data-handler";
import { useLayout } from "../../../../provider/LayoutProvider";
import { useNavigate } from "react-router";
import { NewPagoInDto } from "../../../../types/pago.dto";
import CurrencyIcon from "../../../../assets/icons/dollar.svg";
import { argentinaPesos } from "../../../../helpers/loca-currency";
import checkEmpty from "../../../../helpers/check-empty";
const type = {
  PREJUDICIAL: 1,
  JUDICIAL: 2,
  HONORARIOS_EXTRAORDINARIOS: 5,
};

interface Caso {
  label: string;
  value: string;
  type: string;
}

interface Status {
  label: string;
  value: string;
}

interface ComponentProps {
  newPagoStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  newPagoInformation: NewPagoInDto;
  pagoStatics;
  pagoStaticsStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  judiciales: GetAllJudicialesInDto;
  judicialesStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  prejudiciales: GetAllPrejudicialesInDto;
  prejudicialesStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
}

const Component = ({
  newPagoStates,
  newPagoInformation,
  pagoStaticsStates,
  pagoStatics,
  judiciales,
  judicialesStates,
  prejudiciales,
  prejudicialesStates,
}: ComponentProps) => {
  const { layout, setLayout } = useLayout();
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const { setActions } = useActions();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const actions = useMemo(
    (): Action[] => [
      {
        component: "submit",
        label: "Confirmar",
        form: "new-pago-form",
        options: {
          type: "filled",
          skin: "primary",
          size: "lg",
          marginBottom: "0px",
        },
      },
      {
        component: "link",
        label: "Cancelar",
        href: "/dashboard/pagos/list",
        options: {
          type: "outline",
          skin: "danger",
          size: "lg",
          marginBottom: "0px",
        },
      },
    ],
    []
  );

  const casos = useMemo(
    (): Caso[] => [
      ...(!!judiciales?.hints
        ? judiciales?.hints?.map((judicial) => ({
            label: judicial.caratula,
            value: judicial["item-id"],
            type: "caso-judiciales",
          }))
        : []),
      ...(!!prejudiciales?.hints
        ? prejudiciales?.hints?.map((prejudicial) => ({
            label: prejudicial.title,
            value: prejudicial["item-id"],
            type: "caso-pre-judiciales",
          }))
        : []),
    ],
    [judiciales, prejudiciales]
  );

  const status = useMemo(
    (): Status[] => [
      ...(!!pagoStatics?.categories?.status
        ? pagoStatics?.categories?.status?.map((status) => ({
            label: status.text,
            value: status.id,
          }))
        : []),
    ],
    [pagoStatics]
  );

  const onSubmit = ({ values, actions }) => {
    const casoKey = casos.filter((caso) => caso.value === values.caso);

    const payload = {
      tipo: checkEmpty(values.tipo),
      ...(casoKey[0] &&
        (values["tipo"] === 1 || values["tipo"] === 2) && {
          [casoKey[0].type]: checkEmpty(values.caso),
        }),
      referencia: checkEmpty(values.referencia),
      status: checkEmpty(values.status),
      "fecha-origen-start": checkEmpty(
        setToLocalTimeZone(values["fecha-origen-start"])
      ),
      "date-start": checkEmpty(setToLocalTimeZone(values["date-start"])),
      motivo: checkEmpty(values.motivo),
      "beneficiario-2": checkEmpty(values["beneficiario-2"]),
      compania: checkEmpty(values.compania),
      ...(values?.cbu && { cbu: checkEmpty(String(values.cbu)) }),
      descripcion: checkEmpty(values.descripcion),

      capital: checkEmpty(values.capital, "currency"),
      honorario: checkEmpty(values.honorario, "currency"),
      "honorarios-conciliadormediador": checkEmpty(
        values["honorarios-conciliadormediador"],
        "currency"
      ),
      iva: checkEmpty(values.iva, "currency"),
      intereses: checkEmpty(values.intereses, "currency"),
      aportes: checkEmpty(values.aportes, "currency"),
      t: checkEmpty(values.t, "currency"),
      "otros-gastos": checkEmpty(values["otros-gastos"], "currency"),
    };

    dispatch(newPago(payload));
    setFormActions(actions);
  };

  useEffect(() => {
    setActions(actions);
    dispatch(getStatics());
    !judiciales && dispatch(getAllJudiciales({ page: 0, limit: 5000 }));
    !prejudiciales && dispatch(getAllPrejudiciales({ page: 0, limit: 5000 }));
    setLayout({ ...layout, showBackButton: true });
  }, []);

  useEffect(() => {
    if (newPagoStates.success && !!newPagoInformation) {
      toast.success("👌🏼 Pago creado correctamente");
      setTimeout(() => {
        formActions?.resetForm();
        dispatch(resetNewPago());
        navigate(`/dashboard/pagos/${newPagoInformation["item-id"]}`, {
          replace: true,
        });
      }, 1000);
    }

    if (newPagoStates.error) {
      toast.error(`😱 No se ha podido crear el pago`);
      setTimeout(() => {
        dispatch(resetNewPago());
      }, 2000);
    }
  }, [newPagoStates, newPagoInformation]);

  return (
    <Formik
      initialValues={{ ...initialValues }}
      onSubmit={(values, actions) => {
        if (onSubmit) onSubmit({ values, actions });
      }}
      validateOnChange={formSubmmited}
      validateOnBlur={false}
      validationSchema={schema}
      enableReinitialize
    >
      {({
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        resetForm,
      }) => {
        console.log({ values, errors });
        return (
          <form
            className="theme-form"
            onSubmit={(event) => {
              setFormSubmmited(true);
              handleSubmit(event);
            }}
            id="new-pago-form"
          >
            <Grid.ContainerFluid>
              <Grid.Row>
                <Grid.Col lg={10} offset={{ lg: 1 }}>
                  <Grid.Row>
                    <Grid.Col lg={6} offset={{ lg: 1 }}>
                      <Grid.Row>
                        <Grid.Col>
                          <Tag2>
                            <span>TOTAL</span>
                            <span>
                              {argentinaPesos(values["monto-total"], {
                                showCurrency: true,
                              })}
                            </span>
                          </Tag2>
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <h3>Informacion</h3>
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <ReactSelect
                            name="tipo"
                            disabled={newPagoStates.loading}
                            error={errors["tipo"]}
                            touched={touched["tipo"]}
                            items={pagoStatics?.categories?.tipo?.map(
                              (tipo) => ({
                                label: tipo.text,
                                value: tipo.id,
                              })
                            )}
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Tipo *",
                              marginBottom: 24,
                              loading: pagoStaticsStates.loading,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      {(values["tipo"] === type.JUDICIAL ||
                        values["tipo"] === type.PREJUDICIAL) && (
                        <Grid.Row>
                          <Grid.Col>
                            <ReactSelect
                              disabled={newPagoStates.loading}
                              name="caso"
                              error={errors["caso"]}
                              touched={touched["caso"]}
                              items={[
                                ...(values["tipo"] === type.JUDICIAL &&
                                !!judiciales?.hints
                                  ? judiciales?.hints?.map((judicial) => ({
                                      label: judicial.caratula,
                                      value: judicial["item-id"],
                                    }))
                                  : []),
                                ...(values["tipo"] === type.PREJUDICIAL &&
                                !!prejudiciales?.hints
                                  ? prejudiciales?.hints?.map(
                                      (prejudicial) => ({
                                        label: prejudicial.title,
                                        value: prejudicial["item-id"],
                                      })
                                    )
                                  : []),
                              ]}
                              placeholder="Seleccione"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              options={{
                                label: "Caso/Contrato *",
                                marginBottom: 24,
                                loading:
                                  judicialesStates.loading ||
                                  prejudicialesStates.loading,
                              }}
                            />
                          </Grid.Col>
                        </Grid.Row>
                      )}

                      <Grid.Row>
                        <Grid.Col>
                          <Input
                            name="referencia"
                            disabled={newPagoStates.loading}
                            error={errors["referencia"]}
                            touched={touched["referencia"]}
                            value={values["referencia"]}
                            type="text"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Referencia *",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col>
                          <ReactSelect
                            readOnly
                            name="status"
                            disabled={newPagoStates.loading}
                            error={errors["status"]}
                            touched={touched["status"]}
                            items={status}
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Estado *",
                              marginBottom: 24,
                              loading: pagoStaticsStates.loading,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <Input
                            readOnly
                            name="fecha-origen-start"
                            disabled={newPagoStates.loading}
                            error={errors["fecha-origen-start"]}
                            touched={touched["fecha-origen-start"]}
                            value={values["fecha-origen-start"]}
                            type="date"
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Fecha origen *",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>

                        <Grid.Col>
                          <Input
                            name="date-start"
                            disabled={newPagoStates.loading}
                            error={errors["date-start"]}
                            touched={touched["date-start"]}
                            value={values["date-start"]}
                            type="date"
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Fecha vencimiento *",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col lg={6}>
                          <ReactSelect
                            name="motivo"
                            disabled={newPagoStates.loading}
                            error={errors["motivo"]}
                            touched={touched["motivo"]}
                            items={pagoStatics?.categories?.motivo?.map(
                              (motivo) => ({
                                label: motivo.text,
                                value: motivo.id,
                              })
                            )}
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Concepto *",
                              marginBottom: 24,
                              loading: pagoStaticsStates.loading,
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col>
                          <Input
                            name="beneficiario-2"
                            disabled={newPagoStates.loading}
                            error={errors["beneficiario-2"]}
                            touched={touched["beneficiario-2"]}
                            value={values["beneficiario-2"]}
                            type="text"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Beneficiario",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <ReactSelect
                            name="compania"
                            disabled={newPagoStates.loading}
                            error={errors["compania"]}
                            touched={touched["compania"]}
                            items={pagoStatics?.categories?.compania?.map(
                              (compania) => ({
                                label: compania.text,
                                value: compania.id,
                              })
                            )}
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Compañia *",
                              marginBottom: 24,
                              loading: pagoStaticsStates.loading,
                            }}
                          />
                        </Grid.Col>

                        <Grid.Col>
                          <Input
                            name="cbu"
                            disabled={newPagoStates.loading}
                            error={errors["cbu"]}
                            touched={touched["cbu"]}
                            value={values["cbu"]}
                            type="number"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "CBU",
                              marginBottom: 24,
                              before: (
                                <Icon
                                  icon={BankIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <Textarea
                            name="descripcion"
                            disabled={newPagoStates.loading}
                            error={errors["descripcion"]}
                            touched={touched["descripcion"]}
                            value={values["descripcion"]}
                            placeholder="Seleccione"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Descripcion",
                              marginBottom: 24,
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col>
                          <h3>Montos</h3>
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col lg={6}>
                          <Input
                            disabled={newPagoStates.loading}
                            name="capital"
                            error={errors["capital"]}
                            touched={touched["capital"]}
                            value={values["capital"]}
                            placeholder="00.000,00"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Capital",
                              marginBottom: 24,
                              before: (
                                <Icon
                                  icon={CurrencyIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={6}>
                          <Input
                            disabled={newPagoStates.loading}
                            name="honorario"
                            error={errors["honorario"]}
                            touched={touched["honorario"]}
                            value={values["honorario"]}
                            placeholder="00.000,00"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Honorarios",
                              marginBottom: 24,
                              before: (
                                <Icon
                                  icon={CurrencyIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col lg={6}>
                          <Input
                            disabled={newPagoStates.loading}
                            name="honorarios-conciliadormediador"
                            error={errors["honorarios-conciliadormediador"]}
                            touched={touched["honorarios-conciliadormediador"]}
                            value={values["honorarios-conciliadormediador"]}
                            placeholder="00.000,00"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Honorarios Conciliador",
                              marginBottom: 24,
                              before: (
                                <Icon
                                  icon={CurrencyIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={6}>
                          <Input
                            disabled={newPagoStates.loading}
                            name="iva"
                            error={errors["iva"]}
                            touched={touched["iva"]}
                            value={values["iva"]}
                            placeholder="00.000,00"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "IVA",
                              marginBottom: 24,
                              before: (
                                <Icon
                                  icon={CurrencyIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col lg={6}>
                          <Input
                            disabled={newPagoStates.loading}
                            name="intereses"
                            error={errors["intereses"]}
                            touched={touched["intereses"]}
                            value={values["intereses"]}
                            placeholder="00.000,00"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Intereses",
                              marginBottom: 24,
                              before: (
                                <Icon
                                  icon={CurrencyIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={6}>
                          <Input
                            disabled={newPagoStates.loading}
                            name="aportes"
                            error={errors["aportes"]}
                            touched={touched["aportes"]}
                            value={values["aportes"]}
                            placeholder="00.000,00"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Aportes",
                              marginBottom: 24,
                              before: (
                                <Icon
                                  icon={CurrencyIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col lg={6}>
                          <Input
                            disabled={newPagoStates.loading}
                            name="t"
                            error={errors["t"]}
                            touched={touched["t"]}
                            value={values["t"]}
                            placeholder="00.000,00"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Tasas",
                              marginBottom: 24,
                              before: (
                                <Icon
                                  icon={CurrencyIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col lg={6}>
                          <Input
                            disabled={newPagoStates.loading}
                            name="otros-gastos"
                            error={errors["otros-gastos"]}
                            touched={touched["otros-gastos"]}
                            value={values["otros-gastos"]}
                            placeholder="00.000,00"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Otros gastos",
                              marginBottom: 24,
                              before: (
                                <Icon
                                  icon={CurrencyIcon}
                                  size="14px"
                                  color="black"
                                />
                              ),
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>
                    </Grid.Col>
                  </Grid.Row>
                </Grid.Col>
              </Grid.Row>
            </Grid.ContainerFluid>

            <div style={{ height: "50px" }} />

            <Center horizontal="center" vertical="center">
              <Submit
                isSubmmiting={newPagoStates.loading}
                form="new-pago-form"
                color="Primary"
                options={{
                  type: "filled",
                  skin: "primary",
                  size: "lg",
                  marginBottom: "0px",
                }}
              >
                Confirmar
              </Submit>

              <Link
                href="/dashboard/pagos/list"
                options={{
                  type: "outline",
                  skin: "danger",
                  size: "lg",
                  marginBottom: "0px",
                }}
                style={{ marginLeft: "10px" }}
              >
                Cancelar
              </Link>
            </Center>

            <div style={{ height: "50px" }} />
          </form>
        );
      }}
    </Formik>
  );
};

const states = ({ pagoStore, judicialStore, prejudicialStore }) => {
  const { states: newPagoStates, data: newPagoInformation } = pagoStore.newOne;
  const { data: pagoStatics, states: pagoStaticsStates } = pagoStore.statics;
  const { data: judiciales, states: judicialesStates } = judicialStore.all;
  const { data: prejudiciales, states: prejudicialesStates } =
    prejudicialStore.all;

  return {
    newPagoStates,
    newPagoInformation,
    pagoStaticsStates,
    pagoStatics,
    judiciales,
    judicialesStates,
    prejudiciales,
    prejudicialesStates,
  };
};

export default connect(states)(Component);
