export const GET_STATICS_PREJUDICIAL = "GET_STATICS_PREJUDICIAL";
export const GET_STATICS_PREJUDICIAL_SUCCESS =
  "GET_STATICS_PREJUDICIAL_SUCCESS";
export const GET_STATICS_PREJUDICIAL_ERROR = "GET_STATICS_PREJUDICIAL_ERROR";
export const RESET_GET_STATICS_PREJUDICIAL = "RESET_GET_STATICS_PREJUDICIAL";

export interface GetStaticsPrejudicial {
  type: typeof GET_STATICS_PREJUDICIAL;
  payload: null;
}

export interface GetStaticsPrejudicialSuccess {
  type: typeof GET_STATICS_PREJUDICIAL_SUCCESS;
  payload: any;
}

export interface GetStaticsPrejudicialError {
  type: typeof GET_STATICS_PREJUDICIAL_ERROR;
  payload: string | boolean;
}

export interface ResetGetStaticsPrejudicial {
  type: typeof RESET_GET_STATICS_PREJUDICIAL;
  payload: null;
}

export interface State {
  data: any;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | GetStaticsPrejudicial
  | GetStaticsPrejudicialSuccess
  | GetStaticsPrejudicialError
  | ResetGetStaticsPrejudicial;
