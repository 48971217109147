import { Loading, Text, Cup } from "./styles";

const Component = () => {
  return (
    <Loading>
      <Cup />
      <Text>Estamos cargando el contenido de la página.</Text>
      <Text>Aguarda unos instantes.</Text>
    </Loading>
  );
};

export default Component;
