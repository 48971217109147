import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { AuthContext } from "../../provider/AuthProvider";

const Component = ({ children }: { children: JSX.Element }) => {
  let { isAuthenticated } = React.useContext(AuthContext);
  let location = useLocation();

  if (!isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/sign-in" replace state={{ from: location }} />;
  }

  return children;
};

export default Component;
