import { put, call, all, takeLatest } from "redux-saga/effects";
import services from "../../services";

import {
  NewPrejudicial,
  NEW_PREJUDICIAL,
  NEW_PREJUDICIAL_SUCCESS,
  NEW_PREJUDICIAL_ERROR,
} from "../types/prejudicial/new";

import {
  EditPrejudicial,
  EDIT_PREJUDICIAL,
  EDIT_PREJUDICIAL_SUCCESS,
  EDIT_PREJUDICIAL_ERROR,
} from "../types/prejudicial/edit";

import {
  DeletePrejudicial,
  DELETE_PREJUDICIAL,
  DELETE_PREJUDICIAL_SUCCESS,
  DELETE_PREJUDICIAL_ERROR,
} from "../types/prejudicial/delete";

import {
  GetOnePrejudicial,
  GET_ONE_PREJUDICIAL,
  GET_ONE_PREJUDICIAL_SUCCESS,
  GET_ONE_PREJUDICIAL_ERROR,
} from "../types/prejudicial/getOne";

import {
  SelectOnePrejudicial,
  SELECT_ONE_PREJUDICIAL,
  SELECT_ONE_PREJUDICIAL_SUCCESS,
  SELECT_ONE_PREJUDICIAL_ERROR,
} from "../types/prejudicial/selectOne";

import {
  GetAllPrejudiciales,
  GET_ALL_PREJUDICIALES,
  GET_ALL_PREJUDICIALES_SUCCESS,
  GET_ALL_PREJUDICIALES_ERROR,
} from "../types/prejudicial/getAll";

import {
  GetCommentsPrejudicial,
  GET_COMMENTS_PREJUDICIAL,
  GET_COMMENTS_PREJUDICIAL_SUCCESS,
  GET_COMMENTS_PREJUDICIAL_ERROR,
} from "../types/prejudicial/getComments";

import {
  GetStaticsPrejudicial,
  GET_STATICS_PREJUDICIAL,
  GET_STATICS_PREJUDICIAL_SUCCESS,
  GET_STATICS_PREJUDICIAL_ERROR,
} from "../types/prejudicial/statics";

function* getAllPrejudiciales({ payload }) {
  try {
    const { response, error } = yield call(
      services.prejudicial.getAll,
      payload
    );

    if (error) {
      yield put({
        type: GET_ALL_PREJUDICIALES_ERROR,
        payload: error,
      });
    } else {
      yield put({ type: GET_ALL_PREJUDICIALES_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: GET_ALL_PREJUDICIALES_ERROR,
      payload: "Error inesperado al obtener todos los prejudiciales.",
    });
  }
}

function* getOnePrejudicial({ payload }) {
  try {
    const { response, error } = yield call(
      services.prejudicial.getOne,
      payload
    );

    if (error) {
      yield put({
        type: GET_ONE_PREJUDICIAL_ERROR,
        payload: error,
      });
    } else {
      yield put({ type: GET_ONE_PREJUDICIAL_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: GET_ONE_PREJUDICIAL_ERROR,
      payload: "Error inesperado al obtener el prejudicial seleccionado.",
    });
  }
}

function* selectOnePrejudicial({ payload }) {
  try {
    const { response, error } = yield call(
      services.prejudicial.getOne,
      payload
    );

    if (error) {
      yield put({
        type: SELECT_ONE_PREJUDICIAL_ERROR,
        payload: error,
      });
    } else {
      yield put({ type: SELECT_ONE_PREJUDICIAL_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: SELECT_ONE_PREJUDICIAL_ERROR,
      payload: "Error inesperado al seleccionar el prejudicial.",
    });
  }
}

function* newPrejudicial({ payload }) {
  try {
    const { response, error } = yield call(services.prejudicial.new, payload);

    if (error) {
      yield put({
        type: NEW_PREJUDICIAL_ERROR,
        payload: error,
      });
    } else {
      yield all([
        put({ type: NEW_PREJUDICIAL_SUCCESS, payload: response }),
        put({ type: GET_ALL_PREJUDICIALES, payload: null }),
      ]);
    }
  } catch {
    yield put({
      type: NEW_PREJUDICIAL_ERROR,
      payload: "Error inesperado al crear un nuevo prejudicial.",
    });
  }
}

function* editPrejudicial({ payload }) {
  try {
    const { response, error } = yield call(services.prejudicial.edit, payload);

    if (error) {
      yield put({
        type: EDIT_PREJUDICIAL_ERROR,
        payload: error,
      });
    } else {
      yield all([
        put({ type: EDIT_PREJUDICIAL_SUCCESS, payload: response }),
        put({ type: GET_ALL_PREJUDICIALES, payload: null }),
      ]);
    }
  } catch {
    yield put({
      type: EDIT_PREJUDICIAL_ERROR,
      payload: "Error inesperado al editar el prejudicial seleccionado.",
    });
  }
}

function* deletePrejudicial({ payload }) {
  try {
    const { response, error } = yield call(
      services.prejudicial.delete,
      payload
    );

    if (error) {
      yield put({
        type: DELETE_PREJUDICIAL_ERROR,
        payload: error,
      });
    } else {
      yield all([
        put({ type: DELETE_PREJUDICIAL_SUCCESS, payload: response }),
        put({ type: GET_ALL_PREJUDICIALES, payload: null }),
      ]);
    }
  } catch {
    yield put({
      type: DELETE_PREJUDICIAL_ERROR,
      payload: "Error inesperado al eliminar el prejudicial seleccionado.",
    });
  }
}

function* getCommentsPrejudicial({ payload }) {
  try {
    const { response, error } = yield call(
      services.prejudicial.getComments,
      payload
    );

    if (error) {
      yield put({
        type: GET_COMMENTS_PREJUDICIAL_ERROR,
        payload: error,
      });
    } else {
      yield put({ type: GET_COMMENTS_PREJUDICIAL_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: GET_COMMENTS_PREJUDICIAL_ERROR,
      payload:
        "Error inesperado al ontener comentarios del prejudicial seleccionado.",
    });
  }
}

function* getStatics() {
  try {
    const { response, error } = yield call(services.prejudicial.getStatics);

    if (error) {
      yield put({
        type: GET_STATICS_PREJUDICIAL_ERROR,
        payload: error,
      });
    } else {
      yield put({ type: GET_STATICS_PREJUDICIAL_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: GET_STATICS_PREJUDICIAL_ERROR,
      payload: "Error inesperado al ontener los estaticos de prejudicial.",
    });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllPrejudiciales>(
    GET_ALL_PREJUDICIALES,
    getAllPrejudiciales
  );
  yield takeLatest<GetOnePrejudicial>(GET_ONE_PREJUDICIAL, getOnePrejudicial);
  yield takeLatest<EditPrejudicial>(EDIT_PREJUDICIAL, editPrejudicial);
  yield takeLatest<DeletePrejudicial>(DELETE_PREJUDICIAL, deletePrejudicial);
  yield takeLatest<NewPrejudicial>(NEW_PREJUDICIAL, newPrejudicial);
  yield takeLatest<SelectOnePrejudicial>(
    SELECT_ONE_PREJUDICIAL,
    selectOnePrejudicial
  );
  yield takeLatest<GetCommentsPrejudicial>(
    GET_COMMENTS_PREJUDICIAL,
    getCommentsPrejudicial
  );
  yield takeLatest<GetStaticsPrejudicial>(GET_STATICS_PREJUDICIAL, getStatics);
}
