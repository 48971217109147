import {
  Navbar,
  Nav,
  NavItems,
  NavItem,
  NavLink,
  ButtonLink,
  DropdownMenu,
  DropdownItem,
  SubNavLink,
  Caret,
} from "./styles";
import SweetAlert from "sweetalert2";
import Icon from "../../../../Icon";
import constants from "../../../../../constants";
import { connect, useDispatch } from "react-redux";
import { UserDto } from "../../../../../types/user.dto";
import { signOut } from "../../../../../store/actions/auth";
import { useState } from "react";

import LogoutIcon from "../../../../../assets/icons/sign-out-alt.svg";
import CaretDown from "../../../../../assets/icons/caret-down.svg";

interface ComponentProps {
  user: UserDto;
}

const Component = ({ user }: ComponentProps) => {
  const [activeDropdown, setActiveDropdown] = useState<string>();
  const [activeDropdownChildren, setActiveDropdownChildren] =
    useState<string>();
  const dispatch = useDispatch();

  return (
    <Navbar>
      <Nav>
        <NavItems>
          <>
            {constants.menu.map((menuItem, menuIndex) => {
              const hasChildren =
                !!menuItem.children && menuItem.children?.length > 0;
              const isActiveDropdown =
                menuItem.link === activeDropdown ||
                menuItem.link === activeDropdownChildren;
              return (
                <NavItem key={`menu-${menuIndex}`}>
                  {hasChildren ? (
                    <ButtonLink
                      onClick={(event) => {
                        setActiveDropdown(
                          isActiveDropdown ? "" : menuItem.link
                        );
                      }}
                      className={isActiveDropdown ? "active" : ""}
                    >
                      <span>
                        {!!menuItem.icon && (
                          <Icon
                            icon={menuItem.icon}
                            color="#FFFFFF"
                            size="18px"
                          />
                        )}

                        {menuItem.title}
                      </span>

                      {hasChildren && (
                        <Caret className="caret">
                          <Icon icon={CaretDown} color="#FFFFFF" size="18px" />
                        </Caret>
                      )}
                    </ButtonLink>
                  ) : (
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "active" : undefined
                      }
                      to={`/dashboard${menuItem.link}`}
                      onClick={() => {
                        setActiveDropdown("");
                        setActiveDropdownChildren(menuItem.link);
                      }}
                    >
                      <span>
                        {!!menuItem.icon && (
                          <Icon
                            icon={menuItem.icon}
                            color="#FFFFFF"
                            size="18px"
                          />
                        )}

                        {menuItem.title}
                      </span>

                      {hasChildren && (
                        <Caret className="caret">
                          <Icon icon={CaretDown} color="#FFFFFF" size="18px" />
                        </Caret>
                      )}
                    </NavLink>
                  )}

                  {hasChildren && (
                    <DropdownMenu
                      amountItems={menuItem.children?.length}
                      isActiveDropdown={isActiveDropdown}
                    >
                      {menuItem.children?.map((submenuItem, submenuIndex) => (
                        <DropdownItem key={`submenu-item-${submenuIndex}`}>
                          <SubNavLink
                            className={({ isActive }) =>
                              isActive ? "active" : undefined
                            }
                            onClick={() =>
                              setActiveDropdownChildren(menuItem.link)
                            }
                            to={`/dashboard${menuItem.link}${submenuItem.link}`}
                          >
                            {submenuItem.title}
                          </SubNavLink>
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  )}
                </NavItem>
              );
            })}
          </>
        </NavItems>
      </Nav>

      <Nav style={{ marginBottom: "20px" }}>
        <NavItems>
          <NavItem>
            <ButtonLink
              className="no-styles"
              onClick={() => {
                SweetAlert.fire({
                  title: "¿Estás seguro que quieres salír?",
                  text: "",
                  showCancelButton: false,
                  confirmButtonText: "Confirmar",
                  allowEscapeKey: false,
                  preConfirm: () => {
                    dispatch(signOut());
                  },
                });
              }}
            >
              <Icon icon={LogoutIcon} color="#FFFFFF" size="18px" />
              Salir
            </ButtonLink>
          </NavItem>
        </NavItems>
      </Nav>
    </Navbar>
  );
};

const states = ({ userStore }) => {
  const { data: user } = userStore;
  return {
    user,
  };
};

export default connect(states)(Component);
