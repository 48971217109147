import {
  EditFacturacionOutDto,
  EditFacturacionInDto,
} from "../../../types/facturacion.dto";

export const EDIT_FACTURACION = "EDIT_FACTURACION";
export const EDIT_FACTURACION_SUCCESS = "EDIT_FACTURACION_SUCCESS";
export const EDIT_FACTURACION_ERROR = "EDIT_FACTURACION_ERROR";
export const RESET_EDIT_FACTURACION = "RESET_EDIT_FACTURACION";

export interface EditFacturacion {
  type: typeof EDIT_FACTURACION;
  payload: EditFacturacionOutDto;
}

export interface EditFacturacionSuccess {
  type: typeof EDIT_FACTURACION_SUCCESS;
  payload: EditFacturacionInDto;
}

export interface EditFacturacionError {
  type: typeof EDIT_FACTURACION_ERROR;
  payload: string | boolean;
}

export interface ResetEditFacturacion {
  type: typeof RESET_EDIT_FACTURACION;
  payload: null;
}
export interface State {
  data: EditFacturacionInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | EditFacturacion
  | EditFacturacionSuccess
  | EditFacturacionError
  | ResetEditFacturacion;
