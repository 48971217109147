import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";

import AuthProvider from "../provider/AuthProvider";
import ThemeProvider from "../provider/ThemeProvider";
import ActionsProvider from "../provider/ActionsProvider";
import LayoutProvider from "../provider/LayoutProvider";

import ScrollToTop from "../components/ScrollTop";
import RequireAuth from "../components/RequireAuth";

import SignIn from "./SignIn";
import Activation from "./Activation";

import Page404 from "./NotFound";

import DashboardLayout from "../components/Layout/Dashboard";
import AuthLayout from "../components/Layout/Auth";

import Agenda from "./Dashboard/Agenda";
import Judicial from "./Dashboard/Judiciales";
import Prejudicial from "./Dashboard/Prejudicial";
import Profile from "./Dashboard/Profile";
import Pagos from "./Dashboard/Pagos";
import Facturacion from "./Dashboard/Facturacion";

const Component = () => {
  return (
    <AuthProvider>
      <ThemeProvider>
        <BrowserRouter>
          <ScrollToTop useLocation={useLocation} />

          <Routes>
            <Route
              path="/dashboard/*"
              element={
                <RequireAuth>
                  <ActionsProvider useLocation={useLocation}>
                    <LayoutProvider useLocation={useLocation}>
                      <DashboardLayout />
                    </LayoutProvider>
                  </ActionsProvider>
                </RequireAuth>
              }
            >
              <Route index element={<Navigate to={"profile"} />} />

              <Route path="profile" element={<Profile />} />

              <Route path="judiciales/*">
                <Route index element={<Navigate to="list" />} />
                <Route path="new" element={<Judicial.New />} />
                <Route path="list" element={<Judicial.List />} />
                <Route path=":id" element={<Judicial.Preview />} />
                <Route path="*" element={<Page404 />} />
              </Route>

              <Route path="prejudiciales/*">
                <Route index element={<Navigate to="list" />} />
                <Route path="new" element={<Prejudicial.New />} />
                <Route path="list" element={<Prejudicial.List />} />
                <Route path=":id" element={<Prejudicial.Preview />} />
                <Route path="*" element={<Page404 />} />
              </Route>

              <Route path="agenda/*">
                <Route index element={<Navigate to="list" />} />
                <Route path="list" element={<Agenda.List />} />
                <Route path="new" element={<Agenda.New />} />
                <Route path=":id" element={<Agenda.Preview />} />
                <Route path="*" element={<Page404 />} />
              </Route>

              <Route path="pagos/*">
                <Route index element={<Navigate to="list" />} />
                <Route path="list" element={<Pagos.List />} />
                <Route path="new" element={<Pagos.New />} />
                <Route path=":id" element={<Pagos.Preview />} />
                <Route path="*" element={<Page404 />} />
              </Route>

              <Route path="facturacion/*">
                <Route index element={<Navigate to="list" />} />
                <Route path="list" element={<Facturacion.List />} />
                <Route path="new" element={<Facturacion.New />} />
                <Route path=":id" element={<Facturacion.Preview />} />
                <Route path="*" element={<Page404 />} />
              </Route>

              <Route path="*" element={<Page404 />} />
            </Route>

            <Route path="/" element={<AuthLayout />}>
              <Route index element={<Navigate to="sign-in" />} />
              <Route path="/sign-in" element={<SignIn />} />
              <Route path="/sign-up" element={<SignIn />} />
              <Route path="/account-recovery" element={<SignIn />} />
              <Route path="/activation" element={<Activation />} />
              <Route path="*" element={<Page404 />} />
            </Route>

            <Route path="*" element={<Page404 />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default Component;
