import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Layout, Content, Sidebar, SubHeader } from "./styles";
import Avatar from "../../Avatar";
import GoBack from "../../GoBack";
import Header from "./components/Header";
import Actions from "./components/Actions";
import Filters from "./components/Filters";
import Menu from "./components/Menu";
import Title from "./components/Title";
import Breadcrumb from "./components/Breadcrumb";
import Logo from "./components/Logo";
import { useActions } from "../../../provider/ActionsProvider";
import { useLayout } from "../../../provider/LayoutProvider";
import { useEffect } from "react";
import { Center } from "../../StyledComponents";

interface ComponentProps {}

const Component = ({}: ComponentProps) => {
  const { layout } = useLayout();

  return (
    <Layout
      className={layout.sidebarOpened ? "sidebar-opened" : "sidebar-closed"}
    >
      <Header>
        <Avatar />
      </Header>

      {layout.showSubHeader && (
        <SubHeader>
          <Center style={{ justifyContent: "flex-start" }}>
            {layout.showBackButton && <GoBack />}
            <div style={{ flexShrink: 0 }}>
              <Title />
              <Breadcrumb />
            </div>
          </Center>

          <Center style={{ justifyContent: "flex-end" }}>
            <Filters />
            <Actions />
          </Center>
        </SubHeader>
      )}

      <Content fullFillContent={layout.fullFillContent}>
        <Outlet />
      </Content>

      <Sidebar>
        <Logo />
        <Menu />
      </Sidebar>

      <ToastContainer />
    </Layout>
  );
};

export default Component;
