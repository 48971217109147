import {
  NewJudicialOutDto,
  EditJudicialOutDto,
  DeleteJudicialOutDto,
  GetAllJudicialesOutDto,
  GetOneJudicialOutDto,
  SelectOneJudicialOutDto,
} from "../../types/judicial.dto";

import {
  NEW_JUDICIAL,
  NewJudicial,
  RESET_NEW_JUDICIAL,
  ResetNewJudicial,
} from "../types/judicial/new";

import {
  EDIT_JUDICIAL,
  EditJudicial,
  RESET_EDIT_JUDICIAL,
  ResetEditJudicial,
} from "../types/judicial/edit";

import {
  DELETE_JUDICIAL,
  RESET_DELETE_JUDICIAL,
  ResetDeleteJudicial,
  DeleteJudicial,
} from "../types/judicial/delete";

import {
  GET_ONE_JUDICIAL,
  GetOneJudicial,
  RESET_GET_ONE_JUDICIAL,
  ResetGetOneJudicial,
} from "../types/judicial/getOne";

import {
  SELECT_ONE_JUDICIAL,
  SelectOneJudicial,
  RESET_SELECT_ONE_JUDICIAL,
  ResetSelectOneJudicial,
} from "../types/judicial/selectOne";

import {
  GET_ALL_JUDICIALES,
  GetAllJudiciales,
  RESET_GET_ALL_JUDICIALES,
  ResetGetAllJudiciales,
} from "../types/judicial/getAll";

import {
  GET_STATICS_JUDICIAL,
  GetStaticsJudicial,
  RESET_GET_STATICS_JUDICIAL,
  ResetGetStaticsJudicial,
} from "../types/judicial/statics";

export const getOneJudicial = (
  payload: GetOneJudicialOutDto
): GetOneJudicial => {
  return { type: GET_ONE_JUDICIAL, payload };
};

export const resetGetOneJudicial = (): ResetGetOneJudicial => {
  return { type: RESET_GET_ONE_JUDICIAL, payload: null };
};

export const selectOneJudicial = (
  payload: SelectOneJudicialOutDto
): SelectOneJudicial => {
  return { type: SELECT_ONE_JUDICIAL, payload };
};

export const resetSelectOneJudicial = (): ResetSelectOneJudicial => {
  return { type: RESET_SELECT_ONE_JUDICIAL, payload: null };
};

export const getAllJudiciales = (
  payload: GetAllJudicialesOutDto
): GetAllJudiciales => {
  return { type: GET_ALL_JUDICIALES, payload };
};

export const resetGetAllJudiciales = (): ResetGetAllJudiciales => {
  return { type: RESET_GET_ALL_JUDICIALES, payload: null };
};

export const newJudicial = (payload: NewJudicialOutDto): NewJudicial => {
  return { type: NEW_JUDICIAL, payload };
};

export const resetNewJudicial = (): ResetNewJudicial => {
  return { type: RESET_NEW_JUDICIAL, payload: null };
};

export const editJudicial = (payload: EditJudicialOutDto): EditJudicial => {
  return { type: EDIT_JUDICIAL, payload };
};

export const resetEditJudicial = (): ResetEditJudicial => {
  return { type: RESET_EDIT_JUDICIAL, payload: null };
};

export const deleteJudicial = (
  payload: DeleteJudicialOutDto
): DeleteJudicial => {
  return { type: DELETE_JUDICIAL, payload };
};

export const resetDeleteJudicial = (): ResetDeleteJudicial => {
  return { type: RESET_DELETE_JUDICIAL, payload: null };
};

export const getStatics = (): GetStaticsJudicial => {
  return { type: GET_STATICS_JUDICIAL, payload: null };
};

export const resetGetStatics = (): ResetGetStaticsJudicial => {
  return { type: RESET_GET_STATICS_JUDICIAL, payload: null };
};
