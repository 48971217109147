import { useEffect, useState } from "react";
import { Transition } from "react-transition-group";

import Icon from "../Icon";
import CommentNew from "./components/CommentNew";
import CommentsList from "./components/CommentsList";
import FileNew from "./components/FileNew";
import FilesList from "./components/FilesList";

import { Tabs, Tab, TabContent, Animation, TabsList, Amount } from "./styles";
import CommentsIcon from "../../assets/icons/comment-alt.svg";
import ActivityIcon from "../../assets/icons/time-past.svg";
import FilesIcon from "../../assets/icons/box.svg";
import { EntitiesEnum } from "../../types/entities.enum";
import { connect, useDispatch } from "react-redux";
import { getAllFiles, resetGetAllFiles } from "../../store/actions/files";
import { FileDto } from "../../types/file.dto";
import {
  getAllActivities,
  resetGetAllActivities,
} from "../../store/actions/activity";
import { ActivityDto } from "../../types/activity.dto";

interface ComponentProps {
  entity: EntitiesEnum;
  entityId?: string;
  allComments: ActivityDto[];
  allFiles: FileDto[];
  allFilesStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  oneFile: string;
  oneFileStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
}

const Component = ({
  entity,
  entityId,
  allComments,
  allFiles,
  allFilesStates,
  oneFile,
  oneFileStates,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (!!entity && !!entityId) {
      dispatch(getAllFiles({ entity, entityId }));
      dispatch(getAllActivities({ entity, entityId }));
    }
  }, [entity, entityId]);

  useEffect(() => {
    dispatch(resetGetAllFiles());
    dispatch(resetGetAllActivities());
  }, []);

  return (
    <>
      <Tabs>
        <TabsList>
          <Tab type="button" onClick={() => setActiveTab(0)}>
            <TabContent active={activeTab === 0}>
              <Icon icon={CommentsIcon} size="20px" color="black" /> Comentarios
              {!!allComments && <Amount>{allComments?.length}</Amount>}
            </TabContent>
          </Tab>
          <Tab type="button" onClick={() => setActiveTab(1)}>
            <TabContent active={activeTab === 1}>
              <Icon icon={FilesIcon} size="20px" color="black" /> Archivos
              {!!allFiles && <Amount>{allFiles?.length}</Amount>}
            </TabContent>
          </Tab>
          {/* <Tab type="button" onClick={() => setActiveTab(2)}>

            <TabContent active={activeTab === 2}>
              <Icon icon={ActivityIcon} size="20px" color="black" />
              Actividad
            </TabContent>

          </Tab> */}
        </TabsList>

        {activeTab === 0 && (
          <CommentsList
            setShowForm={setShowForm}
            entity={entity}
            entityId={entityId}
          />
        )}

        {activeTab === 1 && (
          <FilesList
            setShowForm={setShowForm}
            entity={entity}
            entityId={entityId}
          />
        )}
        {/* {activeTab === 2 && <Activity />} */}
      </Tabs>

      <Transition in={showForm} timeout={350} mountOnEnter>
        {(state) => (
          <Animation
            name="fadeInSlideUp"
            className={`animation-${state}`}
            width="100%"
            duration={0.4}
          >
            {activeTab === 0 && (
              <CommentNew
                setShowForm={setShowForm}
                entity={entity}
                entityId={entityId}
              />
            )}

            {activeTab === 1 && (
              <FileNew
                setShowForm={setShowForm}
                entity={entity}
                entityId={entityId}
              />
            )}
            {/* {activeTab === 2 && <Activity />} */}
          </Animation>
        )}
      </Transition>
    </>
  );
};

const state = ({ filesStore, activityStore }) => {
  const { data: allFiles, states: allFilesStates } = filesStore.all;
  const { data: allComments, states: allCommentsStates } = activityStore.all;

  const { data: oneFile, states: oneFileStates } = filesStore.one;

  return {
    allFiles,
    allFilesStates,
    allComments,
    allCommentsStates,
    oneFile,
    oneFileStates,
  };
};

export default connect(state)(Component);
