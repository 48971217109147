import {
  initialState,
  Actions,
  State,
  NEW_PAGO,
  NEW_PAGO_SUCCESS,
  NEW_PAGO_ERROR,
  RESET_NEW_PAGO,
} from "../../types/pago/new";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case NEW_PAGO:
      return {
        ...state,
        data: null,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case NEW_PAGO_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case NEW_PAGO_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: true,
          error: action.payload,
          success: false,
        },
      };
    case RESET_NEW_PAGO:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducers;
