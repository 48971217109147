import { GetCommentOutDto, GetCommentInDto } from "../../../types/comment.dto";

export const GET_COMMENTS_PAGO = "GET_COMMENTS_PAGO";
export const GET_COMMENTS_PAGO_SUCCESS = "GET_COMMENTS_PAGO_SUCCESS";
export const GET_COMMENTS_PAGO_ERROR = "GET_COMMENTS_PAGO_ERROR";
export const RESET_GET_COMMENTS_PAGO = "RESET_GET_COMMENTS_PAGO";

export interface GetCommentsPago {
  type: typeof GET_COMMENTS_PAGO;
  payload: GetCommentOutDto;
}

export interface GetCommentsPagoSuccess {
  type: typeof GET_COMMENTS_PAGO_SUCCESS;
  payload: GetCommentInDto[];
}

export interface GetCommentsPagoError {
  type: typeof GET_COMMENTS_PAGO_ERROR;
  payload: string | boolean;
}

export interface ResetGetCommentsPago {
  type: typeof RESET_GET_COMMENTS_PAGO;
  payload: null;
}

export interface State {
  data: GetCommentInDto[] | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | GetCommentsPago
  | GetCommentsPagoSuccess
  | GetCommentsPagoError
  | ResetGetCommentsPago;
