import styled from "styled-components";
import ButtonUi from "../../Button";

export const Button = styled(ButtonUi)<{ skin }>`
  &:disabled {
    background-color: ${(props) => {
      return props.theme.colors.Primary;
    }};
  }
`;
