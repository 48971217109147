import styled from "styled-components";
import { Animation as AnimationUI } from "../Animation";

export const Tabs = styled("div")`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`;

export const TabsList = styled("div")`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 1px solid #dee2e6;
`;

export const Tab = styled("button")`
  padding: 0px;
  margin: 0px;
  border: 0px;
  background: none;
  margin: 0px 10px;
  cursor: pointer;

  &:first-child {
    margin-left: 0px;
  }

  &:last-child {
    margin-right: 0px;
  }
`;

export const TabContent = styled("div")<{ active: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  padding-bottom: 15px;
  color: ${({ active, theme }) => (active ? theme.colors.Primary : "inherit")};

  & > * {
    margin-bottom: 5px;
    color: ${({ active, theme }) =>
      active ? theme.colors.Primary : "inherit"};

    &:before {
      ${({ active, theme }) =>
        active ? "background-color:" + theme.colors.Primary : ""};
    }
  }

  &:before {
    position: absolute;
    bottom: -1px;
    left: 0px;
    content: "";
    height: 3px;
    width: 100%;
    background-color: ${({ active, theme }) =>
      active ? theme.colors.Primary : ""};
  }
`;

export const Animation = styled(AnimationUI)`
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(255 255 255 / 80%);
  border-radius: 10px;
  padding: 15px;
  backdrop-filter: blur(10px);
`;

export const Amount = styled("div")`
  font-size: 11px;
  min-width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.Primary};
  color: white;
  position: absolute;
  top: -5px;
  right: 50%;
  transform: translate(calc(100% + 4px), 0px);
  padding: 0px 4px;
`;
