import * as Yup from "yup";

export const schema = Yup.object().shape({
  nombre: Yup.string().required("Este campo es requerido."),
  apellido: Yup.string().required("Este campo es requerido."),
});

interface FormValues {
  nombre: string;
  apellido: string;
}

export const initialValues: FormValues = {
  nombre: "",
  apellido: "",
};
