import styled, { keyframes } from "styled-components";

export const Loading = styled("div")`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const animloader = keyframes`
0% {
    box-shadow: 2px 0px rgba(226, 0, 20, 0), 12px 0px rgba(226, 0, 20, 0.3), 20px 0px rgba(226, 0, 20, 0);
  }
      50% {
    box-shadow: 2px -5px rgba(226, 0, 20, 0.5), 12px -3px rgba(226, 0, 20, 0.5), 20px -2px rgba(226, 0, 20, 0.6);
  }
      100% {
    box-shadow: 2px -8px rgba(226, 0, 20, 0), 12px -5px rgba(226, 0, 20, 0), 20px -5px rgba(226, 0, 20, 0);
  }
`;

export const Cup = styled("div")`
  width: 48px;
  height: 40px;
  margin-top: 30px;
  margin-bottom: 30px;
  display: inline-block;
  position: relative;
  background: ${({ theme }) => theme.colors.Primary};
  border-radius: 15% 15% 35% 35%;
}

&:after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 45px;
  top: 8px;
  border: 4px solid ${({ theme }) => theme.colors.Primary};;
  width: 16px;
  height: 20px;
  border-radius: 0 4px 4px 0;
}

&:before {
  content: '';  
  position: absolute;
  width: 1px;
  height: 10px;
  color: ${({ theme }) => theme.colors.Primary};;
  top: -15px;
  left: 11px;
  box-sizing: border-box;
  animation: ${animloader} 1s ease infinite;
}
`;

export const Text = styled("p")`
  margin: 0px;
`;
