import { put, call, all, takeLatest } from "redux-saga/effects";
import services from "../../services";

import {
  NewFile,
  NEW_FILE,
  NEW_FILE_SUCCESS,
  NEW_FILE_ERROR,
} from "../types/files/new";

import {
  EditFile,
  EDIT_FILE,
  EDIT_FILE_SUCCESS,
  EDIT_FILE_ERROR,
} from "../types/files/edit";

import {
  GetOneFile,
  GET_ONE_FILE,
  GET_ONE_FILE_SUCCESS,
  GET_ONE_FILE_ERROR,
} from "../types/files/getOne";

import {
  GetAllFiles,
  GET_ALL_FILES,
  GET_ALL_FILES_SUCCESS,
  GET_ALL_FILES_ERROR,
} from "../types/files/getAll";

import {
  DeleteFile,
  DELETE_FILE,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_ERROR,
} from "../types/files/delete";

function* newFile({ payload }) {
  try {
    const { response, error } = yield call(services.files.new, payload);

    if (error) {
      yield put({
        type: NEW_FILE_ERROR,
        payload: error,
      });
    } else {
      yield all([
        put({ type: NEW_FILE_SUCCESS, payload: response }),
        put({ type: GET_ALL_FILES, payload }),
      ]);
    }
  } catch {
    yield put({
      type: NEW_FILE_ERROR,
      payload: "Error inesperado al crear un nuevo archivo.",
    });
  }
}

function* editFile({ payload }) {
  try {
    const { response, error } = yield call(services.files.edit, payload);

    if (error) {
      yield put({
        type: EDIT_FILE_ERROR,
        payload: error,
      });
    } else {
      yield put({ type: EDIT_FILE_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: EDIT_FILE_ERROR,
      payload: "Error inesperado al obtener archivos del files seleccionado.",
    });
  }
}

function* getFile({ payload }) {
  try {
    const { response, error } = yield call(services.files.getOne, payload);

    if (error) {
      yield put({
        type: GET_ONE_FILE_ERROR,
        payload: error,
      });
    } else {
      yield put({ type: GET_ONE_FILE_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: GET_ONE_FILE_ERROR,
      payload: "Error inesperado al obtener el archivo seleccionado.",
    });
  }
}

function* getAllFiles({ payload }) {
  try {
    const { response, error } = yield call(services.files.getAll, payload);

    if (error) {
      yield put({
        type: GET_ALL_FILES_ERROR,
        payload: error,
      });
    } else {
      yield put({ type: GET_ALL_FILES_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: GET_ALL_FILES_ERROR,
      payload: "Error inesperado al obtener los archivos.",
    });
  }
}

function* deleteFile({ payload }) {
  try {
    const { response, error } = yield call(services.files.delete, payload);

    if (error) {
      yield put({
        type: DELETE_FILE_ERROR,
        payload: error,
      });
    } else {
      yield put({ type: DELETE_FILE_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: DELETE_FILE_ERROR,
      payload: "Error inesperado al intentar eliminar el archivo.",
    });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetOneFile>(GET_ONE_FILE, getFile);

  yield takeLatest<GetAllFiles>(GET_ALL_FILES, getAllFiles);

  yield takeLatest<NewFile>(NEW_FILE, newFile);

  yield takeLatest<DeleteFile>(DELETE_FILE, deleteFile);

  yield takeLatest<EditFile>(EDIT_FILE, editFile);
}
