import { Loading, Text } from "./styles";
import GirlSittingAtTheComputer from "../../assets/images/girl-sitting-at-the-computer.svg";

const Component = () => {
  return (
    <Loading>
      <img src={GirlSittingAtTheComputer} alt="Cargando" />
      <Text>No se encontro contenido.</Text>
    </Loading>
  );
};

export default Component;
