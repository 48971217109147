import { Formik, useFormikContext } from "formik";
import {
  initialValues,
  schema,
} from "../../../../../../constants/form/judicial/filters";
import ReactSelect from "../../../../../../components/Form/ReactSelect";
import Input from "../../../../../../components/Form/Input";
import { Filters } from "./styles";
import { connect, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Center } from "../../../../../../components/StyledComponents";
import { getAllPrejudiciales } from "../../../../../../store/actions/prejudicial";

const AutoSubmit = () => {
  const formik = useFormikContext();

  useEffect(() => {
    if (formik.values !== formik.initialValues) {
      formik.submitForm();
    }
  }, [formik.values]);
  return null;
};

const Component = ({
  prejudicialStatics,
  prejudicialStaticsStates,
  setActiveFilters,
}) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const dispatch = useDispatch();
  const [filters, setFilters] = useState<{ [key: string]: string } | null>(
    null
  );

  const onSubmit = ({ values, actions }) => {
    Object.keys(values).forEach((key) =>
      values[key] === undefined ? delete values[key] : {}
    );
    !!setActiveFilters && setActiveFilters(values);
    localStorage.setItem("prejudiciales-filters", JSON.stringify(values));
    dispatch(getAllPrejudiciales({ page: 0, limit: 10, filters: values }));
  };

  useEffect(() => {
    const filtersSaved = localStorage.getItem("prejudiciales-filters");

    if (filtersSaved) {
      const filtersObj = JSON.parse(filtersSaved);
      setFilters(filtersObj);
    }
  }, []);

  useEffect(() => {
    if (filters) {
      dispatch(getAllPrejudiciales({ page: 0, limit: 10, filters }));
    }
  }, [filters]);

  return (
    <Filters>
      {/* <span style={{ marginRight: "10px" }}>Filtrar: </span> */}

      <Formik
        initialValues={{ ...initialValues, ...(filters && filters) }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        // validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="new-judicial-form"
            >
              <Center horizontal="center" vertical="center">
                <ReactSelect
                  name="estado"
                  error={errors["estado"]}
                  touched={touched["estado"]}
                  items={[
                    {
                      label: "Todos estados",
                      value: undefined,
                    },
                    ...(prejudicialStatics?.categories
                      ? prejudicialStatics?.categories?.estado?.map(
                          (estado) => ({
                            label: estado.text,
                            value: estado.id,
                          })
                        )
                      : []),
                  ]}
                  placeholder="Estado"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={{
                    marginBottom: 0,
                    loading: prejudicialStaticsStates.loading,
                  }}
                  style={{ minWidth: "150px" }}
                />

                <ReactSelect
                  name="tipo"
                  error={errors["tipo"]}
                  touched={touched["tipo"]}
                  items={[
                    {
                      label: "Todos tipos",
                      value: undefined,
                    },
                    ...(prejudicialStatics?.categories
                      ? prejudicialStatics?.categories?.tipo?.map((tipo) => ({
                          label: tipo.text,
                          value: tipo.id,
                        }))
                      : []),
                  ]}
                  placeholder="Tipo"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={{
                    marginBottom: 0,
                    loading: prejudicialStaticsStates.loading,
                  }}
                  style={{ minWidth: "150px" }}
                />

                <ReactSelect
                  name="compania"
                  error={errors["compania"]}
                  touched={touched["compania"]}
                  items={[
                    {
                      label: "Todos empleadores",
                      value: undefined,
                    },
                    ...(prejudicialStatics?.categories
                      ? prejudicialStatics?.categories?.compania?.map(
                          (compania) => {
                            return {
                              label: compania.text,
                              value: compania.id,
                            };
                          }
                        )
                      : []),
                  ]}
                  placeholder="Empleador real"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={{
                    marginBottom: 0,
                    loading: prejudicialStaticsStates.loading,
                  }}
                  style={{ minWidth: "150px" }}
                />

                <ReactSelect
                  name="responsabilidad"
                  error={errors["responsabilidad"]}
                  touched={touched["responsabilidad"]}
                  items={[
                    {
                      label: "Todos responsabilidad",
                      value: undefined,
                    },
                    ...(prejudicialStatics?.categories
                      ? prejudicialStatics?.categories?.responsabilidad?.map(
                          (responsabilidad) => {
                            return {
                              label: responsabilidad.text,
                              value: responsabilidad.id,
                            };
                          }
                        )
                      : []),
                  ]}
                  placeholder="Responsabilidad"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={{
                    marginBottom: 0,
                    loading: prejudicialStaticsStates.loading,
                  }}
                  style={{ minWidth: "150px" }}
                />

                <Input
                  name="fecha-de-audiencia-start"
                  error={errors["fecha-de-audiencia-start"]}
                  touched={touched["fecha-de-audiencia-start"]}
                  value={values["fecha-de-audiencia-start"]}
                  type="date"
                  placeholder="Fecha audiencia"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={{
                    marginBottom: 0,
                  }}
                  style={{ minWidth: "150px" }}
                />

                <AutoSubmit />
              </Center>
            </form>
          );
        }}
      </Formik>
    </Filters>
  );
};

const states = ({ prejudicialStore }) => {
  const { data: prejudicialStatics, states: prejudicialStaticsStates } =
    prejudicialStore.statics;

  return {
    prejudicialStatics,
    prejudicialStaticsStates,
  };
};

export default connect(states)(Component);
