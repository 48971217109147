export const usersTypes = [
  {
    label: "Administrador",
    value: 1,
  },
  {
    label: "Project Manager",
    value: 2,
  },
  {
    label: "Institutional Partner",
    value: 3,
  },
  {
    label: "Empresa",
    value: 4,
  },
  {
    label: "Recruiters y Agencias de RRHH",
    value: 5,
  },
];

export default usersTypes;
