import Link from "../../../../Link";
import Button from "../../../../Button";
import Submit from "../../../../Form/Submit";
import { useActions } from "../../../../../provider/ActionsProvider";
import { Actions } from "./styles";
import { useLayout } from "../../../../../provider/LayoutProvider";

const Component = () => {
  const { layout } = useLayout();
  const { actions } = useActions();

  if (!layout.showActions) {
    return null;
  }

  return (
    <>
      {actions.length > 0 && (
        <Actions>
          {actions.map((action, index) => {
            if (action.component === "link" && !!action.href) {
              return (
                <Link
                  key={`dashboard-action-${index}`}
                  options={action.options}
                  href={action.href}
                >
                  {action.label}
                </Link>
              );
            }

            if (action.component === "button" && !!action.onClick) {
              return (
                <Button
                  key={`dashboard-action-${index}`}
                  options={action.options}
                  onClick={action.onClick}
                >
                  {action.label}
                </Button>
              );
            }

            if (action.component === "submit" && action.form) {
              return (
                <Submit
                  key={`dashboard-action-${index}`}
                  form={action.form}
                  isSubmmiting={action.isLoading || false}
                  options={action.options}
                  onClick={action.onClick}
                >
                  {action.label}
                </Submit>
              );
            }
          })}
        </Actions>
      )}
    </>
  );
};

export default Component;
