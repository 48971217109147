import {
  GET_USER_MAIN_INFORMATION,
  GetUserInformation,
} from "../types/user/get-information";
import {
  UpdateUserInformation,
  UPDATE_USER_MAIN_INFORMATION,
  ResetUpdateUserInformation,
  RESET_UPDATE_USER_MAIN_INFORMATION,
} from "../types/user/update-information";
import {
  RESET_USER_MAIN_INFORMATION,
  ResetUserInformation,
} from "../types/user/reset-information";
import {
  ChangePassword,
  CHANGE_PASSWORD,
  ResetChangePassword,
  RESET_CHANGE_PASSWORD,
} from "../types/user/change-password";

export const getUserInformation = (): GetUserInformation => {
  return { type: GET_USER_MAIN_INFORMATION, payload: null };
};

export const resetUserInformation = (): ResetUserInformation => {
  return { type: RESET_USER_MAIN_INFORMATION, payload: null };
};

export const updateUserInformation = (payload): UpdateUserInformation => {
  return { type: UPDATE_USER_MAIN_INFORMATION, payload };
};

export const resetUpdateUserInformation = () => {
  return { type: RESET_UPDATE_USER_MAIN_INFORMATION, payload: null };
};

export const changePassword = (payload): ChangePassword => {
  return { type: CHANGE_PASSWORD, payload };
};

export const resetChangePassword = (): ResetChangePassword => {
  return { type: RESET_CHANGE_PASSWORD, payload: null };
};
