import {
  SelectOneCalendarOutDto,
  SelectOneCalendarInDto,
} from "../../../types/calendar.dto";

export const SELECT_ONE_CALENDAR = "SELECT_ONE_CALENDAR";
export const SELECT_ONE_CALENDAR_SUCCESS = "SELECT_ONE_CALENDAR_SUCCESS";
export const SELECT_ONE_CALENDAR_ERROR = "SELECT_ONE_CALENDAR_ERROR";
export const RESET_SELECT_ONE_CALENDAR = "RESET_SELECT_ONE_CALENDAR";

export interface SelectOneCalendar {
  type: typeof SELECT_ONE_CALENDAR;
  payload: SelectOneCalendarOutDto;
}

export interface SelectOneCalendarSuccess {
  type: typeof SELECT_ONE_CALENDAR_SUCCESS;
  payload: SelectOneCalendarInDto;
}

export interface SelectOneCalendarError {
  type: typeof SELECT_ONE_CALENDAR_ERROR;
  payload: string | boolean;
}

export interface ResetSelectOneCalendar {
  type: typeof RESET_SELECT_ONE_CALENDAR;
  payload: null;
}

export interface State {
  data: SelectOneCalendarInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | SelectOneCalendar
  | SelectOneCalendarSuccess
  | SelectOneCalendarError
  | ResetSelectOneCalendar;
