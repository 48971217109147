const checkEmpty = (
  value: any,
  type?: "number" | "string" | "currency" | "dni"
) => {
  const returnValue = value === "" ? null : value;

  if (!returnValue) {
    return returnValue;
  }

  switch (type) {
    case "currency":
      return parseFloat(returnValue.replaceAll(".", "").replace(",", "."));
    case "dni":
      return returnValue.replaceAll(".0000", "");
    case "string":
      return String(returnValue);
    default:
      return returnValue;
  }
};

export default checkEmpty;
