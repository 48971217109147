import React, { useEffect, useState } from "react";
import moment from "moment";
import SweetAlert from "sweetalert2";
import { connect, useDispatch } from "react-redux";
import Loader from "../../components/LogoLoader";
import {
  getUserInformation,
  resetUserInformation,
} from "../../store/actions/user";
import services from "../../services/";
import { Center } from "../../components/StyledComponents";

/**
 * User inactive tolerance
 * Amount of time in minutes that a user can be inactive
 */
const USER_INACTIVE_TOLERANCE = 100;

const startTimer = (coutdownDate, callback = () => {}, id) => {
  const now = new Date().getTime();
  const distance = coutdownDate - now;

  // const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  // const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  // const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  // const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  if (distance > 0) {
    //console.log(`${id} Remaining Time: ${days}:${hours}:${minutes}:${seconds}`);
  } else {
    callback();
  }
};

export const AuthContext = React.createContext({
  isAuthenticated: false as boolean,
});

export const Component = ({
  children,
  signInError,
  signInSuccess,
  signOutSuccess,
}) => {
  const dispatch = useDispatch();
  const [checkingAuth, setCheckingAuth] = useState<boolean>(true);
  const [isInactive, setIsInactive] = useState<boolean>(false);
  const [hasValidToken, setHasValidToken] = useState(false);

  let userInterval;
  let tokenInterval;

  const fireMessage = () => {
    SweetAlert.fire({
      title: "Hemos notado que te fuiste",
      text: "Por seguridad finalizamos tu sesión.",
      showCancelButton: false,
      confirmButtonText: "Confirmar",
      allowEscapeKey: false,
      preConfirm: () => {
        window.location.assign("/sign-in");
      },
    });
  };

  const whenTokenExpired = (intervalInstance) => {
    clearInterval(intervalInstance);
    if (isInactive) {
      services.auth.signOut();
      fireMessage();
    } else {
      services.auth.refreshToken();
    }
  };

  const whenUserIsInactive = (intervalInstance) => {
    clearInterval(intervalInstance);
    setIsInactive(true);
    services.auth.signOut();
    fireMessage();
  };

  const createInactiveIntervalInstance = () => {
    const fecha = moment().add(USER_INACTIVE_TOLERANCE, "minutes").format();
    const coutdownDate = new Date(fecha).getTime();
    const instance = setInterval(
      () =>
        startTimer(coutdownDate, () => whenUserIsInactive(instance), "User"),
      1000
    );
    return () => {
      clearInterval(instance);
    };
  };

  const createTokenExpirationIntervalInstance = () => {
    const coutdownDate = services.auth.getTokenExpirationTime()?.getTime();
    const instance = setInterval(
      () => startTimer(coutdownDate, () => whenTokenExpired(instance), "Token"),
      1000
    );
    return () => {
      clearInterval(instance);
    };
  };

  const userAuthenticated = () => {
    services.auth.userAuthenticated((isValidToken) => {
      if (isValidToken) {
        setHasValidToken(true);
        dispatch(getUserInformation());
      } else {
        setHasValidToken(false);
        dispatch(resetUserInformation());
      }

      setCheckingAuth(false);
    });
  };

  useEffect(() => {
    if (hasValidToken) {
      userInterval = createInactiveIntervalInstance();
      tokenInterval = createTokenExpirationIntervalInstance();
    }

    document.addEventListener("click", () => {
      if (typeof userInterval === "function") {
        userInterval();
        userInterval = createInactiveIntervalInstance();
      }

      if (typeof tokenInterval === "function") {
        tokenInterval();
        tokenInterval = createTokenExpirationIntervalInstance();
      }
    });

    return () => {
      document.removeEventListener("click", userInterval);
    };
  }, [hasValidToken]);

  useEffect(() => {
    userAuthenticated();
  }, [signInSuccess]);

  useEffect(() => {
    if (!!signInError) {
      setHasValidToken(false);
    }
  }, [signInError]);

  useEffect(() => {
    userAuthenticated();
  }, []);

  useEffect(() => {
    if (signOutSuccess) {
      window.location.href = "/";
    }
  }, [signOutSuccess]);

  return (
    <AuthContext.Provider value={{ isAuthenticated: hasValidToken }}>
      {checkingAuth ? (
        <Center horizontal="center" vertical="center">
          <Loader />
        </Center>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

const state = ({ authStore }) => {
  const {
    loading: signInLoading,
    error: signInError,
    success: signInSuccess,
  } = authStore.signin.states;
  const {
    loading: signOutLoading,
    error: signOutError,
    success: signOutSuccess,
  } = authStore.signout.states;
  return {
    signInLoading,
    signInError,
    signInSuccess,
    signOutLoading,
    signOutError,
    signOutSuccess,
  };
};

export default connect(state)(Component);
