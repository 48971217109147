import { GetOneFileInDto, GetOneFileOutDto } from "../../../types/file.dto";

export const GET_ONE_FILE = "GET_ONE_FILE";
export const GET_ONE_FILE_SUCCESS = "GET_ONE_FILE_SUCCESS";
export const GET_ONE_FILE_ERROR = "GET_ONE_FILE_ERROR";
export const RESET_GET_ONE_FILE = "RESET_GET_ONE_FILE";

export interface GetOneFile {
  type: typeof GET_ONE_FILE;
  payload: GetOneFileOutDto;
}

export interface GetOneFileSuccess {
  type: typeof GET_ONE_FILE_SUCCESS;
  payload: GetOneFileInDto;
}

export interface GetOneFileError {
  type: typeof GET_ONE_FILE_ERROR;
  payload: string | boolean;
}

export interface ResetGetOneFile {
  type: typeof RESET_GET_ONE_FILE;
  payload: null;
}

export interface State {
  data: GetOneFileInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | GetOneFile
  | GetOneFileSuccess
  | GetOneFileError
  | ResetGetOneFile;
