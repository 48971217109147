import action from "../helpers/rest-client";
import { SignInData, SignUpData } from "../types/auth.dto";

export class ErrorAuth extends Error {}

class Auth {
  public getToken() {
    return localStorage.getItem("token");
  }

  public async refreshToken() {
    const token = localStorage.getItem("token");

    if (!token) {
      return;
    }

    return action
      .Get({
        url: "/token",
      })
      .then((resp) => {
        const { token, user } = resp;
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user));
        return resp.user;
      })
      .catch((error) => {
        throw new ErrorAuth(error.message);
      });
  }

  public getTokenExpirationTime() {
    const token = localStorage.getItem("token");

    if (token) {
      const payload = JSON.parse(atob(token.split(".")[1]));
      return new Date(payload.exp * 1000);
    }
  }

  public getTokenRemainingTime() {
    const tokenExpirationTime = this.getTokenExpirationTime();

    if (tokenExpirationTime) {
      const now = new Date();
      return tokenExpirationTime.getTime() - now.getTime();
    }

    return 0;
  }

  public async userAuthenticated(callback) {
    const tokenRemainingTime = this.getTokenRemainingTime();
    let isAutenticated = false;

    if (tokenRemainingTime) {
      const fiveMinutes = 1000 * 60 * 5;
      isAutenticated = tokenRemainingTime > fiveMinutes;
    }

    if (callback) {
      callback(isAutenticated);
    }
  }

  public async signUp(payload: SignUpData) {
    return action
      .Post({
        url: "/register",
        body: payload,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorAuth(error.message);
      });
  }

  public async signIn({ email, password }: SignInData) {
    return action
      .Post({
        url: "/login",
        body: { email, password },
      })
      .then((resp) => {
        const { token, user } = resp.success?.result;
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user));
        return resp.user;
      })
      .catch((error) => {
        throw new ErrorAuth(error.message);
      });
  }

  public signOut() {
    localStorage.removeItem("token");
  }

  public recoverPassword(payload) {
    return action
      .Post({
        url: "/forgot",
        body: payload,
      })
      .then((resp) => {
        return resp.success;
      })
      .catch((error) => {
        return error;
      });
  }

  public resetPassword(payload) {
    return action
      .Post({
        url: "/reset",
        body: payload,
      })
      .then((resp) => {
        return resp.user;
      })
      .catch((error) => {
        throw new ErrorAuth(error.message);
      });
  }
}

const auth = new Auth();
export default auth;
