import action from "../helpers/rest-client";

export class ErrorJudicial extends Error {}

class Judicial {
  public new(payload) {
    return action
      .Post({
        url: "/judiciales",
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public edit({ itemId, ...rest }) {
    return action
      .Patch({
        url: `/judiciales/${itemId}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getAll({ page, limit, filters }) {
    const queryString = new URLSearchParams(filters).toString();

    return action
      .Get({
        url: `/judiciales?offset=${page * limit}&limit=${limit}${
          !!queryString ? `&${queryString}` : ""
        }`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getOne({ itemId }) {
    return action
      .Get({
        url: `/judiciales/${itemId}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public delete({ itemId }) {
    return action
      .Del({
        url: `/judiciales/${itemId}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getFiles({ itemId }) {
    return action
      .Get({
        url: `/judiciales/files/${itemId}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getComments({ itemId }) {
    return action
      .Get({
        url: `/judiciales/comments/${itemId}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getStatics() {
    return action
      .Get({
        url: "/judiciales/static",
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const judicial = new Judicial();
export default judicial;
