import * as Yup from "yup";

export const schema = Yup.object().shape({
  referncia: Yup.string().nullable().required("Campo obligatorio"),
  "date-start": Yup.date().nullable().required("Campo obligatorio"),
  "hour-start": Yup.string().nullable().required("Campo obligatorio"),
  "date-end": Yup.date()
    .when(
      "date-start",
      (startDate, schema) =>
        startDate &&
        schema.min(startDate, "La fecha de fin debe ser mayor a la de inicio.")
    )
    .nullable()
    .required("Campo obligatorio"),
  "hour-end": Yup.string().nullable().required("Campo obligatorio"),
  participants: Yup.array().nullable(),
  relationship: Yup.number().nullable(),
  direccion: Yup.string().nullable(),
  "tipo-2": Yup.number().nullable().required("Campo obligatorio"),
  "relationship-2": Yup.number()
    .nullable()
    .when("tipo-2", {
      is: 1,
      then: Yup.number()
        .typeError("Campo obligatorio")
        .required("Campo obligatorio"),
    })
    .when("tipo-2", {
      is: 2,
      then: Yup.number()
        .typeError("Campo obligatorio")
        .required("Campo obligatorio"),
    })
    .when("tipo-2", {
      is: 3,
      then: Yup.number()
        .typeError("Campo obligatorio")
        .required("Campo obligatorio"),
    }),
  type: Yup.number()
    .nullable()
    .typeError("Campo obligatorio")
    .required("Campo obligatorio"),
  notes: Yup.string().nullable(),
  "all-day": Yup.array().of(Yup.string()),
});

interface FormValues {
  referncia: string | null;
  type: number | null;
  "date-start": string | null;
  "hour-start": string | null;
  "date-end": string | null;
  "hour-end": string | null;
  "relationship-2"?: number | null;
  "tipo-2": number | null;
  participants: number[];
  relationship: number | null;
  direccion: string | null;
  notes: string | null;
  "all-day": string[];
}

export const initialValues: FormValues = {
  referncia: null,
  type: null,
  "date-start": null,
  "hour-start": "00:00",
  "date-end": null,
  "hour-end": "23:59",
  "relationship-2": null,
  "tipo-2": null,
  participants: [],
  relationship: null,
  direccion: null,
  notes: null,
  "all-day": ["true"],
};
