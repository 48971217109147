const values = [
  { label: "Inicio", value: 1 },
  { label: "Alegato", value: 2 },
  { label: "Prueba", value: 3 },
  {
    label: "Sentencia 1º instancia",
    value: 4,
  },
  {
    label: "Sentencia 2º instancia",
    value: 5,
  },
  { label: "Rebeldia", value: 6 },
];

export default values;
