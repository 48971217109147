import * as Yup from "yup";

export const schema = Yup.object().shape({
  title: Yup.string().nullable().required("Campo obligatorio"),
  "fecha-orig-start": Yup.string().nullable().required("Campo obligatorio"),
  "fecha-start": Yup.string().nullable().required("Campo obligatorio"),
  "compania-2": Yup.string().nullable().required("Campo obligatorio"),
  concepto: Yup.number().nullable().required("Campo obligatorio"),
  tipo: Yup.number().nullable().required("Campo obligatorio"),
  casocontraro: Yup.array()
    .when("tipo", {
      is: 1,
      then: Yup.array().min(1, "Campo obligatorio"),
    })
    .when("tipo", {
      is: 2,
      then: Yup.array().min(1, "Campo obligatorio"),
    }),

  "importe-2": Yup.mixed()
    .nullable()
    .test("currencyCheck", "Ingresa un monto correcto", (value) => {
      return (
        !value || (!!value && /^[+-]?\d{1,3}(.?\d{3})*(,\d+)?$/gm.test(value))
      );
    }),
  "nro-op": Yup.string().nullable(),
  "factura-ndeg": Yup.string().nullable(),
  status: Yup.number().nullable().nullable(),
  descripcion: Yup.string().nullable().nullable(),
});

interface FormValues {
  title: string;
  "fecha-orig-start": string | null;
  "fecha-start": string | null;
  "compania-2": string;
  concepto?: number;
  tipo?: number;
  casocontraro?: number[];
  "importe-2"?: string;
  "nro-op"?: string;
  "factura-ndeg"?: string;
  status?: number;
  descripcion?: string;
}

export const initialValues: FormValues = {
  title: "",
  "fecha-orig-start": null,
  "fecha-start": null,
  "compania-2": "",
  concepto: undefined,
  tipo: undefined,
  casocontraro: undefined,
  "importe-2": undefined,
  "nro-op": undefined,
  "factura-ndeg": undefined,
  status: undefined,
  descripcion: undefined,
};
