import { Icon } from "./styles";

interface ComponentProps {
  icon: string;
  color?: string;
  size?: string;
}

const defaultValues = {
  size: "24px",
};

const Component = (props: ComponentProps) => {
  const values = { ...defaultValues, ...props };
  return <Icon {...values} />;
};

export default Component;
