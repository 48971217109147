import {
  GetOneFacturacionOutDto,
  GetOneFacturacionInDto,
} from "../../../types/facturacion.dto";

export const GET_ONE_FACTURACION = "GET_ONE_FACTURACION";
export const GET_ONE_FACTURACION_SUCCESS = "GET_ONE_FACTURACION_SUCCESS";
export const GET_ONE_FACTURACION_ERROR = "GET_ONE_FACTURACION_ERROR";
export const RESET_GET_ONE_FACTURACION = "RESET_GET_ONE_FACTURACION";

export interface GetOneFacturacion {
  type: typeof GET_ONE_FACTURACION;
  payload: GetOneFacturacionOutDto;
}

export interface GetOneFacturacionSuccess {
  type: typeof GET_ONE_FACTURACION_SUCCESS;
  payload: GetOneFacturacionInDto;
}

export interface GetOneFacturacionError {
  type: typeof GET_ONE_FACTURACION_ERROR;
  payload: string | boolean;
}

export interface ResetGetOneFacturacion {
  type: typeof RESET_GET_ONE_FACTURACION;
  payload: null;
}

export interface State {
  data: GetOneFacturacionInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | GetOneFacturacion
  | GetOneFacturacionSuccess
  | GetOneFacturacionError
  | ResetGetOneFacturacion;
