import { all } from "redux-saga/effects";
import auth from "./auth";
import user from "./user";
import juicio from "./judicial";
import prejudicial from "./prejudicial";
import agenda from "./calendar";
import pago from "./pago";
import facturacion from "./facturacion";
import statics from "./statics";
import files from "./files";
import activity from "./activity";

export default function* rootSaga() {
  yield all([
    auth(),
    user(),
    juicio(),
    prejudicial(),
    agenda(),
    pago(),
    facturacion(),
    statics(),
    files(),
    activity(),
  ]);
}
