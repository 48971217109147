import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import Icon from "../../../../components/Icon";
import EmptyState from "../../../../components/EmptyState";
import Loading from "../../../../components/Loading";
import arrowLeft from "../../../../assets/icons/angle-left.svg";
import arrowRight from "../../../../assets/icons/angle-right.svg";
import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getAllCalendars } from "../../../../store/actions/calendar";
import { CalendarDto } from "../../../../types/calendar.dto";
import { useLayout } from "../../../../provider/LayoutProvider";
import { Action, useActions } from "../../../../provider/ActionsProvider";
import { useNavigate } from "react-router";
// Setup the localizer by providing the moment (or globalize, or Luxon) Object
// to the correct localizer.
require("moment/locale/es.js");
const localizer = momentLocalizer(moment); // or globalizeLocalizer

interface ComponentProps {
  events: {
    hints: CalendarDto[];
    pagination;
  };
}
const Component = ({ events }: ComponentProps) => {
  const { setActions } = useActions();
  const { layout, setLayout } = useLayout();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [calendarEvent, setCalendarEvent] = useState<{ end; start; title }[]>(
    []
  );

  const actions = useMemo(
    (): Action[] => [
      {
        component: "link",
        label: "Nuevo evento",
        href: "/dashboard/agenda/new",
        options: {
          type: "filled",
          size: "lg",
          skin: "primary",
        },
      },
    ],
    []
  );

  const getElement = (event) => {
    navigate(`/dashboard/agenda/${event["item-id"]}`);
  };

  useEffect(() => {
    dispatch(getAllCalendars());
  }, []);

  useEffect(() => {
    if (!!events && !!events?.hints) {
      setCalendarEvent(
        events?.hints
          ?.filter((event) => !!event["date-start"])
          .map((event) => {
            return {
              start: event["date-start"],
              end: event["date-end"] || event["date-start"],
              title: event.referncia || "Evento sin título",
              // ...((!event['date-end'] || event['date-end'] === event['date-start']) && {
              // }),
              allDay: true,
              data: event,
            };
          })
      );
    }

    setActions(actions);
    setLayout({ ...layout, fullFillContent: true, showBackButton: false });
  }, [events]);

  if (!events?.hints) {
    return <Loading />;
  }

  return (
    <>
      <Calendar
        onSelectEvent={(event, _target) => getElement(event.data)}
        localizer={localizer}
        events={calendarEvent}
        startAccessor="start"
        endAccessor="end"
        scrollToTime={moment().set({ h: 10, m: 0 }).toDate()}
        messages={{
          date: "Fecha",
          time: "Hora",
          event: "Evento",
          allDay: "Todo el dia",
          week: "Semana",
          work_week: "Semana Laboral",
          day: "Día",
          month: "Mes",
          previous: <Icon icon={arrowLeft} size="20px" color="black" />,
          next: <Icon icon={arrowRight} size="20px" color="black" />,
          yesterday: "Ayer",
          tomorrow: "Mañana",
          today: "Hoy",
          agenda: "Agenda",

          noEventsInRange: <EmptyState />,
          showMore: function showMore(total) {
            return "+".concat(total, " ver más");
          },
        }}
      />
    </>
  );
};

const state = ({ calendarStore }) => {
  const { data: events } = calendarStore.all;

  return {
    events,
  };
};

export default connect(state)(Component);
