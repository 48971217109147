import tipo from "./tipo";
import fuero from "./fuero";
import empleador from "../empleador";
import estado from "./estado";
import numeroAudiencias from "./numeroAudiencias";
import jurisdiccion from "./jurisdiccion";

const values = {
  tipo,
  fuero,
  empleador,
  estado,
  numeroAudiencias,
  jurisdiccion,
};

export default values;
