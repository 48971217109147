import {
  DeleteActivityOutDto,
  DeleteActivityInDto,
} from "../../../types/activity.dto";

export const DELETE_ACTIVITY = "DELETE_ACTIVITY";
export const DELETE_ACTIVITY_SUCCESS = "DELETE_ACTIVITY_SUCCESS";
export const DELETE_ACTIVITY_ERROR = "DELETE_ACTIVITY_ERROR";
export const RESET_DELETE_ACTIVITY = "RESET_DELETE_ACTIVITY";

export interface DeleteActivity {
  type: typeof DELETE_ACTIVITY;
  payload: DeleteActivityOutDto;
}

export interface DeleteActivitySuccess {
  type: typeof DELETE_ACTIVITY_SUCCESS;
  payload: DeleteActivityInDto;
}

export interface DeleteActivityError {
  type: typeof DELETE_ACTIVITY_ERROR;
  payload: string | boolean;
}

export interface ResetDeleteActivity {
  type: typeof RESET_DELETE_ACTIVITY;
  payload: null;
}

export interface State {
  data: DeleteActivityInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | DeleteActivity
  | DeleteActivitySuccess
  | DeleteActivityError
  | ResetDeleteActivity;
