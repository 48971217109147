import styled from "styled-components";
import { NavLink as NavLinkUi } from "react-router-dom";

export const Letter = styled("div")`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background: ${({ theme }) => theme.colors.LightGray};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.Gray};
`;

export const Link = styled(NavLinkUi)`
  display: block;
  width: 160px;
  height: 160px;
  font-weight: 700;
  text-decoration: none;
  padding: 2px;
  border-radius: 100%;
  background: ${({ theme }) => theme.colors.Gray};
`;
