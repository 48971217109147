import { call, put, takeLatest } from "redux-saga/effects";
import services from "../../services";

import {
  GET_ALL_STATICS,
  GET_ALL_STATICS_SUCCESS,
  GET_ALL_STATICS_ERROR,
} from "../types/statics";

function* getAllStatics() {
  try {
    const { response: clientes } = yield call(services.statics.getClientes, {
      page: 0,
      limit: 5000,
    });
    const { response: comerciales } = yield call(
      services.statics.getComerciales,
      {
        page: 0,
        limit: 5000,
      }
    );
    const { response: corresponsales } = yield call(
      services.statics.getCorresponsales,
      {
        page: 0,
        limit: 5000,
      }
    );
    const { response: jurisdicciones } = yield call(
      services.statics.getJurisdicciones,
      {
        page: 0,
        limit: 5000,
      }
    );
    const { response: mediadores } = yield call(
      services.statics.getMediadores,
      {
        page: 0,
        limit: 5000,
      }
    );
    const { response: sucursales } = yield call(
      services.statics.getSucursales,
      {
        page: 0,
        limit: 5000,
      }
    );
    const { response: contratos } = yield call(services.statics.getContratos, {
      page: 0,
      limit: 5000,
    });

    yield put({
      type: GET_ALL_STATICS_SUCCESS,
      payload: {
        clientes,
        comerciales,
        corresponsales,
        jurisdicciones,
        mediadores,
        sucursales,
        contratos,
      },
    });
  } catch (error) {
    yield put({ type: GET_ALL_STATICS_ERROR, payload: error });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(GET_ALL_STATICS, getAllStatics);
}
