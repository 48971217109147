import naturalezaObjeto from "./naturalezaObjeto";
import responsabilidad from "./responsabilidad";
import etapaProcesal from "./etapaProcesal";
import empleador from "../empleador";
import estado from "./estado";
import cobertura from "./cobertura";
import evaluacion from "./evaluacion";
import jurisdicciones from "./jurisdicciones";

const values = {
  naturalezaObjeto,
  responsabilidad,
  etapaProcesal,
  estado,
  cobertura,
  empleador,
  evaluacion,
  jurisdicciones,
};

export default values;
