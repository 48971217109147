import {
  SelectOnePrejudicialOutDto,
  SelectOnePrejudicialInDto,
} from "../../../types/prejudicial.dto";

export const SELECT_ONE_PREJUDICIAL = "SELECT_ONE_PREJUDICIAL";
export const SELECT_ONE_PREJUDICIAL_SUCCESS = "SELECT_ONE_PREJUDICIAL_SUCCESS";
export const SELECT_ONE_PREJUDICIAL_ERROR = "SELECT_ONE_PREJUDICIAL_ERROR";
export const RESET_SELECT_ONE_PREJUDICIAL = "RESET_SELECT_ONE_PREJUDICIAL";

export interface SelectOnePrejudicial {
  type: typeof SELECT_ONE_PREJUDICIAL;
  payload: SelectOnePrejudicialOutDto;
}

export interface SelectOnePrejudicialSuccess {
  type: typeof SELECT_ONE_PREJUDICIAL_SUCCESS;
  payload: SelectOnePrejudicialInDto;
}

export interface SelectOnePrejudicialError {
  type: typeof SELECT_ONE_PREJUDICIAL_ERROR;
  payload: string | boolean;
}

export interface ResetSelectOnePrejudicial {
  type: typeof RESET_SELECT_ONE_PREJUDICIAL;
  payload: null;
}

export interface State {
  data: SelectOnePrejudicialInDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | SelectOnePrejudicial
  | SelectOnePrejudicialSuccess
  | SelectOnePrejudicialError
  | ResetSelectOnePrejudicial;
