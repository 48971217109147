import action from "../helpers/rest-client";

export class ErrorJudicial extends Error {}

class Judicial {
  public new({ entity, entityId, comment }) {
    return action
      .Post({
        url: `/activity/${entity}/${entityId}`,
        body: { comment },
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public edit({ entity, entityId, commentId, comment }) {
    return action
      .Patch({
        url: `/activity/${entity}/${entityId}/${commentId}`,
        body: { comment },
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getAll({ entity, entityId }) {
    return action
      .Get({
        url: `/activity/${entity}/${entityId}`,
      })
      .then((response) => {
        return { response: response?.success?.result };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getOne({ entity, entityId, comment }) {
    return action
      .Get({
        url: `/activity/${entity}/${entityId}/${comment?.id}`,
      })
      .then((response) => {
        return { response: { data: response?.success?.result, ...comment } };
      })
      .catch((error) => {
        return { error };
      });
  }

  public delete({ entity, entityId, commentId }) {
    return action
      .Del({
        url: `/activity/${entity}/${entityId}/${commentId}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const judicial = new Judicial();
export default judicial;
