/**
 *  Colors
 */

const Red = "#e20014";

const LightGray = "#E5E5E5";
const Gray = "#222d32";
const DarkGray = "#0d1214";

const Black = "#2D3436";
const Black24 = "#C2C2C2";

const Danger = "#f76564";
const Warning = "#f3dd53";
const Success = "#26ab6d";
const Info = "#52ddfc";

const Primary = Red;

const White = "#FFFFFF";
const Transparent = "rgba(255,255,255,0)";

export const colors = {
  Black,
  Black24,
  White,
  Transparent,
  LightGray,
  Gray,
  DarkGray,
  Danger,
  Warning,
  Success,
  Info,
  Primary,
};
