import { Fragment, useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { AuthContext } from "../../provider/AuthProvider";

import { Center } from "../../components/StyledComponents";
import Grid from "../../components/Grid";
import Button from "../../components/Button";
import Form from "../../components/Form";
import Input from "../../components/Form/Input";
import Submit from "../../components/Form/Submit";
import ErrorMessage from "../../components/Form/ErrorMessage";

import {
  schema as signInSchema,
  initialValues as signInInitialValues,
} from "../../constants/form/auth/sign-in";

import {
  schema as recoveryCodeSchema,
  initialValues as recoveryCodeInitialValues,
} from "../../constants/form/auth/recovery-code";

import {
  schema as passwordRecoverySchema,
  initialValues as passwordRecoveryInitialValues,
} from "../../constants/form/auth/password-recovery";

import {
  recoverPassword,
  resetPassword,
  signIn,
} from "../../store/actions/auth";

interface CustomizedState {
  siginStates: {
    loading: boolean;
    success: boolean;
    error: boolean;
  };
  from: {
    pathname: string;
    search: string;
    hash: string;
  };
}

const Page = ({
  userStates,
  authStates,
  userInformation,
  recoverPasswordStates,
  recoverPasswordInformation,
  resetPasswordStates,
}) => {
  let navigate = useNavigate();
  let location = useLocation();
  let { isAuthenticated } = useContext(AuthContext);
  let from = (location.state as CustomizedState)?.from?.pathname || "/";

  const dispatch = useDispatch();

  const { loading: userLoading } = userStates;
  const { error: authError, loading: authLoading } = authStates;

  const [showLogin, setShowLogin] = useState(true);
  const [showPasswordRecovery, setShowPasswordRecovery] = useState(false);
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [accountToRecover, setAccountToRecover] = useState("");

  const appSignIn = ({ values, actions }) => {
    dispatch(signIn(values));
  };

  const sendRecoveryCode = ({ values, actions }) => {
    dispatch(recoverPassword(values));
    setAccountToRecover(values.email);
  };

  const changePassword = ({ values, actions }) => {
    dispatch(
      resetPassword({
        ...values,
        verification: recoverPasswordInformation?.result?.verification,
      })
    );
  };

  useEffect(() => {
    if (recoverPasswordStates.success) {
      setShowLogin(false);
      setShowPasswordRecovery(false);
      setShowPasswordChange(true);
    }
  }, [recoverPasswordStates]);

  useEffect(() => {
    if (resetPasswordStates.success) {
      setShowLogin(true);
      setShowPasswordRecovery(false);
      setShowPasswordChange(false);
    }
  }, [resetPasswordStates]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate(from, { replace: true });
    }
    return () => {};
  }, [isAuthenticated]);

  useEffect(() => {
    if (!!userInformation) {
      navigate("/dashboard", { replace: true });
    }
    return () => {};
  }, []);

  return (
    <>
      {showLogin && (
        <>
          <div>
            <h3>Bienvenido</h3>
            <p>Ingresá tu email y contraseña</p>
          </div>

          <Form
            id="login-form"
            rules={signInSchema}
            values={signInInitialValues}
            onSubmit={appSignIn}
          >
            <Input
              type="email"
              name="email"
              placeholder="Ingrese su e-mail"
              options={{ label: "E-mail", marginBottom: 24 }}
            />

            <Input
              type="password"
              name="password"
              placeholder="Ingrese su contraseña"
              options={{ label: "Contraseña", marginBottom: 24 }}
            />

            <Center style={{ marginBottom: "24px" }}>
              <ErrorMessage visible={!!authError}>
                Usuario o Contraseña incorrecta
              </ErrorMessage>
            </Center>

            <Center horizontal="center" vertical="center">
              <Submit
                form="login-form"
                isSubmmiting={authLoading || userLoading}
              >
                Iniciar sesión
              </Submit>
              <Button
                type="button"
                options={{
                  skin: "primary",
                  size: "lg",
                  type: "outline",
                }}
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  setShowLogin(false);
                  setShowPasswordRecovery(true);
                  setShowPasswordChange(false);
                }}
              >
                Olvide mi contraseña
              </Button>
            </Center>
          </Form>
        </>
      )}

      {showPasswordRecovery && (
        <>
          <div>
            <h3>Recupera tu contraseña</h3>
            <p>Ingresá tu e-mail </p>
          </div>

          <Form
            id="recover-code-form"
            rules={recoveryCodeSchema}
            values={recoveryCodeInitialValues}
            onSubmit={sendRecoveryCode}
          >
            <Input
              type="email"
              name="email"
              placeholder="Ingrese su e-mail"
              options={{ label: "E-mail" }}
            />

            <Center horizontal="center" vertical="center">
              <Submit
                form="recover-code-form"
                isSubmmiting={recoverPasswordStates.loading}
              >
                Recuperar contraseña
              </Submit>

              <Button
                type="button"
                options={{
                  skin: "primary",
                  size: "lg",
                  type: "outline",
                }}
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  setShowLogin(true);
                  setShowPasswordRecovery(false);
                  setShowPasswordChange(false);
                }}
              >
                cancelar
              </Button>
            </Center>
          </Form>
        </>
      )}

      {showPasswordChange && (
        <>
          <div>
            <h3>Recupera tu contraseña</h3>
            <p>
              Ingresá el código de recuperacion que enviamos a tu corre
              electrónico.
            </p>
          </div>

          <Form
            id="recover-password-form"
            rules={passwordRecoverySchema}
            values={{
              ...passwordRecoveryInitialValues,
              email: accountToRecover,
            }}
            onSubmit={changePassword}
          >
            <input type="hidden" name="verification" value="" />

            <Input
              type="text"
              readOnly
              name="email"
              options={{ label: "Email" }}
            />

            <Input
              type="text"
              name="code"
              placeholder="xxxxxx"
              options={{ label: "Código de recuperación" }}
              autocomplete="off"
            />

            <Input
              type="password"
              name="password"
              placeholder="Ingrese su contraseña"
              options={{ label: "Nueva contraseña" }}
              autocomplete="off"
            />

            <Input
              type="password"
              name="confirm-password"
              placeholder="Ingrese su contraseña"
              options={{ label: "Confirmar contraseña" }}
              autocomplete="off"
            />

            <Center horizontal="center" vertical="center">
              <Submit
                form="recover-password-form"
                isSubmmiting={resetPasswordStates.loading}
              >
                Cambiar contraseña
              </Submit>

              <Button
                type="button"
                options={{
                  skin: "primary",
                  size: "lg",
                  type: "outline",
                }}
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  setShowLogin(true);
                  setShowPasswordRecovery(false);
                  setShowPasswordChange(false);
                }}
              >
                cancelar
              </Button>
            </Center>
          </Form>
        </>
      )}
    </>
  );
};

const state = ({ authStore, userStore }) => {
  const { signin, recoverPassword, resetPassword } = authStore;
  const { states: authStates } = signin;
  const { states: recoverPasswordStates, data: recoverPasswordInformation } =
    recoverPassword;
  const { states: resetPasswordStates } = resetPassword;
  const { data: userInformation, states: userStates } = userStore.information;
  return {
    authStates,
    recoverPasswordStates,
    recoverPasswordInformation,
    resetPasswordStates,
    userStates,
    userInformation,
  };
};

export default connect(state)(Page);
