import { createGlobalStyle } from "styled-components";

export const WysiwygGlobalStyles = createGlobalStyle`
  .rdw-editor-toolbar {
    padding: 0px;
    margin: 0px;
    border: 0px; 
  }

  .rdw-editor-wrapper {
    padding: 0px 10px;
    width: 100%;
  }
`;
