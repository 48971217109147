const values = [
  { label: "Cobro de pesos", value: 1 },
  { label: "Ordinario", value: 2 },
  { label: "Despido", value: 3 },
  {
    label: "Accidente/Enfermedad - Acción Civil",
    value: 4,
  },
  { label: "Juicio Abreviado", value: 5 },
  { label: "Ejecución", value: 6 },
  {
    label: "Accidente - Despido",
    value: 7,
  },
  {
    label: "Certificados de Trabajo",
    value: 8,
  },
  { label: "Sumario", value: 9 },
  { label: "Sumarisimo", value: 10 },
  {
    label: "Daños y Perjuicios",
    value: 11,
  },
  {
    label: "Materia a categorizar",
    value: 12,
  },
  { label: "Medida Cautelar", value: 13 },
  { label: "Medida Cautelar", value: 14 },
  { label: "Daños y Perjuicios - Accidente de trabajo", value: 15 },
  {
    label: "Recupero Judicial",
    value: 16,
  },
];

export default values;
