const values = [
  {
    label: "Adecco Argentina S.A.",
    value: 1,
  },
  {
    label: "Adecco RR.HH. Arg. S.A.",
    value: 2,
  },
  {
    label: "Adecco Specialties S.A.",
    value: 3,
  },
];

export default values;
