import styled, { css } from "styled-components";

export const Header = styled("header")`
  grid-area: header;
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  padding: 14px 35px;
  background-color: ${({ theme }) => theme.colors.White};
`;

export const ToogleNavSidebar = styled("button")`
  padding: 0px;
  margin: 0px;
  border: none;
  background: none;
  cursor: pointer;
`;

export const ToggleFullScreen = styled("button")`
  padding: 0px;
  margin: 0px 0px 0px 10px;
  border: none;
  background: none;
  cursor: pointer;
`;

const section = css`
  display: flex;
  align-items: center;
`;
export const LeftSection = styled("div")`
  ${section}
  justify-content: flex-start;
`;
export const RightSection = styled("div")`
  ${section}
  justify-content: flex-end;
`;
