import * as Yup from "yup";

export const schema = Yup.object().shape({
  estado: Yup.number().nullable(),
  responsabilidad: Yup.number().nullable(),
  "compania-2": Yup.number().nullable(),
  "fecha-recepcion-demanda": Yup.string().nullable(),
  "estadoetapa-procesal": Yup.number().nullable(),
});

interface FormValues {
  estado?: number;
  responsabilidad?: number;
  "compania-2"?: number;
  "fecha-recepcion-demanda": string | null;
  "estadoetapa-procesal"?: number;
}

export const initialValues: FormValues = {
  estado: undefined,
  responsabilidad: undefined,
  "compania-2": undefined,
  "fecha-recepcion-demanda": null,
  "estadoetapa-procesal": undefined,
};
