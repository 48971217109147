import styled from "styled-components";
import cross from "../../assets/icons/cross.svg";
import minus from "../../assets/icons/minus.svg";
import plus from "../../assets/icons/plus.svg";
import edit from "../../assets/icons/edit.svg";

export const Page = styled("div")``;

export const PageTitle = styled("h1")`
  line-height: 52px;
  font-size: 40px;
  font-weight: 500;
  color: #273241;
  margin-top: 0px;
  margin-bottom: 40px;
  text-align: center;
`;

export const AddButton = styled("button")`
  border: 0;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.Primary};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:before {
    content: "";
    mask: url(${plus});
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: center;
    width: 100%;
    height: 100%;
    background-color: white;
  }
`;

export const EditButton = styled("button")`
  border: 0;
  width: 29px;
  height: 20px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;

  &:before {
    content: "";
    mask: url(${edit});
    mask-repeat: no-repeat;
    mask-size: cover;
    mask-position: center;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.Primary};
  }
`;

export const RemoveButton = styled("button")`
  border: 0;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.Danger};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:before {
    content: "";
    mask: url(${minus});
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: center;
    width: 100%;
    height: 100%;
    background-color: white;
  }
`;

export const Card = styled("div")`
  padding: 30px 20px;
  border-radius: 10px;
  box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
  position: relative;
`;

export const Tag = styled("div")`
  padding: 0px 10px;
  border-radius: 12px;
  line-height: 24px;
  height: 24px;
  width: auto;
  display: inline-flex;
`;

export const Tag2 = styled("div")`
  display: flex;
  aling-items: center;
  justify-content: space-between;
  background-color: #222d32;
  color: white;
  font-weight: 500;
  border-radius: 5px;
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 6px;
  width: 100%;
`;

const verticalPosition = {
  top: "flex-start",
  bottom: "flex-end",
  center: "center",
};

const horizontalPosition = {
  left: "flex-start",
  right: "flex-end",
  center: "center",
  space: "space-between",
};

export const Center = styled("div")<{
  vertical: "top" | "bottom" | "center";
  horizontal: "left" | "center" | "right" | "space";
  gap?: number;
  column?: boolean;
}>`
  display: flex;
  flex-direction: ${({ column }) => (column ? "column" : "row")};
  justify-content: ${({ horizontal }) => horizontalPosition[horizontal]};
  align-items: ${({ vertical }) => verticalPosition[vertical]};
  gap: ${({ gap }) => (gap ? gap : 0)}px;
`;
