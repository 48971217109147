const values = [
  { value: 6, label: "Acuerdos Espontáneos" },
  { value: 7, label: "Audiencia Conciliación ART.80" },
  { value: 8, label: "Audiencia Testimonial" },
  { value: 4, label: "Mediación" },
  { value: 1, label: "Interna Legales" },
  { value: 2, label: "Reunión cliente" },
  { value: 9, label: "Reunión" },
  { value: 10, label: "Vencimientos" },
  { value: 11, label: "Vista de causa" },
  { value: 12, label: "Otros" },
  { value: 13, label: "SECLO/OCLO/MT" },
];

export default values;
