import AgendaIcon from "../assets/icons/calendar.svg";
import EqualityIcon from "../assets/icons/equality.svg";
import BooksIcon from "../assets/icons/books.svg";
import Money from "../assets/icons/money-bill-wave.svg";
import Invoice from "../assets/icons/file-invoice-dollar.svg";

interface Item {
  link: string;
  title: string;
  icon?: string;
  children?: Item[];
}

const menu: Item[] = [
  {
    link: "/agenda",
    title: "Agenda",
    icon: AgendaIcon,
  },
  {
    link: "/judiciales",
    title: "Judiciales",
    icon: EqualityIcon,
  },
  {
    link: "/prejudiciales",
    title: "Prejudiciales",
    icon: BooksIcon,
  },
  {
    link: "/facturacion",
    title: "Facturación",
    icon: Invoice,
  },
  {
    link: "/pagos",
    title: "Pagos",
    icon: Money,
  },
];

export default menu;
