import AudioIcon from "../assets/icons/file-audio-regular.svg";
import ExcelIcon from "../assets/icons/file-excel-regular.svg";
import ImageIcon from "../assets/icons/file-image-regular.svg";
import VideoIcon from "../assets/icons/file-video-regular.svg";
import WordIcon from "../assets/icons/file-word-regular.svg";
import ZipIcon from "../assets/icons/file-zipper-regular.svg";
import PdfIcon from "../assets/icons/file-pdf-regular.svg";

const aceptedMimes = [
  "image/jpeg",
  "image/png",
  "application/pdf",
  "audio/mpeg",
  "audio/ogg",
  "audio/wav",
  "video/3gpp",
  // "video/quicktime",
  "video/x-msvideo",
  "video/x-ms-wmv",
  "video/mp4",
];

const icons = {
  "application/pdf": PdfIcon,
  "image/jpeg": ImageIcon,
  "image/png": ImageIcon,
  "audio/mpeg": AudioIcon,
  "audio/ogg": AudioIcon,
  "audio/wave": AudioIcon,
  "video/3gpp": VideoIcon,
  // "video/quicktime": VideoIcon,
  "video/x-msvideo": VideoIcon,
  "video/x-ms-wmv": VideoIcon,
  "video/mp4": VideoIcon,
};

const files = {
  aceptedMimes,
  icons,
};

export default files;
